import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import ShippingState from 'theme/store/checkout/types/ShippingState';

const mutations: MutationTree<ShippingState> = {
  [types.SHIPPING_SAVE_DETAILS] (state, shippingDetails) {
    state.shippingDetails = shippingDetails
  },
  [types.SHIPPING_LOAD_DETAILS] (state, storedShippingDetails) {
    state.shippingDetails = storedShippingDetails
  },
  [types.SHIPPING_UPDATE_PROP_VALUE] (state, payload) {
    state.shippingDetails[payload[0]] = payload[1]
  },
  [types.SHIPPING_ADD_METHOD] (state, shippingMethods) {
    state.shippingMethods.push(shippingMethods)
  },
  [types.SHIPPING_SET_METHODS] (state, shippingMethods = []) {
    state.shippingMethods = shippingMethods
  },
  [types.SHIPPING_CITIES_LIST] (state, cities) {
    state.cities = cities || []
  },
  [types.SHIPPING_SHOPS_LIST] (state, { shops }) {
    if (shops.length) {
      state.shops = shops.map(shop => {
        return { ...shop,
          delivery_methods: shop.delivery_methods || []
        }
      })
    } else {
      state.shops = []
    }
  },
  [types.SHIPPING_ALL_SHOPS_LIST] (state, { shops }) {
    if (shops.length) {
      state.shopsAll = shops.map(shop => {
        return { ...shop,
          delivery_methods: shop.delivery_methods || []
        }
      })
    } else {
      state.shopsAll = []
    }
  },
  [types.SHIPPING_GEO_LOCATE_SHOP_RESTORE] (state, restore) {
    state.geoLocateShop = restore
  },
  [types.SHIPPING_GEO_LOCATE_SHOP] (state, { coordinates, shops }) {
    if (typeof shops === 'object') {
      const keys = Object.keys(shops)

      if (!keys.length) {
        state.geoLocateShop = {
          coordinates,
          shops: {}
        }
      }

      for (const key of keys) {
        if (key.indexOf('delivery') !== -1) {
          const methods = shops[key].delivery_methods || []
          const parsedMethods = []

          methods.forEach(method => {
            if (typeof method === 'string') {
              parsedMethods.push(JSON.parse(method))
            }
          })

          shops[key].delivery_methods = parsedMethods
        }
      }
      state.geoLocateShop = {
        coordinates,
        shops
      }
    } else {
      state.geoLocateShop = {
        coordinates,
        shops: {}
      }
    }
  },
  [types.SHIPPING_FILLED_USER_DETAILS] (state, action) {
    state.shippingDetails.userFilledShippingInfo = action
  },
  [types.SHIPPING_LOADED_USER_DETAILS] (state, action) {
    state.shippingDetails.userLoadedShippingInfo = action
  },
  [types.SHIPPING_USER_GEO_POSITION] (state, cords) {
    state.userCoordinates = cords
  },
  [types.SHIPPING_LOADING_DETAIL_SAVE] (state, action) {
    state.loading.shippingDetailsSave = action === true
  },
  [types.SHIPPING_LOADING_SHOPS] (state, action) {
    state.loading.shopList = action === true
  },
  [types.SHIPPING_LOADED_DETAILS] (state, action) {
    state.loaded.shippingDetails = action === true
  },
  [types.SHIPPING_LOADED_SHOPS] (state, action) {
    state.loaded.shopList = action === true
  },
  [types.SHIPPING_DELIVERY_POLYGON] (state, { tms_id, list }) {
    state.deliveryPolygon = { tms_id, list }
  },
  [types.SHIPPING_SET_DEFAULT_CITY] (state, defaultCity) {
    state.defaultCity = defaultCity
  },
  [types.SHIPPING_SET_DEFAULT_SHOP] (state, defaultShop) {
    state.defaultShop = defaultShop
  }
}

export default mutations
