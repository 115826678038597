export default {
  name: 'ScrollLock',

  data () {
    return {
      body: document.querySelector('body')
    }
  },
  methods: {
    enable () {
      this.body.classList.add('body-fixed')
    },
    disable () {
      this.body.classList.remove('body-fixed')
    }
  }
};
