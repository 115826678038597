import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import CheckoutState from '../../types/CheckoutState'
import { Logger } from '@vue-storefront/core/lib/logger'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { getCurrentShopId } from 'theme/store/checkout/helpers'
import { getProductWeight } from 'theme/helpers/product'
import { CheckoutService } from 'theme/store/checkout/data-resolver/CheckoutService'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import config from 'config'
import { clearCheckoutSession } from 'theme/store/checkout/helpers/session'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as cartTypes from 'theme/store/cart/store/mutation-types';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { codes } from '$modules/shipping/config';

const actions: ActionTree<CheckoutState, RootState> = {
  async placeOrder ({ dispatch, rootGetters }, { order }) {
    try {
      const cartToken = rootGetters['cart/getCartToken']
      const userStorage = StorageManager.get('user')
      await userStorage.setItem('check-last-order', cartToken)

      const result = await dispatch('order/placeOrder', order, { root: true })

      if (!result.resultCode || result.resultCode === 200) {
        await dispatch('updateOrderTimestamp')
      }

      return result
    } catch (e) {
      sendToLogs(
        groups.Checkout,
        'checkout:placeOrder:catch:error',
        { message: e.message }
      )

      EventBus.$emit('checkout-failed-place')

      throw new Error(e)
    }
  },
  async afterPlaceOrderClear ({ dispatch }) {
    const userStorage = StorageManager.get('user')

    await dispatch('cart/clear', { sync: false }, { root: true })
    await dispatch('dropPassword')
    await dispatch('cart/deleteProductComment', null, { root: true })
    await userStorage.removeItem('check-last-order')
  },
  async checkLastOrder ({ dispatch, rootGetters }) {
    try {
      const userStorage = StorageManager.get('user')
      const cartSavedToken = await userStorage.getItem('check-last-order')

      if (!cartSavedToken) {
        return false
      }

      await dispatch('user/getLastOrder', {
        pageSize: 1,
        currentPage: 1,
        status: 'online',
        silent: true
      }, { root: true })

      const cartItems = rootGetters['cart/getCartItems']
      const lastOrder = rootGetters['user/lastOrder']

      const items = lastOrder?.items || []

      const lastOrderHash = items.map(i => `${i.sku}:${i.qty}`).sort().join('-')
      const cartOrderHash = cartItems.map(i => `${i.sku}:${i.qty}`).sort().join('-')

      if (lastOrderHash === cartOrderHash) {
        return lastOrder
      }

      return false
    } catch (e) {
      return false
    }
  },
  async updateOrderTimestamp () {
    const userStorage = StorageManager.get('user')
    await userStorage.setItem('last-cart-bypass-ts', new Date().getTime())
  },
  async dropPassword ({ commit, state }) {
    if (state.personalDetails.createAccount) {
      commit(types.CHECKOUT_DROP_PASSWORD)
    }
  },
  async setModifiedAt ({ commit }, timestamp) {
    commit(types.CHECKOUT_SET_MODIFIED_AT, timestamp)
  },
  async savePersonalDetails ({ commit }, personalDetails) {
    commit(types.CHECKOUT_SAVE_PERSONAL_DETAILS, personalDetails)
    sessionStorage.setItem('personalDetails', JSON.stringify(personalDetails))
  },
  async saveShippingDetails ({ commit }, shippingDetails) {
    commit(types.CHECKOUT_SAVE_SHIPPING_DETAILS, shippingDetails)
    sessionStorage.setItem('shippingDetails', JSON.stringify(shippingDetails))
  },
  async savePaymentDetails ({ commit }, paymentDetails) {
    commit(types.CHECKOUT_SAVE_PAYMENT_DETAILS, paymentDetails)
    sessionStorage.setItem('paymentDetails', JSON.stringify(paymentDetails))
  },
  async savePackagingDetails ({ commit }, packagingDetails) {
    commit(types.CHECKOUT_SAVE_PACKAGING_DETAILS, packagingDetails)
    sessionStorage.setItem('packagingDetails', JSON.stringify(packagingDetails))
  },
  async load ({ commit, dispatch }) {
    const checkoutStorage = StorageManager.get('checkout')

    const [
      personalDetails,
      paymentDetails,
      shippingDetails
    ] = await Promise.all([
      checkoutStorage.getItem('personal-details'),
      checkoutStorage.getItem('payment-details'),
      checkoutStorage.getItem('shipping-details')
    ])

    if (personalDetails) {
      commit(types.CHECKOUT_LOAD_PERSONAL_DETAILS, personalDetails)
    }

    if (paymentDetails) {
      commit(types.CHECKOUT_LOAD_PAYMENT_DETAILS, paymentDetails)
    }

    if (shippingDetails) {
      dispatch('shipping/saveShippingDetailsToStore', { shippingDetails, emit: false }, { root: true })
    }
  },
  async setThankYouPage ({ commit }, payload) {
    commit(types.CHECKOUT_SET_THANKYOU, payload)
  },
  async addPaymentMethod ({ commit }, paymentMethod) {
    commit(types.CHECKOUT_ADD_PAYMENT_METHOD, paymentMethod)
  },
  async replacePaymentMethods ({ commit }, paymentMethods) {
    commit(types.CHECKOUT_SET_PAYMENT_METHODS, paymentMethods)
  },
  async updatePaymentDetails ({ commit }, updateData) {
    commit(types.CHECKOUT_UPDATE_PAYMENT_DETAILS, updateData)
  },
  clearTimeSlotsError ({ commit }) {
    commit(`cart/${cartTypes.CART_STATES}`, { shippingTimeSlots: { valid: true } }, { root: true })
  },
  async loadShippingTimeSlots ({ commit, rootGetters, dispatch, getters }, currentTimeslot) {
    let transparentId = null

    try {
      const quoteId = rootStore.getters['cart/getCartToken']

      if (isNaN(quoteId)) return null

      const attributeCodeWeight = config.attributesCodes?.weight || ''
      const currentShippingDetails = rootGetters['shipping-module/getCurrent'] || {}
      const cartItems = rootGetters['cart/getCartItems'] || []
      const shopId = await getCurrentShopId()

      if (!attributeCodeWeight || !currentShippingDetails?.shop?.id || !cartItems.length || !shopId) {
        return null
      }

      const weight = cartItems.reduce((result, product) => {
        return result + (getProductWeight(product) * product.qty);
      }, 0);

      const isDelivery = currentShippingDetails?.method?.includes(codes.delivery)

      const params = {
        deliveryMethod: isDelivery ? codes.delivery : codes.pickup,
        shop_id: shopId,
        weight: weight,
        cartId: quoteId,
        ...(isDelivery
          ? {
            latitude: String(currentShippingDetails.address?.coordinates?.[1]) || null,
            longitude: String(currentShippingDetails.address?.coordinates?.[0]) || null
          } : {}
        )
      }

      const { code, result, transparentId: transparentIdFetch } = await CheckoutService.getShippingTimeSlotsV2(params)

      transparentId = transparentIdFetch

      if (code === 200 && Array.isArray(result)) {
        const item = result?.[0]
        const timeslots = result || []
        if (!timeslots.length) {
          sendToLogs(
            groups.Timeslots,
            'timeslot:load:empty:array',
            {
              code: code,
              result,
              params,
              currentShippingDetails
            },
            transparentId
          )

          Logger.error('Can not load time slots', 'checkout')()
        }

        commit(types.SHIPPING_TIME_SLOTS, timeslots)

        const current = currentTimeslot || getters.getCurrentTimeSlot
        const isValid = timeslots.find(item => item.id === current?.id)

        if (!timeslots.length || !isValid) {
          commit(`cart/${cartTypes.CART_STATES}`, { shippingTimeSlots: { valid: !current?.id } }, { root: true })
          EventBus.$emit('timeslot-has-dropped')
        }

        return item?.sku
      }

      if (code !== 200) {
        sendToLogs(
          groups.Timeslots,
          'timeslot:load:not:200',
          {
            code,
            result,
            params
          },
          transparentId
        )
      }

      if (!Array.isArray(result)) {
        sendToLogs(
          groups.Timeslots,
          'timeslot:load:not:array',
          {
            code,
            result,
            params
          },
          transparentId
        )
      }

      if (code === 401) {
        sendToLogs(
          groups.Timeslots,
          'timeslot:load:logout',
          {
            code,
            result,
            params
          },
          transparentId
        )

        dispatch('user/logout', { silent: true }, { root: true })
        return null
      }

      await rootStore.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t(result.errorMessage),
        action1: { label: i18n.t('OK') }
      })

      return null
    } catch (e) {
      sendToLogs(
        groups.Timeslots,
        'timeslot:load:catch:error',
        { message: e.message },
        transparentId
      )

      Logger.error('Can not load time slots', 'checkout')()
    }
  },
  async setCurrentTimeSlot ({ commit, dispatch, getters }, slot) {
    if (getters.getCurrentTimeSlot?.id === slot?.id) return

    commit(types.SET_CURRENT_TIME_SLOT, slot)
    sessionStorage.setItem('currentTimeSlot', JSON.stringify(slot))

    if (!slot) return

    dispatch('clearTimeSlotsError')
    dispatch('cart/sync', { forceServerSync: true, forceShippingSave: true, skipPull: true }, { root: true })
  },
  async clearCheckoutSession ({ commit }, toShipping = false) {
    commit(types.SET_CURRENT_TIME_SLOT, {})
    commit(types.SHIPPING_TIME_SLOTS, [])
    await clearCheckoutSession(toShipping)

    !toShipping && commit(types.CHECKOUT_SET_ACTIVE_STEP)
  },
  async postPaymentStatusToTheServer (_, payload) {
    const { transactionId, status: transactionStatus } = payload
    const paymentStatusData = {
      transactionId,
      transactionStatus
    }
    return CheckoutService.postPaymentStatusToTheServer(paymentStatusData)
  }
}

export default actions
