export const BREAKPOINT_XS = 376;
export const BREAKPOINT_XSM = 480;
export const BREAKPOINT_SM = 768;
export const BREAKPOINT_MD = 1024;
export const BREAKPOINT_LG = 1200;
export const BREAKPOINT_XL = 2000;

export const breakpoints = {
  xs: BREAKPOINT_XS,
  xsm: BREAKPOINT_XSM,
  sm: BREAKPOINT_SM,
  md: BREAKPOINT_MD,
  lg: BREAKPOINT_LG,
  xl: BREAKPOINT_XL
};
