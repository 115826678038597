import { storeViews, defaultStoreCode } from 'config';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export const LANGUAGE_KEY = 'site/' + (storeViews?.cookieName || 'language');
export const LANGUAGE_KEY_CHANGED = 'site/' + (storeViews?.cookieName || 'language') + '-changed';

export const getLocalStorageLanguage = (): string | null => localStorage.getItem(LANGUAGE_KEY) || null;

export const setLocalStorageLanguage = (value: string): void => {
  localStorage.setItem(LANGUAGE_KEY, value)
  localStorage.setItem(LANGUAGE_KEY_CHANGED, value)
};

export const getStoreCodeLink = (storeCode: string): string => {
  let fullPath = location.pathname + location.search;
  const storeView = storeViews[storeCode];

  if (!storeCode || fullPath.length === 0 || !storeView) return defaultStoreCode;

  const currentStoreRegex = new RegExp(`^${localizedRoute('/')}`);
  const checkFullPathRegex = new RegExp(`/(${storeViews.mapStoreUrlsFor.join('|')})$`);

  if (checkFullPathRegex.test(fullPath)) {
    fullPath += '/'
  }

  const path = fullPath.replace(currentStoreRegex, '/');
  return localizedRoute(path, storeView.storeCode);
}
