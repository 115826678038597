const PAGE_SIZE = 12
const PRODUCTS_PAGE_SIZE = 40

const products = {
  defaultFilters: [
    'price',
    'has_promotion_in_stores',
    'countrymanufacturerforsite'
  ]
}

export {
  PAGE_SIZE,
  PRODUCTS_PAGE_SIZE,
  products
}
