import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const BonusCardService = {
  loadBonusSummary: async (period): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.bonusCard, 'summary_endpoint')
        .replace('{{period}}', encodeURIComponent(period)),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  loadBonusCard: async (): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.bonusCard, 'loadCard_endpoint'),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  addBonusCard: async (card): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.bonusCard, 'addCard_endpoint'),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(card)
      }
    });
  }
}
