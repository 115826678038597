import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserPaymentCardsState from '../types/UserPaymentCardsState'

const getters: GetterTree<UserPaymentCardsState, RootState> = {
  getPaymentCards: (state) => state.paymentCards,
  getPaymentCardsLoaded: (state) => state.paymentCardsLoaded
}

export default getters
