import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BonusCardState from '../types/BonusCardState'
import { BonusCardService } from '../data-resolver/BonusCardService';

import * as types from './mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import { groups, sendToLogs } from 'theme/helpers/web-logging';

const actions: ActionTree<BonusCardState, RootState> = {
  async loadBonusSummary ({ commit, dispatch }, period) {
    try {
      commit(types.BONUS_SUMMARY_LOADING, true)
      const response = await BonusCardService.loadBonusSummary(period)
      if (response.code === 200) {
        if (response?.result) {
          commit(types.BONUS_SUMMARY_LOAD, response.result)
        }
      } else if (response.code === 401) {
        sendToLogs(
          groups.User,
          'oCheckCode:loadBonusSummary:not:200',
          { code: response.code },
          response?.transparentId
        )
        await dispatch('user/logout', { silent: true }, { root: true })
      } else {
        Logger.error('Bonus summary is not loaded', 'loadBonusSummary')();
      }

      return response
    } catch (error) {
      Logger.error(error, 'loadBonusCard')();
    } finally {
      commit(types.BONUS_SUMMARY_LOADING, false)
    }
  },
  async loadBonusCard ({ commit, dispatch }) {
    try {
      const response = await BonusCardService.loadBonusCard()

      if (response.code === 200) {
        if (response?.result) {
          commit(types.BONUS_CARD_LOAD, response.result)
        }
      } else if (response.code === 401) {
        await dispatch('user/logout', { silent: true }, { root: true })
      } else {
        Logger.error('Bonus card is not loaded', 'loadBonusCard')();
      }

      if (response.code !== 200) {
        sendToLogs(
          groups.User,
          'oCheckCode:loadBonusCard:not:200',
          { code: response.code },
          response?.transparentId
        )
      }

      return response
    } catch (error) {
      Logger.error(error, 'loadBonusCard')();
    }
  },
  async addBonusCard ({ dispatch }, card) {
    const response = await BonusCardService.addBonusCard(card)

    if (response.code === 401) {
      await dispatch('user/logout', { silent: true }, { root: true })
    }

    if (response.code !== 200) {
      sendToLogs(
        groups.User,
        'oCheckCode:addBonusCard:not:200',
        { code: response.code },
        response?.transparentId
      )
    }

    return response
  },

  clearBonusCard ({ commit }) {
    commit(types.BONUS_CARD_LOAD, {})
  }
}

export default actions
