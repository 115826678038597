import { mergeDeep } from 'theme/helpers/merge-deep';
import { metaCore } from 'theme/meta/base';

const mergeArray = (a, b) => {
  const aArray = a || []
  const bArray = b || []

  return [...aArray, ...bArray].filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.rel === value.rel && t.href === value.href
    ))
  )
}

export const mergeMeta = (meta) => {
  const links = meta.link || []
  const scripts = meta.script || []

  const result: any = metaCore()

  mergeDeep(
    result,
    meta
  )

  result.link = mergeArray(result.link, links)
  result.script = mergeArray(result.script, scripts)

  result.meta = Object.keys(result.meta)
    .map(k => ({ vmid: k, ...result.meta[k] }))

  return result
}
