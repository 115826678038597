import { MutationTree } from 'vuex'
import UserPaymentCardsState from '../types/UserPaymentCardsState'
import * as types from './mutation-types'

const mutations: MutationTree<UserPaymentCardsState> = {
  [types.PAYMENT_CARDS_LIST_LOADED] (state, cards) {
    state.paymentCards = cards || []
    state.paymentCardsLoaded = true
  }
};

export default mutations
