import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { BrandState } from '../types';
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const BrandStore: Module<BrandState, RootState> = {
  namespaced: true,
  state: () => ({
    categories: [],
    currentBrand: null,
    products: [],
    filtersMap: {},
    currentCategory: null,
    searchProductsStats: {},
    categoriesIsLoading: true,
    productsIsLoading: true,
    availableProducts: true,
    sampleProductsCategory: [],
    isAdditionalLoading: false,
    promotionBanners: []
  }),
  actions,
  mutations,
  getters
}
