<template>
  <div class="m-loader" v-show="isVisible">
    <ALoadingSpinner
      key="spinner"
      :is-fixed-position="true"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';

export default {
  name: 'MLoader',
  components: { ALoadingSpinner },
  computed: mapState({
    isVisible: state => state.ui.loader
  }),
  beforeMount () {
    this.$bus.$on('notification-progress-start', this.show);
    this.$bus.$on('notification-progress-stop', this.hide);
  },
  beforeDestroy () {
    this.$bus.$off('notification-progress-start', this.show);
    this.$bus.$off('notification-progress-stop', this.hide);
  },
  methods: {
    show () {
      this.$store.commit('ui/setLoader', true);
    },
    hide () {
      this.$store.commit('ui/setLoader', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.m-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#fff, 0.65);
  z-index: 1001;
}
</style>

<style lang="scss">
.m-loader {
  .sf-loader__overlay {
    background-color: transparent;
  }
}
</style>
