import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import GiftCardsState from './types/GiftCardsState';
import { CertificateService } from './data-resolver/CertificateService'
import { SearchQuery } from 'storefront-query-builder'
import { prepareCategoryProduct } from 'theme/helpers';
import modulesConfig from '$modules/config';

export const GiftCardsStore: Module<GiftCardsState, RootState> = {
  namespaced: true,
  state: {
    giftCards: []
  },
  getters: {
    getGiftCards: state => {
      return state.giftCards
    }
  },
  actions: {
    checkGiftCertificate (context, barcode) {
      return CertificateService.checkGiftCertificate(barcode)
    },
    async fetchGiftCardsCollection ({ commit, dispatch }) {
      const query = new SearchQuery();
      query.applyFilter({ key: 'certificate', value: { 'eq': 1 }, scope: 'default' });
      const { items } = await dispatch('product/findProducts', {
        query,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields
      }, { root: true })

      commit('SET_GIFT_CARDS_COLLECTION', items.map(prepareCategoryProduct))
    }
  },
  mutations: {
    SET_GIFT_CARDS_COLLECTION (state, giftCards) {
      state.giftCards = giftCards || []
    }
  }
}
