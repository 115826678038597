import Vue from 'vue'

export const setRequestCacheTags = (banners = []) => {
  if (Vue.prototype.$cacheTags) {
    banners.forEach((banner) => {
      Vue.prototype.$cacheTags.add(`B${banner.id}`);
    })
  }
}

export const applyFilterFromToByDatetime = (query) => (
  query.applyFilter({ key: 'datetime_from', value: { 'lt': 'now' } })
    .applyFilter({ key: 'datetime_to', value: { 'gt': 'now-1d' } })
)
