import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

const executeTask = async (
  endpointKey: string,
  method: string,
  replacements: Record<string, string> = {},
  body?: any
): Promise<Task> => {
  let url = processLocalizedURLAddress(getApiEndpointUrl(config.wishlist, endpointKey));
  for (const [key, value] of Object.entries(replacements)) {
    url = url.replace(`{{${key}}}`, encodeURIComponent(value));
  }
  return TaskQueue.execute({
    url,
    payload: {
      method,
      mode: 'cors',
      headers,
      body: body ? JSON.stringify(body) : undefined
    }
  });
};

export const WishlistService = {
  loadWishlists: () => executeTask('wishlists_endpoint', 'GET'),
  createWishlist: (name) => executeTask('create_wishlist_endpoint', 'POST', {}, { name }),
  updateWishlistName: (wishlist_id, name) => executeTask('wishlist_endpoint', 'PUT', { wishlist_id }, { wishlist: { name } }),
  deleteWishlist: (wishlist_id) => executeTask('wishlist_endpoint', 'DELETE', { wishlist_id }),
  addItemsToWishlist: (wishlist_id, items = []) => executeTask('wishlist_items_endpoint', 'POST', { wishlist_id }, { items }),
  addItemsToMultipleWishlists: (ids = [], items = []) => executeTask('wishlists_items_endpoint', 'POST', {}, { ids, items }),
  deleteItemsFromWishlist: (wishlist_id, items = []) => executeTask('wishlist_items_endpoint', 'DELETE', { wishlist_id }, { item_ids: items })
}
