const Home = () =>
  import(/* webpackChunkName: "vsf-home" */ 'theme/pages/Home');
const ErrorPage = () =>
  import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error');
const NotFoundPage = () =>
  import(/* webpackChunkName: "vsf-error" */ 'theme/pages/NotFound');
const Product = () =>
  import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product');
const Category = () =>
  import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category');
const Checkout = () =>
  import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout');
const ThankYouPage = () =>
  import(/* webpackChunkName: "vsf-thank-you-page" */ 'theme/pages/ThankYouPage')
const MyAccount = () =>
  import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/MyAccount');
const Static = () =>
  import(/* webpackChunkName: "vsf-static" */ 'theme/pages/Static');
const CartWishlist = () =>
  import(/* webpackChunkName: "vsf-wishlist" */ 'theme/pages/Cart/Wishlist');
const Search = () =>
  import(/* webpackChunkName: "vsf-search" */ 'theme/pages/Search');
const Login = () =>
  import(/* webpackChunkName: "vsf-login" */ 'theme/pages/Login');
const Brands = () =>
  import(/* webpackChunkName: "vsf-brands" */ 'theme/pages/Brands');
const Brand = () =>
  import(/* webpackChunkName: "vsf-brand" */ 'theme/pages/Brand');
const Promotion = () =>
  import(/* webpackChunkName: "vsf-promotion-category" */ 'theme/pages/Promotion');
const PromotionPage = () =>
  import(/* webpackChunkName: "vsf-promotion-page" */ 'theme/pages/PromotionPage');
const Shops = () =>
  import(/* webpackChunkName: "vsf-stores" */ 'theme/pages/Shops');
const TenderPage = () =>
  import(/* webpackChunkName: "vsf-tender" */ 'theme/pages/Info/Partners/Tenders/TenderPage');
const BlogPage = () =>
  import(/* webpackChunkName: "vsf-blog" */ 'theme/pages/Info/AboutCompany/BlogPage');
const SpecialOffers = () =>
  import(/* webpackChunkName: "vsf-special-offers" */ 'theme/pages/SpecialOffers');
// const VarusPerfect = () =>
//   import(/* webpackChunkName: "vsf-varus-perfect" */ 'theme/pages/VarusPerfect');
const VarusCafe = () =>
  import(/* webpackChunkName: "vsf-varus-cafe" */ 'theme/pages/VarusCafe');
const PaymentRedirect = () =>
  import(/* webpackChunkName: "vsf-payment-redirect" */ 'theme/pages/PaymentRedirect');

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/' },
  {
    name: 'checkout',
    path: '/checkout',
    component: Checkout,
    meta: {
      layout: 'checkout',
      title: 'Checkout Page'
    }
  },
  {
    name: 'thank-you-page',
    path: '/thank-you-page',
    component: ThankYouPage,
    meta: {
      title: 'Thank You Page'
    }
  },
  { name: 'brands', path: '/brands', component: Brands },
  { name: 'brand', path: '/brands/:slug', component: Brand },
  { name: 'legal', path: '/legal', component: Static },
  { name: 'privacy', path: '/privacy', component: Static },
  { name: 'my-account', path: '/my-account/:slug?', component: MyAccount, meta: { title: 'My Account' } },
  { name: 'login', path: '/login', component: Login },
  { name: 'about-us', path: '/about-us', component: Static },
  { name: 'customer-service', path: '/customer-service', component: Static },
  { name: 'store-locator', path: '/store-locator', component: Static },
  { name: 'size-guide', path: '/size-guide', component: Static },
  { name: 'delivery', path: '/delivery', component: Static },
  { name: 'returns', path: '/returns', component: Static },
  { name: 'contact', path: '/contact', component: Static },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'error', page: 'error' } },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: Static },
  { name: 'promotion', path: '/promotion', component: Promotion },
  { name: 'promotion-page', path: '/promotion/:slug', component: PromotionPage },
  { name: 'tender-page', path: '/tenders/:slug', component: TenderPage },
  { name: 'cart-wishlist', path: '/cart/wishlist', component: CartWishlist, meta: { layout: 'empty' } },
  {
    name: 'search',
    path: '/search',
    component: Search,
    props: (route) => ({
      queryProp: route.query.q
    })
  },
  { name: 'shops', path: '/stores', component: Shops },
  { name: 'blog-page', path: '/blog/:slug', component: BlogPage },
  { name: 'special-offers', path: '/rasprodazha', component: SpecialOffers },
  // { name: 'varus-perfect', path: '/varus-perfect', component: VarusPerfect },
  { name: 'varus-cafe', path: '/varuscafe', component: VarusCafe },
  { name: 'varus-cafe-category', path: '/varuscafe/:slug', component: VarusCafe },
  { name: 'payment-redirect', path: '/payment-redirect', component: PaymentRedirect, meta: { layout: 'empty' } },
  { name: 'page-not-found', path: '*', component: NotFoundPage, meta: { page: '404' } } // Этот роут всегда должен быть в конце, иначе этот компонент будет выскакивать на других роутах
];

export default routes;
