export const declension = (forms: any, val): string => {
  const cases = [ 2, 0, 1, 1, 1, 2 ];
  return forms[(val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5]];
};

export function getFormattedPhone (phone = '') {
  if (phone.length === 13) {
    phone = phone.replace(/(\+\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/g, '$1 $2 $3 $4 $5')
  }

  return phone
}

export function getCleanedPhone (phone = '') {
  return phone.replace(/[^\d+]/g, '')
}

export function getCurrentDateString () {
  const date = new Date()

  const year: any = date.getFullYear()
  let month: any = date.getMonth() + 1
  let day: any = date.getDate()
  let hours: any = date.getHours()
  let minutes: any = date.getMinutes()
  let seconds: any = date.getSeconds()

  month = (month < 10) ? '0' + month : month;
  day = (day < 10) ? '0' + day : day;
  hours = (hours < 10) ? '0' + hours : hours;
  minutes = (minutes < 10) ? '0' + minutes : minutes;
  seconds = (seconds < 10) ? '0' + seconds : seconds;

  return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
}
