import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserCommunicationState from '../types/UserCommunicationState'
import { UserCommunicationService } from '../data-resolver/UserCommunicationService';

import * as types from './mutation-types'
import { groups, sendToLogs } from 'theme/helpers/web-logging';

const actions: ActionTree<UserCommunicationState, RootState> = {
  async loadCommunications ({ commit, dispatch }, { skipLoader = false }) {
    !skipLoader && commit(types.USER_COMMUNICATIONS_LOADING, true)
    const response = await UserCommunicationService.loadCommunications()

    if (response.code !== 200) {
      sendToLogs(
        groups.User,
        'loadCommunication:not:200',
        {
          code: response.code,
          result: response?.result || null
        },
        response?.transparentId
      )
    } else {
      commit(types.USER_COMMUNICATIONS_LOADED, {
        communications: response?.result.communications || [],
        subscriptions: response?.result.subscriptions || []
      })
    }

    !skipLoader && commit(types.USER_COMMUNICATIONS_LOADING, false)

    if (response.code === 401) {
      await dispatch('user/logout', { silent: true }, { root: true })
    }

    return response
  },

  async saveCommunications ({ commit, dispatch }, payload) {
    commit(types.USER_COMMUNICATIONS_LOADING, true)
    const response = await UserCommunicationService.saveCommunications(payload)
    commit(types.USER_COMMUNICATIONS_LOADING, false)

    if (response.code === 401) {
      await dispatch('user/logout', { silent: true }, { root: true })
    }

    return response
  },

  async saveSubscriptions ({ commit, dispatch }, payload) {
    commit(types.USER_COMMUNICATIONS_LOADING, true)
    const response = await UserCommunicationService.saveSubscriptions(payload)
    commit(types.USER_COMMUNICATIONS_LOADING, false)

    if (response.code === 401) {
      await dispatch('user/logout', { silent: true }, { root: true })
    }

    return response
  }
}

export default actions
