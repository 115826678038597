<template>
  <div class="m-home-apps">
    <div class="m-home-apps__col">
      <slot
        name="title"
        :title="title"
        v-if="title"
      >
        <p class="m-home-apps__title">
          {{ title }}
        </p>
      </slot>
      <slot
        name="description"
        :description="description"
        v-if="description"
      >
        <p class="m-home-apps__description">
          {{ description }}
        </p>
      </slot>
      <div class="m-home-apps__group">
        <SfLink
          target="_blank"
          rel="nofollow"
          class="m-home-apps__icon"
          :link="appleStoreLink"
        >
          <img
            class="m-home-apps__svg"
            :src="backgroundColor === 'black' ? '/assets/app-store.svg' : '/assets/app-store-white.svg'"
            alt="app-store"
            loading="lazy"
          >
        </SfLink>
        <SfLink
          target="_blank"
          rel="nofollow"
          class="m-home-apps__icon"
          :link="googlePlayLink"
        >
          <img
            class="m-home-apps__svg"
            :src="backgroundColor === 'black' ? '/assets/google-play.svg' : '/assets/google-play-white.svg'"
            alt="google-play"
            loading="lazy"
          >
        </SfLink>
      </div>
    </div>
  </div>
</template>

<script>

import {
  SfLink
} from '@storefront-ui/vue';
import config from 'config';

export default {
  name: 'MHomeApps',
  components: { SfLink },
  data () {
    return {
      apps: ['apple', 'google'],
      appleStoreLink: config.socialLinks?.appleStore || '#',
      googlePlayLink: config.socialLinks?.googlePlay || '#'
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-home-apps {
  &__group {
    margin: 0 -5px;
    line-height: 1;
    font-size: 0;
  }

  &__icon {
    margin: 0 var(--spacer-5);
    display: inline-flex;
  }

  &__svg {
    width: 136px;
    height: 100%;

    @include for-desktop {
      width: 128px;
    }

    @media (min-width: $desktop-l-min) {
      width: 136px;
    }
  }

  &__title {
    font-size: var(--font-size-40);
    font-weight: var(--font-bold);
    font-family: var(--font-family-headers);
    text-transform: uppercase;
    color: var(--white);
    line-height: var(--spacer-42);
    margin: 0 0 var(--spacer-10);
  }

  &__description {
    font-size: var(--font-size-21);
    line-height: var(--font-size-22);
    font-weight: var(--font-normal);
    font-family: var(--font-family-headers);
    color: var(--white);
    margin: 0 0 var(--spacer-25) 0;

    @media (min-width: $tablet-min) {
      margin: 0 0 var(--spacer-20) 0;
    }
  }
}
</style>
