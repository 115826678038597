import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'ShippingControlsMixin',
  methods: {
    openShippingModal () {
      this.$store.dispatch('ui/hideBottomNavigation', true)
      this.$store.dispatch('ui/openModal', {
        name: ModalList.ShippingModal
      })
    }
  }
}
