import { Logger } from '@vue-storefront/core/lib/logger'
import { isServer } from '@vue-storefront/core/helpers'
import { allSettled } from 'theme/helpers/allSetted';

let broadcastChannel = null
let tabId = null

const channelHandlers = {}

const onStorageMessage = (event) => {
  try {
    if (!event?.data) return

    const { data } = event

    if (!data || !channelHandlers[data.channel] || data?.tabId === tabId) return

    const actions = channelHandlers[data.channel].map(a => {
      try {
        return a(data.payload)
      } catch (e) {
        Logger.error(`TabEvent: Error on event action: ${e}`)()
      }
    })

    return allSettled(actions)
  } catch (e) {
    Logger.error(`TabEvent: Error on subscribe action: ${e}`)()
  }
}

export const addHandler = (callback, channel = '*') => {
  if (isServer) return

  if (typeof callback !== 'function') {
    Logger.error(`TabEvent: Callback is not a function for "${channel}"`)()

    return
  }

  Logger.debug(`TabEvent: Callback for "${channel}" initialized`)()

  if (channelHandlers[channel]) {
    return channelHandlers[channel].push(callback)
  }

  channelHandlers[channel] = [callback]
}

export const emitTabEvent = (payload, channel = '*') => {
  if (!broadcastChannel) return

  broadcastChannel.postMessage({
    channel,
    payload,
    tabId
  })
}

const initial = () => {
  broadcastChannel = new BroadcastChannel('tabSyncChannel')
  broadcastChannel.addEventListener('message', onStorageMessage);
  tabId = (Math.random() + 1).toString(36).substring(2)
}

if (!isServer) void initial()
