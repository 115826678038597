<template>
  <div class="m-footer-copy">
    <div class="m-footer-copy__container">
      <p class="m-footer-copy__item m-footer-copy__text">
        &#169; {{ new Date().getFullYear() }} {{ $t('VARUS - supermarket / market') }}
      </p>
      <SfLink
        :link="localizedRoute('/user-agreement')"
        class="m-footer-copy__item link"
      >
        {{ $t('User agreement') }}
      </SfLink>
      <p class="m-footer-copy__logo">
        RSR by&nbsp;&nbsp;&nbsp;
        <SfLink
          link="#"
          target="_blank"
          rel="nofollow"
        >
          <svg class="m-footer-copy__icon" width="104" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="m53.6 10.8-.1.6c-.7 2-.3 1.6-2.2 1.6h-2.8c0-1.8.4-2.2 2-2.2h3.1ZM101 16.7h-2.1v-5.9h-.2l-1.2 3.4c-.2 1-.9.8-1.6.9a1 1 0 0 1-.7-.2 1 1 0 0 1-.4-.7 19 19 0 0 0-1.4-3.5v6h-2.1v-9h3c.2 0 .4.4.5.7l1.4 4c.5-1.4 1-2.7 1.3-4 0-.2.2-.4.4-.5.1-.2.3-.2.5-.2h2.6v9ZM47 16.7h-2.2v-6h-.2l-1.1 3.5c-.3 1-1 .8-1.6.8a1 1 0 0 1-.8-.1 1 1 0 0 1-.4-.7 19 19 0 0 0-1.3-3.5v6h-2.2v-9h3c.3 0 .5.4.6.6l1.4 4.1 1.3-4c0-.3.1-.4.3-.6.2-.1.4-.2.6-.1H47v9ZM86.2 14.1c.4-1.9 1-3.5 1.3-5.2.2-1.1.7-1.4 1.8-1.3h1.1l-.9 2.6L87 18c-.8 2.3-1.5 2.7-4 2.4v-1.9h.8a1.2 1.2 0 0 0 1-.6 1.2 1.2 0 0 0 0-1.2c-1-2.7-2-5.5-2.8-8.3l-.2-.7h2.2c.2 0 .4.4.5.7l1.4 4.9.4 1ZM73 7.7h2.3v.8a3.5 3.5 0 0 1 3.8-1c.7.3 1.3.7 1.7 1.2.4.6.7 1.3.7 2 .2 1.1.1 2.3-.1 3.5a3.6 3.6 0 0 1-2.5 2.7 3.4 3.4 0 0 1-3.4-1v4.3H73V7.7Zm6.3 4.6c0-.5 0-.9-.2-1.3 0-.4-.3-.8-.6-1-.4-.3-.8-.5-1.2-.4a1.6 1.6 0 0 0-1.2.3 1.6 1.6 0 0 0-.6 1.2 6 6 0 0 0 .2 2.7 2.2 2.2 0 0 0 1.6 1A1.9 1.9 0 0 0 79 14c.3-.5.4-1 .4-1.7ZM7.3 16.7H4.7V6.6H1V4.4h8.3c.8 0 1.4 1.8 1.4 2.1H7.3v10.2ZM60.7 3.2h.5c.7 0 1.3-.5 1.5.5.1.4 0 .9-.1 1.3a2 2 0 0 1-1 1c-.7.2-1.5.3-2.3.3-1.4.2-2 .7-2.2 2.3.5-.3 1.1-.6 1.7-.7 2.7-.6 4.7 1 5 3.9.2 2.8-1.6 5.1-4.1 5.2-3 0-4.7-1.4-5-4.5v-3c.3-3.3 1.7-4.7 4.7-5 .9 0 1.5-.1 1.3-1.3Zm.6 9.3c0-2-.7-3.1-2-3.1-1.4 0-2.2 1-2.2 2.7 0 1.7.8 2.8 2 2.8.5 0 .9 0 1.2-.3.4-.3.6-.6.8-1l.2-1.1ZM68.3 14.1c.4-1.9 1-3.5 1.3-5.2.2-1.1.7-1.4 1.8-1.3h1.1l-.9 2.6L69 18c-.8 2.3-1.5 2.7-4 2.4v-1.9h.8a1.2 1.2 0 0 0 1-.6 1.2 1.2 0 0 0 0-1.1c-1-2.8-2-5.6-2.8-8.4l-.3-.7H66c.2 0 .4.4.5.7l1.4 4.9.4 1ZM32.1 14l1.4-5c.2-1.2.7-1.5 1.8-1.4h1l-.8 2.6-2.6 7.7c-.8 2.3-1.5 2.6-4 2.3v-1.8h.7a1.2 1.2 0 0 0 1-.6 1.2 1.2 0 0 0 0-1.2l-2.8-8.3-.2-.7h2.3l.5.7 1.4 4.8.3 1Z" fill="#333" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="m14.2 14 1.4-5c.2-1.2.7-1.5 1.8-1.4h1l-.8 2.6L15 18c-.8 2.3-1.5 2.6-4 2.3v-1.8h.7a1.2 1.2 0 0 0 1-.6 1.2 1.2 0 0 0 0-1.2 2008 2008 0 0 0-3-9H12l.5.7 1.4 4.8.3 1ZM19 7.6h2.2l.1.9a3.5 3.5 0 0 1 3.8-1c.6.2 1.2.6 1.6 1.2.4.5.7 1.2.8 1.9 0 1.2 0 2.4-.2 3.5a3.6 3.6 0 0 1-2.4 2.7 3.4 3.4 0 0 1-3.5-1v4.3H19V7.6Zm6.3 4.6c0-.4-.1-.9-.3-1.3 0-.4-.3-.8-.6-1-.3-.3-.7-.4-1.1-.4a1.6 1.6 0 0 0-1.2.4 1.6 1.6 0 0 0-.6 1.1 6 6 0 0 0 .2 2.8 2.2 2.2 0 0 0 1.6 1 1.9 1.9 0 0 0 1.6-1l.4-1.6Z" fill="#333" />
          </svg>
        </SfLink>
      </p>
    </div>
  </div>
</template>

<script>
import {
  SfLink
} from '@storefront-ui/vue';

export default {
  name: 'MFooterCopy',
  components: { SfLink }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-footer-copy {
  border-top: 1px solid var(--line-color);
  padding: var(--spacer-sm) 0;

  @media (min-width: $tablet-min) {
    padding: var(--spacer-sm) 0 var(--spacer-base) 0;
  }

  @media (min-width: $desktop-min) {
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid transparent;
  }

  &__container {
    box-sizing: border-box;
    padding: 0 var(--spacer-sm);
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    width: 100%;

    @media (min-width: $desktop-min) {
      flex-wrap: nowrap;
      max-width: var(--max-width);
    }
  }

  &__item {
    font-weight: var(--font-normal);
    font-size: var(--font-size-13);
    color: var(--dark-gray);
    display: inline-flex;
    align-items: center;
    margin: 0 var(--spacer-xs) 0 0;

    @media (min-width: $desktop-min) {
      font-size: var(--font-size-12);
      width: auto;
      margin-right: auto;
    }

    &.sf-link {
      @media (min-width: $desktop-min) {
        font-size: var(--font-size-12);
      }
    }
  }

  &__text {
    width: 100%;

    @media (min-width: $desktop-min) {
      width: 25%;
      margin-right: 0;
    }

    @media (min-width: $tablet-min) and (max-width: $tablet-max) {
      width: 41%;
      margin-right: 0;
    }
  }

  &__logo {
    font-weight: var(--font-normal);
    font-size: var(--font-size-13);
    color: var(--dark-gray);
    display: inline-flex;
    align-items: center;
    margin: 0 auto 0 0;

    @media (min-width: $tablet-min) {
      margin: 0;
    }

    @media (min-width: $desktop-min) {
      font-size: var(--font-sm);
    }
  }

  &__icon {
    position: relative;
    top: 2px;
  }

  .link {
    &:hover {
      @media (min-width: $desktop-min) {
        color: var(--orange-hover);
      }
    }
  }
}
</style>
