import config from 'config'
import { Filters } from 'core/modules/catalog-next/types/Category'
import { router } from '@vue-storefront/core/app'

export const getSystemFilterNames: string[] = config.products.systemFilterNames

export const getFiltersFromQuery = ({ filtersQuery = {}, availableFilters = {} } = {}): { filters: Filters } => {
  const searchQuery = {
    filters: {}
  }
  Object.keys(filtersQuery).forEach(filterKey => {
    const filter = availableFilters[filterKey]
    let queryValue = filtersQuery[filterKey]
    if (!filter) return
    // keep original value for system filters - for example sort
    if (getSystemFilterNames.includes(filterKey)) {
      searchQuery[filterKey] = queryValue
    } else {
      queryValue = [].concat(filtersQuery[filterKey])
      queryValue.map(singleValue => {
        const variant = Array.isArray(filter) ? filter.find(filterVariant => filterVariant.id === singleValue) : filter
        if (!variant) return
        if (!Array.isArray(searchQuery.filters[filterKey])) searchQuery.filters[filterKey] = []
        searchQuery.filters[filterKey].push({ ...variant, attribute_code: filterKey })
      })
    }
  })
  return searchQuery
}

export const getFilterLink = (filter) => {
  const query = { ...router.currentRoute[config.products.routerFiltersSource] }

  if ('page' in query) {
    delete query['page'];
  }

  const routeQueryNamesArray = Object.keys(query)
  const separator = routeQueryNamesArray.length || router.currentRoute.fullPath.includes('?') ? '&' : '?'

  return router.currentRoute.fullPath + separator + filter.type + '=' + filter.id
}
