import { getProductImagePath } from '../helpers'

export const truncate = function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
}

export const productImagePath = (sku, width, height) => {
  return getProductImagePath(sku, width, height)
}
