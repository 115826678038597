import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isServer } from '@vue-storefront/core/helpers';

const state = {
  isBlocked: false,
  handler: null,
  result: null
}

export const SYNC_NAME = 'DELIVERY_DEFAULTS_SYNC'

const waitAnswer = async () => new Promise((resolve) => {
  return EventBus.$once(SYNC_NAME, (data) => resolve(data))
})

const initDebounce = (dispatch, payload) => {
  const handler = async () => {
    state.isBlocked = true

    try {
      state.result = await dispatch('requestDefaultCityAndShipping', payload)

      EventBus.$emit(SYNC_NAME, state.result)
    } catch (e) {
      console.error(e)
    } finally {
      state.isBlocked = false
      state.handler = null
    }
  }

  state.handler = handler()
}

export const requestDefaultShop = async (dispatch, storeCode) => {
  try {
    if (isServer) return dispatch('requestDefaultCityAndShipping', { storeCode })

    if (state.result) return state.result

    if (state.handler || state.isBlocked) {
      return waitAnswer()
    }

    initDebounce(dispatch, { storeCode })

    return waitAnswer()
  } catch (e) {
    console.error(e)
  }
}
