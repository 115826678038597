import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ProductExtensionState from '../types/ProductExtensionState'
import * as types from './mutation-types'
import { SearchQuery } from 'storefront-query-builder'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { buildFilterProductsQuery } from 'theme/helpers'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'
import cloneDeep from 'lodash/cloneDeep'

const actions: ActionTree<ProductExtensionState, RootState> = {
  async loadCharacteristicAttributes ({ commit }, { product }) {
    const attributeCodes = Object.keys(product?.all_attributes_list || {})

    const topAttributeCodes = Object.keys(product?.top_attributes_list || {})

    if (!attributeCodes.length && !topAttributeCodes.length) {
      return
    }

    const query = new SearchQuery();
    query
      .applyFilter({ key: 'is_visible', value: { 'eq': true } })
      .applyFilter({
        key: 'attribute_code',
        value: { 'in': [...new Set([...attributeCodes, ...topAttributeCodes])] }
      })

    const { items } = await quickSearchByQuery({
      query,
      size: attributeCodes.length,
      entityType: 'attribute',
      includeFields: config.entities.product.includeFieldsForCharacteristicAttributes
    })

    commit(types.SET_CHARACTERISTIC_ATTRIBUTE_CODES, attributeCodes)
    commit(types.SET_TOP_CHARACTERISTIC_ATTRIBUTE_CODES, topAttributeCodes)
    commit(types.SET_CHARACTERISTIC_ATTRIBUTES, items)
  },
  async loadProductsOptionByValues ({ commit, state }, payload): Promise<void> {
    if (!payload.productValues || !payload.productValues?.length) {
      return;
    }

    const existedIds = Object.keys(state.productAttributeLabelMap).map((key) => Number(key));
    const toRequest = payload.productValues
      .filter((value) => !existedIds.includes(value))

    if (!toRequest.length) {
      return
    }

    const query = new SearchQuery();
    query.applyFilter({
      key: 'options.value',
      value: { 'in': toRequest }
    });

    const { items } = await quickSearchByQuery({
      query,
      size: toRequest.length,
      entityType: 'attribute',
      includeFields: ['options']
    });

    if (items.length === 0) {
      return;
    }

    let attributeOptions = []

    if (payload?.isBrands) {
      attributeOptions = items.map(item => {
        const option = item.options?.filter((elem) => {
          return elem.value === payload.productValues[0].toString()
        })
        return { value: option?.[0].value, label: option?.[0].label }
      });
    } else {
      items.forEach((item) => {
        const options = item.options.filter((elem) => toRequest.includes(Number(elem.value)));

        if (options.length) {
          attributeOptions.push(...options.map((option) => ({ value: option.value, label: option.label })));
        }
      });
    }

    commit(types.SET_PRODUCT_ATTRIBUTE_OPTIONS, attributeOptions);
  },

  async loadVarusPerfectProducts ({ dispatch, commit }): Promise<void> {
    const varusPerfectCategoryId = parseInt(await dispatch('config-varus/get', { path: 'categories_map_perfect_category_id' }, { root: true }));
    const filters = { id: varusPerfectCategoryId }
    const fullVarusPerfectCategoryInfo = await dispatch('category-next/loadCategory', { filters }, { root: true });

    const query = buildFilterProductsQuery(fullVarusPerfectCategoryInfo)

    const { items } = await dispatch('product/findProducts', {
      query,
      onlyInStock: true,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      }
    }, { root: true });

    commit(types.SET_VARUS_PERFECT_PRODUCTS, items);
  },
  async loadInStockProductVariants ({ dispatch, commit }, { ids }): Promise<void> {
    if (!ids.length) return

    const query = new SearchQuery()

    query
      .applyFilter({ key: 'id', value: { 'in': ids } })
      .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
      .applyFilter({ key: 'status', value: { 'in': [1] } })

    const { items } = await dispatch('product/findProducts', {
      query,
      includeFields: ['sku', 'id', 'name'],
      onlyInStock: true
    }, { root: true });

    commit(types.SET_IN_STOCK_PRODUCT_VARIANTS, items || []);
  },
  /**
   * Check stock for current product
   *
   * @param commit
   * @param rootGetters
   */
  async loadStockForCurrentProduct ({ rootGetters, dispatch, commit }) {
    try {
      const [item] = await dispatch('product/getSimpleProductsByID', {
        ids: [rootGetters['product/getCurrentProduct'].id],
        includeFields: ['sqpp', 'stock']
      }, { root: true });

      if (!item?.sqpp || !item?.stock) return

      commit(types.UPDATE_CURRENT_PRODUCT_STOCK, { sqpp: item?.sqpp, stock: item?.stock });
    } catch (e) {
      Logger.debug(e, 'cart')()
    }
  },
  async loadProductBreadcrumbs ({ dispatch, rootGetters }, { product } = {}) {
    if (product && product.category_ids) {
      const currentCategory = rootGetters['category-next/getCurrentCategory']
      let breadcrumbCategory
      let productCategory
      const categoryFilters = Object.assign({ 'id': [...product.category_ids] }, cloneDeep(config.entities.category.breadcrumbFilterFields))
      const categories = await dispatch('category-next/loadCategories', { filters: categoryFilters, reloadAll: Object.keys(config.entities.category.breadcrumbFilterFields).length > 0 }, { root: true })
      if (
        (currentCategory && currentCategory.id) && // current category exist
          (config.entities.category.categoriesRootCategoryId !== currentCategory.id) && // is not highest category (All) - if we open product from different page then category page
          (categories.findIndex(category => category.id === currentCategory.id) >= 0) // can be found in fetched categories
      ) {
        breadcrumbCategory = currentCategory // use current category if set and included in the filtered list
      } else {
        productCategory = product.category.filter(elem => elem.is_product_category && elem.level > 2).sort((a, b) => (a.level > b.level) ? -1 : 1)
        if (productCategory.length > 0) {
          breadcrumbCategory = await dispatch(
            'category-next/loadCategory',
            { filters: { 'id': productCategory[0].category_id } },
            { root: true }
          )
        } else {
          breadcrumbCategory = categories.sort((a, b) => (a.level > b.level) ? -1 : 1)[0] // sort starting by deepest level
        }
      }
      await dispatch('category-extension/loadCategoryBreadcrumbs', { category: breadcrumbCategory, currentRouteName: product.name }, { root: true })
    }
  }
};

export default actions
