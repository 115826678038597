import { MutationTree } from 'vuex'
import UserCommunicationState from '../types/UserCommunicationState'
import * as types from './mutation-types'

const mutations: MutationTree<UserCommunicationState> = {
  [types.USER_COMMUNICATIONS_LOADED] (state, payload) {
    state.communications = payload.communications || []
    state.subscriptions = payload.subscriptions || []
  },
  [types.USER_COMMUNICATIONS_LOADING] (state, value) {
    state.communicationsLoading = value
  }
};

export default mutations
