import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { once } from '@vue-storefront/core/helpers'
import config from 'config'
// const defaultImpl = VueI18n.prototype.getChoiceIndex
//
// VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
//   // <------------ Add support for Russian and Ukrainian pluralization
//   // this === VueI18n instance, so the locale property also exists here
//   if (this.locale !== 'ru-RU' && this.locale !== 'uk-UA') {
//     return defaultImpl.apply(this, arguments) // default implementation
//   }
//   if (choice === 0) {
//     return 0
//   }
//   const teen = choice > 10 && choice < 20
//   const endsWithOne = choice % 10 === 1
//   if (!teen && endsWithOne) {
//     return 1
//   }
//   if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
//     return 2
//   }
//   return (choicesLength < 4) ? 2 : 3
// }

once('__VUE_EXTEND_I18N__', () => {
  Vue.use(VueI18n)
})

const defaultLocale = config.i18n.defaultLocale || 'en-US'
const loadedLanguages = [defaultLocale]
const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: defaultLocale,
  messages: config.i18n.bundleAllStoreviewLanguages ? require('./resource/i18n/multistoreLanguages.json') : {
    [defaultLocale]: require(`./resource/i18n/${defaultLocale}.json`)
  }
})

function setI18nLanguage (lang: string): string {
  i18n.locale = lang
  return lang
}

/**
 * Lazy load date locales file for current switched language.
 */
const loadDateLocales = async (lang = 'en'): Promise<void> => {
  let localeCode = lang.toLocaleLowerCase()
  try { // try to load full locale name
    await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
  } catch (e) { // load simplified locale name, example: de-DE -> de
    const separatorIndex = localeCode.indexOf('-')
    if (separatorIndex) {
      localeCode = separatorIndex ? localeCode.substr(0, separatorIndex) : localeCode
      try {
        await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
      } catch (err) {
        Logger.debug('Unable to load translation from dayjs')()
      }
    }
  }
}

export async function loadLanguageAsync (lang: string): Promise<string> {
  await loadDateLocales(lang)
  if (!config.i18n.bundleAllStoreviewLanguages) {
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        try {
          const msgs = await import(/* webpackChunkName: "lang-[request]" */ `./resource/i18n/${lang}.json`)
          i18n.setLocaleMessage(lang, msgs.default)
          loadedLanguages.push(lang)
          return setI18nLanguage(lang)
        } catch (e) { // eslint-disable-line handle-callback-err
          Logger.debug('Unable to load translation')()
          return ''
        }
      }
      return setI18nLanguage(lang)
    }
  } else {
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  }
  return lang
}

export default i18n
