export const PROMOTION = 'promotion';
export const SET_BRANDS_MAIN_COLLECTION = 'SET_BRANDS_MAIN_COLLECTION';
export const SET_PROMOTION_CATEGORY_COLLECTION = 'SET_PROMOTION_CATEGORY_COLLECTION';
export const SET_PROMOTION_METADATA = 'SET_PROMOTION_METADATA';
export const SET_PROMOTION_CURRENT_CATEGORY_COLLECTION = 'SET_PROMOTION_CURRENT_CATEGORY_COLLECTION';
export const SET_PROMOTION_CURRENT_TYPE_COLLECTION = 'SET_PROMOTION_CURRENT_TYPE_COLLECTION';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADER = 'SET_LOADER';
export const SET_PROMOTION_PRODUCTS_TOTAL = 'SET_PROMOTION_PRODUCTS_TOTAL';
export const SET_PRODUCTS_IS_LOADING = `${PROMOTION}/SET_PRODUCTS_IS_LOADING`
export const SET_PROMOTIONS = `${PROMOTION}/SET_PROMOTIONS`
export const UPDATE_PROMOTIONS = `${PROMOTION}/UPDATE_PROMOTIONS`
export const SET_SEARCH_PRODUCTS_STATS = `${PROMOTION}/SET_SEARCH_PRODUCTS_STATS`
export const SET_IS_ADDITIONAL_PROMOTIONS_LOADING = `${PROMOTION}/SET_IS_ADDITIONAL_PROMOTIONS_LOADING`
export const SET_IS_ADDITIONAL_PROMOTION_PRODUCTS_LOADING = `${PROMOTION}/SET_IS_ADDITIONAL_PROMOTION_PRODUCTS_LOADING`
export const AVAILABLE_PRODUCTS = `${PROMOTION}/AVAILABLE_PRODUCTS`
