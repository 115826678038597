import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { FAQStore } from './store'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';

export const FAQModule: StorefrontModule = function ({ store }) {
  store.registerModule('faq', FAQStore)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('faq_question', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'faq_question', start, size)
      }
    });
  });
}
