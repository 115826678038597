<template>
  <div class="sw-placeholder">
    <svg width="239" height="36" viewBox="0 0 239 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="20" width="239" height="16" rx="3" fill="#EEEEEE"/>
      <rect width="65" height="16" rx="3" fill="#EEEEEE"/>
      <animate
          attributeType="CSS"
          attributeName="opacity"
          from="1"
          to=".3"
          dur="2s"
          values="1; .3; 1"
          keyTimes="0; 0.5; 1"
          repeatCount="indefinite"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ShippingWidgetPlaceholder'
};
</script>

<style lang="scss">
.sw-placeholder {
  svg {
    width: 100%;

    path, rect {
      fill: var(--placeholder-color)
    }
  }
}
</style>
