import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { StateStock, StateStockNotEnough, StateStockReplacements } from 'theme/store/cart/types/CartState';
import { getMinSalableValue } from 'theme/store/checkout/helpers';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';

export const productStockStateDefaults = {
  valid: true,
  replacements: [],
  notEnough: []
}

const storageKey = 'productStockState'

export class ProductStockState {
  private stockState: StateStock = productStockStateDefaults

  private newReplacements: StateStockReplacements[] = []
  private newNotEnough: StateStockNotEnough[] = []

  public constructor (prevState: StateStock) {
    if (prevState && prevState.notEnough && prevState.replacements) {
      this.stockState = prevState
    }
  }

  public async loadFromStorage () {
    const fromStorage = await StorageManager.get('cart').getItem(storageKey)

    this.stockState = fromStorage || this.stockState

    return this
  }

  private checkReplacement (sku: number, serverQty: number, minSalableQty: number) {
    if (serverQty >= minSalableQty) {
      return false
    }

    this.newReplacements.push({
      sku
    })

    return true
  }

  private checkNotEnough (sku: number, serverQty: number, cartQty: number, extension_attributes) {
    if (serverQty > cartQty) return

    const toEqual = {
      sku,
      extension_attributes
    } as unknown as CartItem

    const notEnough = this.stockState.notEnough.find(i => (
      isSkuEqual(toEqual, i as unknown as CartItem)
    ))

    if (
      (notEnough && notEnough?.qty !== cartQty && notEnough?.qty !== serverQty) ||
      (!notEnough && serverQty === cartQty)
    ) {
      return
    }

    this.newNotEnough.push({
      sku,
      qty: +this.roundQty(serverQty),
      extension_attributes
    })
  }

  public checkItem = (cartItem, serverItem) => {
    const sku = Number(cartItem.sku)
    const minSalableQty = getMinSalableValue(cartItem)
    const isReplacement = this.checkReplacement(sku, serverItem?.qty, minSalableQty)

    if (isReplacement) return true

    this.checkNotEnough(sku, serverItem?.qty, cartItem?.qty, serverItem?.extension_attributes || {})
  }

  public async result (): Promise<StateStock> {
    const stateStock:any = {
      valid: !this.newReplacements.length && !this.newNotEnough.length,
      replacements: this.newReplacements,
      notEnough: this.newNotEnough
    }

    if (this.newNotEnough.length) {
      stateStock.showNotEnoughMessage = this.stockState.hasOwnProperty('showNotEnoughMessage')
        ? this.stockState.showNotEnoughMessage
        : !!this.newNotEnough.length
    }

    await StorageManager.get('cart').setItem(storageKey, stateStock)

    return stateStock
  }

  public static clearStorage () {
    try {
      return StorageManager.get('cart').removeItem(storageKey)
    } catch (e) {}
  }

  public static async hideNotEnoughMessage () {
    const fromStorage = await StorageManager.get('cart').getItem(storageKey)

    if (!fromStorage) {
      return {
        valid: true,
        replacements: [],
        notEnough: []
      }
    }

    fromStorage.showNotEnoughMessage = false

    await StorageManager.get('cart').setItem(storageKey, fromStorage)

    return fromStorage
  }

  public roundQty (serverQty) {
    return (Math.round(serverQty * 10) / 10).toFixed(2);
  }
}
