<template>
  <div v-if="categories.length" class="m-catalog-menu">
    <MMegaMenu
      v-if="isDesktop"
      key="desktop-menu"
      :categories="categories"
      :is-mobile="false"
    />
    <NoSSR>
      <MMobileMenu
        v-show="!isDesktop"
        key="mobile-menu"
        :categories="categories"
        is-mobile
      />
    </NoSSR>
  </div>
</template>
<script>
import { prepareCategoryMenuItem } from 'theme/helpers';
import MMegaMenu from 'theme/components/molecules/m-mega-menu';
import { mapState } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'MCatalogMenu',
  components: {
    NoSSR,
    MMobileMenu: () => process.browser ? import('theme/components/molecules/m-mobile-menu') : null,
    MMegaMenu
  },
  mixins: [DeviceType],
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    categoriesIds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      getCategories: state => state.category.list
    }),
    categories () {
      return this.findChildCategory(this.categoriesIds)
        .sort((a, b) => a.position - b.position);
    }
  },
  mounted () {
    this.$store.dispatch('promoted/updateMenuBanners')
  },
  methods: {
    findChildCategory (categories) {
      const result = []

      for (const subCategory of categories) {
        const category = this.getCategories.find(cat => cat.id === subCategory.id)

        if (!category || !category.include_in_menu) {
          continue
        }

        const preparedCategory = prepareCategoryMenuItem(category, (item) => {
          if (!item.items.length) {
            return item
          }

          item.items.push({
            ...item,
            items: [],
            name: this.$t('Whole section')
          })

          return item
        })

        result.push(preparedCategory)
      }

      return result.sort((a, b) => a.position - b.position)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-catalog-menu {
  @include for-mobile {
    position: fixed;
    right: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    top: 0;
    height: calc(100vh - calc(var(--bottom-navigation-height) ));
    overflow: auto;
    z-index: 1;
    padding: 0;
  }

  @include for-desktop {
    position: absolute;
    max-width: var(--max-width);
    width: var(--max-width);
    height: 680px;
    max-height: calc(100vh - var(--max-width));
    opacity: 0;
    visibility: hidden;
    top: 100%;
    margin: 0 auto;
    z-index: 0;
    transition: visibility, opacity .2s ease;
  }
  &--active {
    opacity: 1;
    visibility: visible;
    z-index: 5;

    @include for-mobile {
      height: calc(100% - var(--bottom-navigation-height));
    }
  }
}
</style>
