<template>
  <div class="m-menu bg-white">
    <div class="m-menu__content">
      <ul class="m-menu__submenu-nav">
        <li
          v-for="(subMenuItem, i) in subMenu"
          :key="i"
          class="o-header__submenu-nav-item"
        >
          <SfLink
            :link="subMenuItem.path"
            class="m-menu__submenu-nav-item"
            data-transaction-name="Menu - Go To Link"
            @click.native="closeMenu"
            :class="{ 'orange-color': subMenuItem.path === '/varuscafe' }"
          >
            {{ subMenuItem.name }}
          </SfLink>
        </li>
      </ul>
      <ul class="m-menu__menu-nav">
        <li>
          <SfLink
            :link="shippingLink.link"
            data-transaction-name="Menu - Shipping Link"
            @click.native="closeMenu"
            class="m-menu__nav-item"
          >
            {{ shippingLink.name }}
          </SfLink>
        </li>
        <li
          v-for="(mainMenuItem, i) in mainMenu"
          :key="i"
        >
          <SfLink
            v-if="!mainMenuItem.items"
            :link="mainMenuItem.link"
            data-transaction-name="Menu - Close Empty"
            @click.native="closeMenu"
            class="m-menu__nav-item"
          >
            {{ mainMenuItem.name }}
          </SfLink>
          <span
            v-else
            data-transaction-name="Menu - Open Sidebar"
            class="m-menu__nav-item dropdown-chevron"
            @click="openSidebar(mainMenuItem.path)"
          >
            {{ mainMenuItem.name }}
          </span>
        </li>
      </ul>
      <div class="m-menu__aside-icons">
        <AWishlistIcon @click.native="closeMenu" />
        <lazy-hydrate when-idle>
          <AAccountIcon />
        </lazy-hydrate>
      </div>
      <div class="m-menu__aside">
        <ShippingWidgetLite class="m-menu__aside-locate" />
        <div class="m-menu__aside-language">
          <ALanguageSwitcher :type="'panel'" />
        </div>
      </div>
    </div>
    <SfSidebar
      :visible="isSidebarOpen"
    >
      <template
        #bar
        v-if="currentSubmenu.length"
      >
        <SfBar
          :title="currentSubmenu[0].name"
          :back="true"
        >
          <template #back>
            <SfButton
              data-transaction-name="Menu - Close Sidebar"
              @click.native="closeSidebar"
              class="sf-button--close a-submenu-header__button"
            >
              <span class="a-submenu-header__button-back" />
            </SfButton>
          </template>
        </SfBar>
      </template>
      <div
        v-if="currentSubmenu.length"
        class="m-menu__sidebar-content"
      >
        <div
          v-for="(subMenuItem, index) in currentSubmenu[0].items"
          :key="index"
        >
          <div
            v-if="subMenuItem.link === '/logout'"
            data-transaction-name="Menu - Logout"
            @click="logout"
            class="m-menu__nav-item logout-link"
          >
            {{ subMenuItem.name }}
          </div>
          <SfLink
            v-else
            :link="localizedRoute(subMenuItem.link)"
            data-transaction-name="Menu - Close"
            @click.native="closeMenu"
            class="m-menu__nav-item"
          >
            {{ subMenuItem.name }}
          </SfLink>
        </div>
      </div>
    </SfSidebar>
  </div>
</template>
<script>
import AWishlistIcon from 'theme/components/atoms/a-wishlist-icon'
import AAccountIcon from 'theme/components/atoms/a-account-icon'
import { SfLink, SfSidebar, SfButton, SfBar } from '@storefront-ui/vue';
import ALanguageSwitcher from 'theme/components/atoms/a-language-switcher';
import { mapGetters } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
export default {
  components: {
    ALanguageSwitcher,
    ShippingWidgetLite: () => import('$modules/shipping/components/shipping-widget-lite.vue'),
    SfLink,
    SfSidebar,
    SfBar,
    SfButton,
    AWishlistIcon,
    AAccountIcon,
    LazyHydrate
  },
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false
    },
    authMenu: {
      type: Array,
      default: () => []
    },
    mainMenu: {
      type: Array,
      default: () => []
    },
    subMenu: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      currentMenu: '',
      shippingLink: {
        name: this.$t('Shipping and payment'),
        link: '/help'
      }
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),

    currentSubmenu () {
      const submenu = this.currentMenu === '/my-account' ? this.authMenu : this.mainMenu
      return this.currentMenu ? submenu.filter(menu => menu.path === this.currentMenu) : []
    },
    authMenuItem () {
      return this.authMenu[0]
    }
  },
  methods: {
    closeMenu () {
      this.$store.commit('ui/closeMenu')
      this.$store.dispatch('ui/hideBottomNavigation', false)
      this.closeSidebar();
    },
    closeSidebar () {
      this.$emit('click', false)
    },
    openSidebar (link) {
      this.currentMenu = link
      this.$emit('click', true)
    },
    logout () {
      this.closeMenu()
      this.$store.dispatch('user/logout', {});

      if (this.$route.path.includes('/my-account')) {
        this.$router.push(this.localizedRoute('/'))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-menu {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  padding: var(--spacer-lg) var(--spacer-lg) 0 var(--spacer-lg);
  box-sizing: border-box;

  .router-link-exact-active {
    --menu-item-font-weight: bold;
  }

  &__close {
    display: flex;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    height: auto;
    min-height: 100%;

    .m-menu__submenu-nav {
      .orange-color {
        color: var(--orange);
      }
    }
  }

  &__sidebar-content {
    display: flex;
    flex-direction: column;

    .m-menu__nav-item {
      font-size: var(--font-size-16);
    }
  }

  &__menu-nav {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray);
    padding: var(--spacer-base) 0 0 0;
    margin: 0;
    list-style: none;
    .m-menu__nav-item {
      color: var(--dark-gray);
    }
  }

  &__submenu-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--gray);
  }

  &__submenu-nav-item {
    margin-bottom: var(--spacer-base);
    display: inline-block;
    font-size: var(--font-base);

    &:focus {
      color: var(--orange-hover);
    }
  }

  &__nav-item {
    font-size: var(--font-size-15);
    color: var(--black);
    margin-bottom: var(--spacer-base);
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      color: var(--orange-hover);
    }
  }

  &__aside-icons {
    padding-top: var(--spacer-base);

    button {
      margin-bottom: var(--spacer-base);
      position: relative;
    }

    .dropdown-chevron:after {
      @include for-mobile {
        transform: rotate(45deg);
      }
    }
  }

  &__aside {
    margin-top: auto;

    &-language {
      font-size: var(--font-size-15);
      color: var(--dark-gray);
      border-top: 1px solid var(--line-color);
      padding-top: var(--spacer-xs);
      margin-top: var(--spacer-sm);

      @include for-mobile {
        padding-top: var(--spacer-20);
      }
    }
    ::v-deep {
      .m-user-address__popup {
        display: none;
      }
    }
  }

  .logout-link {
    cursor: pointer;
    border-top: 1px solid var(--light-gray);
    padding-top: var(--spacer-15);
    color: var(--orange);
  }

  .a-submenu-header__button-back {
    @include font-icon(var(--icon-arrow-bold-left));

    &:before {
      color: var(--black);
      font-size: var(--font-size-12);
    }
  }
}

::v-deep {
  .sf-overlay {
    display: none;
  }
  .sf-sidebar__aside{
    top: 50px;
    box-shadow: none;
  }
  .sf-sidebar__top {
    display: none;
  }
  .sf-bar {
    --bar-background: transparent;
    --bar-padding: 0;
    --bar-height: 4.375rem;
    --bar-font-size: var(--font-size-22);
    --bar-font-weight: var(--font-bold);
    --bar-font-family: var(--font-family-headers);
    margin: 0 var(--spacer-20);
    border-bottom: 1px solid var(--gray);
    justify-content: flex-start;

    &__title {
      text-transform: uppercase;
      padding-left: var(--spacer-15);
    }
  }
}
</style>
