<template>
  <div class="sf-accordion-item">
    <!-- @slot -->
    <slot
        name="header"
        v-bind="{
        header,
        isOpen,
        accordionClick,
        showChevron: $parent.showChevron,
      }"
    >
      <SfButton
          :aria-pressed="isOpen.toString()"
          :aria-expanded="isOpen.toString()"
          :class="{ 'sf-accordion-item__header--open': isOpen }"
          class="sf-button--pure sf-accordion-item__header"
          @click="accordionClick"
      >
        {{ header }}
        <SfChevron
            tabindex="0"
            class="sf-accordion-item__chevron"
            :class="{ 'sf-chevron--right': !isOpen }"
        />
      </SfButton>
    </slot>
    <transition :name="$parent.transition">
      <div v-if="isOpen" class="sf-accordion-item__content">
        <!-- @slot -->
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import focus from '$ui/directives/focus';
import SfChevron from '$ui/components/atoms/SfChevron/SfChevron.vue';
import SfButton from '$ui/components/atoms/SfButton/SfButton.vue';
export default {
  name: 'SfAccordionItem',
  directives: { focus },
  components: {
    SfChevron,
    SfButton
  },
  props: {
    header: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  methods: {
    accordionClick () {
      this.$parent.$emit('toggle', this._uid);
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/organisms/SfAccordion.scss";
</style>
