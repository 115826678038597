import { ShippingDetailsType } from 'theme/store/checkout/types/ShippingDetails';

export const initialState: ShippingDetailsType = {
  cityDetails: {},
  cityLocation: {
    latLng: {
      lat: 0,
      lng: 0
    },
    region: ''
  },
  city: '',
  shippingMethod: '', // PICKUP, DELIVERY
  shippingCarrier: '',
  shippingMethodType: '', // PICKUP, VARUS_DRIVE, DELIVERY, DELIVERY_EXPRESS
  shippingMethodTypes: [], // [PICKUP, VARUS_DRIVE] || [DELIVERY, DELIVERY_EXPRESS]
  streetAddress: '',
  deliveryAddressCoords: {
    lat: 0,
    lng: 0
  },
  shopId: null,
  shop: {},
  deliveryMethod: {},
  availablePaymentMethods: [],
  userFilledShippingInfo: false,
  userLoadedShippingInfo: false,
  currentShippingMethod: {}
}
