import supportsWebP from 'supports-webp';
import { ModalList, modalStore } from './modals';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';

export const uiStore = {
  namespaced: true,
  modules: {
    modal: modalStore
  },
  state: {
    sidebar: false,
    microcart: false,
    searchpanel: false,
    overlay: false,
    loader: false,
    deliveryLoader: false,
    categoryLoader: true,
    productLoader: false,
    openMyAccount: false,
    needToOpenAuth: false,
    submenu: {
      depth: false,
      path: []
    },
    userLocate: false,
    isWebpSupported: true,
    isReviewProductTab: false,
    isMobileMenu: false,
    isMobileCatalog: false,
    hideBottomNavigation: false,
    plugPageFlag: false,
    shippingDetailsClearModal: false,
    desktopBreakpoint: false,
    preHeaderBanner: false,
    isAccountMenuOpen: false,
    waitSmsSeconds: config.sms?.waitBeforeSendSms || 30,
    isOrderPreparingToProcess: false,
    canSendSms: true,
    pagingLoading: false
  },
  mutations: {
    setMicrocart (state, action) {
      state.microcart = action === true;
      state.overlay = action === true;
    },
    setHideBottomNavigation (state, action) {
      state.hideBottomNavigation = action === true;
    },
    setPlugPageFlag (state, action) {
      state.plugPageFlag = action === true;
    },
    setDesktopBreakpoint (state, action) {
      state.desktopBreakpoint = action === true;
    },
    setPreHeaderBanner (state, action) {
      state.preHeaderBanner = action === true;
    },
    setSidebar (state, action) {
      state.sidebar = action === true;
      state.overlay = action === true;
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id);
      } else if (state.submenu.path.length && !isServer) {
        setTimeout(() => {
          state.submenu.path.pop();
        }, 300);
      }
      state.submenu.depth = state.submenu.depth > 0 && depth;
    },
    setShippingDetailsClearModal (state, action) {
      state.shippingDetailsClearModal = action === true;
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true;
      state.overlay = action === true;
    },
    setOverlay (state, action) {
      state.overlay = action === true;
    },
    setLoader (state, action) {
      state.loader = action === true;
    },
    setDeliveryLoader (state, action) {
      state.deliveryLoader = action === true;
    },
    setCategoryLoader (state, action) {
      state.categoryLoader = action === true;
    },
    setProductLoader (state, action) {
      state.productLoader = action === true;
    },
    setWebpSupport (state, action) {
      state.isWebpSupported = action
    },
    setReviewProductTab (state, action) {
      state.isReviewProductTab = action
    },
    openMenu (state) {
      state.isMobileMenu = true
    },
    openCatalog (state) {
      state.isMobileCatalog = true
    },
    setPersonalAccountMenu (state, action) {
      state.isAccountMenuOpen = action === true;
    },
    closeMenu (state) {
      state.isMobileMenu = false
    },
    closeCatalog (state) {
      state.isMobileCatalog = false
    },
    setUserLocate (state, action) {
      state.userLocate = action
    },
    setNeedToOpenAuthMutation (state, needToOpenAuthState) {
      state.needToOpenAuth = needToOpenAuthState;
    },
    setCanSendSmsMutation (state, canSendSms) {
      state.canSendSms = canSendSms;
    },
    setWaitSmsSecondsMutation (state, seconds) {
      state.waitSmsSeconds = seconds;
    },
    setOrderPreparingToProcess (state, value) {
      state.isOrderPreparingToProcess = value;
    },
    setPaginationLoading (state, isLoading) {
      state.pagingLoading = isLoading
    }
  },
  actions: {
    setMicrocart ({ commit, dispatch }, payload) {
      commit('setMicrocart', payload)
      if (!payload) dispatch('closeModal', { name: ModalList.CartPopupPromotion })
    },
    toggleMicrocart ({ state, rootGetters, dispatch }, payload) {
      const force = payload?.force || false
      const isShippingFilled = rootGetters['shipping/getShippingDetails']?.userFilledShippingInfo

      if (!isShippingFilled && !force) {
        dispatch('hideBottomNavigation', true)
        dispatch('openModal', {
          name: ModalList.ShippingModal,
          payload: {
            openMicrocart: true
          }
        })

        return
      }

      dispatch('setMicrocart', !state.microcart);
    },
    categoryLoader ({ commit }, action) {
      commit('setCategoryLoader', action)
    },
    productLoader ({ commit }, action) {
      commit('setProductLoader', action)
    },
    checkWebpSupport ({ commit }) {
      supportsWebP.then(isWebpSupported => {
        commit('setWebpSupport', isWebpSupported);
      })
    },
    hideBottomNavigation ({ commit }, action) {
      commit('setHideBottomNavigation', action)
    },
    isPlugPage ({ commit }, action) {
      commit('setPlugPageFlag', action)
    },
    checkDesktopBreakpoint ({ commit }, action) {
      commit('setDesktopBreakpoint', action)
    },
    showPreHeaderBanner ({ commit }, action) {
      commit('setPreHeaderBanner', action)
    },
    isPersonalAccountMenuOpen ({ commit }, action) {
      commit('setPersonalAccountMenu', action)
    },
    closeMicrocart ({ state, dispatch }) {
      if (state.microcart) dispatch('setMicrocart', false)
    },
    closeWishlist ({ commit, state }) {
      if (state.wishlist) commit('setWishlist', false)
    },
    setUserLocate ({ commit }, action) {
      commit('setUserLocate', action)
    },
    setNeedToOpenAuth ({ commit }, needToOpenAuthState) {
      commit('setNeedToOpenAuthMutation', needToOpenAuthState)
    },
    setCanSendSms ({ commit }, canSendSms) {
      commit('setCanSendSmsMutation', canSendSms)
    },
    setWaitSmsSeconds ({ commit }, seconds) {
      commit('setWaitSmsSecondsMutation', seconds)
    },
    setOrderPreparingToProcess ({ commit }, value) {
      commit('setOrderPreparingToProcess', value)
    },
    async loadCatalogMenu ({ dispatch, rootState }) {
      if (rootState.category.list.length) return

      await dispatch('category/list', {
        parent: config.entities.category.categoriesRootCategoryId,
        level:
          config.entities.category.categoriesDynamicPrefetch &&
          config.entities.category.categoriesDynamicPrefetchLevel >= 0
            ? config.entities.category.categoriesDynamicPrefetchLevel
            : null,
        includeFields:
          config.entities.optimize && isServer
            ? config.entities.category.includeFields
            : null,
        excludeFields: [
          ...config.entities.category.excludeFields,
          'description',
          'brand_data',
          'promotion_banner_ids'
        ],
        // excludeSlugs: ['brands'],
        includesInMenu: true
      }, { root: true })
    },
    async setPagingLoading ({ commit }, isLoading) {
      commit('setPaginationLoading', isLoading)
    },
    async setAdultConfirmation ({
      rootState,
      dispatch
    }) {
      if (!rootState.user.current) {
        localStorage.setItem('adultConfirm', 'confirmed')
        return
      }

      await dispatch('user/update', {
        customer: {
          legal_age: 1
        }
      }, { root: true })
    },
    async syncAdultConfirmation ({
      rootState,
      dispatch
    }) {
      try {
        if (localStorage.getItem('adultConfirm') !== 'confirmed') return

        localStorage.removeItem('adultConfirm')

        if (rootState.user.current?.legal_age) return

        await dispatch('user/update', {
          customer: {
            legal_age: 1
          }
        }, { root: true })
      } catch (e) {}
    },
    async adultConfirmation ({ rootState, dispatch }, { product }) {
      const adultConfirmLS = !rootState.user.current &&
          localStorage.getItem('adultConfirm')

      if (
        !product ||
          !product?.is_18_plus ||
          rootState.user.current?.legal_age ||
          adultConfirmLS
      ) return

      dispatch('openModal', {
        name: ModalList.AdultConfirmation,
        payload: {
          product
        }
      })

      return true
    }
  }
};
