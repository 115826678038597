import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import Task from 'core/lib/sync/types/Task';
import { UserService as UserServiceRoot } from '@vue-storefront/core/data-resolver';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const UserService = {
  ...UserServiceRoot,
  login: async (username: string, password: string, captchaToken = ''): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'login_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ username, password, captchaToken })
      },
      silent: true
    }),
  getOrdersHistory: async (
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null
  ): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'online_orders_endpoint') // .replace('{{pageSize}}', pageSize + '').replace('{{currentPage}}', currentPage + '')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ pageSize, currentPage, fromDate, toDate })
      }
    }),
  loadOnlineOrdersHistory: async (
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null
  ): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'online_orders_endpoint') // .replace('{{pageSize}}', pageSize + '').replace('{{currentPage}}', currentPage + '')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ pageSize, currentPage, fromDate, toDate })
      }
    }),
  loadOfflineOrdersHistory: async (
    pageSize = config.ordersHistory.pageSize,
    currentPage = 1,
    fromDate = null,
    toDate = null
  ): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'offline_orders_endpoint')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ pageSize, currentPage, fromDate, toDate })
      }
    }),
  loadOfflineOrderItems: async (chequeId: string): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'offline_order_items_endpoint')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ chequeId: chequeId })
      }
    }),
  cancelOrder: async (orderNumber, reasonId): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'orderCancel_endpoint')
          .replace('{{increment_id}}', orderNumber)
          .replace('{{reason_id}}', reasonId)
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ comment: '' })
      }
    }),
  trackingSave: async ({ receivedAt, provider, trackingId }): Promise<Task> => {
    const body = {
      customerTrack: {
        provider,
        received_at: receivedAt
      }
    }

    if (trackingId) {
      body.customerTrack['track_id'] = trackingId
    }

    return TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.users, 'trackingSave')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(body)
      }
    })
  }
};
