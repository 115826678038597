import { RobotsEnum } from './types';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';

export function metaPages () {
  const title = `${this.$t(this.$route.meta.title)} | VARUS`;
  const description = `${title} ${this.$t('META_DESCRIPTION')}`;
  const relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  const meta = {
    title: title,
    meta: {
      ...relRobots,
      title: {
        name: 'title',
        content: title
      },
      description: {
        name: 'description',
        content: description
      }
    }
  };

  return mergeMeta(meta)
}
