import { Module } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import { NewsletterService } from 'theme/store/newsletter/data-resolver/NewsletterService';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import Task from '@vue-storefront/core/lib/sync/types/Task'

export const newsletterStore: Module<void, RootState> = {
  namespaced: true,
  actions: {
    async subscribe (ctx, email): Promise<Task> {
      const { storeCode } = currentStoreView()
      return NewsletterService.subscribe(email, storeCode)
    }
  }
}
