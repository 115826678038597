<template>
  <div class="sf-chevron">
    <!-- @slot Custom chevron markup -->
    <slot>
      <span class="sf-chevron__bar sf-chevron__bar--left"></span>
      <span class="sf-chevron__bar sf-chevron__bar--right"></span>
    </slot>
  </div>
</template>
<script>
import focus from '$ui/directives/focus';
export default {
  name: 'SfChevron',
  directives: {
    focus
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfChevron.scss";
</style>
