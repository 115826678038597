import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import { SearchQuery } from 'storefront-query-builder'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import config from 'config'
import modulesConfig from '$modules/config'
import { getCurrentShopId } from 'theme/store/checkout/helpers';
import { prepareCategoryProduct } from 'theme/helpers';
import rootStore from '@vue-storefront/core/store'

export const homepageStore = {
  namespaced: true,
  state: {
    bestsellers: [],
    sampleSpecialCategories: [],
    sampleProducts: [],
    sampleProductsPopular: [],
    sampleProductsVarusCafe: [],
    sampleProductsCategory: [],
    advantagesContent: '',
    mainCategory: [],
    bordProducts: []
  },
  actions: {
    async loadBestsellers ({ commit, dispatch }) {
      const response = await dispatch('product/findProducts', {
        query: prepareQuery({ queryConfig: 'bestSellers' }),
        size: 8,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        skipLoadOptions: true
      }, { root: true })

      commit('SET_BESTSELLERS', response.items)
    },
    async loadSampleSpecialCategories ({ commit, dispatch }) {
      const filters = { is_not_product_category: true };

      let specialCategories = await dispatch('category-next/loadCategories', { filters }, { root: true });

      if (specialCategories.length) {
        const productLoadingTasks = specialCategories.map(category => ({
          category,
          loadProductsTask: dispatch('loadProductsForCategory', { categoryId: category.id })
        }));

        const loadedCategories = await Promise.all(productLoadingTasks.map(task => task.loadProductsTask));

        // Merge products back into categories
        specialCategories = specialCategories
          .map((category, index) => {
            return { ...category, products: (loadedCategories[index] || []) };
          })
          .filter((item) => !!item.products.length)
      }

      commit('SET_SAMPLE_SPECIAL_CATEGORIES', specialCategories);
    },
    async loadProductsForCategory ({ dispatch }, { categoryId }) {
      const query = new SearchQuery();
      query.applyFilter({ key: 'category_id', value: { 'eq': categoryId }, scope: 'default' });

      const loadProducts = {
        query,
        size: config.entities.product.carouselSize,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        skipLoadOptions: true,
        onlyInStock: true
      };

      const response = await dispatch('product/findProducts', loadProducts, { root: true });
      return response.items;
    },
    async loadSampleProducts ({ commit, dispatch }, type = '') {
      let query = new SearchQuery()
      let onlyInStock = false

      if (type === 'specialOffers') {
        const shopId = await getCurrentShopId();

        // todo:: need to use the product attribute for SpecialOffers when it will be created
        query = query.applyFilter(
          {
            key: 'has_promotion_in_stores',
            value: { 'in': [shopId] },
            scope: 'catalog'
          });

        onlyInStock = true
      }

      const loadProducts = {
        query,
        size: config.entities.product.carouselSize,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        onlyInStock,
        skipLoadOptions: true
      }

      const response = await dispatch('product/findProducts', loadProducts, { root: true })

      commit('SET_SAMPLE_PRODUCTS', response.items)
    },
    async loadSampleProductsCategory ({ commit, dispatch }, categoryId = 0) {
      if (!categoryId) return

      const currentRoute = rootStore.state.url.currentRoute
      const isBrandCategory = currentRoute.name.includes('brands')
      const shopId = await getCurrentShopId()

      const query = new SearchQuery()
        .applyFilter({
          key: isBrandCategory ? 'pim_brand_id' : 'category_ids',
          value: { 'in': isBrandCategory ? [categoryId] : categoryId }
        })
        .applyFilter(
          {
            key: 'has_promotion_in_stores',
            value: { 'in': [shopId] },
            scope: 'catalog'
          })

      const response = await dispatch('product/findProducts', {
        query,
        size: config.entities.product.carouselSize,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        onlyInStock: true,
        skipLoadOptions: true
      }, { root: true })

      commit('SET_SAMPLE_PRODUCTS_CATEGORY', response.items)
      return response.items
    },
    async loadSampleProductsPopular ({ commit, dispatch }, onlyInStock = false) {
      const query = new SearchQuery()

      const response = await dispatch('product/findProducts', {
        query,
        size: config.entities.product.queryMaxSize,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProductWithCats.includeFields,
        excludeFields: modulesConfig.smallProductWithCats.excludeFields,
        onlyInStock: onlyInStock,
        skipLoadOptions: true
      }, { root: true })

      commit('SET_SAMPLE_PRODUCTS_POPULAR', response.items)
    },
    async loadSampleProductsVarusCafe ({ commit, dispatch }, onlyInStock = false) {
      // Helper function to create a query.
      const createQuery = (categoryId) => {
        const query = new SearchQuery();

        query.applyFilter({ key: 'category_id', value: { 'eq': categoryId }, scope: 'default' })
          .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
          .applyFilter({ key: 'status', value: { 'in': [0, 1] } })
        return query;
      };

      const findProducts = async (query) => {
        return dispatch('product/findProducts', {
          query,
          size: config.entities.product.carouselSize,
          sort: 'created_at:desc',
          onlyInStock,
          includeFields: modulesConfig.smallProductWithCats.includeFields,
          excludeFields: modulesConfig.smallProductWithCats.excludeFields,
          skipLoadOptions: true
        }, { root: true });
      };

      const queries = modulesConfig.sampleVarusCafe.categoryIds.map(createQuery);
      const responses = await Promise.all(queries.map(findProducts));
      const allItems = responses.flatMap(response => response.items);

      commit('SET_SAMPLE_PRODUCTS_VARUS_CAFE', allItems)
    },
    async loadAdvantagesContent ({ commit, getters }) {
      if (getters.getAdvantagesContent.length) return

      const query = new SearchQuery()
      query.applyFilter({ key: 'identifier', value: { 'in': config.cmsBlocks.advantages } })

      const { items } = await quickSearchByQuery({
        query,
        entityType: 'cms_block',
        size: 1
      })

      commit('SET_ADVANTAGES_CONTENT', items[0]?.content || '')
    },
    async loadMainCategory ({ commit }) {
      const filters = { id: config.entities.category.categoriesRootCategoryId }
      const category = await rootStore.dispatch('category-extension/loadCategoryPure', {
        filters,
        includeFields: ['description', 'id']
      }, { root: true })

      commit('SET_MAIN_CATEGORY', category || {});
    },
    async loadBordProduct ({ commit, dispatch }) {
      const searchQuery = new SearchQuery();
      const query = searchQuery.applyFilter({ key: 'boardProduct', value: { 'eq': 1 }, scope: 'default' });

      const currentProducts = await dispatch('product/findProducts', {
        query,
        size: 12,
        sort: 'created_at:desc',
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        onlyInStock: true,
        skipLoadOptions: true
      }, { root: true });

      commit('SET_BORD_PRODUCTS', currentProducts.items);
    }
  },
  mutations: {
    SET_BESTSELLERS (state, bestsellers) {
      state.bestsellers = bestsellers
    },
    SET_SAMPLE_SPECIAL_CATEGORIES (state, sampleSpecialCategories) {
      state.sampleSpecialCategories = sampleSpecialCategories
    },
    SET_SAMPLE_PRODUCTS (state, sampleProducts) {
      state.sampleProducts = sampleProducts
    },
    SET_SAMPLE_PRODUCTS_POPULAR (state, sampleProductsPopular) {
      state.sampleProductsPopular = sampleProductsPopular
    },
    SET_SAMPLE_PRODUCTS_VARUS_CAFE (state, sampleProductsVarusCafe) {
      state.sampleProductsVarusCafe = sampleProductsVarusCafe.map(prepareCategoryProduct)
    },
    SET_ADVANTAGES_CONTENT (state, content) {
      state.advantagesContent = content
    },
    SET_SAMPLE_PRODUCTS_CATEGORY (state, sampleProductsCategory) {
      state.sampleProductsCategory = sampleProductsCategory
    },
    SET_MAIN_CATEGORY (state, mainCategory) {
      state.mainCategory = mainCategory
    },
    SET_BORD_PRODUCTS (state, products) {
      state.bordProducts = products
    }
  },
  getters: {
    getBestsellers (state) {
      return state.bestsellers
    },
    getSampleSpecialCategories (state) {
      return state.sampleSpecialCategories
    },
    getSampleProducts (state) {
      return state.sampleProducts
    },
    getSampleProductsPopular (state) {
      return state.sampleProductsPopular
    },
    getSampleProductsVarusCafe (state) {
      return state.sampleProductsVarusCafe
    },
    getAdvantagesContent (state) {
      return state.advantagesContent
    },
    getSampleProductsCategory (state) {
      return state.sampleProductsCategory
    },
    getMainCategory (state) {
      return state.mainCategory
    },
    getBordProducts (state) {
      return state.bordProducts
    }
  }
}
