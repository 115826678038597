export default {
  name: 'DeviceType',

  computed: {
    isMobile () {
      return this.$mq === 'xsm' || this.$mq === 'sm' || this.$mq === 'xs';
    },
    isDesktop () {
      return !this.isMobile && !this.isTablet;
    },
    isTablet () {
      return this.$mq === 'md';
    },
    isLaptop () {
      return this.$mq === 'lg';
    }
  }
};
