/* eslint-disable */
// @ts-nocheck
import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import { cartStore as coreCartStore } from '@vue-storefront/core/modules/cart/store';
import RootState from 'core/types/RootState';
import { CartState } from '../types/CartState'
import merge from 'lodash/merge';

export const cartStore: Module<CartState, RootState> = {
  namespaced: true,
  state: merge(coreCartStore.state, {
    editMode: null,
    cartStates: {
      shippingMethod: {
        valid: true,
        message: ''
      },
      productStock: {
        valid: true,
        replacements: [],
        notEnough: []
      },
      shippingTimeSlots: {
        valid: true
      },
      maxWeight: {
        valid: true,
        maxWeightToKG: 0
      }
    },
    cartSQPP: [],
    productStockResult: [],
    productComments: [],
    isConnecting: false,
    isSyncing: false,
    isSyncingTotal: false,
    lastPull: null,
    revertRule: null
  }),
  getters: merge(coreCartStore.getters, getters),
  actions: merge(coreCartStore.actions, actions),
  mutations: merge(coreCartStore.mutations, mutations)
}
