import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ShippingState from '../../types/ShippingState'
import getters from 'theme/store/checkout/store/shipping/getters';
import actions from 'theme/store/checkout/store/shipping/actions';
import mutations from 'theme/store/checkout/store/shipping/mutations';
import config from 'config'
import { initialState } from 'theme/store/checkout/helpers/delivery-helper';

export const shippingModule: Module<ShippingState, RootState> = {
  namespaced: true,
  state: {
    loaded: {
      shippingDetails: false,
      shopList: false
    },
    loading: {
      shippingDetailsSave: false,
      shopList: false
    },
    cities: [],
    defaultCity: null,
    defaultShop: null,
    shops: [],
    shopsAll: [],
    selectedShop: {},
    geoLocateShop: {
      coordinates: {
        lat: 0,
        lng: 0
      },
      shops: {}
    },
    userCoordinates: {
      lat: 0,
      lng: 0
    },
    shippingMethods: config.shipping.methods,
    shippingDetails: initialState,
    deliveryPolygon: {
      tms_id: null,
      list: []
    }
  },
  getters,
  actions,
  mutations
}
