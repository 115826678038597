import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import config from 'config';

const getCacheKey = (product, cacheByKey, shopId) => {
  if (!product[cacheByKey]) {
    cacheByKey = 'id';
  }

  return entityKeyName(
    cacheByKey,
    product[cacheByKey === 'sku' && product['parentSku'] ? 'parentSku' : cacheByKey],
    shopId
  );
};

export const storeProductToCache = (product, cacheByKey, shopId) => {
  const cacheKey = getCacheKey(product, cacheByKey, shopId);
  const cache = StorageManager.get('elasticCache');

  // @ts-ignore
  cache.setItem(cacheKey, product, null, config.products.disablePersistentProductsCache, 'product')
};
