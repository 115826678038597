import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CategoryExtensionState from '../types/CategoryExtensionState'
import Vue from 'vue';

const mutations: MutationTree<CategoryExtensionState> = {
  [types.SET_CHILD_CATEGORIES] (state, categories) {
    state.childCategories = categories
  },
  [types.SET_RELATED_CHILD_CATEGORIES] (state, categories) {
    state.relatedChildCategories = categories
  },
  [types.SET_CATEGORIES_PRODUCTS_COUNT] (state, counts) {
    state.categoriesProductsCount = counts
  },
  [types.SET_IS_CATEGORY_PRODUCTS_OUT_OF_STOCK] (state, isCategoryProductsOutOfStock) {
    state.isCategoryProductsOutOfStock = isCategoryProductsOutOfStock;
  },
  [types.CATEGORY_SET_CATEGORY_FILTERS] (state, { category, filters }) {
    Vue.set(state.filtersMap, category.id, filters)
  },
  [types.CATEGORY_SET_RESULT_CATEGORIES] (state, payload) {
    state.categories = [...payload]
  },
  [types.SET_VARUS_CAFE_CATEGORIES] (state, payload) {
    state.varusCafeCategories = [...payload]
  },
  [types.SET_VARUS_CAFE_CATEGORIES_LOADING] (state, payload) {
    state.varusCafeCategoriesLoading = !!payload
  },
  [types.SET_CURRENT_CATEGORY] (state, category) {
    state.currentCategory = category
  },
  [types.SET_PRODUCT_AGGREGATION] (state, aggregations) {
    state.aggregations = aggregations
  },
  [types.SET_FILTER_PRICE] (state, filter) {
    state.filterPrice = filter
  },
  [types.SET_VARUS_NEW_PRODUCTS] (state, products) {
    state.varusNewProducts = products;
  },
  [types.ADD_VARUS_NEW_PRODUCTS] (state, products) {
    state.varusNewProducts.push(...products)
  },
  [types.SET_IS_BRAND_CATEGORY] (state, status) {
    state.isBrandCategory = status;
  },
  [types.SET_VARUS_NEW_PRODUCTS_LOADING] (state, isLoading) {
    state.newProductsLoading = isLoading;
  },
  [types.SET_VARUS_NEW_PRODUCTS_TOTAL] (state, total) {
    state.newProductsTotal = total;
  },
  [types.SET_CLICK_LOAD_MORE] (state, status) {
    state.clickLoadMore = status;
  },
  [types.SET_SPECIAL_CATEGORY_LOADING] (state, isLoading) {
    state.specialCategoryLoading = isLoading;
  }
};

export default mutations;
