import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import BonusCardState from '../types/BonusCardState'

const getters: GetterTree<BonusCardState, RootState> = {
  getBonusCard: (state) => state.bonusCard,
  isBonusCardExist: (state) => Object.keys(state.bonusCard).length
}

export default getters
