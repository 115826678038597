import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from './types/UserState'

const getters: GetterTree<UserState, RootState> = {
  getCurrentUser: (state) => state.currentUser,
  getSearchHistory: (state) => state.searchHistory,
  getIsNewUserFlag: (state) => state.isNewUser,
  getUserAvatar: (state) => state.avatar,
  validationStatus: (state) => state.validationStatus
};

export default getters
