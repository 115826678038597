import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import RootState from '@vue-storefront/core/types/RootState';
import ProductExtensionState from './../types/ProductExtensionState';

export const categoryExtStore: Module<ProductExtensionState, RootState> = {
  namespaced: true,
  state: {
    characteristicAttributes: [],
    characteristicAttributeCodes: [],
    topCharacteristicAttributeCodes: [],
    productAttributeLabelMap: {},
    inStockProductVariants: [],
    varusPerfectProducts: []
  },
  actions,
  mutations,
  getters
}
