import { GetterTree } from 'vuex'
import CheckoutState from '../../types/CheckoutState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<CheckoutState, RootState> = {
  getPersonalDetails: state => state.personalDetails,
  getPaymentDetails: state => state.paymentDetails,
  isThankYouPage: state => state.isThankYouPage,
  getModifiedAt: state => state.modifiedAt,
  isUserInCheckout: state => ((Date.now() - state.modifiedAt) <= (60 * 30 * 1000)),
  isCheckoutPage: (state, getters, rootState) => {
    // @ts-ignore
    return ['checkout', 'ru-checkout'].includes(rootState?.route?.name)
  },
  getPaymentMethods: (state, getters, rootState, rootGetters) => {
    const isVirtualCart = rootGetters['cart/isVirtualCart']

    return state.paymentMethods.filter(method => !isVirtualCart || method.code !== 'cashondelivery')
  },
  getDefaultPaymentMethod: (state, getters, rootState, rootGetters) => {
    const currentShop = rootGetters['shipping/getCurrentShop'];
    if (!currentShop) return;

    const currentShippingMethod = rootGetters['shipping/getShippingDetails'].shippingMethod;
    const availablePaymentMethods = (currentShop.delivery_methods || []).find(item => item.key === currentShippingMethod);
    if (!availablePaymentMethods) return;

    const defaultPaymentMethod = availablePaymentMethods.default_payment_method;
    return getters.getPaymentMethods.find(item => item.code === defaultPaymentMethod);
  },
  getNotServerPaymentMethods: (state, getters) =>
    getters.getPaymentMethods.filter((itm) =>
      (typeof itm !== 'object' || !itm.is_server_method)
    ),
  getActiveStep: state => state.activeStep,
  getConfirmationInfo: state => state.confirmation,
  getOrderInformation: state => state.order,
  getSummary: state => state.summary,
  // @deprecated
  getShippingDetails: (state, getters, rootState, rootGetters) => (
    rootGetters['shipping-module/fallbackShippingDetails']
  ),
  // @deprecated
  getShippingMethods: (state, getters, rootState, rootGetters) => {
    return rootGetters['shipping/getShippingMethods']
  },
  getShippingTimeSlots: state => state.shippingTimeSlots,
  getCurrentTimeSlot: state => state.currentTimeSlot
}

export default getters
