import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserPaymentCardsState from '../types/UserPaymentCardsState';
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const userPaymentCardsStore: Module<UserPaymentCardsState, RootState> = {
  namespaced: true,
  state: {
    paymentCards: [],
    paymentCardsLoaded: false
  },
  actions,
  getters,
  mutations
}
