import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ReviewState from '../types/ReviewState';
import { ReviewService } from '../data-resolver/ReviewServices'
import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'
import { SearchQuery } from 'storefront-query-builder';

export const ReviewStore: Module<ReviewState, RootState> = {
  namespaced: true,
  state: {
    reviewProduct: [],
    reviewProductByCustomer: []
  },
  getters: {
    getReviewsProduct: (state) => state.reviewProduct,
    getReviewsProductByCustomer: (state) => state.reviewProductByCustomer
  },
  actions: {
    async sendReview (ctx, review) {
      await ReviewService.setReview({ review: review })
    },

    async upvoteReview (ctx, id) {
      await ReviewService.setUpvote({ id: id })
    },

    async downvoteReview (ctx, id) {
      await ReviewService.setDownvote({ id: id })
    },

    async answerReview (ctx, review) {
      await ReviewService.setAnswer({ review: review })
    },

    async loadReview ({ commit }, productId) {
      const query = new SearchQuery()

      query.applyFilter({ key: 'product_id', value: { 'eq': productId } })
      const reviewList = await quickSearchByQuery({
        query,
        sort: 'created_at:desc',
        entityType: 'aw_review'
      });

      commit('SET_REVIEW_PRODUCT', reviewList.items)
    },

    async loadReviewByCustomer ({ commit }, customerId) {
      const query = new SearchQuery()

      query.applyFilter({ key: 'customer_id', value: { 'eq': customerId } })
      const reviewList = await quickSearchByQuery({
        query,
        sort: 'created_at:desc',
        entityType: 'aw_review'
      });

      commit('SET_REVIEW_CUSTOMER', reviewList.items)
    }
  },
  mutations: {
    SET_REVIEW_PRODUCT (state, review) {
      state.reviewProduct = review || []
    },
    SET_REVIEW_CUSTOMER (state, review) {
      state.reviewProductByCustomer = review || []
    }
  }
}
