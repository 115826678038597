import { server } from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'

if (process.env.NODE_ENV === 'production' || server.devServiceWorker) {
  self.addEventListener('install', () => {
    self.skipWaiting();
  });

  const serviceWorker = {
    ready () {
      Logger.log(
        'App is being served from cache by a service worker.'
      )
    },
    cached () {
      Logger.log('Content has been cached for offline use.')()
    },
    updated () {
      Logger.log('New content is available, please refresh.')()
    },
    offline () {
      Logger.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error (error) {
      Logger.error('Error during service worker registration:', error)()
    }
  }

  const checkDate = async () => {
    try {
      const getDataFromLS = localStorage.getItem('last-cache-key')

      if (!self.errver || getDataFromLS === self.errver) return

      const cacheNames = await caches.keys()

      for (let name of cacheNames) {
        await caches.delete(name);
      }

      await localStorage.setItem('last-cache-key', window.errver)

      const registrations = await navigator.serviceWorker.getRegistrations()

      for (const registration of registrations) {
        const validateVersionedSW = new RegExp('dist\\/v[a-zA-Z0-9]{0,8}', 'g')
          .test(registration.scope)

        const validateOldSW = (!!registration?.active?.scriptURL && !!self.location.host) && (
          new RegExp(`${self.location.host}\\/service-worker`, 'g')
            .test(registration.active.scriptURL)
        )

        if (validateVersionedSW || validateOldSW) {
          await registration.unregister()
        }
      }

      await navigator.serviceWorker.register(`/dist/${self.errver}/service-worker.js`, serviceWorker)
    } catch (e) {
      console.error('[ServiceWorker]', e)
    }
  }

  void checkDate()
}
