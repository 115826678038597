import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import VarusCafeState from '../types/VarusCafeState'
import { Category } from '../types/Category'
import { getFiltersFromQuery } from 'theme/helpers/filterHelpers';

const getters: GetterTree<VarusCafeState, RootState> = {
  getCategoryById: (state, getters) => (id: number | string) => {
    const categories = getters.getCategoriesMap as Category[]
    return categories[id] || {}
  },
  getCurrentCategoryPath: state => state.current_path,
  getAllCategoryFilters: state => state.filters,
  getActiveCategoryFilters: state => state.filters.chosen,
  getAvailableCategoryFilters: state => state.filters.available,
  getCurrentCategoryProductQuery: state => state.current_product_query,
  getCategoryBreadcrumbs: state => state.breadcrumbs,
  getCategoriesMap: state => {
    const categoriesMap = {}

    const addCategoryToMap = (category) => {
      categoriesMap[category.id] = category
      if (category.children_data && category.children_data.length > 0) {
        category.children_data.forEach(addCategoryToMap)
      }
    }

    state.list.forEach(addCategoryToMap)

    return categoriesMap
  },
  getCurrentFiltersFrom: (state, getters, rootState) => (filters, category) => {
    const currentQuery = filters || { }
    const availableFilters = rootState['category-extension']?.filtersMap[category.id] || {}
    return getFiltersFromQuery({ availableFilters, filtersQuery: currentQuery })
  },
  getCurrentFilters: (state, getters) => (route, category) => getters.getCurrentSearchQuery(route, category).filters,
  getCurrentSearchQuery: (state, getters) => (route, category) => getters.getCurrentFiltersFrom(route?.query, category)
}

export default getters
