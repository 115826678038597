import { Module } from 'vuex'
import { SearchQuery } from 'storefront-query-builder'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'
import RootState from '@vue-storefront/core/types/RootState'
import BlogState from './types/BlogState';
import { BlogService } from './data-resolver/BlogService'
import { Logger } from '@vue-storefront/core/lib/logger'

export const BlogStore: Module<BlogState, RootState> = {
  namespaced: true,
  state: {
    isLoadingBlog: false,
    isFilterLoading: false,
    posts: [],
    postPage: {},
    years: [],
    blogCategories: [],
    total: 0,
    currentPage: 1
  },
  getters: {
    getDefaultYear: state => {
      return state.years.sort((a, b) => a - b)[0]
    },
    getPosts: state => {
      return state.posts
    },
    getYears: state => {
      return state.years
    },
    getBlogCategories: state => {
      return state.blogCategories
    },
    getIsLoadingBlog: state => {
      return state.isLoadingBlog
    },
    getIsFilterLoading: state => {
      return state.isFilterLoading
    },
    getTotal: state => {
      return state.total
    },
    getCurrentPage: state => {
      return state.currentPage
    },
    getPostPage: state => {
      return state.postPage
    }
  },
  actions: {
    getVotes () {
      return JSON.parse(localStorage.getItem('blog/votes')) || []
    },
    async fetchBlogs (ctx, { query, pageSize = 24, start = 0 }) {
      query.applyFilter({ key: 'publish_time', value: { 'lt': 'now' } })
      query.applySort({ field: 'publish_time', options: { order: 'desc' } })

      return BlogService.loadBlogs({ query, pageSize, start })
    },
    async saveVotes ({ dispatch }, { postId }) {
      const getVoteIds = await dispatch('getVotes')

      getVoteIds.push(postId)

      localStorage.setItem('blog/votes', JSON.stringify(getVoteIds))
    },
    async checkVote ({ dispatch }, { postId }) {
      const getVoteIds = await dispatch('getVotes')

      return getVoteIds.length > 0 && getVoteIds.includes(postId)
    },
    async getRelatedArticlesByproductId ({ commit }, { productId }) {
      const query = new SearchQuery()

      query.applyFilter({ key: 'related_product_ids', value: { 'eq': productId } })

      const { items } = await BlogService.loadBlogs({ query })

      commit('SET_POSTS', items)
    },
    async loadBlogs ({ commit, dispatch, state }, { route } = {}) {
      commit('SET_LOADING_BLOG', true)

      const defaultPageSize = 24
      const { page = 1, loadMore = false } = route?.query || {}

      const start = (page - 1) * defaultPageSize;
      const pageSize = loadMore ? page * defaultPageSize : defaultPageSize;

      commit('SET_CURRENT_PAGE', page)

      const query = new SearchQuery()
      query.addAvailableFilter({ field: 'year', scope: 'catalog' })

      const response = await dispatch('fetchBlogs', { query, pageSize, start })

      const {
        items = [],
        total = { value: 0 },
        aggregations = { agg_terms_year: { buckets: [] } }
      } = response || {}

      await dispatch('loadBlogCategories')

      const posts = [...state.posts, ...items]

      commit('SET_POSTS', posts)
      commit('SET_TOTAL_POSTS', total.value)
      commit('SET_FILTER_YEARS', aggregations.agg_terms_year.buckets)
      commit('SET_LOADING_BLOG', false)

      return posts
    },
    async loadBlogCategories ({ commit }) {
      const query = new SearchQuery()
      const { items } = await BlogService.getBlogCategories({ query })

      commit('SET_FILTER_BLOG_CATEGORIES', items)
    },
    async filterByValue ({ commit, dispatch }, { key, value }) {
      commit('SET_FILTER_LOADING', true)
      const query = new SearchQuery()

      query.applyFilter({ key, value })
      const response = await dispatch('fetchBlogs', { query })
      const { items, total } = response

      commit('SET_CURRENT_PAGE', 1)
      commit('SET_POSTS', items)
      commit('SET_TOTAL_POSTS', total?.value)
      commit('SET_FILTER_LOADING', false)
    },
    async resetPosts ({ commit, dispatch }) {
      commit('SET_FILTER_LOADING', true)
      const searchQuery = new SearchQuery()

      const response = await dispatch('fetchBlogs', { query: searchQuery })

      const items = response?.items
      const total = response?.total.value

      commit('SET_POSTS', items)
      commit('SET_TOTAL_POSTS', total)
      commit('SET_FILTER_LOADING', false)
    },
    async updateVote ({ dispatch }, { postId, voteType, vote }) {
      try {
        let res;
        const { code, result } = await BlogService.setPostVote({ postId, voteType, vote })

        if (code === 200) {
          res = result
          dispatch('saveVotes', { postId })
        } else {
          res = vote
        }

        return res
      } catch (e) {
        Logger.debug(e, 'updateVote')()

        return vote
      }
    },
    async loadPostByUrl ({ commit }, { identifier }) {
      const query = new SearchQuery()

      commit('SET_LOADING_BLOG', true)

      query.applyFilter({ key: 'identifier', value: { 'eq': identifier } })

      const { items } = await BlogService.loadBlogs({ query })

      const item = items.length > 0 ? items[0] : []

      commit('SET_POST_PAGE', item)

      commit('SET_LOADING_BLOG', false)
    },
    async loadViews (ctx, { postId, oldViews }) {
      try {
        const { result, code } = await BlogService.setViews({ postId })
        if (code === 200) {
          return result
        } else {
          return oldViews
        }
      } catch (error) {
        return oldViews
      }
    },
    resetCurrentPage ({ commit }) {
      commit('SET_CURRENT_PAGE', 1)
      commit('SET_POSTS', [])
    }
  },
  mutations: {
    SET_POSTS (state, posts) {
      state.posts = posts || []
    },
    SET_TOTAL_POSTS (state, total) {
      state.total = total
    },
    SET_LOADING_BLOG (state, status) {
      state.isLoadingBlog = status
    },
    SET_FILTER_LOADING (state, status) {
      state.isFilterLoading = status
    },
    SET_FILTER_YEARS (state, years) {
      state.years = years || []
    },
    SET_FILTER_BLOG_CATEGORIES (state, categories) {
      state.blogCategories = categories || []
    },
    SET_CURRENT_PAGE (state, status) {
      state.currentPage = status
    },
    SET_POST_PAGE (state, status) {
      state.postPage = status
    }
  }
}

export const BlogModule: StorefrontModule = function () {
  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('blog_posts', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'blog_posts', start, size)
      }
    });
  });
  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('blog_category', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'blog_category', start, size)
      }
    });
  });
}
