import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { getFAQForProductCategory } from '../data-resolver/FAQService'
import { FAQState } from '$modules/faq/types';
import * as types from './mutation-types'

const actions: ActionTree<FAQState, RootState> = {
  async loadFAQForCategory ({ commit }, { id }) {
    const response = await getFAQForProductCategory({ id })
    commit(types.SET_QUESTIONS, { questions: response })
  }
}

export default actions
