export const searchOptions = [
  { id: 'relevance', label: 'Relevance' },
  { id: 'price.asc', label: 'Price: Low to high' },
  { id: 'price.desc', label: 'Price: High to low' }
]

export default {
  name: 'Multisearch',
  computed: {
    sortOptions () {
      return searchOptions;
    }
  },
  methods: {
    autocomplete (searchText) {
      return this.$store.dispatch(
        'search-result/multisearchAutocomplete',
        {
          searchText
        }
      )
    },
    autocompleteClear () {
      return this.$store.dispatch(
        'search-result/multisearchAutocompleteClear'
      )
    },
    autocompleteCorrectionClear () {
      return this.$store.dispatch('search-result/multisearchCorrectionClear')
    }
  }
}
