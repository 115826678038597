export const allSettled = (promises) => Promise.all(
  promises.map(
    async (promise) => {
      try {
        const value = await promise

        return {
          status: 'fulfilled', value
        }
      } catch (reason) {
        return {
          status: 'rejected',
          reason
        }
      }
    }
  )
)
