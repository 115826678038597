<template>
  <component
      :is="linkComponentTag"
      v-focus
      v-bind="urlTag"
      class="sf-link"
      v-on="$listeners"
      event
      @click.native="handleClick"
  >
    <!-- @slot -->
    <slot />
  </component>
</template>
<script>
import focus from '$ui/directives/focus';
import { isServer } from '@vue-storefront/core/helpers';
export default {
  name: 'SfLink',
  directives: { focus },
  props: {
    /**
     * Page route
     */
    link: {
      type: [String, Object],
      default: ''
    },
    muted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isExternal () {
      return (
        typeof this.link === 'string' && this.link.search(/(^\/|^#)/g) === -1
      );
    },
    isNativeLinkTag () {
      return this.isExternal || !this.$router;
    },
    urlTag () {
      return this.isNativeLinkTag ? { href: this.link } : { to: this.link };
    },
    linkComponentTag () {
      const routerLink = this.$nuxt ? 'nuxt-link' : 'router-link';
      return this.isNativeLinkTag ? 'a' : routerLink;
    }
  },
  methods: {
    handleClick (event) {
      if (isServer) return;

      if (this.muted) {
        event.preventDefault();
        this.$emit('click', event);
      } else {
        this.$router.push(this.link);
      }
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfLink.scss";
</style>
