import { ActionTree } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import { SearchQuery } from 'storefront-query-builder'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import modulesConfig from '$modules/config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { ProductService } from '../../data-resolver/ProductService'
import { registerProductsMapping, setRequestCacheTags } from '@vue-storefront/core/modules/catalog/helpers'
import { getProductStock, prepareCategoryProduct, prepareProductStepValues } from 'theme/helpers'
import { getProductConfigurationOptions } from '@vue-storefront/core/modules/catalog/helpers/productOptions'
import * as types from '@vue-storefront/core/modules/catalog/store/product/mutation-types'
import rootStore from '@vue-storefront/core/store'
import { checkParentRedirection } from '@vue-storefront/core/modules/catalog/events'

const actions: ActionTree<ProductState, RootState> = {
  async findProducts (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    excludeFields = modulesConfig.smallProduct.excludeFields,
    includeFields = modulesConfig.smallProduct.includeFields,
    configuration = null,
    populateRequestCacheTags = false,
    onlyInStock = false,
    onlyMarkdown = false,
    loadDiscountedProducts = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants,
      skipLoadOptions = false
    } = {}
  } = {}) {
    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      onlyInStock,
      onlyMarkdown,
      loadDiscountedProducts,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    registerProductsMapping(context, items)

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }

    if (populateRequestCacheTags || populateRequestCacheTagsNew) {
      setRequestCacheTags({ products: items })
    }

    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true })

    const productValues = prepareProductStepValues(items);

    if (!skipLoadOptions && productValues.length !== 0) {
      await context.dispatch('product-extension/loadProductsOptionByValues', { productValues }, { root: true });
    }

    return { ...restResponseData, items }
  },
  async categoryProductsInStock ({ rootGetters }, {
    query,
    sort = ''
  } = {}) {
    try {
      if (isServer) return true

      const isNewPost = rootGetters['shipping-module/isCurrentNewPost']

      const { items } = await ProductService.getProducts({
        query,
        start: 0,
        size: 1,
        sort,
        excludeFields: [],
        includeFields: ['forNewPost'],
        onlyInStock: true
      })

      const item = items?.[0] || null

      if (isNewPost) return !!(item?.forNewPost && item?.stock.is_in_stock)

      return !!(item?.stock.is_in_stock)
    } catch (e) {
      return false
    }
  },
  async findConfigurableParent (context, { product, configuration }) {
    const searchQuery = new SearchQuery()
    const query = searchQuery.applyFilter({ key: 'configurable_children.sku', value: { 'eq': product.sku } })
    const products = await context.dispatch('findProducts', { query, configuration })
    return products.items && products.items.length > 0 ? products.items[0] : null
  },

  async single (context, {
    options = {},
    setCurrentProduct = false,
    key = 'sku',
    skipCache = false
  } = {}) {
    if (setCurrentProduct) {
      Logger.warn('option `setCurrentProduct` is deprecated, will be not used from 1.13')()
    }
    if (!options[key]) {
      throw new Error('Please provide the search key ' + key + ' for product/single action!')
    }

    const product = await ProductService.getProductByKey({
      options,
      key,
      skipCache
    })

    await context.dispatch('tax/calculateTaxes', { products: [product] }, { root: true })

    if (setCurrentProduct) await context.dispatch('setCurrent', product)

    const pimBrandId = product[config.attributesCodes.pimBrandId];
    if (pimBrandId) {
      await context.dispatch('product-extension/loadProductsOptionByValues', { productValues: [pimBrandId], isBrands: true }, { root: true });
    }

    EventBus.$emitFilter('product-after-single', { key, options, product })

    return product
  },

  async loadPackages ({ dispatch }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'type_id', value: 'package' })

    return dispatch('findProducts', {
      query: query,
      size: config.entities.product.carouselSize
    })
  },

  async findRelatedProducts (context, { query, size, onlyInStock = false }) {
    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      size,
      onlyInStock,
      includeFields: modulesConfig.smallProductWithCats.includeFields,
      excludeFields: modulesConfig.smallProductWithCats.excludeFields
    });
    registerProductsMapping(context, items);
    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true });

    return { ...restResponseData, items };
  },

  /**
   * Set current product with given variant's properties
   * @param {Object} context
   * @param {Object} product
   */
  setCurrent (context, product) {
    if (product && typeof product === 'object') {
      const { configuration, ...restProduct } = product
      const productUpdated = Object.assign({}, restProduct, prepareCategoryProduct(restProduct))
      if (!config.products.gallery.mergeConfigurableChildren) {
        context.dispatch('setProductGallery', { product: productUpdated })
      }
      const productOptions = getProductConfigurationOptions({ product, attribute: context.rootState.attribute })
      context.commit(types.PRODUCT_SET_CURRENT_OPTIONS, productOptions)
      context.commit(types.PRODUCT_SET_CURRENT_CONFIGURATION, configuration || {})
      context.commit(types.PRODUCT_SET_CURRENT, productUpdated)
      return productUpdated
    } else Logger.debug('Unable to update current product.', 'product')()
  },
  async getProductsBySku ({ dispatch }, {
    skus = [],
    prefetchGroupProducts = false,
    updateState = false,
    onlyInStock = false
  }) {
    if (!skus || !skus.length) return []

    const query = new SearchQuery().applyFilter({ key: 'sku', value: { 'in': skus } })

    const response = await dispatch('findProducts', {
      query,
      size: skus.length,
      prefetchGroupProducts,
      updateState,
      onlyInStock
    });

    return response?.items || []
  },
  async getSimpleProductsBySku (ctx, {
    skus = [],
    includeFields = modulesConfig.smallProductWithCats.includeFields,
    excludeFields = modulesConfig.smallProductWithCats.excludeFields,
    loadDiscountedProducts = false,
    size = 4000,
    onlyMarkdown = false
  }) {
    if (!skus || !skus.length) return []

    const query = new SearchQuery().applyFilter({ key: 'sku', value: { 'in': skus } })

    const { items } = await ProductService.getProducts({
      query,
      size,
      includeFields,
      excludeFields,
      loadDiscountedProducts,
      onlyMarkdown
    });

    return items || []
  },
  async getSimpleProductsByID (ctx, {
    ids = [],
    includeFields = modulesConfig.smallProductWithCats.includeFields,
    excludeFields = modulesConfig.smallProductWithCats.excludeFields
  }) {
    if (!ids || !ids.length) return []

    const query = new SearchQuery().applyFilter({ key: 'id', value: { 'in': ids } })

    const { items } = await ProductService.getProducts({
      query,
      size: ids.length,
      includeFields,
      excludeFields
    });

    return items || []
  },
  async getProductsByID ({ dispatch }, {
    ids = [],
    prefetchGroupProducts = false,
    updateState = false,
    onlyInStock = false
  }) {
    if (!ids || !ids.length) return []

    const query = new SearchQuery().applyFilter({ key: 'id', value: { 'in': ids } })

    const response = await dispatch('findProducts', {
      query,
      size: ids.length,
      prefetchGroupProducts,
      updateState,
      onlyInStock,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      loadDiscountedProducts: true
    });

    return response?.items || []
  },
  /**
   * Load the product data and sets current product
   */
  async loadProduct ({ dispatch }, { slug, route = null, skipCache = false }) {
    Logger.info('Fetching product data asynchronously', 'product', { slug })()
    EventBus.$emit('product-before-load', { store: rootStore, route: route })

    const product = await dispatch('single', {
      options: {
        slug: slug,
        loadDiscountedProducts: true
      },
      key: 'slug',
      skipCache
    })

    product.stock = getProductStock(product)

    setRequestCacheTags({ products: [product] })

    await dispatch('setCurrent', product)

    if (product.status >= 2) {
      throw new Error(`Product query returned empty result product status = ${product.status}`)
    }

    if (product.visibility === 1) { // not visible individually (https://magento.stackexchange.com/questions/171584/magento-2-table-name-for-product-visibility)
      if (config.products.preventConfigurableChildrenDirectAccess) {
        const parentProduct = await dispatch('findConfigurableParent', { product })

        const check = checkParentRedirection(product, parentProduct)

        if (!check) {
          throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
        }
      } else {
        throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
      }
    }

    if (config.entities.attribute.loadByAttributeMetadata) {
      await dispatch('attribute/loadProductAttributes', { products: [product], merge: true }, { root: true })
    } else {
      await dispatch('loadProductAttributes', { product })
    }

    const syncPromises = []
    const gallerySetup = dispatch('setProductGallery', { product })
    if (isServer) {
      syncPromises.push(gallerySetup)
    }
    await Promise.all(syncPromises)
    await EventBus.$emitFilter('product-after-load', { store: rootStore, route: route })
    return product
  },
  async loadDiscountProducts ({ dispatch, commit }, { ids }) {
    if (!ids || !ids.length) {
      commit(types.SET_DISCOUNT_PRODUCTS, [])
      return
    }
    const products = await dispatch('getProductsByID', { ids: ids, prefetchGroupProducts: false, updateState: true, onlyInStock: true })
    const filteredProducts = products.filter(product => product.stock.is_in_stock === true)
    commit(types.SET_DISCOUNT_PRODUCTS, filteredProducts)
  }
}

export default actions
