import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const UserPaymentCardsService = {
  getPaymentOptions: async (): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.paymentCards, 'wayforpayVerificationLookup_endpoint'),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers
      }
    });
  },
  sendTokenData: async (payload): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.paymentCards, 'card_token_data_endpoint')
        .replace('{{phone}}', encodeURIComponent(payload.phone)),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ tokenData: JSON.stringify(payload.tokenData) })
      }
    });
  },
  changeDefaultPaymentCard: async (card): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.paymentCards, 'cardDefault_endpoint')
        .replace('{{cardMask}}', encodeURIComponent(card.card_mask)),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ merchantGateCard: card })
      }
    });
  },
  deletePaymentCard: async (card): Promise<Task> => {
    const sourceUrl = getApiEndpointUrl(config.paymentCards, 'card_endpoint')
    const preparedUrl = sourceUrl.replace('{{cardMask}}', encodeURIComponent(card.card_mask))
    return TaskQueue.execute({
      url: preparedUrl,
      payload: {
        method: 'DELETE',
        mode: 'cors',
        headers
      }
    });
  },
  getPaymentCardsList: async (): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.paymentCards, 'cardsList_endpoint'),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  }
}
