import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import ProductExtensionState, { AttributeOptionItem } from '../types/ProductExtensionState';
import Vue from 'vue';
import rootStore from '@vue-storefront/core/store'

const mutations: MutationTree<ProductExtensionState> = {
  [types.SET_CHARACTERISTIC_ATTRIBUTES] (state, attributes) {
    state.characteristicAttributes = attributes;
  },
  [types.SET_CHARACTERISTIC_ATTRIBUTE_CODES] (state, codes) {
    state.characteristicAttributeCodes = codes;
  },
  [types.SET_TOP_CHARACTERISTIC_ATTRIBUTE_CODES] (state, codes) {
    state.topCharacteristicAttributeCodes = codes;
  },
  [types.SET_PRODUCT_ATTRIBUTE_OPTIONS] (state, items: AttributeOptionItem[]) {
    for (const { label, value } of items) {
      if (label !== undefined && value !== undefined) {
        Vue.set(state.productAttributeLabelMap, value, label)
      }
    }
  },
  [types.SET_VARUS_PERFECT_PRODUCTS] (state, products) {
    state.varusPerfectProducts = products;
  },
  [types.SET_IN_STOCK_PRODUCT_VARIANTS] (state, products = []) {
    state.inStockProductVariants = products;
  },
  [types.UPDATE_CURRENT_PRODUCT_STOCK] (state, { sqpp, stock }) {
    rootStore.state.product.current.sqpp = sqpp
    rootStore.state.product.current.stock = stock
  }
};

export default mutations;
