import NotificationItem from '../types/NotificationItem'

export const Notification = {
  name: 'Notification',
  computed: {
    notification (): NotificationItem[] {
      return this.$store.getters['notification/notification']
    }
  }
}
