import rootStore from '@vue-storefront/core/store';
import VueRouter, { RouteConfig } from 'vue-router'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { ErrorHandler, RawLocation, Route, RouterOptions } from 'vue-router/types/router'
import { once } from '@vue-storefront/core/helpers'
import { router } from '@vue-storefront/core/app'
import { isServer } from '@vue-storefront/core/helpers/index';

once('__VUE_EXTEND_PUSH_RR__', () => {
  const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push (location: RawLocation, onComplete: any = () => true, onAbort?: ErrorHandler): Promise<Route> {
    if (onComplete || onAbort) return originalPush.call(this, location, onComplete, onAbort)
    return originalPush.call(this, location).catch(err => err)
  }
})

const isProductComponent = (component) => {
  const { name: componentName } = component;

  return componentName === 'Product';
}

const getScrollPosition = (to, from) => {
  if (to.hash) {
    return { selector: to.hash }
  }

  if (rootStore.getters['url/isBackRoute']) {
    const { scrollPosition = { x: 0, y: 0 } } = rootStore.getters['url/getCurrentRoute']
    return scrollPosition
  }

  if (to.path !== from.path) { // do not change scroll position when navigating on the same page (ex. change filters)
    return { x: 0, y: 0 }
  }
}

const getScrollBehavior = (to, from) => {
  const currentComponent = (
    router.currentRoute.matched?.[0]?.components?.default ||
    router.currentRoute.matched?.[0]?.instances?.default?.$options
  );

  if (currentComponent && isProductComponent(currentComponent)) {
    setTimeout(() => window.scroll(-1, 0))

    return { left: 0, top: 0 }
  }

  return getScrollPosition(to, from);
}

export const createRouter = (): VueRouter => {
  const options: RouterOptions = {
    mode: 'history',
    base: __dirname
  }

  if (!isServer) {
    options.scrollBehavior = getScrollBehavior
  }

  return new VueRouter(options)
}

export const createRouterProxy = (router: VueRouter): VueRouter => {
  const ProxyConstructor = Proxy || require('proxy-polyfill/src/proxy')

  return new ProxyConstructor(router, {
    get (target, propKey) {
      const origMethod = target[propKey]

      if (propKey === 'addRoutes') {
        return function (routes: RouteConfig[], ...args): void {
          return RouterManager.addRoutes(routes, ...args)
        }
      }

      return origMethod
    }
  })
}
