import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const CertificateService = {
  checkGiftCertificate: async (barcode): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.giftCertificate, 'check_gift_certificate')
        .replace('{{barcode}}', encodeURIComponent(barcode)),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  }
}
