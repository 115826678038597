import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from './types/UserState'
import * as types from './mutation-types'
import { UserInfoService } from 'theme/store/userInfo/data-resolver/UserInfoService';
import { UserProfile } from '@vue-storefront/core/modules/user/types/UserProfile';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import i18n from '@vue-storefront/i18n'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { groups, sendToLogs } from 'theme/helpers/web-logging';

const actions: ActionTree<UserState, RootState> = {
  setCurrent ({ commit }) {
    commit(types.USER_SET_CURRENT)
  },

  createAddress ({ commit }, address) {
    commit(types.USER_CREATE_ADDRESS, address)
  },

  async validate ({ dispatch }, phone) {
    const requestValidation = UserInfoService.getValidation(phone)
    const response = await requestValidation

    if (response && response.code === 200 && response.result && response.result.status) {
      dispatch('setValidationStatus', response.result.status)
    } else {
      sendToLogs(
        groups.Auth,
        'user:validate:not:200',
        { code: response.code, phone, result: response.result || null },
        response?.transparentId
      )
    }

    return requestValidation
  },

  setValidationStatus ({ commit }, status) {
    commit(types.USER_SET_VALIDATION_STATUS, status)
  },

  sendOtp (context, phone) {
    try {
      EventBus.$emit('wait-before-send-sms')
      return UserInfoService.sendOtp(phone)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:sendOtp:error',
        { error: e?.message, phone }
      )

      throw new Error(e)
    }
  },

  resetPinCode (context, params) {
    try {
      return UserInfoService.resetPinCode(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:resetPinCode:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },

  createPinCode (context, params) {
    try {
      return UserInfoService.createPinCode(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:createPinCode:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },

  changePinCode (context, params) {
    try {
      return UserInfoService.changePinCode(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:changePinCode:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },

  loadSearchHistory ({ commit }) {
    const searchHistory = JSON.parse(localStorage.getItem('shop/user/searchHistory')) || []
    commit(types.USER_SET_SEARCH_HISTORY, searchHistory)
  },

  saveSearchHistory ({ commit }, searchQuery) {
    const searchHistory = JSON.parse(localStorage.getItem('shop/user/searchHistory')) || []
    if (!searchHistory.includes(searchQuery)) {
      searchHistory.push(searchQuery)
    }
    if (searchHistory.length > 6) {
      searchHistory.shift()
    }
    localStorage.setItem('shop/user/searchHistory', JSON.stringify(searchHistory));
    commit(types.USER_SET_SEARCH_HISTORY, searchHistory)
  },

  setIsNewUserFlag ({ commit }, status) {
    commit(types.USER_SET_IS_NEW_USER_FLAG, status)
  },
  updatePhone (context, params) {
    try {
      return UserInfoService.updatePhone(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:updatePhone:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },
  updatePhoneConfirm (context, params) {
    try {
      return UserInfoService.updatePhoneConfirm(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:updatePhoneConfirm:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },
  addAdditionalPhone (context, params) {
    try {
      return UserInfoService.addAdditionalPhone(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:addAdditionalPhone:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },
  deleteAdditionalPhone (context, params) {
    try {
      return UserInfoService.deleteAdditionalPhone(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:deleteAdditionalPhone:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },
  uploadAvatar (context, params) {
    try {
      return UserInfoService.uploadAvatar(params)
    } catch (e) {
      sendToLogs(
        groups.Auth,
        'user:uploadAvatar:error',
        { error: e?.message, params }
      )

      throw new Error(e)
    }
  },
  async getAvatar ({ commit }) {
    try {
      const userStorage = StorageManager.get('user')
      const avatar = await userStorage.getItem('avatar') || null

      avatar && commit(types.USER_SET_AVATAR, avatar)

      const response = await UserInfoService.getAvatar()

      if (response.code === 200 && response?.result !== avatar) {
        commit(types.USER_SET_AVATAR, response.result)
        await userStorage.setItem('avatar', response.result)

        return
      }

      sendToLogs(
        groups.User,
        'user:getAvatar:bad:result',
        { code: response.code, result: response?.result },
        response?.transparentId
      )
    } catch (error) {
      sendToLogs(
        groups.User,
        'user:getAvatar:error',
        { error: error?.message }
      )

      console.log(error);
    }
  },
  clearAvatar ({ commit }) {
    commit(types.USER_SET_AVATAR, '')
  },
  async update (_, profile: UserProfile) {
    try {
      EventBus.$emit('notification-progress-start')
      await UserInfoService.updateProfile(profile, 'userInfo/handleUpdateProfile')
    } catch (e) {
      sendToLogs(
        groups.User,
        'user:update:error',
        { error: e?.message }
      )

      throw new Error(e)
    }
  },
  async handleUpdateProfile ({ dispatch }, event) {
    EventBus.$emit('notification-progress-stop')
    if (event.resultCode === 200) {
      await dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Account data has successfully been updated'),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      await dispatch('user/setCurrentUser', event.result, { root: true })
    } else if (event.result.errorMessage === 'A customer with the same email address already exists in an associated website.') {
      dispatch('notification/spawnNotification', {
        type: 'danger',
        message: `${i18n.t('Account data has not been updated')}. ${i18n.t('A customer with the same email address already exists in an associated website')}`,
        action1: { label: i18n.t('OK') }
      }, { root: true })
    } else {
      dispatch('notification/spawnNotification', {
        type: 'danger',
        message: i18n.t('Account data has not been updated'),
        action1: { label: i18n.t('OK') }
      }, { root: true })
    }
  }
}

export default actions
