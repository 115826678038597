import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import config from 'config'
import Task from '@vue-storefront/core/lib/sync/types/Task';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import CartItem from 'core/modules/cart/types/CartItem';

const headers = {
  'Accept': '*/*',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json'
};

export const UserCartService = {
  cartExt: async (quoteId: string, extend = {}): Promise<any> => {
    const endpoint = getApiEndpointUrl(config.cart, 'cart_ext_endpoint')

    return TaskQueue.execute({
      url: processLocalizedURLAddress(endpoint),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          ...extend,
          quote_id: quoteId || null
        })
      }
    });
  },
  cartClear: async (cartToken): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'cart_clear_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          quoteId: cartToken
        })
      }
    });
  },
  updateItems: async (cartServerToken: string, cartItems: CartItem[]): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.cart, 'updateitem_endpoint')),
      payload: {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          cartItems: cartItems.map(i => ({
            ...i,
            quoteId: i.quoteId || cartServerToken
          }))
        })
      }
    })
};
