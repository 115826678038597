import { quickSearchByQuery } from '@vue-storefront/core/lib/search';

export const BannerService = {
  getBanners: async ({ query }): Promise<any> => {
    return quickSearchByQuery({
      query,
      entityType: 'banner',
      excludeFields: [
        'tms',
        'tsk',
        'sgn',
        'paths',
        'created_time',
        'update_time'
      ]
    });
  }
};
