import * as types from './mutation-types'
import * as rootTypes from '@vue-storefront/core/modules/cart/store/mutation-types'
import { MutationTree } from 'vuex'
import { CartState } from '../types/CartState'
import productsEquals, { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const mutations: MutationTree<CartState> = {
  [types.CART_OPEN_EDIT_MODE] (state, { productId, selectedOptions, qty }) {
    state.editMode = { productId, selectedOptions, qty }
  },
  [types.CART_EDIT_MODE_SET_FILTERS] (state, { filterOptions }) {
    state.editMode.selectedOptions[filterOptions.type] = filterOptions
  },
  [types.CART_EDIT_QTY] (state, { qty }) {
    state.editMode.qty = qty
  },
  [types.CART_CLOSE_EDIT_MODE] (state) {
    state.editMode = null
  },
  [types.CART_STATES] (state, cartState) {
    state.cartStates = Object.assign(state.cartStates, cartState)
  },
  [types.CART_SET_SQPP] (state, cartSQPP) {
    state.cartSQPP = cartSQPP
  },
  [types.CART_STOCK_RESULTS] (state, payload) {
    state.productStockResult = payload
  },
  [types.CART_SET_PRODUCT_COMMENTS] (state, productComments) {
    state.productComments = productComments
  },
  [rootTypes.CART_UPD_ITEM_SAFE] (state, { product, qty }) {
    const record = state.cartItems.find(p => isSkuEqual(product, p))

    const newQty = +qty

    if (record) {
      EventBus.$emit('cart-before-update', { product: record })
      record.uiModifiedAt = Date.now()
      record.qty = newQty
      record.client_qty = newQty
      EventBus.$emit('cart-after-update', { product: record })
    }
  },
  [rootTypes.CART_UPD_ITEM] (state, { product, qty }) {
    const record = state.cartItems.find(p => productsEquals(p, product))
    const newQty = product.stock.is_qty_decimal
      ? parseFloat(qty)
      : parseInt(qty)

    if (record) {
      EventBus.$emit('cart-before-update', { product: record })
      record.uiModifiedAt = Date.now()
      record.qty = newQty
      record.client_qty = newQty
      EventBus.$emit('cart-after-update', { product: record })
    }
  },
  [types.CART_SET_IS_SYNCING] (state, bool) {
    state.isSyncing = bool;
  },
  [types.CART_SET_IS_SYNCING_TOTAL] (state, bool) {
    state.isSyncingTotal = bool;
  },
  [types.CART_SET_IS_CONNECTING] (state, bool) {
    state.isSyncing = bool;
  },
  [types.CART_SET_LAST_PULL] (state, payload) {
    state.lastPull = payload;
  },
  [types.CART_SET_REVERT_RULES] (state, payload) {
    state.revertRule = payload;
  }
}

export default mutations
