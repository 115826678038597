const toSkipQueries = [
  'clid',
  'utm_',
  'campaign_',
  'ad_',
  'adset_',
  'sc_content',
  'srsltid',
  'sc_content',
  'deep_link',
  'tr',
  'gbraid',
  'etm_'
]

const toSkipEq = [
  'shortlink',
  'pid',
  'af_xp',
  'c'
]

export const clearQueries = (tag) => {
  try {
    const url = new URL(tag)

    // @ts-ignore
    const entries = Array.from(url.searchParams)
    const toRemove = []

    // @ts-ignore
    for (const [key] of entries) {
      const isSkip = !!toSkipQueries.filter(i => key.includes(i)).length || toSkipEq.includes(key)

      if (isSkip) toRemove.push(key)
    }

    for (const key of toRemove) {
      url.searchParams.delete(key)
    }

    return url.toString()
  } catch (e) {
    return tag
  }
}
