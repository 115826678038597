import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import SearchResultState from '../types/SearchResultState';
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const searchResultStore: Module<SearchResultState, RootState> = {
  namespaced: true,
  state: {
    searchResultProducts: [],
    categories: [],
    availableFilters: {},
    filterLabels: {},
    resultStats: {},
    currentCategory: null,
    searchTotalProducts: 0,
    lastSearchQueryHash: '',
    autocompleteHash: '',
    activeFilters: {},
    filterPrice: {},
    metaTags: [],
    loading: {
      autocomplete: false,
      resultPage: true
    },
    loaded: {
      resultPage: false
    },
    autocomplete: {
      corrected: null,
      query: '',
      total: 0,
      results: {
        suggest: [],
        categories: [],
        brands: [],
        items: []
      }
    }

  },
  actions,
  getters,
  mutations
}
