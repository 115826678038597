<template>
  <no-ssr>
    <div class="checkout-layout">
      <MLoader />
      <OCheckoutHeader />
      <div id="viewport">
        <slot />
      </div>
      <OCheckoutFooter />
      <OMicrocart />
      <OModal />
      <ONotification />
      <MCookieNotification />
      <MOfflineBadge />
      <MAddBinanceCard />
      <vue-progress-bar />
    </div>
  </no-ssr>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import config from 'config'
import { ModalList } from '../store/ui/modals'
import { registerModule } from '@vue-storefront/core/lib/modules'
import ShippingLocationMixin from '$modules/shipping/mixins/shipping-location-mixin';
import { OrderModule } from 'theme/store/order'
import NoSSR from 'vue-no-ssr';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaPages } from 'theme/meta/pages';
import Prefetch from 'theme/mixins/Prefetch';

export default {
  components: {
    OCheckoutHeader: () => import('theme/components/organisms/o-checkout-header'),
    OCheckoutFooter: () => import('theme/components/organisms/o-checkout-footer'),
    MLoader: () => import('theme/components/molecules/m-loader'),
    ONotification: () => import('theme/components/organisms/o-notification'),
    MCookieNotification: () => import('theme/components/molecules/m-cookie-notification'),
    MOfflineBadge: () => import('theme/components/molecules/m-offline-badge'),
    OModal: () => import('theme/components/organisms/o-modal'),
    OMicrocart: () => import('theme/components/organisms/o-microcart'),
    'no-ssr': NoSSR,
    MAddBinanceCard: () => import('theme/components/molecules/m-add-binance-card')
  },
  mixins: [ShippingLocationMixin, GoogleTagManager, Prefetch],
  data () {
    return {
      quicklink: null
    };
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/getCartItems'
    }),
    quicklinkEnabled () {
      return typeof config.quicklink !== 'undefined' && config.quicklink.enabled
    }
  },
  beforeCreate () {
    registerModule(OrderModule);
  },
  beforeMount () {
    if (!this.cartItems.length) {
      this.$router.push(this.localizedRoute('/'))
      this.notifyEmptyCart()
      return
    }
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$Progress.increase(40);
      next();
    });
    this.$router.afterEach(() => {
      if (!isServer && this.quicklinkEnabled) {
        this.quicklink.listen();
      }
      this.$Progress.finish();
    });
    this.$bus.$on('cart-authorize-diff-items', this.onCartsConfirmation);
  },
  mounted () {
    this.gtmProductsHandler(this.cartItems, 'begin_checkout')
    if (!isServer && this.quicklinkEnabled) {
      this.quicklink = require('quicklink');
      this.quicklink.listen();
    }
    this.$store.dispatch('ui/checkWebpSupport');
  },
  beforeDestroy () {
    this.$bus.$off('cart-authorize-diff-items', this.onCartsConfirmation);
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    onCartsConfirmation (payload) {
      this.openModal({ name: ModalList.CartsConfirmation, payload });
    },
    showNotification ({ type, message }) {
      this.$store.dispatch('notification/spawnNotification', {
        type,
        message,
        action1: { label: this.$t('OK') }
      });
    },
    notifyEmptyCart () {
      this.showNotification({
        type: 'warning',
        message: this.$t(
          'Shopping cart is empty. Please add some products before entering Checkout'
        )
      });
    }
  },
  serverPrefetch () {
    return this.fetchMenuData();
  },
  metaInfo: metaPages
};
</script>

<style lang="scss" scoped>
.checkout-layout {
  min-width: var(--min-width);
  background: var(--light-gray);
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #viewport {
    flex: 1;
  }

  ::v-deep .o-microcart-panel__address {
    pointer-events: none;
  }
}
</style>
