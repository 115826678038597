import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

const prepareConfigItems = (items: any) => {
  const result = {};

  items.forEach((item) => {
    result[item.config_path] = item.config_value;
  });

  return result;
};

const resultHandler = (items, path) => {
  if (Array.isArray(path)) {
    return prepareConfigItems(items);
  }

  return items.length > 0 ? items[0].config_value : '';
}

const configStore = {
  namespaced: true,
  state: {
    config: []
  },
  mutations: {
    ADD_TO_CONFIG (state, payload) {
      const config = state.config

      const configIndex = config.findIndex(({ config_path }) => config_path === payload.config_path)

      if (configIndex >= 0) {
        config[configIndex] = payload
      } else {
        config.push(payload)
      }

      state.config = config
    }
  },
  actions: {
    get: async ({ state, commit }, { path }): Promise<any> => {
      const paths = Array.isArray(path) ? path : [path]

      const values = state.config.filter(({ config_path }) => (
        paths.includes(config_path)
      ))

      if (values.length === paths.length) {
        return resultHandler(values, path)
      }

      const hasConfig = values.map(({ config_path }) => config_path)
      const pathsToRequest = paths.filter(p => !hasConfig.includes(p))

      const query = new SearchQuery();

      query.applyFilter({
        key: 'config_path',
        value: { 'in': pathsToRequest }
      });

      const res = await quickSearchByQuery({
        query,
        entityType: 'config',
        includeFields: ['*']
      })

      res.items.forEach(n => (
        commit('ADD_TO_CONFIG', { config_path: n['config_path'], config_value: n['config_value'] })
      ))

      return resultHandler([...values, ...res.items], path)
    }
  }
};

export const ConfigVarusModule: StorefrontModule = function ({ store }) {
  store.registerModule('config-varus', configStore);

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('config', {
      queryProcessor: (query) => {
        return query;
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'config', start, size)
      }
    });
  });
};
