import { MutationTree } from 'vuex'
import { FAQState } from '../types';
import * as types from './mutation-types'

const mutations: MutationTree<FAQState> = {
  [types.SET_QUESTIONS] (state, { questions }) {
    state.questions = questions
  }
}

export default mutations
