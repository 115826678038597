<template>
  <div class="m-social-contact">
    <SfLink
      target="_blank"
      rel="nofollow"
      :link="instagramLink"
      class="m-social-contact__link m-social-contact__link-inst"
    />
    <SfLink
      target="_blank"
      rel="nofollow"
      :link="facebookLink"
      class="m-social-contact__link m-social-contact__link-fb"
    />
    <SfLink
      target="_blank"
      rel="nofollow"
      :link="twitterLink"
      class="m-social-contact__link m-social-contact__link-twitter"
    />
    <SfLink
      target="_blank"
      rel="nofollow"
      :link="telegramLink"
      class="m-social-contact__link m-social-contact__link-telegram"
    />
  </div>
</template>

<script>
import {
  SfLink
} from '@storefront-ui/vue';
import config from 'config';

export default {
  name: 'MSocialContact',
  components: { SfLink },
  data () {
    return {
      instagramLink: config.socialLinks?.instagram || '#',
      facebookLink: config.socialLinks?.facebook || '#',
      twitterLink: config.socialLinks?.twitter || '#',
      telegramLink: config.socialLinks?.telegramChannel || '#'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-social-contact {
  font-size: 0;
  padding-top: var(--spacer-xs);
  display: flex;
  align-items: center;

  &__link {
    margin-right: 20px;

    &-inst {
      @include font-icon(var(--icon-inst));

      &:before {
        font-size: var(--font-size-24);
        color: var(--black);
      }

      &:hover {
        &:before {
          color: var(--orange-hover);
        }
      }
    }

    &-fb {
      @include font-icon(var(--icon-fb));

      &:before {
        font-size: var(--font-size-22);
      }
    }

    &-twitter {
      @include font-icon(var(--icon-twitter));

      &:before {
        font-size: var(--font-size-12)
      }
    }

    &-telegram {
      @include font-icon(var(--icon-telegram));

      &:before {
        font-size: var(--font-size-10)
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.app .sf-link {
  &.m-social-contact__link-fb,
  &.m-social-contact__link-twitter,
  &.m-social-contact__link-telegram {
    background: var(--black);
    width: 24px;
    height: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: var(--orange-hover);
    }

    &:before {
      color: var(--white);
    }
  }
}
</style>
