<template>
  <button
    v-focus
    class="sf-button"
    v-bind="$attrs"
    :disabled="disabled"
    v-on="$listeners"
  >
    <!--@slot Use this slot to place content inside the button.-->
    <slot />
  </button>
</template>
<script>
import focus from '$ui/directives/focus';
export default {
  name: 'SfButton',
  directives: {
    focus
  },
  props: {
    /**
     * Native button disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfButton.scss";
</style>
