import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const UserCommunicationService = {
  loadCommunications: async (): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.userCommunication, 'communications_endpoint'),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  saveCommunications: async (payload): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.userCommunication, 'communication_endpoint'),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(payload)
      }
    });
  },
  saveSubscriptions: async (payload): Promise<Task> => {
    return TaskQueue.execute({
      url: getApiEndpointUrl(config.userCommunication, 'subscription_endpoint'),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(payload)
      }
    });
  }
}
