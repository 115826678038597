import { Module, Store } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import { Context } from '@vue-storefront/core/server/utils/types';
import { isServer } from '@vue-storefront/core/helpers';

interface UrlData {
  host: string,
  protocol: string
}

interface SiteDataStore extends UrlData {
  updated: boolean
}

export const siteDataStore: Module<SiteDataStore, RootState> = {
  namespaced: true,
  state: {
    host: '',
    protocol: '',
    updated: false
  },
  getters: {
    baseUrl: (state): string => `${state.protocol}://${state.host}`
  },
  mutations: {
    SET_URL_DATA (state, { host, protocol }: UrlData): void {
      state.host = host;
      state.protocol = protocol;
      state.updated = true;
    }
  },
  actions: {
    setUrlData ({ commit }, urlData: UrlData): void {
      commit('SET_URL_DATA', urlData);
    }
  }
}

export const updateSiteData = async (store: Store<RootState>, context: Context): Promise<void> => {
  const updated = store.state['siteData/host'];

  if (updated) return;

  let host: string;
  let protocol: string;

  if (isServer) {
    // @ts-ignore
    host = context?.server?.request?.headers?.host || '';
    // @ts-ignore
    protocol = context?.server?.request?.protocol || '';
  } else {
    host = window.location.host;
    protocol = window.location.protocol.replace(':', '');
  }

  if (host.length === 0 || protocol.length === 0) return;

  await store.dispatch('siteData/setUrlData', { host, protocol });
}
