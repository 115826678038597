<template>
  <SfButton
    class="sf-button--pure a-account-icon"
    aria-label="Account Icon"
    data-transaction-name="Account Button"
    @click="goToAccount"
    :class="className"
  >
    <span class="a-account-icon__caption">{{ accountCaption }}</span>
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ModalList } from 'theme/store/ui/modals'
import DeviceType from 'theme/mixins/DeviceType';
import { getFormattedPhone } from 'theme/helpers/text';

export default {
  name: 'AAccountIcon',
  components: { SfButton },
  mixins: [DeviceType],
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    ...mapGetters('user', ['isLoggedIn']),
    className () {
      return {
        'a-account-icon--active': this.isLoggedIn
      }
    },
    accountCaption () {
      let caption = this.$t('Login or register');

      if (this.isLoggedIn) {
        caption = this.currentUser?.firstname || this.formattedPhone || caption
      }

      return caption
    },
    formattedPhone () {
      const phone = this.currentUser?.phone || ''
      return getFormattedPhone(phone)
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    goToAccount () {
      if (this.isLoggedIn) {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.$store.commit('ui/closeMenu')
        this.$router.push(this.localizedRoute('/my-account'))
      } else {
        this.$store.dispatch('ui/hideBottomNavigation', false)
        this.$store.commit('ui/closeMenu')
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.a-account-icon {
  @include font-icon(var(--icon-user));

  &:before {
    color: var(--black);
    font-size: var(--font-size-24);
  }

  &:hover {
    &:before {
      color: var(--orange);
    }
  }

  @include for-desktop {
    margin: 0 0 0 20px;
    position: relative;

    &--active {
      &:hover {
        &:before {
          color: var(--orange);
        }
      }
    }
  }

  &__caption {
    font-size: var(--font-sm);
    font-weight: normal;
    display: inline-flex;
    padding-left: var(--spacer-xs);

    @include for-desktop {
      display: none;
    }
  }
}
</style>
