import * as coreTypes from '@vue-storefront/core/modules/cart/store/mutation-types'
import { createDiffLog, productsEquals } from '@vue-storefront/core/modules/cart/helpers'
import { productQuantityUpdated } from 'theme/store/cart/helpers/notifications'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import createCartItemForUpdate from 'theme/store/cart/helpers/createCartItemForUpdate'
import { UserCartService } from 'theme/store/cart/data-resolver/UserCartService'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem'
import { CartExtPayloadBody } from 'theme/store/cart/types/CartState';
import { cartHooksExecutors } from '@vue-storefront/core/modules/cart/hooks'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from 'theme/store/cart/store/mutation-types';
import * as notifications from 'theme/store/cart/helpers/notifications'
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const mergeActions = {
  async updateTotalsAfterMerge ({ dispatch, getters, commit }, { clientItems, dryRun, forceSync }) {
    if (dryRun) return

    if ((getters.isTotalsSyncRequired && clientItems.length > 0) || forceSync) {
      await dispatch('syncTotals', { forceServerSync: forceSync })
    } else if (getters.isTotalsSyncRequired && clientItems.length === 0) {
      await dispatch('cartResetTotals')
    }

    commit(coreTypes.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
  },

  async mergeClientItem ({ dispatch }, { clientItem, serverItems, forceClientState, dryRun, mergeQty }) {
    const serverItem = serverItems.find(itm => productsEquals(itm, clientItem))

    const result = await dispatch('synchronizeServerItem', { serverItem, clientItem, forceClientState, dryRun, mergeQty })

    if (!result.diffLog.isEmpty()) return result

    const toUpdate = [...result.toUpdate]

    Logger.info('Server and client item with SKU ' + clientItem.sku + ' synced. Updating cart.', 'cart', 'cart')()
    if (!dryRun) {
      const extension_attributes: {[key: string]: any} = {}

      if (serverItem?.extension_attributes?.markdown_id) {
        extension_attributes.markdown_id = serverItem?.extension_attributes?.markdown_id
      }

      const product = {
        sku: clientItem.sku,
        server_cart_id: serverItem.quote_id,
        server_item_id: serverItem.item_id,
        product_option: serverItem.product_option,
        type_id: serverItem.product_type,
        extension_attributes
      }

      toUpdate.push(product)

      await dispatch('updateItem', { product })
    }

    return {
      toUpdate,
      diffLog: result.diffLog
    }
  },

  async mergeClientItems ({ dispatch, commit }, { clientItems, serverItems, forceClientState, dryRun, mergeQty, keepCart }) {
    const diffLog = createDiffLog()
    const toUpdate = []

    for (const clientItem of clientItems) {
      try {
        const mergeClientItemDiffLog = await dispatch('mergeClientItem', { clientItem, serverItems, forceClientState, dryRun, mergeQty })
        const items = mergeClientItemDiffLog.toUpdate.filter(i => i?.clientItem?.server_item_id && !i?.serverItem)

        if (!keepCart && items.length) {
          commit(coreTypes.CART_DEL_ITEM, { product: clientItem, removeByParentSku: false })

          continue
        }

        diffLog.merge(mergeClientItemDiffLog.diffLog)
        toUpdate.push(...mergeClientItemDiffLog.toUpdate)
      } catch (e) {
        Logger.debug('Problem syncing clientItem', 'cart', clientItem)()
      }
    }

    return {
      toUpdate,
      diffLog
    }
  },

  async synchronizeServerItem ({ dispatch }, { serverItem, clientItem, forceClientState, dryRun, mergeQty }) {
    const diffLog = createDiffLog()
    const toUpdate = []

    if (!serverItem) {
      Logger.warn('No server item with sku ' + clientItem.sku + ' on stock.', 'cart')()
      diffLog.pushServerParty({ sku: clientItem.sku, status: 'no-item' })

      if (dryRun) {
        return diffLog
      }

      if (forceClientState || !config.cart.serverSyncCanRemoveLocalItems) {
        const updateServerItemDiffLog = await dispatch('updateServerItemOnMerge', { clientItem, serverItem, updateIds: false })

        toUpdate.push(...(updateServerItemDiffLog.toUpdate || []))

        return {
          toUpdate,
          diffLog: diffLog.merge(updateServerItemDiffLog.diffLog)
        }
      }

      await dispatch('removeItem', { product: clientItem })

      return {
        toUpdate,
        diffLog
      }
    }

    if (serverItem.qty !== clientItem.qty || mergeQty) {
      Logger.log('Wrong qty for ' + clientItem.sku, clientItem.qty, serverItem.qty)()
      diffLog.pushServerParty({ sku: clientItem.sku, status: 'wrong-qty', 'client-qty': clientItem.qty, 'server-qty': serverItem.qty })
      if (dryRun) return diffLog
      if (forceClientState || !config.cart.serverSyncCanModifyLocalItems) {
        const updateServerItemDiffLog = await dispatch('updateServerItemOnMerge', { clientItem, serverItem, updateIds: true, mergeQty })

        toUpdate.push(...updateServerItemDiffLog.toUpdate)

        return {
          toUpdate,
          diffLog: diffLog.merge(updateServerItemDiffLog.diffLog)
        }
      }

      await dispatch('updateItem', { product: serverItem })
    }

    return {
      toUpdate,
      diffLog
    }
  },

  async updateServerItemOnMerge (ctx, { clientItem, serverItem, updateIds, mergeQty }) {
    const diffLog = createDiffLog()
    const cartItem = createCartItemForUpdate(clientItem, serverItem, updateIds, mergeQty)

    return {
      toUpdate: [
        {
          cartItem,
          serverItem,
          clientItem
        }
      ],
      diffLog
    }
  },

  async updateServerItems ({ getters, rootGetters, rootState, commit, dispatch }, {
    toUpdate,
    toShippingSave,
    revertRule,
    clientItems
  }) {
    const diffLog = createDiffLog()

    try {
      const cartItems = toUpdate?.filter(i => !i?.clientItem?.gift?.isGift)?.map(i => i.cartItem) || []
      const payload: CartExtPayloadBody = {}

      if (cartItems.length) {
        payload.items = cartItems
      }

      if (toShippingSave) {
        payload.address_information = toShippingSave
      }

      if (revertRule) {
        payload.restore_rule_id = revertRule
        dispatch('cart/revertRule', null, { root: true })
      }

      const clientItemsUpdate = toUpdate
        ?.filter(i => i.clientItem)
        ?.map(i => i.clientItem) || []

      const events = await UserCartService.cartExt(
        getters.getCartToken,
        payload
      )

      const toUpdateHasAddNewItemToCart = toUpdate
        ?.filter(i => (!i.cartItem?.item_id))
        ?.length || 0

      commit(types.CART_SET_LAST_PULL, { result: events.result, resultCode: events.resultCode })

      await dispatch('mergeServerItems', { clientItems, serverItems: events.result?.items || [] })

      await dispatch('removeGiftFromClient', { clientItems, serverItems: events.result?.items || [] })

      const wasUpdatedSuccessfully = events.resultCode === 200
      Logger.debug('Cart items server sync' + events, 'cart')()

      if (wasUpdatedSuccessfully && !!toUpdateHasAddNewItemToCart && !rootState.ui.microcart) {
        dispatch(
          'notification/spawnNotification',
          notifications.productAddedToCart(),
          { root: true }
        );
      }

      for (const cartItem of cartItems) {
        diffLog.pushServerResponse({ status: events.resultCode, sku: cartItem.sku, result: events })
      }

      const withoutServerItem = toUpdate?.filter(i => !i.serverItem && i.clientItems)
        ?.map(i => i.clientItems) || []

      if (!wasUpdatedSuccessfully && withoutServerItem.length) {
        for (const clientItem of withoutServerItem) {
          commit(coreTypes.CART_DEL_ITEM, { product: clientItem, removeByParentSku: false })
        }

        return {
          diffLog,
          items: events.result.items,
          totals: events.result.totals
        }
      }

      const toRestoreQty = clientItemsUpdate?.filter(i => i.server_item_id || i?.item_id) || []

      if (!wasUpdatedSuccessfully && toRestoreQty.length) {
        for (const clientItem of toRestoreQty) {
          await dispatch('restoreQuantity', { product: clientItem })
        }

        return {
          diffLog,
          items: events.result.items,
          totals: events.result.totals
        }
      }

      if (!wasUpdatedSuccessfully) {
        sendToLogs(
          groups.Cart,
          'updateServerItems:not:200',
          { code: events.resultCode, result: events?.result || null },
          events?.transparentId
        )

        for (const clientItem of clientItemsUpdate) {
          Logger.warn('Removing product from cart', 'cart', clientItem)()
          commit(coreTypes.CART_DEL_NON_CONFIRMED_ITEM, { product: clientItem })
        }

        return {
          diffLog,
          items: events.result.items,
          totals: events.result.totals
        }
      }

      if (!rootGetters['checkout/isUserInCheckout']) {
        for (const clientItem of clientItemsUpdate) {
          const isThisNewItemAddedToTheCart = (!clientItem || !clientItem.server_item_id)

          if (!isThisNewItemAddedToTheCart) {
            diffLog.pushNotification(productQuantityUpdated())
          }
        }
      }

      for (const clientItem of clientItemsUpdate) {
        const found = events.result?.items?.find(i => isSkuEqual(i, clientItem))
        await dispatch('updateClientItem', { clientItem, serverItem: found })
      }

      return {
        diffLog,
        items: events.result.items,
        totals: events.result.totals
      }
    } catch (e) {
      Logger.debug('Problem syncing updateServerItems', 'cart', e)()

      sendToLogs(
        groups.Cart,
        'updateServerItems:catch:error',
        { message: e.message }
      )

      return { diffLog }
    }
  },
  async merge ({ getters, dispatch }, {
    serverItems,
    clientItems,
    keepCart = false,
    dryRun = false,
    forceClientState = false,
    mergeQty = false,
    toShippingSave = null,
    revertRule = null
  }) {
    const hookResult = cartHooksExecutors.beforeSync({ clientItems, serverItems })
    let totalsAfterMerge = null
    let itemsAfterMerge = null

    const diffLog = createDiffLog()
    const mergeParameters = {
      clientItems: hookResult.clientItems,
      serverItems: hookResult.serverItems,
      keepCart,
      forceClientState,
      dryRun,
      mergeQty
    }

    const mergeClientItems = await dispatch('mergeClientItems', mergeParameters)
    const mergeServerItems = await dispatch('mergeServerItems', mergeParameters)

    diffLog
      .merge(mergeClientItems.diffLog)
      .merge(mergeServerItems.diffLog)

    const toUpdate = [
      ...mergeClientItems.toUpdate,
      ...mergeServerItems.toUpdate
    ]

    const isUpdateRequired = toUpdate.length > 0 || toShippingSave || revertRule

    if (isUpdateRequired) {
      const { diffLog: toDiffLog, totals, items } = await dispatch('updateServerItems', {
        toUpdate,
        toShippingSave,
        revertRule,
        clientItems: mergeParameters.clientItems
      })

      if (!toDiffLog.isEmpty()) {
        diffLog.merge(toDiffLog)
      }

      if (items) {
        itemsAfterMerge = items
      }

      if (totals) {
        totalsAfterMerge = totals
      }
    }

    diffLog
      .pushClientParty({ status: getters.isCartHashChanged ? 'update-required' : 'no-changes' })
      .pushServerParty({ status: getters.isTotalsSyncRequired ? 'update-required' : 'no-changes' })

    EventBus.$emit('servercart-after-diff', { diffLog: diffLog, serverItems: hookResult.serverItem, clientItems: hookResult.clientItems, dryRun: dryRun })
    Logger.info('Client/Server cart synchronised ', 'cart', diffLog)()

    if (isUpdateRequired || !!diffLog?.items?.find(i => i.sku)) {
      EventBus.$emit('update-changed-cart', { shippingChanged: !!toShippingSave })
    }

    return { totalsAfterMerge, itemsAfterMerge, diffLog }
  },
  async mergeServerItems ({ dispatch }, { serverItems, clientItems, forceClientState, dryRun }) {
    const diffLog = createDiffLog()
    const toUpdate = []
    const filteredServerItems = await dispatch('excludeClientItemsFromServerItems', { serverItems, clientItems })

    if (dryRun) return { diffLog, toUpdate }

    if (forceClientState && filteredServerItems?.length) {
      const serverItemsToRemove = await dispatch('prepareServerItemsToRemoveFromTheCart', { filteredServerItems })
      diffLog.merge(serverItemsToRemove.diffLog)
      toUpdate.push(...serverItemsToRemove.toUpdate)
      return { diffLog, toUpdate }
    }

    if (filteredServerItems?.length) {
      Logger.info('No client items for' + filteredServerItems, 'cart')()
      filteredServerItems.forEach(serverItem => {
        diffLog.pushClientParty({ sku: serverItem.sku, status: 'no-item' })
      })
      const productsToAdd = await dispatch('getServerItems', { filteredServerItems })

      for (const productToAdd of productsToAdd) {
        try {
          dispatch('addItem', {
            productToAdd,
            forceServerSilence: true,
            notification: false
          })
        } catch (e) {
          Logger.debug('Problem syncing serverItem', 'cart', productToAdd)()
        }
      }
    }

    return {
      diffLog,
      toUpdate
    }
  },
  async excludeClientItemsFromServerItems (ctx, { serverItems, clientItems }) {
    return serverItems.filter((serverItem) => {
      return !clientItems.some((clientItem) => productsEquals(clientItem, serverItem))
    })
  },
  async prepareServerItemsToRemoveFromTheCart (ctx, { filteredServerItems }) {
    const diffLog = createDiffLog()
    const toUpdate = filteredServerItems.map(serverItem => {
      const extension_attributes: {[key: string]: any} = {
        deleted: true
      }

      if (serverItem?.extension_attributes?.markdown_id) {
        extension_attributes.markdown_id = serverItem?.extension_attributes?.markdown_id
      }

      const cartItem = {
        extension_attributes,
        sku: serverItem.sku,
        item_id: serverItem?.item_id,
        quoteId: serverItem.quote_id
      } as any as CartItem

      Logger.info('Removing product from cart', 'cart', serverItem)()
      Logger.log('Removing item' + serverItem.sku + serverItem?.item_id, 'cart')()

      return { cartItem, serverItem }
    })

    return { diffLog, toUpdate }
  },
  async getServerItems ({ dispatch }, { filteredServerItems }) {
    const skuList = filteredServerItems.map(item => item.sku)
    const skus = [...new Set([...skuList])]

    const products = await dispatch('product/getSimpleProductsBySku', {
      skus,
      loadDiscountedProducts: true
    }, { root: true })

    return dispatch('processServerItems', { filteredServerItems, products })
  },
  async processServerItems (ctx, { filteredServerItems, products }) {
    const result = []

    const sortedProducts = products.sort((a, b) => {
      const indexA = filteredServerItems.findIndex(x => isSkuEqual(x, a));
      const indexB = filteredServerItems.findIndex(x => isSkuEqual(x, b));
      return indexA - indexB;
    });

    for (const product of sortedProducts) {
      const filteredServerItem = filteredServerItems.find(serverItem => isSkuEqual(serverItem, product))

      if (!filteredServerItem) continue

      result.push({
        ...product,
        server_item_id: filteredServerItem.item_id,
        qty: filteredServerItem.qty,
        server_cart_id: filteredServerItem.quote_id,
        product_option: filteredServerItem.product_option || product.product_option,
        extension_attributes: filteredServerItem.extension_attributes || {}
      })
    }

    return result
  },
  removeGiftFromClient ({ commit }, { clientItems, serverItems }) {
    const gifts = clientItems.filter(i => i?.gift?.isGift || i?.gift?.isPromotion)
    const items = serverItems.map(i => i.sku)

    for (const gift of gifts) {
      if (items.includes(gift.sku)) continue

      commit(coreTypes.CART_DEL_ITEM, { product: gift, removeByParentSku: false })
    }
  }
}

export default mergeActions;
