import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';
import { StorefrontModule } from '@vue-storefront/core/lib/modules'

export const BannerModule: StorefrontModule = async function () {
  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('banner', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'banner', start, size)
      }
    });
  });
}
