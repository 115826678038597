<template>
  <div class="m-add-binance-card">
    <SfOModal
      :visible="isVisible"
      :is-bar-back="false"
      :is-bar-close="true"
      @close="closeModal"
      @back="closeModal"
    >
      <iframe :src="url" width="100%" height="100%" />
    </SfOModal>
  </div>
</template>

<script>
import SfOModal from 'theme/components/storefront-override/SfOModal';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export default {
  name: 'MAddBinanceCard',
  components: {
    SfOModal
  },
  data () {
    return {
      isVisible: false,
      url: ''
    }
  },
  methods: {
    setBinanceUrl (url) {
      this.url = url
      this.isVisible = !!url
    },
    closeModal () {
      EventBus.$emit('add-binance-card:close')
      this.setBinanceUrl('')
    }
  },
  mounted () {
    EventBus.$on('add-binance-card', this.setBinanceUrl)
  },
  beforeDestroy () {
    EventBus.$off('add-binance-card', this.setBinanceUrl)
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-add-binance-card {
  ::v-deep {
    .sf-modal__content {
      flex: 1;
      overflow: hidden;
      padding: 0!important;
    }

    .sf-modal__container {
      @media (max-width: $mobile-max) {
        z-index: var(--modal-content-index);
      }
    }

    .sf-bar,
    .sf-modal__content,
    {
      background: rgb(246, 246, 246);
    }

    .sf-modal {
      --bar-padding: 0 var(--spacer-20);

      @media (min-width: $tablet-min) {
        --modal-width: 450px;
        --modal-top: 50%;
        --modal-left: 50%;
        --modal-bottom: none;
        --modal-right: none;
        --modal-transform: translate3d(-50%, -50%, 0);
        --modal-height: auto;
        --modal-content-padding: 0;
      }
    }
  }

  iframe {
    border: 0;
    padding: 0;
    margin: 0;

    @media (min-width: $mobile-max) {
      min-height: 450px;
    }
  }
}
</style>
