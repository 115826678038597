<template>
  <div class="a-loading-spinner" :class="{
    'a-loading-spinner--absolute': isAbsolutePosition,
    'a-loading-spinner--fixed': isFixedPosition,
  }"
  >
    <SfLoader
      :loading="true"
      :size="size"
      :weight="weight"
      :background="background"
      :color="color"
    />
  </div>
</template>
<script>
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'

export default {
  name: 'ALoadingSpinner',
  components: { SfLoader },
  props: {
    isAbsolutePosition: {
      type: Boolean,
      default: false
    },
    isFixedPosition: {
      type: Boolean,
      default: false
    },
    size: {
      default: null,
      type: [Number, null]
    },
    weight: {
      default: null,
      type: [Number, null]
    },
    background: {
      default: '',
      type: String
    },
    color: {
      default: '',
      type: String
    }
  }
};
</script>
<style lang="scss" scoped>
.a-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  &--fixed, &--absolute {
    opacity: 0.8;
    top: 0;
    left: 0;
  }

  &--absolute {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  &--fixed {
    position: fixed;
    z-index: var(--fixed-loader);
    right: 0;
    bottom: 0;
  }
}
</style>
