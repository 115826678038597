import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import CheckoutState from '../../types/CheckoutState'

export const checkoutModule: Module<CheckoutState, RootState> = {
  namespaced: true,
  state: {
    order: {},
    paymentMethods: [],
    personalDetails: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      createAccount: false
    },
    shippingDetails: {
      city: '',
      comment: '',
      premisesType: '',
      companyName: '',
      officeNumber: '',
      country: '',
      doorPostalHouseNumber: '',
      entranceNumber: '',
      flour: '',
      houseBuilding: '',
      houseNumber: '',
      intercomeCode: '',
      isOtherRecipient: false,
      otherRecipientName: '',
      firstName: '',
      lastName: '',
      phone: '',
      postcode: '',
      latitude: '',
      longitude: '',
      street: ''
    },
    paymentDetails: {
      firstName: '',
      lastName: '',
      company: '',
      country: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      state: '',
      region_id: 0,
      zipCode: '',
      phoneNumber: '',
      taxId: '',
      paymentMethod: '',
      paymentMethodAdditional: {}
    },
    isThankYouPage: false,
    modifiedAt: 0,
    activeStep: 'personalDetails',
    confirmation: {
      backendOrderId: '',
      orderNumber: ''
    },
    summary: {
      prices: null,
      itemsQuantity: '',
      totalWeight: '',
      coupon: ''
    },
    shippingTimeSlots: [],
    currentTimeSlot: {},
    packagingDetails: {
      replace: '',
      packaging: '',
      product: {},
      comment: ''
    }
  },
  getters,
  actions,
  mutations
}
