import store from '@vue-storefront/core/store'
import { getProductPrice, getProductStock } from 'theme/helpers';
import { newPostMethods } from '$modules/shipping/config';

export const getDefaultShopId = (): number => {
  return parseInt(store.getters['shipping-module/getDefaultShop']?.id || 0)
}

export const getCurrentShopId = async (): Promise<number> => {
  let shopId = null

  if (!getDefaultShopId()) {
    const result = await store.dispatch('shipping-module/loadDefaultCityAndShipping', {})

    shopId = result.defaultShop?.id
  }

  return parseInt(store.getters['shipping/getShippingDetails']?.shopId || shopId || getDefaultShopId())
}

export const isCurrentNewPost = (): boolean => {
  return newPostMethods.includes(store.getters['shipping/getShippingDetails']?.shippingMethod)
}

export const getSQPPShopKey = async (includeFields, extendShopId = null) => {
  const shopId = extendShopId || await getCurrentShopId()
  const sqppShopKey = `sqpp_data_${shopId}`
  const includeFieldsArray = includeFields || []

  const included = includeFieldsArray.length ? [
    ...includeFieldsArray,
    sqppShopKey
  ] : []

  return {
    included,
    key: sqppShopKey
  }
}

export const getMinSalableValue = (item) => (
  item?.['productminsalablequantity'] ||
    item?.['productquantityunitstep'] ||
    0
)

export const productRemap = (key: string) => (item) => {
  if (item.sqpp) return item

  const minSalableQty = getMinSalableValue(item)

  const sqpp = item[key]
  const in_stock = sqpp?.qty >= minSalableQty

  item.sqpp = { ...sqpp, in_stock: in_stock }
  item.stock = getProductStock(item)
  item.price = getProductPrice(item)

  delete item[key]

  return item
}
