<template>
  <div class="o-notification">
    <no-ssr>
      <SfONotification
        v-if="notification"
        :key="notification.type"
        class="notification"
        :visible="true"
        :type="getType(notification)"
        :hide-icon="notification.hideIcon"
        :message="message | htmlDecode"
      >
        <template #icon>
          <span class="sf-notification__icon" :class="notification.type">
            <span class="mark-icon" />
          </span>
        </template>

        <template #action>
          <SfButton
            v-for="action in getActions(notification)"
            :key="action.label"
            class="sf-button--pure sf-notification__action"
            data-transaction-name="Notification Action"
            @click="executeAction(action.action, notification.id)"
          >
            {{ action.label }}
          </SfButton>
        </template>
        <template #close>
          <span
            class="close-icon"
            data-transaction-name="Remove Notification"
            @click="removeNotification(notification.id)"
          />
        </template>
      </SfONotification>
    </no-ssr>
  </div>
</template>

<script>
import { Notification } from '@vue-storefront/core/modules/notification/components/Notification';
import { SfButton } from '@storefront-ui/vue';
import SfONotification from 'theme/components/storefront-override/SfONotification';
import NoSSR from 'vue-no-ssr'

export default {
  name: 'ONotification',
  mixins: [Notification],
  components: {
    SfButton,
    SfONotification,
    'no-ssr': NoSSR
  },
  computed: {
    message () {
      return this.notification?.message || ''
    }
  },
  methods: {
    getType ({ type }) {
      return ['secondary', 'info', 'success', 'warning', 'danger', 'wishlist', 'account'].includes(type) ? type : 'danger';
    },
    getActions ({ action1, action2, allowActions = false }) {
      return allowActions ? [action1, action2].filter(Boolean) : [];
    },
    executeAction (action, id) {
      if (typeof action === 'function') {
        action();
      }
      this.$store.dispatch('notification/removeNotificationById', id);
    },
    removeNotification (id) {
      this.$store.dispatch('notification/removeNotificationById', id);
    },
    isCustomIcon (type) {
      return type === 'success'
    },
    isWishlist (type) {
      return type === 'wishlist'
    },
    isAccount (type) {
      return type === 'account'
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';

.o-notification {
  $headerHeight: var(--spacer-50);
  $contentMaxWidth: 1210px;

  --notification-max-width: 100%;
  position: fixed;
  z-index: 1000;

  @include for-mobile {
    left: 50%;
    top: calc(#{$headerHeight} + var(--spacer-10));
    transform: translateX(-50%);
    min-width: px2rem(300);
  }

  @include for-tablet {
    top: calc(#{$headerHeight} + var(--spacer-20));
    left: unset;
    transform: unset;
  }

  @include for-desktop {
    $headerHeightDesktop: px2rem(50);
    top: calc(#{$headerHeightDesktop} + var(--spacer-20));
    right: calc((100vw - #{$contentMaxWidth}) * 0.5);

    display: flex;
    flex-direction: column;
  }

  @media (max-width: $contentMaxWidth + 20px) {
    right: var(--spacer-sm);
  }
}
.notification {
  box-shadow: 0 0 10px 1px rgba(45, 45, 45, 0.15);

  &:not(:first-child) {
    margin-top: 1rem;
  }
  .sf-notification__action {
    cursor: pointer;
    margin: 0.3rem 1rem 0 0;
  }
  .sf-notification__icon {
    position: relative;

    &:before {
      font-size: var(--font-size-24);
      color: var(--orange);
    }

    .mark-icon {
      width: 12px;
      height: 12px;
      position: absolute;
      top: 0;
      right: 0;
      background: var(--white);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include font-icon(var(--icon-checkmark));

      &:before {
        font-size: 6px;
        color: var(--orange);
      }
    }

    &.account {
      @include font-icon(var(--icon-user));
    }

    &.wishlist {
      @include font-icon(var(--icon-heart));

      &:before {
        font-size: var(--font-size-19);
      }
    }

    &.success {
      @include font-icon(var(--icon-cart));
    }
  }

  .close-icon {
    position: absolute;
    right: 7px;
    top: 5px;
    cursor: pointer;

    &:before {
      font-size: var(--font-size-13);
    }
  }

  @include for-desktop {
    max-width: 32rem;
    align-self: end;
  }
}
</style>
