<template>
  <div class="default-layout">
    <MLoader />
    <div id="viewport">
      <MBannerPreHeader />
      <OHeader />
      <OHeaderBottom />
      <SfOverlay
        :class="{'overlay-search': isSearchOverlay }"
        :visible="isSearchOverlay"
        :transition="'none'"
        @click="hideOverlay"
      />
      <OMicrocart />
      <slot />
      <OFooter />
      <OModal />
      <ONotification />
      <MCookieNotification />
      <MOfflineBadge />
      <OBottomNavigation />
      <no-ssr>
        <MAddBinanceCard />
      </no-ssr>
    </div>
    <no-ssr>
      <vue-progress-bar />
    </no-ssr>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import OMicrocart from '../components/organisms/o-microcart';
import OModal from '../components/organisms/o-modal';
import OBottomNavigation from '../components/organisms/o-bottom-navigation';
import MLoader from '../components/molecules/m-loader';
import ONotification from '../components/organisms/o-notification';
import MCookieNotification from '../components/molecules/m-cookie-notification';
import MOfflineBadge from '../components/molecules/m-offline-badge';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';
import { ModalList } from '../store/ui/modals'
import OFooter from '../components/organisms/o-footer';
import OHeader from '../components/organisms/o-header';
import OHeaderBottom from '../components/organisms/o-header-bottom';
import MBannerPreHeader from 'theme/components/molecules/m-banner-pre-header';
import { metaCore } from 'theme/meta/base';
import DeviceType from 'theme/mixins/DeviceType';
import ShippingLocationMixin from '$modules/shipping/mixins/shipping-location-mixin';
import Prefetch from 'theme/mixins/Prefetch';
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import NoSSR from 'vue-no-ssr';
import MAddBinanceCard from 'theme/components/molecules/m-add-binance-card'
import { SfOverlay } from '@storefront-ui/vue'

export default {
  components: {
    OHeader,
    OHeaderBottom,
    OFooter,
    OMicrocart,
    MLoader,
    ONotification,
    MCookieNotification,
    MOfflineBadge,
    OBottomNavigation,
    OModal,
    MBannerPreHeader,
    'no-ssr': NoSSR,
    MAddBinanceCard,
    SfOverlay
  },
  data () {
    return {
      quicklink: null
    };
  },
  mixins: [ShippingLocationMixin, DeviceType, Prefetch],
  computed: {
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel
    }),
    quicklinkEnabled () {
      return typeof config.quicklink !== 'undefined' && config.quicklink.enabled
    },
    isSearchOverlay () {
      return this.isSearchPanelVisible
    }
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$Progress.increase(40);
      next();
    });
    this.$router.afterEach(() => {
      if (!isServer && this.quicklinkEnabled) {
        this.quicklink.listen();
      }
      this.$Progress.finish();
    });
    this.$bus.$on('cart-authorize-diff-items', this.onCartsConfirmation);
  },
  mounted () {
    this.$store.dispatch('promoted/updatePreHeaderBanners')
    if (!isServer && this.quicklinkEnabled) {
      this.quicklink = require('quicklink');
      this.quicklink.listen();
    }
    this.$store.dispatch('ui/checkWebpSupport');
    this.$store.dispatch('user/trackingCheckQuery');
  },
  beforeDestroy () {
    this.$bus.$off('cart-authorize-diff-items', this.onCartsConfirmation);
  },
  watch: {
    isDesktop: {
      handler () {
        this.$nextTick(() => {
          this.isDesktop
            ? this.$store.dispatch('ui/checkDesktopBreakpoint', true)
            : this.$store.dispatch('ui/checkDesktopBreakpoint', false)
        });
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    onCartsConfirmation (payload) {
      this.openModal({ name: ModalList.CartsConfirmation, payload });
    },
    hideOverlay () {
      this.$store.commit('ui/setSearchpanel', false);
      this.$store.commit('ui/closeCatalog');
      this.$store.commit('ui/closeMenu');
    }
  },
  metaInfo: mergeMeta(metaCore)
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.default-layout {
  min-width: var(--min-width);

  @include for-desktop {
    padding-top: 0;
  }
}
</style>
