import { AbortController } from 'node-abort-controller';

const isServer: boolean = typeof window === 'undefined'

const Fetch = isServer ? async function () {
  let controller = new AbortController();

  let timeout = setTimeout(() => {
    controller.abort();
  }, 30000);

  try {
    arguments[1] = arguments?.[1] ? {
      ...arguments?.[1],
      keepalive: false,
      signal: controller.signal
    } : {
      keepalive: false,
      signal: controller.signal
    }

    // @ts-ignore
    return await process.nativeFetch.apply(null, arguments)
  } catch (e) {
    throw new Error(e)
  } finally {
    timeout && clearTimeout(timeout)
    timeout = null
    controller = null
  }
} : function () {
  return window.fetch.apply(null, arguments)
}

export default Fetch
