<template>
  <div
    @mouseleave="closeMenu"
    class="m-megamenu"
    :class="{
        'm-megamenu--is-at-bottom': isAtBottom
      }"
    data-transaction-name="Close Mega Menu"
    @click="closeCatalog"
  >
    <div
      ref="catalogMenu"
      class="m-megamenu__main bg-white"
      :class="{'m-megamenu__main--open': isCatalogOpened}"
    >
      <div class="m-megamenu__main__catalog" @scroll="handleScroll">
        <ul class="m-megamenu-list m-megamenu__main-list">
          <AMegaMenuItem
            v-for="(item, index) in categories"
            :key="index"
            :name="item.name"
            :link="item.link"
            @mouseover="selectFirstCurrent(item)"
            :is-active="currentFirstId === item.id"
            :has-children="Boolean(item.items && item.items.length)"
            :is-mobile="isMobile"
            :item-id="Number(item.pimcore_id) || 0"
            is-show-preview
            class="a-megamenu-item--main"
          >
            <div
              v-show="(currentFirstId === item.id) && item.items.length"
              class="m-megamenu-sub"
            >
              <ul
                ref="catalogMenuSub"
                class="m-megamenu-sub-list m-megamenu-sub__main"
                :class="{'m-megamenu--is-brand-logo': item.brand_menu}"
              >
                <AMegaMenuItem
                  v-for="(l, dx) in item.items"
                  :key="dx"
                  :name="l.name"
                  :link="l.link"
                  @mouseover="selectSecondCurrent(l)"
                  @close="closeCatalog"
                  :has-children="Boolean(l.items && l.items.length)"
                  :is-mobile="isMobile"
                  :is-active="currentTwiceId === l.id"
                >
                  <div class="m-megamenu-sub__twice">
                    <ul
                      ref="catalogMenuSub"
                      class="m-megamenu-sub-list m-megamenu-list-wrap"
                    >
                      <AMegaMenuItem
                        v-for="(d, idx) in l.items"
                        :key="idx"
                        :name="d.name"
                        :link="d.link"
                        @mouseover="selectThirdCurrent(d, d.items)"
                        @close="closeCatalog"
                        :is-mobile="isMobile"
                        :has-children="Boolean(d.items && d.items.length)"
                        :is-active="currentThirdId === d.id"
                      >
                        <div class="m-megamenu-sub__third">
                          <ul
                            ref="catalogMenuSub"
                            class="m-megamenu-sub-list m-megamenu-list-wrap"
                            v-show="d.items"
                          >
                            <AMegaMenuItem
                              v-for="(ite, indx) in d.items"
                              :key="indx"
                              :name="ite.name"
                              :link="ite.link"
                              @mouseover="getLastCategory(ite.id)"
                              @close="closeCatalog"
                              :is-mobile="isMobile"
                              :has-children="Boolean(ite.items && ite.items.length)"
                              :is-active="currentFourthId === ite.id"
                            />
                          </ul>
                        </div>
                      </AMegaMenuItem>
                    </ul>
                  </div>
                </AMegaMenuItem>
              </ul>
              <div
                v-show="isShowBanner"
                class="m-megamenu__banner"
                :class="{'active': isShowBanner}"
              >
                <MBannerMenu
                  :category-id="item.id"
                  :banner-is-showed="!!(currentFirstId === item.id && item.items.length && isShowBanner)"
                />
              </div>
              <div
                v-if="item.brand_menu"
                class="m-megamenu__brands"
              >
                <NoSSR>
                  <MBrandMenu :items="item.brand_menu" />
                </NoSSR>
              </div>
            </div>
          </AMegaMenuItem>
        </ul>
      </div>
    </div>
    <div
      v-show="isShowMainAside"
      class="m-megamenu__main-aside"
    >
      <div class="m-megamenu__banner" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import AMegaMenuItem from 'theme/components/atoms/a-megamenu-item';
import MBannerMenu from 'theme/components/molecules/m-banner-menu';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'MMegaMenu',
  components: {
    AMegaMenuItem,
    MBannerMenu,
    MBrandMenu: () => process.browser ? import('theme/components/molecules/m-brand-menu') : null,
    NoSSR
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentFirstId: 0,
      currentTwiceId: 0,
      currentThirdId: 0,
      currentFourthId: 0,
      currentIndex: 0,
      isShowBanner: true,
      isShowMainAside: true,
      isAtBottom: false
    }
  },
  computed: {
    ...mapState({
      isCatalogOpened: state => state.ui.isMobileCatalog
    })
  },
  methods: {
    selectFirstCurrent (item) {
      this.currentFirstId = item.id;
      this.currentTwiceId = 0
      this.currentThirdId = 0
      this.currentFourthId = 0
      this.isShowMainAside = !this.currentFirstId
      this.isShowBanner = true;
    },
    selectSecondCurrent (item) {
      this.currentTwiceId = item.id;
      this.currentThirdId = 0
      this.currentFourthId = 0
      this.isShowBanner = true;
    },
    selectThirdCurrent (item, children) {
      this.currentThirdId = item.id
      this.currentFourthId = 0
      this.isShowBanner = !children.length;
    },
    getLastCategory (id) {
      this.currentFourthId = id
    },
    resetCurrent () {
      this.currentFirstId = 0
      this.currentTwiceId = 0
      this.currentThirdId = 0
      this.currentFourthId = 0
    },
    closeMenu () {
      this.resetCurrent()
    },
    closeCatalog () {
      this.$store.commit('ui/setSearchpanel', false);
      this.$store.commit('ui/closeCatalog');
      this.$store.commit('ui/closeMenu');
    },
    handleScroll (event) {
      const element = event.target;
      const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;

      if (atBottom) {
        this.isAtBottom = true;
      } else if (this.isAtBottom) {
        this.isAtBottom = false;
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-megamenu {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  z-index: 3;

  @include for-mobile {
    overflow-y: auto;
    overflow-x: hidden;
    visibility: visible;
    opacity: 1;
    top: 0;
    height: 100%;

    &--hidden {
      display: none;
    }

    &--show {
      display: block;
    }
  }

  &-list {
    list-style: none;
    box-sizing: border-box;
  }

  &__main__catalog {
    height: 100%;
    overflow-y: auto;
    padding: 0 25px;
  }

  &__main {
    width: 34%;
    height: 680px;
    padding: var(--spacer-20) 0;
    max-height: calc(100vh - 8rem);
    overflow-y: hidden;
    box-shadow: 0 0 15px 6px rgba(0, 0, 0, .2);
    box-sizing: border-box;
    display: none;
    &--open {
      display: block;
    }

    @include for-desktop {
      margin-top: 0;
      box-shadow: 11px 4px 28px 0 rgba(221,221,221, 0.25);
    }
  }

  &__main-list {
    margin: 0;
    padding: 0;
  }

  &__banner {
    position: absolute;
    min-width: 245px;
    top: 0;
    right: 0;
    margin: 25px;
    max-width: 245px;
    &.active {
      z-index: 2;
    }
  }

  &__main-aside {
    flex: 1 0 auto;
    display: none;

    .m-megamenu__banner {
      width: 18.216%;
    }
  }

  &-sub {
    width: 66%;
    height: 100%;
    background: var(--white);
    position: absolute;
    right: 0;
    top: 0;
    padding: 25px;
    box-sizing: border-box;

    @include for-desktop {
      z-index: -1;
    }

    &-list {
      list-style: none;
      box-sizing: border-box;

      .a-megamenu-item {
        &__link {
          width: 100%;
          display: flex;
          flex-direction: column;
          position: relative;
          padding: var(--font-sm) 0;

          @include for-desktop {
            padding: var(--spacer-xs) var(--spacer-xl) var(--spacer-xs) 0;
            box-sizing: border-box;
            cursor: pointer;
            border-bottom: none;
          }
        }

        &:last-child {
          .a-megamenu-item__link {
            color: var(--orange);
          }
        }
      }
    }

    &__title {
      padding: var(--spacer-xs) var(--spacer-sm) var(--spacer-xs) var(--spacer-base);
      display: flex;
    }

    &__main {
      border-right: 1px solid var(--line-color);
      width: 31.8%;
      overflow-y: auto;
      height: 100%;
      max-height: calc(100vh - 10rem);
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    &__twice {
      display: none;
      position: absolute;
      top: 25px;
      left: 33%;
      box-sizing: border-box;
      width: 32.8%;
      padding: 0 0 0 25px;
      height: 500px;
      max-height: calc(100vh - 10rem);
    }

    &__third {
      position: absolute;
      top: 0;
      left: 90.5%;
      border-left: 1px solid var(--line-color);
      padding: 0 0 8px 25px;
      margin: 0 0 0 25px;
      width: 94%;
      white-space: nowrap;
      height: 500px;
      max-height: calc(100vh - 10rem);
      display: none;

      @include for-desktop {
        z-index: 2;
        background-color: #fff;
      }
    }
  }

  &--is-brand-logo {
    @include for-desktop {
      height: calc(100% - 85px);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 33%;
    height: 60px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 59.43%);
  }

  &--is-at-bottom {
    &:after {
      display: none;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(235, 103, 71, 0.3);
    border-right: 5px white solid;
    background-clip: padding-box;
    &:hover {
        background-color: rgb(235, 103, 71);
    }
  }
}

.m-megamenu-list-wrap {
  height: 100%;
  overflow-y: auto;
  overflow-x: initial;
  padding: 0;
  margin: 0;
}
</style>
