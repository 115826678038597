import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import RootState from '@vue-storefront/core/types/RootState';
import CategoryExtensionState from './../types/CategoryExtensionState';

export const categoryExtStore: Module<CategoryExtensionState, RootState> = {
  namespaced: true,
  state: {
    childCategories: [],
    relatedChildCategories: [],
    varusCafeCategories: [],
    varusCafeCategoriesLoading: true,
    categoriesProductsCount: {},
    isCategoryProductsOutOfStock: false,
    filtersMap: {},
    categories: [],
    currentCategory: null,
    aggregations: {},
    activeFilters: [],
    activeSort: [],
    filterPrice: {},
    varusNewProducts: [],
    isBrandCategory: false,
    newProductsLoading: true,
    newProductsTotal: null,
    clickLoadMore: false,
    specialCategoryLoading: true
  },
  actions,
  mutations,
  getters
}
