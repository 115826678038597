<template>
  <SfButton
    class="sf-button--pure a-microcart-icon"
    data-transaction-name="Open Microcart"
    @click="openMicrocart"
    v-cloak
  >
    <div class="a-microcart-icon__wrapper">
      <SfBadge v-show="isShowBarge && areTotalsVisible"
        class="sf-icon__badge sf-badge--number"
      >
        {{ itemsQuantity }}
      </SfBadge>
    </div>
    <span class="a-microcart-value" :class="{ 'a-microcart-value--loading': isLoading }">
      <template v-if="!areTotalsVisible">
        {{ $t('Basket') }}
      </template>

      <template v-else>
        {{ getCartTotal | price }}
      </template>

      <ALoadingSpinner
        v-show="isLoading"
        :size="24"
        :weight="3"
        :is-absolute-position="true"
      />
    </span>
  </SfButton>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { SfButton, SfBadge } from '@storefront-ui/vue';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'

export default {
  components: {
    SfButton,
    SfBadge,
    ALoadingSpinner
  },
  props: {
    floatingIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMounted: false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    ...mapState({
      isCartConnecting: state => state.cart.isConnecting,
      isCartSync: state => state.cart.isSyncing,
      isSyncingTotal: state => state.cart.isSyncingTotal,
      isDeliveryLoader: state => state.ui.deliveryLoader,
      isShippingDetailSave: state => state.shipping.loading.shippingDetailsSave
    }),
    ...mapGetters({
      itemsQuantity: 'cart/getNonGiftItemsQuantityWithQTY',
      getCartTotal: 'cart/getCartTotal'
    }),
    isLoading () {
      return !this.isMounted ||
        this.isCartSync ||
        this.isSyncingTotal ||
        this.isDeliveryLoader ||
        this.isShippingDetailSave ||
        this.isCartConnecting
    },
    isShowBarge () {
      return !this.floatingIcon && this.itemsQuantity
    },
    areTotalsVisible () {
      return this.itemsQuantity && !isNaN(this.getCartTotal)
    }
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    })
  }
};
</script>

<style lang="scss" scoped>
  @import '~theme/css/fonts';

  .a-microcart-icon {
    font-family: var(--font-family-primary);
    font-size: var(--font-sm);
    position: relative;
    margin: 0;
    width: 100%;
    display: inline-flex;
    height: 100%;
    justify-content: flex-start;
    padding-left: 20px;
    white-space: nowrap;

    .sf-header__icon {
      cursor: pointer;
    }

    &:hover {
      .a-microcart-icon__wrapper {
        &:before {
          color: var(--orange-hover);
        }
      }
      .sf-badge--number {
        background: var(--orange-hover);
      }
    }
  }
  .sf-badge--number {
    background: var(--c-primary);
    --badge-min-width: 1.875rem;
    --badge-min-height: 1.875rem;
    --badge-padding: 0 4px;
    --badge-font-size: var(--font-size-13);
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .a-microcart-icon__wrapper {
    display: flex;
    align-items: center;
    margin-right: var(--spacer-15);
    @include font-icon(var(--icon-cart));

    &:before {
      font-size: var(--font-size-25);
      color: var(--orange);
    }
  }

  .sf-icon__badge {
    position: static;
  }

  .a-microcart-value {
    color: var(--black);
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: color 200ms;

    &--loading {
      color: var(--white)
    }
  }
</style>
