import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import VarusCafeState from '../types/VarusCafeState'

export const VarusCafeStore: Module<VarusCafeState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    filters: {
      available: {},
      chosen: {}
    },
    breadcrumbs: {
      routes: []
    },
    current_product_query: null,
    current_path: [], // list of categories from root to current,
    productsIsLoading: true,
    isAdditionalLoading: false,
    categoriesIsLoading: true,
    products: [],
    productCountsByCategory: {},
    categoryDescription: '',
    sampleSpecialOffers: [],
    categoryPromotionBannersIds: {},
    childCategories: [],
    topBanners: [],
    middleBanners: [],
    availableProducts: true
  },
  getters,
  actions,
  mutations
}
