import { MutationTree } from 'vuex'
import * as types from '../types/mutation-types'
import ShippingModuleState from '../types/shipping-module-state';
import Vue from 'vue';

const mutations: MutationTree<ShippingModuleState> = {
  [types.SM_SET_CITY_LIST] (state, cityList) {
    state.cityList = (cityList || [])
      .sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  [types.SM_SET_SHOP_LIST] (state, shopList) {
    state.shopList = shopList
  },
  [types.SM_SET_DEFAULT_CITY] (state, city) {
    state.defaultCity = city
  },
  [types.SM_SET_DEFAULT_SHOP] (state, shop) {
    state.defaultShop = shop
  },
  [types.SM_SET_LOADING_SHOP_LIST] (state, loading) {
    state.loading.shopList = loading === true
  },
  [types.SM_SET_LOADED_SHOP_LIST] (state, loaded) {
    state.loaded.shopList = loaded === true
  },
  [types.SM_SET_LOADING_GEOCODE] (state, loading) {
    state.loading.geocode = loading === true
  },
  [types.SM_SET_LOADED_GEOCODE] (state, loaded) {
    state.loaded.geocode = loaded === true
  },
  [types.SM_SET_LOADING_SHIPPING] (state, loading) {
    state.loading.shipping = loading === true
  },
  [types.SM_SET_LOADED_SHIPPING] (state, loaded) {
    state.loaded.shipping = loaded === true
  },
  [types.SM_SET_DRAFT_CITY] (state, city) {
    state.draft.city = city
  },
  [types.SM_SET_DRAFT_METHOD] (state, method) {
    state.draft.method = method
  },
  [types.SM_SET_DRAFT_SHOP] (state, shop) {
    state.draft.shop = shop
  },
  [types.SM_SET_DRAFT_TYPE] (state, type) {
    state.draft.type = type
  },
  [types.SM_SET_DRAFT_TYPE_GROUP] (state, group) {
    state.draft.group = (group || [])
  },
  [types.SM_SET_DRAFT_ADDRESS] (state, address) {
    state.draft.address = address
  },
  [types.SM_SET_DRAFT_SHIPPING] (state, draft) {
    const keys = Object.keys(draft)

    for (const key of keys) {
      Vue.set(state.draft, key, draft[key])
    }
  },
  [types.SM_SET_CURRENT_SHIPPING] (state, current) {
    const keys = Object.keys(current)

    for (const key of keys) {
      Vue.set(state.current, key, current[key])
    }
  },
  [types.SM_SET_DELIVERY_POLYGON] (state, { tms_id, list }) {
    state.deliveryPolygon = { tms_id, list }
  },
  [types.SM_SET_CITY_LIST_FOR_GEOCODE] (state, cityList) {
    state.cityListForGeocode = cityList
  },
  [types.SM_SET_NP_CENTER] (state, payload) {
    state.npCenter = payload
  },
  [types.SM_SET_NP_TYPE] (state, payload) {
    state.draft.npType = payload
  },
  [types.SM_SET_NP_CITY_CURRENT] (state, payload) {
    state.draft.npCity = payload
  },
  [types.SM_SET_NP_CITY_LIST] (state, payload) {
    state.npCityList = payload
  },
  [types.SM_SET_NP_SHOP_CURRENT] (state, payload) {
    state.draft.npShop = payload
  },
  [types.SM_SET_NP_SHOP_LIST] (state, payload) {
    state.npShopList = payload
  }
}

export default mutations
