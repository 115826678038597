<template>
  <SfButton
    data-transaction-name="TM - Close"
    @click.native="goToMenu"
    aria-label="Toggle Icon"
    class="sf-link sf-link--primary a-toggle-menu"
    :class="className"
  >
    <span class="close-icon a-toggle-menu__close" />
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapState } from 'vuex';

export default {
  name: 'AToggleMenu',
  components: {
    SfButton
  },
  props: {
  },
  computed: {
    ...mapState({
      isMobileMenu: state => state.ui.isMobileMenu
    }),
    className () {
      return {
        'a-toggle-menu--active': this.isMobileMenu
      }
    }
  },
  methods: {
    goToMenu () {
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.dispatch('ui/hideBottomNavigation', true)
      if (this.isMobileMenu) {
        this.$emit('close')
        this.$store.dispatch('ui/hideBottomNavigation', false)
      } else {
        this.$store.commit('ui/openMenu')
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/fonts";

.a-toggle-menu {
  width: 30px;
  height: 30px;

  @include font-icon(var(--icon-menu));

  &:before {
    font-size: 38px;
    color: var(--orange);
  }

  @include for-desktop {
    display: none;
  }
}

.a-toggle-menu--active {
  display: inline-flex;
  border-radius: 5px;

  @include for-desktop {
    display: none;
  }

  &.sf-link {
    background: var(--light-gray);
  }

  &:before {
    display: none;
  }

  .a-toggle-menu__close {
    display: inline-flex;
  }
}

.a-toggle-menu__close {
  display: none;
}
</style>
