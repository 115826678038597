import { MutationTree } from 'vuex'
import UserState from './types/UserState'
import * as types from './mutation-types'

const mutations: MutationTree<UserState> = {
  [types.USER_SET_CURRENT] (state) {
    if (!state.currentUser.addresses.length && localStorage.getItem('currentUser')) {
      state.currentUser = { ...JSON.parse(localStorage.getItem('currentUser')) }
    }
  },

  [types.USER_CREATE_ADDRESS] (state, address) {
    state.currentUser.addresses.push(address)
    localStorage.setItem('currentUser', JSON.stringify(state.currentUser))
  },

  [types.USER_SET_SEARCH_HISTORY] (state, history) {
    state.searchHistory = history
  },

  [types.USER_SET_IS_NEW_USER_FLAG] (state, status) {
    state.isNewUser = status
  },
  [types.USER_SET_AVATAR] (state, avatar) {
    state.avatar = avatar
  },
  [types.USER_SET_VALIDATION_STATUS] (state, status) {
    state.validationStatus = status
  }
};

export default mutations
