<template>
  <section class="sf-modal" :class="[staticClass, className]">
    <SfOOverlay
      v-if="overlay"
      class="sf-modal__overlay"
      :transition="transitionOverlay"
      :visible="isVisible"
      @click="checkPersistence"
    />
    <transition :name="transitionModal">
      <div v-if="isVisible" v-focus-trap class="sf-modal__container">
        <!--@slot Use this slot to place content inside the modal bar.-->
        <slot name="modal-bar">
          <SfBar
            class="sf-modal__bar"
            :back="isBarBack"
            :close="false"
            :title="title"
            :class="barClass"
            @click:back="back"
            @click:close="close"
          />
        </slot>
        <SfButton
          v-if="cross"
          class="sf-button--pure sf-modal__close"
          :class="{'desktop-only': !isBarClose}"
          aria-label="Close modal"
          data-transaction-name="Close Modal"
          @click="close"
        >
          <!--@slot Use this slot to place content inside the close button.-->
          <slot name="close">
            <SfIcon icon="cross" size="0.875rem" color="black" />
          </slot>
        </SfButton>
        <div ref="content" class="sf-modal__content">
          <!--@slot Use this slot to place content inside the modal.-->
          <slot />
        </div>
      </div>
    </transition>
    <slot name="undermodal" />
  </section>
</template>
<script>
import SfBar from '@storefront-ui/vue/src/components/molecules/SfBar/SfBar.vue';
import SfOOverlay from 'theme/components/storefront-override/SfOOverlay';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import ScrollLock from 'theme/mixins/ScrollLock';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'SfOModal',
  directives: { focusTrap },
  components: {
    SfBar,
    SfOOverlay,
    SfIcon,
    SfButton
  },
  model: {
    prop: 'visible',
    event: 'close'
  },
  props: {
    /**
     * Heading title of the modal
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Visibility of the modal
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * Cross closing modal button
     */
    cross: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to show the overlay
     */
    overlay: {
      type: Boolean,
      default: true
    },
    /**
     * If true clicking outside will not dismiss the modal
     */
    persistent: {
      type: Boolean,
      default: false
    },
    /**
     * overlay transition effect
     */
    transitionOverlay: {
      type: String,
      default: 'fade'
    },
    /**
     * overlay transition effect
     */
    transitionModal: {
      type: String,
      default: ''
    },
    /**
     * Visibility of Back button on SfBar
     */
    isBarBack: {
      type: Boolean,
      default: false
    },
    /**
     * Visibility of Close button on SfBar
     */
    isBarClose: {
      type: Boolean,
      default: true
    },
    isMobileBar: {
      type: Boolean,
      default: true
    },
    customBarClass: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      staticClass: null,
      className: null,
      isMounted: false
    };
  },
  mixins: [ScrollLock, DeviceType],
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            this.enableScroll()
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          this.disableScroll()
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true
    }
  },
  computed: {
    barClass () {
      const customBarClass = typeof this.customBarClass === 'object' ? this.customBarClass : {}

      return {
        ...customBarClass,
        'mobile-only': this.isMobileBar
      }
    },
    isVisible () {
      return this.visible && this.isMounted
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    close () {
      this.$emit('close', false);
    },
    back () {
      this.$emit('back', false);
    },
    checkPersistence () {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler (e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    enableScroll () {
      this.enable()
    },
    disableScroll () {
      this.disable()
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfModal.scss";
</style>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.sf-overlay {
  z-index: var(--sf-overlay-index);
}
</style>
