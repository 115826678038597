import { Module } from 'vuex'
import NotificationItem from '../types/NotificationItem'
import NotificationState from '../types/NotificationState'
import { isServer } from '@vue-storefront/core/helpers';

export const notificationStore: Module<NotificationState, any> = {
  namespaced: true,
  state: {
    notification: null
  },
  getters: {
    notification: state => state.notification
  },
  mutations: {
    add (state, payload) {
      state.notification = payload
    },
    remove (state) {
      state.notification = null
    }
  },
  actions: {
    spawnNotification ({ commit, state, dispatch }, notification: NotificationItem): NotificationItem {
      if (state.notification && state.notification.message === notification.message) {
        return
      }

      const id = Math.floor(Math.random() * 100000)
      const newNotification = { id, ...notification }

      commit('add', newNotification)

      if (!newNotification.hasNoTimeout && !isServer) {
        setTimeout(() => {
          dispatch('removeNotificationById', id)
        }, newNotification.timeToLive || 5000)
      }

      return newNotification
    },
    removeNotification ({ commit }) {
      commit('remove')
    },
    removeNotificationById ({ commit, state }, id: number) {
      if (state.notification?.id === id) {
        commit('remove')
      }
    }
  }
}
