<template>
  <div class="minimal-layout">
    <div id="viewport">
      <OHeaderMinimal :is-error="true" />
      <slot />
      <OFooterMinimal :is-error="true" />
    </div>
  </div>
</template>

<script>
import OHeaderMinimal from 'theme/components/organisms/o-header-minimal';
import OFooterMinimal from 'theme/components/organisms/o-footer-minimal';

export default {
  components: {
    OHeaderMinimal,
    OFooterMinimal
  }
}
</script>
