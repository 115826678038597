import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { checkoutModule } from './store/checkout'
import { paymentModule } from './store/payment'
import { shippingModule } from './store/shipping'
import { Logger } from '@vue-storefront/core/lib/logger'
import * as typesCache from 'theme/store/checkout/types/CheckoutCacheTypes';
import * as typesCheckout from './store/checkout/mutation-types'
import * as typesShipping from './store/shipping/mutation-types'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory'
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const CheckoutModule: StorefrontModule = function ({ store }) {
  StorageManager.init(typesCache.CHECKOUT_CACHE)

  store.registerModule('shipping', shippingModule)
  store.registerModule('payment', paymentModule)
  store.registerModule('checkout', checkoutModule)

  store.subscribe((mutation, state) => {
    const type = mutation.type

    // Shipping
    if (
      type.endsWith(typesShipping.SHIPPING_GEO_LOCATE_SHOP)
    ) {
      StorageManager.get(typesCache.CHECKOUT_CACHE).setItem(
        typesCache.SHIPPING_CACHE_DELIVERY_COORDINATES,
        state.shipping.geoLocateShop
      ).catch((reason) => {
        Logger.error(reason)()
      })
    }

    if (
      type.endsWith(typesShipping.SHIPPING_SAVE_DETAILS) || type.endsWith(typesShipping.SHIPPING_UPDATE_PROP_VALUE)
    ) {
      StorageManager.get(typesCache.CHECKOUT_CACHE).setItem(
        typesCache.SHIPPING_CACHE_DETAILS,
        state.shipping.shippingDetails
      ).catch((reason) => {
        Logger.error(reason)() // it doesn't work on SSR
      }) // populate cache
    }

    // Checkout
    if (
      type.endsWith(typesCheckout.CHECKOUT_SAVE_PERSONAL_DETAILS)
    ) {
      StorageManager.get(typesCache.CHECKOUT_CACHE).setItem(
        typesCache.PERSONAL_CACHE_DETAILS,
        state.checkout.personalDetails
      ).catch((reason) => {
        Logger.error(reason)() // it doesn't work on SSR
      }) // populate cache
    }

    if (
      type.endsWith(typesCheckout.CHECKOUT_SAVE_PAYMENT_DETAILS)
    ) {
      StorageManager.get(typesCache.CHECKOUT_CACHE).setItem(
        typesCache.PAYMENT_CACHE_DETAILS,
        state.checkout.paymentDetails
      ).catch((reason) => {
        Logger.error(reason)() // it doesn't work on SSR
      }) // populate cache
    }
  })

  if (!isServer) {
    EventBus.$on('user-after-logout', () => {
      store.dispatch('checkout/clearCheckoutSession')
    })
    EventBus.$on('checkout-after-shippingMethodChanged', () => {
      store.dispatch('checkout/clearCheckoutSession', true)
    })
  }

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('tms_city', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'tms_city', start, size)
      }
    });
    searchAdapter.registerEntityType('tms_shop', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'tms_shop', start, size)
      }
    });
  });
}
