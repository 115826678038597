import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ImageState from './types/ImageState';
import actions from './actions'

export const ImagePhotoGallery: Module<ImageState, RootState> = {
  namespaced: true,
  state: {
    id: null,
    title: '',
    src: ''
  },
  actions
}
