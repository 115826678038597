<template>
  <p class="company-phone">
    <SfLink
      target="_blank"
      link="tel:0800307080"
      class="company-phone__link"
      :class="className"
    >
      {{ contactPhone }}
    </SfLink>
    <span
      class="company-phone__schedule"
      :class="classNameCaption"
    >
      {{ $t('Everyday from 8:00 to 22:00') }}
    </span>
    <SfLink
      v-if="isEmailShown"
      :link="`mailto:${contactEmail}`"
      class="company-phone__email"
      @click="copyEmailToClipboard"
    >
      {{ contactEmail }}
    </SfLink>
  </p>
</template>

<script>
import config from 'config';
import {
  SfLink
} from '@storefront-ui/vue';

export default {
  name: 'PhoneContact',
  components: { SfLink },
  props: {
    size: {
      type: String,
      default: 'large'
    },
    isEmailShown: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    contactPhone () {
      return config.seo.contactPhone;
    },
    contactEmail () {
      return config.seo.email;
    },
    className () {
      return {
        'company-phone__link--sm': this.size === 'small'
      }
    },
    classNameCaption () {
      return {
        'company-phone__caption--sm': this.size === 'small'
      }
    }
  },
  methods: {
    copyEmailToClipboard () {
      navigator.clipboard.writeText(this.contactEmail);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.company-phone__schedule {
  display: block;
  color: var(--dark-gray);
  font-size: var(--font-sm);

  @media screen and (min-width: 375px){
    white-space: nowrap;
  }

  &--sm {
    font-size: var(--font-xs);
  }
}

.company-phone__link {
  color: var(--black);
  --link-font-size: var(--font-size-20);
  --link-font-weight: var(--font-medium);
  white-space: nowrap;

  &--sm {
    --link-font-size: var(--font-size-15);

    @include for-desktop {
      --link-font-size: var(--font-size-17);
    }
  }
}

.company-phone__email {
  display: block;
  color: var(--dark-gray);
  font-size: var(--font-sm);
  text-wrap: pretty;

  @media screen and (min-width: 375px){
    white-space: nowrap;
  }

  &--sm {
    font-size: var(--font-xs);
  }
}
</style>
