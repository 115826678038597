import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import SearchState from '../types/SearchResultState'

const getters: GetterTree<SearchState, RootState> = {
  getSearchProducts: state => state.searchResultProducts,
  getCategories: state => state.categories,
  getSearchResultStats: state => state.resultStats,
  getTotalPages: state => state.resultStats.totalPages,
  getTotalProducts: state => state.resultStats.totalProducts,
  getSearchTotalProducts: state => state.searchTotalProducts,
  getCurrentCategory: state => state.currentCategory,
  getAvailableFilters: (state) => state.availableFilters,
  getFilterLabels: (state) => state.filterLabels,
  getCurrentFilters: (state) => state.activeFilters,
  getFilterPrice: (state) => state.filterPrice,
  getMetaTags: (state) => state.metaTags,
  getSearchResult: (state) => state.autocomplete,
  getLoadingAutocomplete: (state) => state.loading.autocomplete,
  getLoadingResultPage: (state) => state.loading.resultPage,
  getLoadedResultPage: (state) => state.loaded.resultPage
};

export default getters
