import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { Logger } from '@vue-storefront/core/lib/logger'
import ShippingState from 'theme/store/checkout/types/ShippingState';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { CheckoutService } from 'theme/store/checkout/data-resolver/CheckoutService';
import * as types from './mutation-types'
import { SearchQuery } from 'storefront-query-builder';
import * as typesCache from 'theme/store/checkout/types/CheckoutCacheTypes'
import modulesConfig from '$modules/config'

const actions: ActionTree<ShippingState, RootState> = {
  async loadGeoLocateShop ({ commit }, payload) {
    try {
      const shippingStorage = StorageManager.get(typesCache.CHECKOUT_CACHE)

      const deliveryCoordinates = await shippingStorage.getItem(
        typesCache.SHIPPING_CACHE_DELIVERY_COORDINATES
      )

      const cachedCoordinates = deliveryCoordinates?.coordinates || {}

      if (
        !payload.force &&
        cachedCoordinates.lat === payload.lat &&
        cachedCoordinates.lng === payload.lng
      ) {
        commit(types.SHIPPING_GEO_LOCATE_SHOP_RESTORE, deliveryCoordinates)
        return
      }

      const coordinates = { lat: payload.lat, lng: payload.lng }

      const res = await CheckoutService.getGeoLocateShop(payload.lat, payload.lng, payload.cityId || null)

      if (res.code === 200 && (typeof res.result === 'object' && !res.result.code)) {
        commit(types.SHIPPING_GEO_LOCATE_SHOP, { coordinates, shops: res.result })
      } else {
        commit(types.SHIPPING_GEO_LOCATE_SHOP, { coordinates, shops: {} })
      }

      return res
    } catch (e) {
      Logger.error('Can not load shop by coordinates', 'checkout')()

      const coordinates = { lat: 0, lng: 0 }
      commit(types.SHIPPING_GEO_LOCATE_SHOP, { coordinates, shops: {} })
    }
  },
  clearGeoLocateShop ({ commit }) {
    commit(types.SHIPPING_GEO_LOCATE_SHOP, {})
  },
  async setFilledUserShippingDetails ({ commit }, action) {
    commit(types.SHIPPING_FILLED_USER_DETAILS, action)
  },
  async loadCitiesList ({ commit }, storeCode = null) {
    const query = new SearchQuery()
    try {
      const cities = await CheckoutService.getCitiesList({
        query,
        storeCode,
        excludeFields: modulesConfig.cityList.excludeFields
      })

      if (!storeCode) {
        const list = cities.items.map((city) => {
          const coordinates = {
            lng: +city.long,
            lat: +city.lat
          }

          const region = city.name

          delete city.long
          delete city.lat

          return {
            ...city,
            region,
            coordinates
          }
        })

        commit(types.SHIPPING_CITIES_LIST, list)

        return list
      }

      return cities.items
    } catch (e) {
      Logger.error('Can not load cities list', 'checkout')()
      return null
    }
  },
  async loadCurrentCoordinates ({ commit, state }, { tms_id }) {
    const query = new SearchQuery()

    if (!tms_id) return []

    query.applyFilter({ key: 'tms_id', value: { 'eq': tms_id } })

    try {
      if (+tms_id === +state.deliveryPolygon.tms_id && !!state.deliveryPolygon.list.length) { return [] }

      const cities = await CheckoutService.getCitiesList({
        query,
        includeFields: modulesConfig.cityList.includeFields
      })

      const list = (cities.items?.[0]?.district_polygon?.[0] || []).map(i => [i.lng, i.lat])
      const tmsId = cities.items?.[0]?.tms_id || []

      commit(types.SHIPPING_DELIVERY_POLYGON, { list, tms_id: tmsId })

      return list
    } catch (e) {
      commit(types.SHIPPING_DELIVERY_POLYGON, { list: [], tms_id })

      Logger.error('Can not load cities list', 'checkout')()
      return []
    }
  },
  addMethod ({ dispatch }, shippingMethod) {
    Logger.error('The action shipping/addMethod has been deprecated please change to checkout/addShippingMethod')()
    dispatch('shipping/addShippingMethod', shippingMethod, { root: true })
  },
  replaceMethods ({ dispatch }, shippingMethods) {
    Logger.error('The action shipping/replaceMethods has been deprecated please change to checkout/replaceShippingMethods')()
    dispatch('shipping/replaceShippingMethods', shippingMethods, { root: true })
  }
}

export default actions
