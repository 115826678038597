<template>
  <div
      class="sf-image"
      :class="{ 'sf-image--has-size': size }"
      :style="size"
      v-on="$listeners"
  >
    <template v-if="isPicture">
      <picture>
        <source
            :srcset="source.desktop.url"
            :media="`(min-width: ${pictureBreakpoint}px)`"
        />
        <source
            :srcset="source.mobile.url"
            :media="`(max-width: ${pictureBreakpoint - 1}px)`"
        />
        <img
            v-show="source.desktop.url"
            :src="source.desktop.url"
            v-bind="$attrs"
            :width="width"
            :height="height"
            :loading="lazy ? 'lazy' : 'eager'"
        />
      </picture>
    </template>
    <template v-else>
      <img
          v-show="source"
          :src="source"
          v-bind="$attrs"
          :width="width"
          :height="height"
          :loading="lazy ? 'lazy' : 'eager'"
      />
    </template>
    <noscript v-if="lazy && noscript" inline-template>
      <img
          class="noscript"
          :src="noscript"
          v-bind="$attrs"
          :width="width"
          :height="height"
          :loading="lazy ? 'lazy' : 'eager'"
      />
    </noscript>
    <div v-if="hasOverlay" class="sf-image__overlay">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SfImage',
  inheritAttrs: false,
  props: {
    src: {
      type: [String, Object],
      default: ''
    },
    lazy: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: null
    },
    height: {
      type: [String, Number],
      default: null
    },
    pictureBreakpoint: {
      type: Number,
      default: 1024
    },
    rootMargin: {
      type: String,
      default: '0px 0px 0px 0px'
    },
    threshold: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      isLoaded: false
    };
  },
  computed: {
    isPicture () {
      return !!this.src && typeof this.src === 'object';
    },
    source () {
      return this.src;
    },
    noscript () {
      return this.isPicture ? this.src.desktop.url : this.src;
    },
    size () {
      return (
        this.width &&
          this.height && {
          '--_image-width': this.width,
          '--_image-height': this.height
        }
      );
    },
    hasOverlay () {
      return this.$slots.default;
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfImage.scss";
</style>
