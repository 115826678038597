import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import Task from '@vue-storefront/core/lib/sync/types/Task'

export const BlogService = {
  loadBlogs: async ({ query, pageSize, start }: any): Promise<any> => {
    return quickSearchByQuery({
      entityType: 'blog_posts',
      query,
      start,
      size: pageSize,
      excludeStoreCode: true
    });
  },
  getBlogCategories: async ({ query }: any): Promise<any> => {
    return quickSearchByQuery({
      entityType: 'blog_category',
      query,
      excludeStoreCode: true
    });
  },
  setPostVote: async ({ postId, voteType }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(
      `/api/ext/blog/${voteType}/${postId}`
    )

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      },
      silent: true
    })
  },
  setViews: async ({ postId }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(
      `/api/ext/blog/view/${postId}`
    )

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      },
      silent: true
    })
  }
};
