import Fetch from '@vue-storefront/core/lib/isomorphic-fetch'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder'
import { category } from '../config';
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import { Category } from '@vue-storefront/core/modules/catalog-next/types/Category';

const createGraphQLQuery = ({ blockId, cookie, category, categoryField }: any): string => {
  return JSON.stringify({
    query: `
      query ($blockId: Int!, $cookie: String, $category: String, $categoryField: String) {
        recommendations {
          products(blockId: $blockId, category: $category, categoryField: $categoryField, cookie: $cookie) {
            products {
              id
              url
            }
          }
        }
      }
    `,
    variables: {
      'blockId': blockId,
      'cookie': cookie,
      'category': category,
      'categoryField': categoryField
    }
  });
}

const getBrandPageProduct = async (params: any): Promise<any> => {
  const graphqlQuery = createGraphQLQuery(params);

  return Fetch('https://ai.esputnik.com/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: graphqlQuery
  })
}
const getCategories = async ({
  parentId = null,
  filters = {},
  level = null,
  onlyActive = true,
  onlyNotEmpty = false,
  size = 200,
  start = 0,
  sort = 'position:asc',
  includeFields = category.includeFields,
  excludeFields = category.excludeFields
}: DataResolver.CategorySearchOptions = {}): Promise<Category[]> => {
  let searchQuery = new SearchQuery()
  if (parentId) {
    searchQuery = searchQuery.applyFilter({ key: 'parent_id', value: { 'eq': parentId } })
  }
  if (level) {
    searchQuery = searchQuery.applyFilter({ key: 'level', value: { 'eq': level } })
  }

  for (const [key, value] of Object.entries(filters)) {
    if (value !== null) {
      if (Array.isArray(value)) {
        searchQuery = searchQuery.applyFilter({ key: key, value: { 'in': value } })
      } else if (typeof value === 'object') {
        searchQuery = searchQuery.applyFilter({ key: key, value: value })
      } else {
        searchQuery = searchQuery.applyFilter({ key: key, value: { 'eq': value } })
      }
    }
  }

  if (onlyActive === true) {
    searchQuery = searchQuery.applyFilter({ key: 'is_active', value: { 'eq': true } })
  }

  if (onlyNotEmpty === true) {
    searchQuery = searchQuery.applyFilter({ key: 'product_count', value: { 'gt': 0 } })
  }
  const response = await quickSearchByQuery({ entityType: 'category', query: searchQuery, sort: sort, size: size, start: start, includeFields: includeFields, excludeFields: excludeFields })
  return response.items as Category[]
}

export const BrandService = {
  getCategories,
  getBrandPageProduct
}
