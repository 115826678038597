import cloneDeep from 'lodash/cloneDeep'

import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const makeKey = (c) => {
  const key = [c.sku]

  if (c?.extension_attributes?.markdown_id || c?.markdown_id) {
    key.push(c?.extension_attributes?.markdown_id || c?.markdown_id)
  }

  return key.join(':')
}

export const prepareClientItems = (clientItems, serverItems) => {
  const items = cloneDeep(serverItems)

  const serverPrepare = items
    .reduce((a, c) => {
      const key = makeKey(c)
      a[key] = { time: +c?.extension_attributes?.ui_modified_at || 0, qty: c?.qty }

      return a
    }, {})

  return cloneDeep(clientItems).map(c => {
    const key = makeKey(c)
    const seed = c.uiModifiedAt && c.uiModifiedAt > serverPrepare[key]?.time

    c.qty = seed ? (c.client_qty || c.qty) : (serverPrepare[key]?.qty || c.client_qty || c.qty)

    EventBus.$emit('cart-after-itemchanged', { item: c })

    return c
  })
}
