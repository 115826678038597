import * as types from './mutation-types'
import modulesConfig from '$modules/config'
import getCurrentConfigurationFromTotals from '../helpers/getCurrentConfigurationFromTotals'
import { SearchQuery } from 'storefront-query-builder';
import { Logger } from '@vue-storefront/core/lib/logger';
import connectActions from './actions/connectActions'
import itemActions from './actions/itemActions'
import mergeActions from 'theme/store/cart/store/actions/mergeActions';
import totalsActions from 'theme/store/cart/store/actions/totalsActions';
import productActions from './actions/productActions';
import quantityActions from './actions/quantityActions';
import synchronizeActions from './actions/synchronizeActions';
import * as coreTypes from '@vue-storefront/core/modules/cart/store/mutation-types'
import { ProductPricesState } from 'theme/store/cart/helpers/productPricesState';
import { ProductStockState } from 'theme/store/cart/helpers/productStockState';
import { weightGToKg } from 'theme/filters';
import { getProductWeight } from 'theme/helpers/product';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const actions = {
  ...connectActions,
  ...itemActions,
  ...productActions,
  ...mergeActions,
  ...totalsActions,
  ...synchronizeActions,
  ...quantityActions,

  async configureProduct (context, { product }) {
    if (product.type_id === 'simple') {
      const configuration = getCurrentConfigurationFromTotals(product)
      const parentProduct = await context.dispatch('product/findConfigurableParent', { product, configuration }, { root: true })
      return context.dispatch('cart/updateItem', { product: parentProduct }, { root: true })
    }

    return Promise.resolve()
  },
  openEditMode (context, { product, selectedOptions }) {
    context.commit(types.CART_OPEN_EDIT_MODE, { productId: product.id, qty: product.qty, selectedOptions })
  },
  editModeSetFilters ({ commit }, { filterOptions }) {
    commit(types.CART_EDIT_MODE_SET_FILTERS, { filterOptions })
  },
  editModeSetQty ({ commit }, { qty }) {
    commit(types.CART_EDIT_QTY, { qty })
  },
  closeEditMode ({ commit }) {
    commit(types.CART_CLOSE_EDIT_MODE)
  },
  setCheckoutMethod ({ commit }, method) {
    commit(coreTypes.CART_UPD_SHIPPING, method)
  },

  /**
   * Clear product price state
   *
   * @param commit
   */
  clearProductPriceState ({ commit }) {
    const productPrice = {
      valid: true,
      totalDiffPrice: 0,
      diff: [],
      isShowDiff: false
    }

    commit(types.CART_STATES, { productPrice })
  },

  /**
   * Check stocks of cart items
   *
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param stockResult
   */
  async checkProductsStock ({ commit, rootGetters }, stockResult) {
    try {
      const cartItems = rootGetters['cart/getCartItems']
      const cartStates = rootGetters['cart/getCartStates']

      commit(types.CART_STOCK_RESULTS, stockResult)

      const productStockState = await new ProductStockState(cartStates.productPrice)
        .loadFromStorage()

      for (const product of stockResult) {
        const cartItem = cartItems.find(item => isSkuEqual(product, item))

        productStockState.checkItem(cartItem, product)
      }

      const productStock = await productStockState.result()

      commit(types.CART_STATES, { productStock })
    } catch (e) {
      Logger.debug('Error checkProductsStock: ' + e.message, 'cart')()
    }
  },

  /**
   * Clear notEnough message
   *
   * @param commit
   */
  async clearNotEnough ({ commit }) {
    const productStock = await ProductStockState.hideNotEnoughMessage()

    commit(types.CART_STATES, { productStock })
  },

  /**
   * Check difference between cart item price and product price on backend
   *
   * @param commit
   * @param rootGetters
   * @param stockResult
   */
  async checkProductsPrices ({ commit, rootGetters }, stockResult) {
    try {
      const cartItems = rootGetters['cart/getCartItems']
      const cartStates = rootGetters['cart/getCartStates']
      const replacements = cartStates.productStock.replacements.map(({ sku }) => sku)

      const productPriceState = await new ProductPricesState(cartStates.productPrice, replacements)
        .loadFromStorage()

      for (const product of stockResult) {
        const cartItem = cartItems.find(item => isSkuEqual(item, product))
        const productPrice = product.totals?.price || product.price || null

        const isPromotion = cartItem.totals?.extension_attributes?.promotion?.has_promotion

        if (!cartItem || !productPrice || cartItem.price.special || cartItem.special_price || isPromotion) {
          continue
        }

        const qty = cartItem.qty || 1

        productPriceState.checkItem(
          cartItem.totals?.price,
          productPrice,
          qty,
          cartItem.sku,
          product?.extension_attributes || {}
        )
      }

      const productPrice = await productPriceState.result()

      commit(types.CART_STATES, { productPrice })
    } catch (e) {
      Logger.debug('Error checkProductsPrices: ' + e.message, 'cart')()
    }
  },

  /**
   * * Get product stock and prices, checking conditions
   * @param dispatch
   * @param cartItems
   **/
  async processCartSQPP ({ commit }, cartItems) {
    if (!cartItems) {
      return
    }

    const result = cartItems.map((i) => {
      const sqpp = i?.extension_attributes?.sqpp_data

      if (!sqpp) {
        return null
      }

      const formatDate = (sqpp.special_price_to_date || '').split('-')

      const discountDate = formatDate.length === 3 ? {
        day: +formatDate[2],
        month: +formatDate[1],
        year: +formatDate[0]
      } : null

      const extension_attributes: {[key: string]: any} = {}

      if (i?.extension_attributes?.markdown_id) {
        extension_attributes.markdown_id = i?.extension_attributes?.markdown_id
      }

      return {
        price: sqpp.special_price || sqpp.price,
        originalPrice: sqpp.price || null,
        specialPrice: sqpp.special_price || null,
        discount: sqpp.special_price_discount || null,
        discountDate,
        qty: sqpp.qty,
        sku: +i.sku,
        extension_attributes
      }
    })

    commit(types.CART_SET_SQPP, result)
  },

  /**
   * * Get product stock and prices, checking conditions
   * @param dispatch
   * @param rootGetters
  **/
  async checkCartStateProductStockAndProductPrices ({ dispatch, rootGetters }) {
    try {
      const cartSQPP = rootGetters['cart/getCartSQPP']

      if (!cartSQPP || !Array.isArray(cartSQPP) || !cartSQPP.length) {
        return
      }

      await dispatch('checkProductsStock', cartSQPP)
      await dispatch('checkProductsPrices', cartSQPP)
    } catch (e) {
      Logger.debug(e, 'cart')()
    } finally {
      await dispatch('checkCartStateWeight')
    }
  },

  checkCartStateWeight ({ getters, rootGetters, commit }) {
    try {
      const totalWeightInGrams = getters.getCartItems.reduce((accumulatedWeight, product) => {
        return accumulatedWeight + (getProductWeight(product) * product.qty);
      }, 0);

      const totalWeightInKg = weightGToKg(totalWeightInGrams);
      const maxWeightInGrams = rootGetters['shipping/getShippingDetails'].deliveryMethod?.max_weight || 0;
      const maxWeightInKg = weightGToKg(maxWeightInGrams);

      const isWeightValid = !(maxWeightInKg && totalWeightInKg > maxWeightInKg);

      const cartState = {
        valid: isWeightValid,
        maxWeightToKG: maxWeightInKg
      };

      commit(types.CART_STATES, { maxWeight: cartState });
    } catch (error) {
      Logger.debug(error, 'cart')();
    }
  },

  async checkTimeSlots ({ rootGetters, commit, dispatch }, { currentTimeSlot }) {
    try {
      await dispatch('checkout/loadShippingTimeSlots', null, { root: true })
      const shippingTimeSlots = rootGetters['checkout/getShippingTimeSlots']

      const cartState = {
        valid: false
      };

      if (!shippingTimeSlots?.length) {
        sendToLogs(
          groups.Timeslots,
          'timeslot:check:array:empty',
          { length: shippingTimeSlots?.length }
        )

        commit(types.CART_STATES, { shippingTimeSlots: cartState });
        return false
      }

      cartState.valid = Boolean(shippingTimeSlots.find(item => item.id === currentTimeSlot?.id))
      commit(types.CART_STATES, { shippingTimeSlots: cartState });

      return cartState.valid
    } catch (error) {
      sendToLogs(
        groups.Timeslots,
        'timeslot:check:error',
        { message: error.message }
      )

      Logger.debug(error, 'cart')();
    }
  },

  async getCartItemReplacements ({ dispatch }) {
    const query = new SearchQuery()

    const { items } = await dispatch('product/findProducts', {
      query,
      size: 25,
      sort: 'created_at:desc',
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      skipLoadOptions: true
    }, { root: true })

    return items
  },

  setProductComment ({ commit }) {
    const comments = JSON.parse(localStorage.getItem(`cart/productComments`)) || []
    commit(types.CART_SET_PRODUCT_COMMENTS, comments)
  },
  addProductComment ({ commit, getters }, productComment) {
    const comments = getters.getProductComments.filter(comment => comment.sku !== productComment.sku) || []
    comments.push(productComment)
    commit(types.CART_SET_PRODUCT_COMMENTS, comments)
    localStorage.setItem(`cart/productComments`, JSON.stringify(comments))
  },
  deleteProductComment ({ commit, getters }, sku = '') {
    const comments = !sku ? [] : (getters.getProductComments.filter(comment => comment.sku !== sku) || [])
    commit(types.CART_SET_PRODUCT_COMMENTS, comments)
    localStorage.setItem(`cart/productComments`, JSON.stringify(comments))
  },
  revertRule ({ commit, dispatch }, id: number | null) {
    commit(types.CART_SET_REVERT_RULES, id)
    dispatch('sync', { forceSync: true, skipPull: true })
  },
  async pullMethods () {
    Logger.debug('Skipping payment & shipping methods synchronization', 'cart')()
  }
}
export default actions
