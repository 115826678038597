export const SN_CHECKOUT = 'checkout'
export const CHECKOUT_PLACE_ORDER = SN_CHECKOUT + '/PLACE_ORDER'
export const CHECKOUT_SAVE_PERSONAL_DETAILS = SN_CHECKOUT + '/SAVE_PERSONAL_DETAILS'
export const CHECKOUT_SAVE_SHIPPING_DETAILS = SN_CHECKOUT + '/SAVE_SHIPPING_DETAILS'
export const CHECKOUT_SAVE_PAYMENT_DETAILS = SN_CHECKOUT + '/SAVE_PAYMENT_DETAILS'
export const CHECKOUT_SAVE_PACKAGING_DETAILS = SN_CHECKOUT + '/SAVE_PACKAGING_DETAILS'
export const CHECKOUT_LOAD_PERSONAL_DETAILS = SN_CHECKOUT + '/LOAD_PERSONAL_DETAILS'
export const CHECKOUT_LOAD_PAYMENT_DETAILS = SN_CHECKOUT + '/LOAD_PAYMENT_DETAILS'
export const CHECKOUT_DROP_PASSWORD = SN_CHECKOUT + '/DROP_PASSWORD'
export const CHECKOUT_SET_THANKYOU = SN_CHECKOUT + '/SET_THANKYOU'
export const CHECKOUT_SET_MODIFIED_AT = SN_CHECKOUT + '/SET_MODIFIEDAT'
export const CHECKOUT_ADD_PAYMENT_METHOD = SN_CHECKOUT + '/ADD_PAYMENT_METHOD'
export const CHECKOUT_SET_PAYMENT_METHODS = SN_CHECKOUT + '/SET_PAYMENT_METHODS'
export const CHECKOUT_UPDATE_PAYMENT_DETAILS = SN_CHECKOUT + '/UPDATE_PAYMENT_DETAILS'
export const CHECKOUT_SET_ACTIVE_STEP = SN_CHECKOUT + '/SET_ACTIVE_STEP'
export const CHECKOUT_SET_ORDER_CONFIRMATION_INFO = SN_CHECKOUT + '/CHECKOUT_SET_ORDER_CONFIRMATION_INFO'
export const CHECKOUT_SET_SUMMARY = SN_CHECKOUT + '/CHECKOUT_SET_SUMMARY'
export const SHIPPING_TIME_SLOTS = SN_CHECKOUT + '/SHIPPING_TIME_SLOTS'
export const SET_CURRENT_TIME_SLOT = SN_CHECKOUT + '/SET_CURRENT_TIME_SLOT'
