<template>
  <div class="sf-collected-product">
    <div class="sf-collected-product__main">
      <div class="sf-collected-product__details">
        <slot name="title" v-bind="{ title }">
          <div class="sf-collected-product__title-wraper">
            <SfLink :link="link" class="sf-collected-product__title">
              {{ title }}
            </SfLink>
          </div>
        </slot>
        <slot name="price" v-bind="{ specialPrice, regularPrice }">
          <SfPrice
              v-if="regularPrice"
              :regular="regularPrice"
              :special="specialPrice"
          />
        </slot>
        <slot name="configuration" />
      </div>
      <div class="sf-collected-product__actions">
        <slot name="actions" />
      </div>
    </div>
    <div class="sf-collected-product__aside">
      <slot name="image" v-bind="{ image, title }">
        <SfImage
            :src="image"
            :alt="title"
            :width="imageWidth"
            :height="imageHeight"
            class="sf-collected-product__image"
        />
      </slot>
      <slot name="input">
        <div class="sf-collected-product__quantity-wrapper">
          <SfQuantitySelector
              :qty="qty"
              class="sf-collected-product__quantity-selector"
              @input="$emit('input', $event)"
          />
        </div>
      </slot>
    </div>
    <slot name="remove" v-bind="{ removeHandler }">
      <SfCircleIcon
          icon="cross"
          aria-label="Remove"
          class="sf-circle-icon--small sf-collected-product__remove sf-collected-product__remove--circle-icon"
      />
      <SfButton
          class="sf-button--text sf-collected-product__remove sf-collected-product__remove--text"
          @click="removeHandler"
      >Remove</SfButton
      >
    </slot>
    <slot name="more-actions">
      <SfButton
          aria-label="More actions"
          class="sf-button--pure sf-collected-product__more-actions mobile-only"
      >
        <SfIcon icon="more" />
      </SfButton>
    </slot>
  </div>
</template>
<script>
import SfPrice from '$ui/components/atoms/SfPrice/SfPrice.vue';
import SfIcon from '$ui/components/atoms/SfIcon/SfIcon.vue';
import SfImage from '$ui/components/atoms/SfImage/SfImage.vue';
import SfCircleIcon from '$ui/components/atoms/SfCircleIcon/SfCircleIcon.vue';
import SfButton from '$ui/components/atoms/SfButton/SfButton.vue';
import SfQuantitySelector from '$ui/components/atoms/SfQuantitySelector/SfQuantitySelector.vue';
import SfLink from '$ui/components/atoms/SfLink/SfLink.vue';
export default {
  name: 'SfCollectedProduct',
  components: {
    SfButton,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfPrice,
    SfQuantitySelector,
    SfLink
  },
  model: {
    prop: 'qty'
  },
  props: {
    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: [String, Object],
      default: ''
    },
    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 140
    },
    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 200
    },
    /**
     * Product title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Product regular price
     */
    regularPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Product special price
     */
    specialPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Selected quantity
     */
    qty: {
      type: [Number, String],
      default: 1
    },
    /**
     * Link to product
     */
    link: {
      type: [String, Object],
      default: ''
    }
  },
  methods: {
    removeHandler () {
      this.$emit('click:remove');
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/organisms/SfCollectedProduct.scss";
</style>
