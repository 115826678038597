import * as coreTypes from '@vue-storefront/core/modules/cart/store/mutation-types';
import {
  createOrderData,
  prepareShippingInfoForUpdateTotals
} from '@vue-storefront/core/modules/cart/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import * as types from 'theme/store/cart/store/mutation-types';
import { isServer } from '@vue-storefront/core/helpers';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const totalsActions = {
  cartResetTotals ({ commit }) {
    commit(coreTypes.CART_UPD_TOTALS, {
      itemsAfterPlatformTotals: {},
      platformTotals: null,
      platformTotalSegments: null
    })
  },
  async syncTotals ({ dispatch, getters, rootGetters, commit }, payload: {
    forceServerSync: boolean,
    methodsData?: any
  } = { forceServerSync: false, methodsData: null }) {
    try {
      if (isServer) return

      commit(types.CART_SET_IS_SYNCING_TOTAL, true)

      const methodsData = payload ? payload.methodsData : null
      await dispatch('pullMethods', { forceServerSync: payload.forceServerSync })

      if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
        const shippingMethodsData = methodsData || createOrderData({
          shippingDetails: rootGetters['shipping/getShippingDetails'],
          shippingMethods: rootGetters['shipping/getShippingMethods'],
          paymentMethods: rootGetters['checkout/getPaymentMethods'],
          paymentDetails: rootGetters['checkout/getPaymentDetails']
        })

        if (shippingMethodsData.country) {
          await dispatch('overrideServerTotals')
          return
        }

        Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')()
      }
    } catch (e) {
      Logger.error(e, 'cart')()
    } finally {
      commit(types.CART_SET_IS_SYNCING_TOTAL, false)
    }
  },
  async getTotals ({ dispatch }) {
    return dispatch('overrideServerTotals')
  },
  async overrideServerTotals ({ dispatch }) {
    const { totals, result } = await dispatch('cartPull', {})

    await dispatch('cart/processCartSQPP', result, { root: true })

    if (totals) {
      await dispatch('overrideTotalsFromPayload', { totals, result })
    }
  },
  async overrideTotalsFromPayload ({ dispatch, commit, getters }, { totals, result }) {
    Logger.info('Overriding server totals. ', 'cart', totals)()
    const itemsAfterTotal = prepareShippingInfoForUpdateTotals(totals.items)
    const storedItems = getters['getCartItems'] || []
    const items = Array.isArray(result) ? result : []

    for (const key of Object.keys(itemsAfterTotal)) {
      const item = itemsAfterTotal[key]
      const itemTotal = items?.find((i) => i?.item_id === item?.item_id)
      const itemCart = storedItems?.find((i) => isSkuEqual(i, itemTotal))

      const payload = itemCart?.id && itemTotal?.sku ? {
        id: itemCart?.id,
        sku: itemTotal?.sku
      } : {}

      const product: { [key: string]: any } = {
        ...payload,
        server_item_id: item.item_id,
        totals: item,
        qty: item.qty,
        gift: {
          limit: item?.extension_attributes?.promotion?.limit || null,
          isGift: !!item?.extension_attributes?.promotion?.is_gift,
          isPromotion: !!item?.extension_attributes?.promotion?.has_promotion,
          label: item?.extension_attributes?.promotion?.label || null,
          description: item?.extension_attributes?.promotion?.description || null,
          appended: null
        },
        extension_attributes: itemTotal?.extension_attributes || {}
      }

      await dispatch('updateItem', { product })
    }

    const extendedSegments = (totals?.extension_attributes?.discount_breakdown || [])
      .map(i => ({
        code: `discount_${i.code}`,
        title: i.title,
        value: i.amount || i.value || 0
      }))

    const segments = [
      ...totals.total_segments,
      ...extendedSegments
    ]

    commit(coreTypes.CART_UPD_TOTALS, { itemsAfterTotal, totals, platformTotalSegments: segments })
    commit(coreTypes.CART_SET_TOTALS_SYNC)
  }
}

export default totalsActions
