var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({staticClass:"sf-bottom-navigation-item",class:{
    'sf-bottom-navigation-item--active': _vm.isActive,
    'sf-bottom-navigation-item--floating': _vm.isFloating,
    'sf-bottom-navigation-item--center': !_vm.icon || !_vm.label,
  }},_vm.$listeners),[_vm._t("icon",function(){return [(_vm.isFloating)?_c('SfCircleIcon',{attrs:{"icon":_vm.icon,"icon-color":"white","icon-size":"28px"}}):(_vm.icon)?_c('SfButton',{staticClass:"sf-button--pure sf-bottom-navigation-item__icon"},[_c('SfIcon',{attrs:{"icon":_vm.currentIcon,"size":_vm.iconSize}})],1):_vm._e()]},null,{ icon: _vm.icon, iconActive: _vm.iconActive, iconSize: _vm.iconSize, isFloating: _vm.isFloating }),_vm._v(" "),_vm._t("label",function(){return [(_vm.label)?_c('div',{staticClass:"sf-bottom-navigation-item__label",class:{
        'sf-bottom-navigation-item--has-margin': _vm.icon,
      }},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]):_vm._e()]},null,{ label: _vm.label })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }