import { GetterTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import ProductExtensionState from '../types/ProductExtensionState';

const getters: GetterTree<ProductExtensionState, RootState> = {
  getCharacteristicAttributes: (state) => state.characteristicAttributes,
  getAllCharacteristicAttributeCodes: (state) => state.characteristicAttributeCodes,
  getTopCharacteristicAttributeCodes: (state) => state.topCharacteristicAttributeCodes,
  getProductAttributeLabel: (state) => state.productAttributeLabelMap,
  getInStockProductVariantsIds: (state) => state.inStockProductVariants.map(e => e.id) || [],
  getVarusPerfectProducts: (state) => state.varusPerfectProducts
};

export default getters;
