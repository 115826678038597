import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import * as coreTypes from '@vue-storefront/core/modules/wishlist/store/mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import WishlistState from '../types/WishlistState'
import { WishlistService } from 'theme/store/wishlist/data-resolver/WishlistService';
import { Logger } from '@vue-storefront/core/lib/logger'
import { getUniqueItemsIDs, sortProductsByWishlistPosition } from '../helpers'

const actions: ActionTree<WishlistState, RootState> = {
  async addGroup ({ commit }, { name, items = [] }) {
    commit(types.WISHLIST_ADD_GROUP, {
      id: Date.now(),
      name,
      items
    })
  },

  async deleteGroup ({ commit }, { id }) {
    commit(types.WISHLIST_DELETE_GROUP, id)
  },

  async saveGroup ({ commit }, group) {
    commit(types.WISHLIST_SAVE_GROUP, group)
  },

  async addItemsToGroup ({ commit }, { id, items = [] }) {
    commit(types.WISHLIST_GROUP_ADD_ITEMS, {
      id,
      items
    })
  },

  async removeItemFromGroup ({ commit }, { product, groupId }) {
    commit(coreTypes.WISH_DEL_ITEM, { product, groupId })
  },

  async setModeCreateNewGroup ({ commit }, value) {
    commit(types.SET_MODE_CREATE_NEW_GROUP, value)
  },
  async loadWishlists ({ commit, dispatch }) {
    try {
      const response = await WishlistService.loadWishlists()
      let wishlists = []

      if (response.code === 200) {
        wishlists = response.result
        commit(types.SET_WISHLISTS, wishlists)
        dispatch('collectItemsFromWishlists')
      }
    } catch (e) {
      Logger.error('Can not load wishlist ' + e, 'wishlist')()
    }
  },
  clear ({ commit }) {
    commit(types.CLEAR_WISHLIST)
    commit(types.CLEAR_WISHLIST_ITEMS)
  },
  async createWishlist ({ commit }, wishlistName) {
    if (!wishlistName) {
      Logger.error('wishlist Name is empty', 'wishlist')()
      return
    }

    try {
      const response = await WishlistService.createWishlist(wishlistName)

      if (response.code === 200) {
        const wishlist = response.result
        commit(types.ADD_WISHLIST, wishlist)
      }
    } catch (e) {
      Logger.error('Can not create wishlist ' + e, 'wishlist')()
    }
  },
  async updateWishlistName ({ commit }, { wishlistId, name }) {
    if (!name || !wishlistId) {
      Logger.error('wishlist Id or Name is empty', 'wishlist')()
      return
    }

    try {
      const response = await WishlistService.updateWishlistName(wishlistId, name)

      if (response.code === 200) {
        const wishlist = response.result
        commit(types.UPDATE_WISHLIST, wishlist)
      }
    } catch (e) {
      Logger.error('Can not rename wishlist ' + e, 'wishlist')()
    }
  },
  async deleteWishlist ({ dispatch }, wishlistId) {
    if (!wishlistId) {
      Logger.error('wishlist Id is empty', 'wishlist')()
      return
    }

    try {
      const response = await WishlistService.deleteWishlist(wishlistId)
      if (response.code === 200) {
        dispatch('loadWishlists')
      }
    } catch (e) {
      Logger.error('Can not delete wishlist ' + e, 'wishlist')()
    }
  },
  async addItemsToWishlist ({ commit }, { wishlistId, items = [] }) {
    if (!items.length || !wishlistId) return

    try {
      const response = await WishlistService.addItemsToWishlist(wishlistId, items)

      if (response.code === 200) {
        const wishlist = response.result
        commit(types.UPDATE_WISHLIST, wishlist)
      }
    } catch (e) {
      Logger.error('Can not add items to wishlist ' + e, 'wishlist')()
    }
  },
  async addItemsToMultipleWishlists ({ commit }, { wishlistsIDs = [], items = [] }) {
    if (!items.length || !wishlistsIDs.length) {
      return;
    }

    try {
      const { result, code } = await WishlistService.addItemsToMultipleWishlists(wishlistsIDs, items);

      if (code !== 200) return;

      result.forEach(wishlist => commit(types.UPDATE_WISHLIST, wishlist));
    } catch (error) {
      Logger.error('Can not add items to wishlists', 'wishlist', error)();
    }
  },
  async deleteItemsFromWishlist ({ commit }, { wishlistId, items = [] }) {
    if (!items.length || !wishlistId) return

    try {
      const response = await WishlistService.deleteItemsFromWishlist(wishlistId, items)

      if (response.code === 200) {
        const wishlist = response.result
        commit(types.UPDATE_WISHLIST, wishlist)
      }
    } catch (e) {
      Logger.error('Can not delete items from wishlist ' + e, 'wishlist')()
    }
  },
  async collectItemsFromWishlists ({ dispatch, getters }) {
    const wishlists = getters.getWishlists;
    const items = wishlists.flatMap(wishlist => wishlist.items);
    const uniqueItemsIDs = getUniqueItemsIDs(items);
    const products = await dispatch('product/getProductsByID', { ids: uniqueItemsIDs }, { root: true });
    const sortedProducts = sortProductsByWishlistPosition(products, items);

    dispatch('setWishlistItems', sortedProducts);
  },
  setWishlistItems ({ commit }, items) {
    commit(types.SET_WISHLISTS_ITEMS, items);
  }
}

export default actions
