<template>
  <ul class="sf-list">
    <!-- @slot -->
    <slot />
  </ul>
</template>
<script>
import SfListItem from '$ui/components/organisms/SfList/_internal/SfListItem.vue';
import Vue from 'vue';
Vue.component('SfListItem', SfListItem);
export default {
  name: 'SfList'
};
</script>
<style lang="scss">
@import "$ui/styles/components/organisms/SfList.scss";
</style>
