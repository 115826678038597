<template>
  <SfButton class="sf-arrow" type="button" v-on="$listeners">
    <!--@slot Use this slot to replace arrow icon-->
    <slot>
      <SfIcon
          size="1.5rem"
          icon="arrow_left"
          aria-hidden="true"
          class="sf-arrow__icon"
      />
    </slot>
  </SfButton>
</template>
<script>
import SfButton from '$ui/components/atoms/SfButton/SfButton.vue';
import SfIcon from '$ui/components/atoms/SfIcon/SfIcon';
export default {
  name: 'SfArrow',
  components: { SfButton, SfIcon }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfArrow.scss";
</style>
