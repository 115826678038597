<template>
  <div class="m-footer-subscribe">
    <p class="m-footer-subscribe__title">
      {{ titleMessage }}
    </p>
    <form
      v-if="!isSubscribingSuccess"
      @submit.prevent="subscribeExtend"
      class="m-footer-subscribe__form"
    >
      <SfInput
        v-model="email"
        name="email"
        label="Ваш e-mail"
        :valid="isEmailInputValid"
        :error-message="errorMessage"
        data-transaction-name="Footer - Subscribe - Email"
        class="sf-input--filled"
        :disabled="inProcess"
        @input="resetStatuses"
      />
      <span
        data-transaction-name="Footer - Subscribe"
        @click="subscribeExtend"
        class="m-footer-subscribe__icon"
        :class="{ 'm-footer-subscribe__icon--disabled': inProcess }"
      >
        {{ $t('Subscribe') }}
      </span>
    </form>
    <div v-else class="m-footer-subscribe__subscribed">
      {{ $t('E-mail with a confirmation link was sent on provided e-mail') }}
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import SfInput from 'theme/components/storefront-override/SfInput'
import Subscribe from 'theme/store/newsletter/mixins/Subscribe';

export default {
  name: 'MFooterSubscribe',
  components: { SfInput },
  mixins: [ Subscribe ],
  data () {
    return {
      email: '',
      inProcess: false,
      isSubscribingSuccess: false,
      isAlreadySubscribed: false,
      responseErrorMessage: ''
    };
  },
  methods: {
    async subscribeExtend () {
      if (!this.canSubscribe()) {
        return;
      }

      this.beforeSubscribeRequest();

      try {
        const resp = await this.subscribe();

        this.handleSubscriptionResponse(resp);
      } finally {
        this.afterSubscribeRequest();
      }
    },
    beforeSubscribeRequest () {
      this.setInProcess(true)
      this.resetStatuses();
    },
    afterSubscribeRequest () {
      this.setInProcess(false)
    },
    resetStatuses () {
      this.isSubscribingSuccess = false;
      this.isAlreadySubscribed = false;
    },
    setInProcess (value) {
      this.inProcess = value;
    },
    handleSubscriptionResponse (resp) {
      const { subscriber_status, code, errorMessage } = resp

      if (subscriber_status === 1) {
        this.isSubscribingSuccess = true;
      } else if (code === 400) {
        this.isAlreadySubscribed = true;
        this.responseErrorMessage = errorMessage;
      }
    },
    canSubscribe () {
      if (this.inProcess || this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      return true;
    }
  },
  computed: {
    errorMessage () {
      if (!this.$v.email.required) {
        return this.$t('Enter your e-mail')
      } else if (!this.$v.email.email) {
        return this.$t('Enter a valid e-mail')
      } else if (this.isAlreadySubscribed) {
        return this.$t(this.responseErrorMessage);
      } else {
        return '';
      }
    },
    titleMessage () {
      if (this.isSubscribingSuccess) {
        return this.$t('Thank you!');
      } else {
        return this.$t('Best deals');
      }
    },
    isEmailInputValid () {
      return !(this.$v.email.$error || this.isAlreadySubscribed);
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-footer-subscribe {
  display: block;

  &__title {
    font-size: var(--font-base);
    font-weight: var(--font-medium);
    color: var(--black);
    margin: 0 0 var(--spacer-10) 0;

    @media (min-width: $tablet-min) {
      margin: 0 0 var(--spacer-20) 0;
    }

    @media (min-width: $desktop-min) {
      font-size: var(--font-size-22);
    }
  }

  &__subscribed {
    font-size: var(--font-sm);
    color: var(--dark-gray);
    margin-bottom: var(--spacer-44);
  }

  &__form {
    position: relative;
  }

  ::v-deep input {
    padding-right: 130px;

    &[disabled] {
      color: var(--dark-gray);
    }
  }

  &__icon {
    position: absolute;
    color: var(--c-primary);
    cursor: pointer;
    top: var(--spacer-20);
    bottom: auto;
    right: 15px;

    &--disabled {
      color: var(--orange-disabled);
      cursor: not-allowed;
    }

    @include for-desktop{
      top: var(--spacer-18);

      &:not(&--disabled):hover {
        color: var(--orange-hover);
      }
    }

    @media (max-width: $mobile-max) {
      font-size: var(--font-sm);
    }
  }

  @supports (-webkit-overflow-scrolling: touch) {
    input:focus {
      font-size: var(--font-base);
    }
  }
}
</style>
