export const SN_SHIPPING = 'shipping'
export const SHIPPING_SAVE_DETAILS = SN_SHIPPING + '/SAVE_SHIPPING_DETAILS'
export const SHIPPING_LOAD_DETAILS = SN_SHIPPING + '/LOAD_SHIPPING_DETAILS'
export const SHIPPING_LOAD_DETAILS_LOADED = SN_SHIPPING + '/LOAD_SHIPPING_DETAILS_LOADED'
export const SHIPPING_UPDATE_PROP_VALUE = SN_SHIPPING + '/UPDATE_PROP_VALUE'
export const SHIPPING_ADD_METHOD = SN_SHIPPING + '/ADD_SHIPPING_METHOD'
export const SHIPPING_SET_METHODS = SN_SHIPPING + '/SET_SHIPPING_METHOD'
export const SHIPPING_GEO_LOCATE_SHOP = SN_SHIPPING + '/GEO_LOCATE_SHOP'
export const SHIPPING_GEO_LOCATE_SHOP_RESTORE = SN_SHIPPING + '/GEO_LOCATE_SHOP_RESTORE'
export const SHIPPING_FILLED_USER_DETAILS = SN_SHIPPING + '/SHIPPING_FILLED_USER_DETAILS'
export const SHIPPING_LOADED_USER_DETAILS = SN_SHIPPING + '/SHIPPING_LOADED_USER_DETAILS'
export const SHIPPING_CITIES_LIST = SN_SHIPPING + '/SHIPPING_CITIES_LIST'
export const SHIPPING_SHOPS_LIST = SN_SHIPPING + '/SHIPPING_SHOPS_LIST'
export const SHIPPING_ALL_SHOPS_LIST = SN_SHIPPING + '/SHIPPING_ALL_SHOPS_LIST'
export const SHIPPING_USER_GEO_POSITION = SN_SHIPPING + '/SHIPPING_USER_GEO_POSITION'
export const SHIPPING_LOADING_DETAIL_SAVE = SN_SHIPPING + '/SHIPPING_LOADING_DETAIL_SAVE'
export const SHIPPING_LOADING_SHOPS = SN_SHIPPING + '/SHIPPING_LOADING_DETAIL_SAVE'
export const SHIPPING_LOADED_DETAILS = SN_SHIPPING + '/SHIPPING_LOADED_DETAILS'
export const SHIPPING_LOADED_SHOPS = SN_SHIPPING + '/SHIPPING_LOADED_SHOPS'
export const SHIPPING_DELIVERY_POLYGON = SN_SHIPPING + '/SHIPPING_DELIVERY_POLYGON'
export const SHIPPING_SET_DEFAULT_CITY = SN_SHIPPING + '/SHIPPING_SET_DEFAULT_CITY'
export const SHIPPING_SET_DEFAULT_SHOP = SN_SHIPPING + '/SHIPPING_SET_DEFAULT_SHOP'
