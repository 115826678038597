<template>
  <td class="sf-table__data">
    <slot />
  </td>
</template>
<script>
export default {
  name: 'SfTableData'
};
</script>
