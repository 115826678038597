import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from './types/UserState';
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const VarusUserStore: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    currentUser: {
      addresses: []
    },
    isNewUser: false,
    searchHistory: [],
    avatar: '',
    validationStatus: ''
  },
  actions,
  getters,
  mutations
}
