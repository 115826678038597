export const SN_SPECIAL_OFFERS = 'special-offers'

export const SPECIAL_OFFERS_SET_CATEGORIES = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_CATEGORIES`
export const SPECIAL_OFFERS_SET_SPECIAL_OFFERS_CATEGORY = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_SPECIAL_OFFERS_CATEGORY`
export const SPECIAL_OFFERS_ADD_PRODUCTS = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_ADD_PRODUCTS`
export const SPECIAL_OFFERS_UPDATE_PRODUCTS = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_UPDATE_PRODUCTS`

export const SPECIAL_OFFERS_SET_CATEGORY_FILTERS = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_CATEGORY_FILTERS`
export const SPECIAL_OFFERS_SET_CURRENT_CATEGORY = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_CURRENT_CATEGORY`
export const SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS`
export const SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING`
export const SPECIAL_OFFERS_SET_PRODUCTS_IS_LOADING = `${SN_SPECIAL_OFFERS}/SPECIAL_OFFERS_SET_PRODUCTS_IS_LOADING`
export const SET_IS_ADDITIONAL_LOADING = `${SN_SPECIAL_OFFERS}/SET_IS_ADDITIONAL_LOADING`;
export const SET_BANNERS = `${SN_SPECIAL_OFFERS}/SET_BANNERS`;
