import { render, staticRenderFns } from "./a-wishlist-icon.vue?vue&type=template&id=45843752&scoped=true"
import script from "./a-wishlist-icon.vue?vue&type=script&lang=js"
export * from "./a-wishlist-icon.vue?vue&type=script&lang=js"
import style0 from "./a-wishlist-icon.vue?vue&type=style&index=0&id=45843752&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45843752",
  null
  
)

export default component.exports