<template>
  <div class="o-header-minimal">
    <ALogo :is-pure="isError" />
  </div>
</template>

<script>
import ALogo from 'theme/components/atoms/a-logo';

export default {
  name: 'OHeaderMinimal',
  components: {
    ALogo
  },
  props: {
    isError: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.o-header-minimal {
  display: flex;

  .a-logo {
    margin: var(--spacer-10) auto;
  }
}
</style>
