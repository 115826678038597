import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'
import { NewPostCity, NewPostSingle, NewPostWarehouse } from '$modules/shipping/types/shipping-service-types';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const ShippingModuleService = {
  getNewPostCity: async (payload: NewPostCity): Promise<Task> => {
    const query = []

    if (payload.q) query.push(`q=${payload.q}`)
    if (payload.storeCode) query.push(`storeCode=${payload.storeCode}`)

    const url = [
      getApiEndpointUrl(config.newPost, 'np_city_endpoint')
    ]

    if (query.length) url.push(query.join('&'))

    return TaskQueue.execute({
      url: url.join('?'),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  getNewPostWarehouse: async (payload: NewPostWarehouse): Promise<Task> => {
    const query = []

    if (payload.q) query.push(`q=${payload.q}`)
    if (payload.cityRef) query.push(`cityRef=${payload.cityRef}`)
    if (payload.isPostMachine) query.push(`isPostMachine=${+payload.isPostMachine}`)
    if (payload.storeCode) query.push(`storeCode=${payload.storeCode}`)

    const url = [
      getApiEndpointUrl(config.newPost, 'np_warehouse_endpoint')
    ]

    if (query.length) url.push(query.join('&'))

    return TaskQueue.execute({
      url: url.join('?'),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  getNewPostSingle: async (payload: NewPostSingle): Promise<Task> => {
    const url = [
      getApiEndpointUrl(config.newPost, 'np_single_endpoint'),
      payload.type,
      payload.ref
    ].join('/')

    return TaskQueue.execute({
      url: `${url}?storeCode=${payload.storeCode}`,
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  }
}
