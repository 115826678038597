import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import TendersState from '../types/TendersState';
import { SearchQuery } from 'storefront-query-builder';
import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'

export const TendersStore: Module<TendersState, RootState> = {
  namespaced: true,
  state: {
    tendersList: {},
    tendersActiveList: {},
    tendersDisActiveList: {},
    tendersCurrent: []
  },
  getters: {
    getTendersList: state => {
      return state.tendersList
    },
    getTendersActiveList: state => {
      return state.tendersActiveList
    },
    getTendersDisActiveList: state => {
      return state.tendersDisActiveList
    },
    getTendersCurrent: state => {
      return state.tendersCurrent
    }
  },
  actions: {
    async fetchTendersCollection ({ commit }, { route } = {}) {
      const nowDate = new Date().toISOString().split('T')[0];
      let queryActive = new SearchQuery()
      let queryDisActive = new SearchQuery()
      let start = 0
      let pageSize = 10

      if (route.query && route.query.page) {
        start = (route.query.page - 1) * pageSize;
      }

      if (route.params && route.params.loadMore && route.params.loadMore === true) {
        pageSize = (route.query.page) * pageSize;
        start = 0
      }

      queryActive = queryActive.applyFilter({ key: 'tender_deadline', value: { 'gte': nowDate } })

      const tenderActive = await quickSearchByQuery({
        query: queryActive,
        start,
        size: pageSize,
        entityType: 'tender'
      });

      queryDisActive = queryDisActive.applyFilter({ key: 'tender_deadline', value: { 'lt': nowDate } })

      const tenderDisActive = await quickSearchByQuery({
        query: queryDisActive,
        start,
        size: pageSize,
        entityType: 'tender'
      });

      commit('SET_TENDER_ACTIVE_LIST_COLLECTION', tenderActive)
      commit('SET_TENDER_DIS_ACTIVE_LIST_COLLECTION', tenderDisActive)
    },
    async fetchTendersCurrent ({ commit }, { route } = {}) {
      let query = new SearchQuery()

      query = query.applyFilter({ key: 'id', value: { 'in': route.params.slug } })

      const tender = await quickSearchByQuery({
        query: query,
        entityType: 'tender'
      });

      commit('SET_TENDER_CURRENT_COLLECTION', tender.items)
    }

  },
  mutations: {
    SET_TENDER_ACTIVE_LIST_COLLECTION (state, tender) {
      state.tendersActiveList = tender || []
    },
    SET_TENDER_DIS_ACTIVE_LIST_COLLECTION (state, tender) {
      state.tendersDisActiveList = tender || []
    },
    SET_TENDER_CURRENT_COLLECTION (state, tender) {
      state.tendersCurrent = tender || []
    }
  }
}
