import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import { UserProfile } from 'core/modules/user/types/UserProfile';

const headers = {
  'Accept': '*/*',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json'
};

export const UserInfoService = {
  getValidation: async (phone): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'validate_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ phone: phone })
      }
    });
  },
  sendOtp: async (phone): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'sendOtp_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ phone: phone })
      }
    });
  },
  resetPinCode: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'resetPin_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  createPinCode: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'createPin_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  changePinCode: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'changePin_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  updatePhone: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'updatePhone_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  updatePhoneConfirm: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'updatePhoneConfirm_endpoint')),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  addAdditionalPhone: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'addAdditionalPhone_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  deleteAdditionalPhone: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'deleteAdditionalPhone_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  uploadAvatar: async (params): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'avatar_endpoint')),
      payload: {
        method: 'PUT',
        mode: 'cors',
        headers,
        body: JSON.stringify(params)
      }
    });
  },
  getAvatar: async (): Promise<any> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'avatar_endpoint')),
      payload: {
        method: 'GET',
        mode: 'cors',
        headers
      }
    });
  },
  updateProfile: async (userProfile: UserProfile, actionName: string): Promise<any> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(getApiEndpointUrl(config.users, 'me_endpoint')),
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(userProfile)
      },
      callback_event: `store:${actionName}`
    })
};
