import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ShippingState from 'theme/store/checkout/types/ShippingState';

const getters: GetterTree<ShippingState, RootState> = {
  getShippingDetails: (state, getters, rootState, rootGetters) => (
    rootGetters['shipping-module/fallbackShippingDetails']
  ),
  isLoadedShippingDetails: (state, getters, rootState, rootGetters) => (
    !!rootGetters['shipping-module/getShippingLoaded']
  ),
  getShippingMethods: state => state.shippingMethods,
  getDefaultShippingMethod: state => state.shippingMethods.find(item => item.default),
  getCities: (state, getters, rootState, rootGetters) => (
    (rootGetters['shipping-module/getCityList'] || [])
      .filter(city => !!city.active_shops)
      .sort((a, b) => (a.name > b.name) ? 1 : -1)
  ),
  getDefaultCity: (state, getters, rootState, rootGetters) => (
    rootGetters['shipping-module/getDefaultCity']
  ),
  getDefaultShop: (state, getters, rootState, rootGetters) => (
    rootGetters['shipping-module/getDefaultShop']
  ),
  getTemporaryDefaultShop: (state) => [].concat(state.shops.filter(shop => shop.is_default))[0] || {},
  getCurrentShop: (state) => {
    if (!state.shippingDetails?.shop?.id) {
      return null
    }

    const id = state.shippingDetails?.shop?.id
    const shop = state.shops.find(s => +s.id === id)

    return shop || state.shippingDetails?.shop
  },
  getShops: (state, getters, rootState, rootGetters) => (
    rootGetters['shipping-module/getShopList']
  ),
  getAllShops: (state) => state.shopsAll,
  getGeoLocateShop: (state) => state.geoLocateShop.shops,
  isFilledShippingInfo: (state, getters, rootState, rootGetters) => (
    !!rootGetters['shipping-module/getCurrent']?.shop
  ),
  getUserCoordinates: state => state.userCoordinates,
  // @deprecated
  shippingMethods: state => state.shippingMethods,
  getCoordinateList: state => state.deliveryPolygon.list
}

export default getters
