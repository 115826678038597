// Icon sizes
export const sizes = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  xl3: 'xl3',
  xl4: 'xl4'
};

export const sizesValues = Object.values(sizes);

export default sizesValues
