export const iconColors = {
  greenPrimary: 'green-primary',
  greenSecondary: 'green-secondary',
  black: 'black',
  darkSecondary: 'dark-secondary',
  grayPrimary: 'gray-primary',
  graySecondary: 'gray-secondary',
  lightPrimary: 'light-primary',
  white: 'white',
  redPrimary: 'red-primary',
  redSecondary: 'red-secondary',
  yellowPrimary: 'yellow-primary',
  yellowSecondary: 'yellow-secondary',
  bluePrimary: 'blue-primary',
  blueSecondary: 'blue-secondary'
};

export const iconColorsValues = Object.values(iconColors);

export default iconColorsValues
