import { Module } from 'vuex'
import actions from './actions'
import merge from 'lodash/merge';
import { productModule as coreProductModule } from '@vue-storefront/core/modules/catalog/store/product'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '@vue-storefront/core/modules/catalog/types/ProductState'

export const productModule: Module<ProductState, RootState> = {
  namespaced: true,
  state: coreProductModule.state,
  getters: coreProductModule.getters,
  actions: merge(coreProductModule.actions, actions),
  mutations: coreProductModule.mutations
}
