import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import * as coreTypes from '@vue-storefront/core/modules/wishlist/store/mutation-types'
import WishlistState from '../types/WishlistState'

const mutations: MutationTree<WishlistState> = {
  [types.WISHLIST_ADD_GROUP] (state, group) {
    state.groups.push(group)
  },
  [types.WISHLIST_DELETE_GROUP] (state, id) {
    state.groups = state.groups.filter(group => group.id !== id);
  },
  [types.WISHLIST_SAVE_GROUP] (state, group) {
    const stateGroup = state.groups.find(item => item.id === group.id)

    if (stateGroup) {
      stateGroup.name = group.name
    }
  },
  [types.WISHLIST_GROUP_ADD_ITEMS] (state, { id, items }) {
    const group = state.groups.find(stateGroup => String(stateGroup.id) === String(id))

    if (group) {
      items.forEach(item => {
        const itemIndex = group.items.findIndex(groupItem => groupItem.sku === item.sku)

        if (itemIndex === -1) {
          group.items.push(item)
        }
      })
    }
  },
  [coreTypes.WISH_DEL_ITEM] (state, { product, groupId = 0 }) {
    state.items = state.items.filter(item => item.sku !== product.sku)

    state.groups.forEach(group => {
      if (!groupId || groupId === group.id) {
        group.items = group.items.filter(item => item.sku !== product.sku)
      }
    })
  },
  [types.SET_MODE_CREATE_NEW_GROUP] (state, value = false) {
    state.isModeCreateNewGroup = value
  },
  [types.SET_WISHLISTS] (state, wishlists) {
    state.wishlists = wishlists
  },
  [types.CLEAR_WISHLIST] (state) {
    state.wishlists = []
  },
  [types.CLEAR_WISHLIST_ITEMS] (state) {
    state.items = []
  },
  [types.ADD_WISHLIST] (state, wishlist) {
    wishlist.items = []
    state.wishlists.push(wishlist)
  },
  [types.UPDATE_WISHLIST] (state, wishlist) {
    state.wishlists = state.wishlists.map(item =>
      item.wishlist_id === wishlist.wishlist_id ? { ...item, ...wishlist } : item
    );
  },
  [types.SET_WISHLISTS_ITEMS] (state, items) {
    state.items = items
  }
}

export default mutations
