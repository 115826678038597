<template>
  <div class="a-language-switcher">
    <div v-if="type === 'panel'" class="a-language-switcher__panel">
      <span class="a-language-switcher__label">{{ $t('Language') }}</span>
      <div class="a-language-switcher__select">
        <div
          v-for="(storeView, storeCode) in storeViews"
          :key="storeCode"
          :class="{ 'a-language-switcher--selected': selectedLanguage === storeCode }"
          class="a-language-switcher__option"
          @click="selectLanguage(storeCode)"
        >
          <span>{{ storeView.name }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="a-language-switcher__selector"
    >
      <span class="a-language-switcher__label">
        {{ currentLabel }}
        <span class="dropdown-chevron"></span>
      </span>
      <div class="a-language-switcher__select">
        <div
          v-for="(storeView, storeCode) in storeViews"
          :key="storeCode"
          :class="{ 'a-language-switcher--selected': selectedLanguage === storeCode }"
          class="a-language-switcher__option"
        >
          <span @click="selectLanguage(storeCode)">{{ storeView.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getLocalStorageLanguage, getStoreCodeLink, setLocalStorageLanguage } from 'theme/helpers/language';
import config from 'config';

export default {
  name: 'ALanguageSwitcher',
  props: {
    type: {
      type: String,
      default: () => 'select'
    }
  },
  data () {
    return {
      selectedLanguage: currentStoreView().storeCode
    }
  },
  computed: {
    currentLabel () {
      return config.storeViews?.[this.selectedLanguage]?.name
    },
    storeViews () {
      return Object.keys(config.storeViews).reduce((storeViews, storeCode) => {
        if (this.isValidStoreCode(storeCode)) {
          storeViews[storeCode] = config.storeViews[storeCode]
        }
        return storeViews
      }, {})
    }
  },
  watch: {
    selectedLanguage (storeCode) {
      this.setLocalStorageLanguage(storeCode);
      window.location.assign(getStoreCodeLink(storeCode));
    }
  },
  methods: {
    isValidStoreCode (storeCode) {
      const storeView = config.storeViews[storeCode]
      return !!(storeView && typeof storeView === 'object' && storeView.i18n)
    },
    setLocalStorageLanguage (storeCode) {
      setLocalStorageLanguage(storeCode);
    },
    selectLanguage (storeCode) {
      this.selectedLanguage = storeCode;
    }
  },
  mounted () {
    if (!getLocalStorageLanguage()) {
      setLocalStorageLanguage(currentStoreView().storeCode);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-language-switcher {
  justify-content: flex-end;

  &__selector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--font-size-15);
    position: relative;

    &:hover {
      .a-language-switcher__label {
        color: var(--orange);

        .dropdown-chevron:after {
          transform: rotate(225deg);
          top: 2px;
          border-color: var(--orange);
        }
      }

      .a-language-switcher__select {
        pointer-events: all;
        top: 100%;
        opacity: 1;
      }
    }

    .a-language-switcher__option {
      cursor: pointer;
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      text-align: center;
      width: 100%;
      justify-content: center;

      &:hover {
        color: var(--orange);
      }
    }

    .a-language-switcher__label {
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: var(--dark-gray);
      z-index: 1;

      .dropdown-chevron {
        position: absolute;
        right: var(--spacer-10);
      }
    }

    .a-language-switcher__select {
      position: absolute;
      background: var(--white);
      box-shadow: var(--select-dropdown-box-shadow, 0 4px 11px rgba(var(--c-dark-base), 0.1));
      padding-top: var(--spacer-16);
      padding-bottom: var(--spacer-16);
      text-align: center;
      transition: 200ms all;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
    }

    .a-language-switcher--selected {
      display: none;
    }
  }

  &__select {
    ::v-deep &.sf-select--is-active {
      .sf-select__selected {
        color: var(--orange);
      }
    }

    ::v-deep & > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 4.5rem;
      padding: var(--spacer-2xs) 0 var(--spacer-2xs) var(--spacer-20);
      box-sizing: border-box;
    }

    @include for-desktop {
      display: block;
      --select-option-border: none;
      --select-dropdown-box-shadow: 0 11px 24px rgba(0, 0, 0, 0.06);
      --select-animation-enter: ssdesktop 0;
      --select-animation-leave: ssdesktop 0 reverse;
    }

    ::v-deep {
      .sf-select__options {
        max-height: 100%!important;
      }
    }
  }

  &--selected {
    display: none;
  }

  &__option {
    font-size: var(--font-sm);
    color: var(--black);
    padding: var(--spacer-5) var(--spacer-5) var(--spacer-5) var(--spacer-20);
    text-align: left;

    @include for-mobile {
      padding: 0;
      border: none;
      margin-left: var(--spacer-15);
    }
  }
}

.a-language-switcher__panel {
  display: flex;
  justify-content: space-between;

  .a-language-switcher__label {
    display: inline-block;
    color: var(--dark-gray);
  }
  .a-language-switcher__select {
    display: flex;
    justify-content: flex-end;
  }
  .a-language-switcher--selected {
    color: var(--orange);
  }
  .a-language-switcher__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 4.5rem;
    padding: var(--spacer-2xs) 0 var(--spacer-2xs) var(--spacer-20);
    box-sizing: border-box;

    @include for-mobile {
      padding: 0;
      width: auto;
    }
  }
}

</style>
