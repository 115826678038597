<template>
  <transition name="fade">
    <div v-if="visible" class="sf-notification" :class="`color-${type}`">
      <!--@slot Custom notification icon. Slot content will replace default icon.-->
      <slot v-if="!hideIcon" name="icon" v-bind="{ icon }">
        <SfIcon
          v-if="!!icon"
          class="sf-notification__icon"
          :icon="icon"
          size="lg"
          color="white"
        />
      </slot>
      <div>
        <!--@slot Custom title. Slot content will replace default title.-->
        <slot name="title" v-bind="{ title }">
          <div v-if="title" class="sf-notification__title mobile-only">
            {{ title }}
          </div>
        </slot>
        <!--@slot Custom message. Slot content will replace default message.-->
        <slot name="message" v-bind="{ message }">
          <span v-if="message" class="sf-notification__message">
            {{ message }}
          </span>
        </slot>
        <!--@slot Custom action. Slot content will replace default action.-->
        <slot name="action" v-bind="{ action, actionHandler }">
          <SfButton
            v-if="action"
            class="sf-button--pure sf-notification__action"
            data-transaction-name="Notification Close"
            @click="actionHandler"
          >
            {{ action }}
          </SfButton>
        </slot>
      </div>
      <!--@slot Custom notification close icon. Slot content will replace default close icon.-->
      <slot name="close" v-bind="{ closeHandler }">
        <SfButton
          aria-label="Close notification"
          class="sf-button--pure sf-notification__close"
          data-transaction-name="Notification Close"
          @click="closeHandler"
        >
          <SfIcon icon="cross" color="white" />
        </SfButton>
      </slot>
    </div>
  </transition>
</template>
<script>
import { SfIcon, SfButton } from '@storefront-ui/vue';
export default {
  name: 'SfONotification',
  components: {
    SfIcon,
    SfButton
  },
  props: {
    /**
     * Visibility of the Notification. Default value is false.
     */
    visible: {
      type: Boolean,
      default: false
    },
    /**
     * Title that will be displayed in Notification.
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Message that will be displayed in Notification.
     */
    message: {
      type: String,
      default: ''
    },
    /**
     * Action that will be displayed in Notification.
     */
    action: {
      type: String,
      default: ''
    },
    /**
     * Notification type ("secondary", "info", "success", "warning", "danger", "wishlist"). Check "Knobs" section to see how they look like.
     */
    type: {
      type: String,
      default: 'secondary',
      validator: function (value) {
        return ['secondary', 'info', 'success', 'warning', 'danger', 'wishlist', 'account'].includes(
          value
        );
      }
    },
    isWishlist: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon () {
      switch (this.type) {
        case 'success':
          return 'added_to_cart';
        case 'danger':
          return 'info_shield';
        default:
          return 'info_circle';
      }
    }
  },
  methods: {
    actionHandler () {
      /**
       * Event for action button
       * @type {Event}
       */
      this.$emit('click:action');
    },
    closeHandler () {
      /**
       * Event for close icon
       * @type {Event}
       */
      this.$emit('click:close');
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfNotification.scss";
</style>
