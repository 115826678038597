<template>
  <div class="sf-sidebar" :class="[staticClass, className]">
    <SfOverlay :visible="visibleOverlay" @click="close" />
    <transition :name="transitionName">
      <aside v-if="visible" v-focus-trap class="sf-sidebar__aside">
        <!--@slot Use this slot to place content inside the modal bar.-->
        <slot name="bar">
          <SfBar
              :title="title"
              class="mobile-only"
              :back="true"
              @click:back="close"
          />
        </slot>
        <!--@slot Use this slot to replace close icon.-->
        <slot name="circle-icon" v-bind="{ close, button }">
          <SfCircleIcon
              v-if="button"
              icon-size="12px"
              aria-label="Close sidebar"
              icon="cross"
              class="sf-sidebar__circle-icon desktop-only"
              @click="close"
          />
        </slot>
        <div v-if="title || hasTop" class="sf-sidebar__top">
          <!--@slot Use this slot to replace SfHeading component.-->
          <slot name="title" v-bind="{ title, subtitle, headingLevel }">
            <SfHeading
                v-if="title"
                :title="title"
                :subtitle="subtitle"
                :level="headingLevel"
                class="sf-heading--left sf-heading--no-underline sf-sidebar__title desktop-only"
            />
          </slot>
          <!--@slot Use this slot to add sticky top content.-->
          <slot name="content-top" />
        </div>
        <div ref="content" class="sf-sidebar__content">
          <!--@slot Use this slot to add SfSidebar content.-->
          <slot />
        </div>
        <!--@slot Use this slot to place content to sticky bottom.-->
        <div v-if="hasBottom" class="sf-sidebar__bottom">
          <slot name="content-bottom" />
        </div>
      </aside>
    </transition>
  </div>
</template>
<script>
import focusTrap from '$ui/directives/focus-trap';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import isClient from '$ui/utilities/is-client';
import SfBar from '$ui/components/molecules/SfBar/SfBar.vue';
import SfCircleIcon from '$ui/components/atoms/SfCircleIcon/SfCircleIcon.vue';
import SfOverlay from '$ui/components/atoms/SfOverlay/SfOverlay.vue';
import SfHeading from '$ui/components/atoms/SfHeading/SfHeading.vue';
export default {
  name: 'SfSidebar',
  directives: { focusTrap },
  components: {
    SfBar,
    SfCircleIcon,
    SfOverlay,
    SfHeading
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    headingLevel: {
      type: Number,
      default: 3
    },
    button: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      position: 'left',
      staticClass: null,
      className: null
    };
  },
  computed: {
    visibleOverlay () {
      return this.visible && this.overlay;
    },
    transitionName () {
      return 'slide-' + this.position;
    },
    hasTop () {
      return this.$slots.hasOwnProperty('content-top');
    },
    hasBottom () {
      return this.$slots.hasOwnProperty('content-bottom');
    }
  },
  watch: {
    visible: {
      handler (value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            disableBodyScroll(this.$refs.content);
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.classHandler();
  },
  updated () {
    this.classHandler();
  },
  methods: {
    close () {
      this.$emit('close');
    },
    keydownHandler (e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler () {
      let update = false;
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
        update = true;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
        update = true;
      }
      if (update) {
        this.position =
            [this.staticClass, this.className].toString().search('--right') > -1
              ? 'right'
              : 'left';
      }
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/organisms/SfSidebar.scss";
</style>
