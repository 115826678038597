<template>
  <div
    class="o-header-wrapper"
    :class="[
      {'o-header-wrapper__hide': isPersonalAccountMenuOpen},
      {'pre-header-exist': showPreHeaderBanner && !isMobileMenu && !isPersonalAccountMenuOpen},
      {'o-header-wrapper--mobile-menu': isMobileMenu}
    ]"
  >
    <SfOverlay
      class="overlay"
      :visible="isOverlay"
      :transition="'none'"
      @mouseover="hideOverlay"
      @click="hideOverlay"
    />
    <div
      class="sf-header"
      :class="[className, isMicrocartOpen, {'sf-header__open-catalog': isMobileCatalog }]"
      :style="styleProperty"
    >
      <div class="sf-header-wrapper">
        <header
          ref="header"
          class="o-header"
        >
          <div class="o-header__row">
            <div class="o-header__col o-header__col--left">
              <ALogo />
              <ALanguageSwitcher class="o-header__language" />
            </div>
            <LazyHydrate :trigger-hydration="isMounted">
              <div class="o-header__col o-header__col--top">
                <ul class="o-header__menu-nav">
                  <li
                    v-for="(mainMenuItem, i) in mainMenu"
                    :key="i"
                    class="o-header__menu-nav-group"
                  >
                    <SfLink
                      :link="mainMenuItem.path"
                      :class="menuClassName(mainMenuItem)"
                      class="o-header__menu-nav-item"
                    >
                      {{ mainMenuItem.name }}
                    </SfLink>
                    <ul
                      :key="i"
                      v-show="mainMenuItem.items"
                      class="o-header__menu-sub"
                    >
                      <li
                        v-for="(submenu, index) in mainMenuItem.items"
                        :key="index"
                        class="o-header__menu-sub-item"
                      >
                        <SfLink
                          :link="submenu.link"
                          class="o-header__menu-sub-link"
                        >
                          {{ submenu.name }}
                        </SfLink>
                      </li>
                    </ul>
                  </li>
                </ul>
                <MPhoneContact
                  class="o-header__phone-contact"
                  size="small"
                  :is-email-shown="false"
                />
                <div class="o-header__locate"
                     :class="{'o-header__locate--open-catalog': isMobileCatalog }"
                >
                  <ShippingWidgetLite />
                </div>
              </div>
            </LazyHydrate>
            <div class="o-header__col o-header__col--icon">
              <div class="o-header__icons">
                <AWishlistIcon />
                <lazy-hydrate when-idle>
                  <div>
                    <AAccountIcon v-if="!isLoggedIn" />
                    <div
                      v-else
                      class="o-header__menu-nav-group o-header__menu-auth"
                    >
                      <AAccountIcon :class="menuClassName(authMenuItem)" />
                      <ul class="o-header__menu-sub auth__menu-sub">
                        <li
                          v-for="(submenu, index) in authMenuItem.items"
                          :key="index"
                          class="o-header__menu-sub-item"
                        >
                          <div
                            v-if="submenu.link === '/logout'"
                            data-transaction-name="Logout"
                            @click="logout"
                            class="o-header__menu-sub-link logout-link"
                          >
                            {{ submenu.name }}
                          </div>
                          <SfLink
                            v-else
                            :link="localizedRoute(submenu.link)"
                            class="o-header__menu-sub-link"
                          >
                            {{ submenu.name }}
                          </SfLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </lazy-hydrate>
              </div>
              <AToggleMenu
                @close="closeSidebarMenu"
                class="o-header__toggle-menu"
              />
            </div>
          </div>
        </header>
      </div>
    </div>
    <MMenu
      v-show="isMobileMenu && !isMobileCatalog"
      class="mobile-menu"
      :auth-menu="filteredAuthMenu"
      :main-menu="mainMenu"
      :sub-menu="subMenu"
      :is-sidebar-open="isSidebarOpen"
      @click="sidebarToggle"
      @close="closeSidebarMenu"
    />
  </div>
</template>

<script>
import {
  SfLink,
  SfOverlay
} from '@storefront-ui/vue';
import { getTopLevelCategories } from 'theme/helpers';
import ALogo from 'theme/components/atoms/a-logo';
import AAccountIcon from 'theme/components/atoms/a-account-icon';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex';
import MMenu from 'theme/components/molecules/m-menu';
import MPhoneContact from '../molecules/m-phone-contact';
import AWishlistIcon from 'theme/components/atoms/a-wishlist-icon';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import AToggleMenu from '../atoms/a-toggle-menu';
import ALanguageSwitcher from 'theme/components/atoms/a-language-switcher';
import ShippingWidgetLite from '$modules/shipping/components/shipping-widget-lite.vue';
import DeviceType from 'theme/mixins/DeviceType';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  name: 'OHeader',
  components: {
    ShippingWidgetLite,
    ALanguageSwitcher,
    AToggleMenu,
    AWishlistIcon,
    LazyHydrate,
    MPhoneContact,
    SfLink,
    ALogo,
    AAccountIcon,
    MMenu,
    SfOverlay
  },
  mixins: [DeviceType],
  data () {
    return {
      isMounted: false,
      isHoveredMenu: false,
      isSidebarOpen: false,
      height: 0,
      isLoading: false,
      activeSubMenuItem: ''
    }
  },
  mounted () {
    this.isMounted = true
    this.isLoading = true
  },
  computed: {
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel,
      isMobileMenu: state => state.ui.isMobileMenu,
      isMobileCatalog: state => state.ui.isMobileCatalog,
      microcart: state => state.ui.microcart,
      showPreHeaderBanner: state => state.ui.preHeaderBanner,
      isPersonalAccountMenuOpen: state => state.ui.isAccountMenuOpen,
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      isBonusCardExist: 'bonusCard/isBonusCardExist'
    }),
    mainMenu () {
      return [
        {
          name: this.$t('For clients'),
          path: this.localizedRoute('/buyers'),
          items: [
            { name: this.$t('Stock'), link: this.localizedRoute('/promotion') },
            { name: this.$t('Everything new'), link: this.localizedRoute('/novelty') },
            { name: this.$t('Shops'), link: this.localizedRoute('/stores') },
            { name: this.$t('Loyalty program'), link: this.localizedRoute('/loyalty') },
            { name: this.$t('Own TM'), link: this.localizedRoute('/own-tm') },
            { name: this.$t('Gift certificates'), link: this.localizedRoute('/giftcards') },
            { name: this.$t('Interview'), link: this.localizedRoute('/quiz') },
            { name: this.$t('Delivery for business'), link: this.localizedRoute('/optovi-zakupivli') },
            { name: this.$t('Financial Statements'), link: this.localizedRoute('/finansova-zvitnist') },
            { name: this.$t('Help'), link: this.localizedRoute('/help') },
            { name: this.$t('Favorable offers from banks'), link: this.localizedRoute('/vigidni-propozitsiyi-vid-bankiv') },
            { name: this.$t('Giveaway from TM VARTO'), link: this.localizedRoute('/tm-varto') }
          ]
        },
        {
          name: this.$t('For partners'),
          path: this.localizedRoute('/partners'),
          items: [
            {
              name: this.$t('Suppliers'),
              link: this.localizedRoute('/suppliers')
            },
            { name: this.$t('Tenders'), link: this.localizedRoute('/tenders') },
            { name: this.$t('Rent'), link: this.localizedRoute('/rent') },
            { name: this.$t('For advertisers'), link: this.localizedRoute('/advertisers') },
            { name: this.$t('Anti-corruption'), link: this.localizedRoute('/anti-corruption') }
          ]
        },
        { name: this.$t('Work'),
          path: this.localizedRoute('/work'),
          items: [
            {
              name: this.$t('Delicious work'),
              link: this.localizedRoute('/smachna_robota')
            },
            { name: this.$t('Luscious life with VARUS'), link: this.localizedRoute('/juicy-life-with-varus') },
            { name: this.$t('Hot vacancies'), link: this.localizedRoute('/hot-vacancies') },
            { name: this.$t('Career with taste'), link: this.localizedRoute('/career-with-taste') },
            { name: this.$t('Experience of your future'), link: this.localizedRoute('/experience-of-your-future') }
          ]
        }
      ]
    },
    subMenu () {
      return [
        // {
        //   name: this.$t('VARUS Perfect'),
        //   path: '/varus-perfect'
        // },
        { name: this.$t('Delivery for business'),
          path: '/optovi-zakupivli'
        },
        {
          name: this.$t('VARUS CAFE'),
          path: this.localizedRoute('/varuscafe')
        },
        {
          name: this.$t('Promotional newspaper'),
          path: this.localizedRoute('/gazeta-varus-0')
        },
        {
          name: this.$t('Stock'),
          path: this.localizedRoute('/promotion')
        }
      ]
    },
    authMenu () {
      return [
        {
          name: this.$t('My Account'),
          path: '/my-account',
          items: [
            { name: this.$t('Profile'), link: '/my-account/profile' },
            { name: this.$t('My purchases'), link: '/my-account/orders' },
            { name: this.$t('My lists'), link: '/my-account/wishlist' },
            { name: this.$t('Recently viewed products'), link: '/my-account/viewed' },
            { name: this.$t('Bonus card'), link: '/my-account/bonus-card' },
            // { name: this.$t('Promo codes and coupons'), link: '/my-account/promo-codes' },
            { name: this.$t('Testimonials'), link: '/my-account/reviews' },
            // { name: this.$t('Saved addresses'), link: '/my-account/addresses' },
            { name: this.$t('Payment cards'), link: '/my-account/payment-cards' },
            // { name: this.$t('Log in from devices'), link: ytv'/my-account/devices' },
            { name: this.$t('Manage notifications'), link: '/my-account/notifications' },
            { name: this.$t('Logout cabinet'), link: '/logout' }
          ]
        }
      ]
    },
    isOverlay () {
      return this.isMobileCatalog
    },
    className () {
      return {
        'sf-header--has-mobile-search': this.isSearchPanelVisible,
        'sf-header--is-sticky': this.isSearchPanelVisible
      };
    },
    styleProperty () {
      return {
        '--_header-height': `${this.height}px`
      }
    },
    categories () {
      return getTopLevelCategories(this.getCategories);
    },
    headerHeightDeps () {
      return {
        isSearchPanelVisible: this.isSearchPanelVisible,
        screenSize: this.$mq
      }
    },
    authMenuItem () {
      return this.filteredAuthMenu[0]
    },
    isMicrocartOpen () {
      return {
        'sf-header--is-microcart-open': this.microcart
      }
    },
    filteredAuthMenu () {
      let menu = [Object.assign({}, ...this.authMenu)]

      if (!this.isBonusCardExist) {
        menu[0].items = this.authMenu[0].items.filter(e => e.link !== '/my-account/bonus-card')
      }

      return menu
    }
  },
  methods: {
    ...mapMutations('ui', {
      setSearchpanel: 'setSearchpanel',
      closeCatalog: 'closeCatalog',
      closeMenu: 'closeMenu',
      openCatalog: 'openCatalog'
    }),
    ...mapActions('user', {
      logoutUser: 'logout'
    }),
    hideOverlay () {
      this.setSearchpanel(false);
      this.closeCatalog();
      this.closeMenu();
    },
    menuClassName (link) {
      const hasItems = link.hasOwnProperty('items');
      return {
        'o-header__menu-nav--has-child': hasItems,
        'dropdown-chevron': hasItems
      };
    },
    closeSidebarMenu () {
      this.closeMenu();
      this.isSidebarOpen = false;
    },
    sidebarToggle (value) {
      this.isSidebarOpen = value
    },
    logout () {
      this.logoutUser({});

      if (this.$route.path.includes('/my-account')) {
        this.$router.push(this.localizedRoute('/'));
      }
    }
  },
  watch: {
    isSearchPanelVisible () {
      if (this.isSearchPanelVisible) {
        this.$store.commit('ui/closeCatalog');
      }
    },
    headerHeightDeps: {
      handler () {
        if (!isClient) return;
        this.$nextTick(() => {
          this.height = this.$refs.header?.offsetHeight || 0;
        });
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.o-header-wrapper {
  @include for-mobile {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: var(--header-sticky-index);
  }

  &--mobile-menu {
    @include for-mobile {
      position: fixed;
    }
  }

  &__hide {
    @include for-mobile {
      display: none;
    }
  }
}

.sf-header {
  position: static;
  background-color: var(--white);
  border-bottom: 1px solid rgba(195, 198, 208, .5);

  @include for-desktop {
    height: 70px;
  }

  &__open-catalog, &--has-mobile-search, &--is-microcart-open {
    @include only-mobile {
      display: none;
    }
  }

  &--is-sticky {
    @include for-mobile {
      position: fixed;
      top: 0;
      width: 100%;
      overflow: hidden;
      background-color: var(--white);
      z-index: var(--header-language-index);
    }
  }

  .o-header__row {
    .o-header__catalog {
      @include for-mobile {
        width: 100%;
      }

      &--active:after {
        display: none;
      }
    }

    .o-header__col--center {
      .orange-color {
        color: var(--orange);
        transition: color 0.3s ease-in-out;

        &:hover {
          color: var(--orange-hover);
        }
      }
    }

    &:after {
      border-color: var(--line-gray);
    }
  }

  .search-container {
    position: relative;
  }

  .logout-link {
    cursor: pointer;
    border-top: 1px solid var(--light-gray);
    padding-top: var(--spacer-15);
    color: var(--orange);
  }
}

.o-header__submenu-nav-item {
  ::v-deep .sf-link {
    color: var(--black);
  }
}

.o-header__menu-auth {
  margin: 0 !important;
  position: relative;

  &:hover {
    .a-account-icon--active:before {
      color: var(--orange);
    }
  }

  &:before {
    content: '';
    width: 1px;
    height: 24px;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    position: absolute;
    background: #C3C6D0;
    opacity: 0.5;
  }

  .auth__menu-sub {
    right: 0;
    left: unset;
  }
}

.overlay {
  position: absolute;
}

</style>
