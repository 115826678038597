<template>
  <hr class="sf-divider" />
</template>
<script>
export default {
  name: 'SfDivider'
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfDivider.scss";
</style>
