import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserCommunicationState from '../types/UserCommunicationState';
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const userCommunicationStore: Module<UserCommunicationState, RootState> = {
  namespaced: true,
  state: {
    communications: [],
    subscriptions: [],
    communicationsLoading: false
  },
  actions,
  getters,
  mutations
}
