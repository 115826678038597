import { isServer } from '@vue-storefront/core/helpers';
import { getLocalStorageLanguage, getStoreCodeLink } from 'theme/helpers/language';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export const processRedirect = (): void => {
  // We can't make a redirect using server because App uses redis cache for SSR response
  if (isServer) return;

  const lang = getLocalStorageLanguage();
  const storeCode = currentStoreView().storeCode;

  if (!lang || !storeCode || lang === storeCode) return;

  window.location.assign(getStoreCodeLink(lang));
}
