import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'

const getters: GetterTree<UserState, RootState> = {
  isLoggedIn (state) {
    return state.current !== null
  },
  isLocalDataLoaded: state => state.local_data_loaded,
  getUserToken (state) {
    return state.token
  },
  getOrdersHistory (state) {
    return state.orders_history ? state.orders_history.items : []
  },
  getToken (state) {
    return state.token
  },
  getUserEmail (state, getters) {
    return getters.isLoggedIn ? state.current.email : null
  },
  currentUser: (state) => state.current,
  getOrdersHistoryTotalOnline (state) {
    return state.ordersOnline ? state.ordersOnline.total_online : 0
  },
  getOrdersHistoryTotalOffline (state) {
    return state.ordersOnline ? state.ordersOnline.total_offline : 0
  },
  getOrdersHistoryTotal (state, getters) {
    return getters.getOrdersHistoryTotalOnline + getters.getOrdersHistoryTotalOffline
  },
  getOrdersHistoryTotalCurrent: (state) => (type = 'online') => {
    if (type === 'online') {
      return state.ordersOnline.current_total_orders || 0
    } else {
      return state.ordersOffline.current_total_orders || 0
    }
  },
  getOrdersHistoryDateFrom (state) {
    return state.ordersOnline ? state.ordersOnline.date_from : ''
  },
  getOrdersHistoryDateTo (state) {
    return state.ordersOnline ? state.ordersOnline.date_to : ''
  },
  getOrdersHistoryLoaded (state) {
    return Object.keys(state.ordersOnline).length || Object.keys(state.ordersOffline).length
  },
  lastOrder (state) {
    return state.lastOrder
  }
};

export default getters
