import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
};

export const RecentlyViewedService = {
  loadRecentlyViewed: async (payload): Promise<Task> =>
    TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.recentlyViewed, 'loadRecentlyViewed_endpoint')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(payload)
      }
    }),
  saveRecentlyViewed: async (items): Promise<Task> => {
    return TaskQueue.execute({
      url: processLocalizedURLAddress(
        getApiEndpointUrl(config.recentlyViewed, 'saveRecentlyViewed_endpoint')
      ),
      payload: {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify({ products: items })
      }
    });
  }
}
