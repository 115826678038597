export const CATEGORY_EXT = 'category-ext'
export const SET_CHILD_CATEGORIES = CATEGORY_EXT + '/CHILD_CATEGORIES'
export const SET_RELATED_CHILD_CATEGORIES = CATEGORY_EXT + '/RELATED_CHILD_CATEGORIES'
export const SET_VARUS_CAFE_CATEGORIES = CATEGORY_EXT + '/VARUS_CAFE_CATEGORIES'
export const SET_VARUS_CAFE_CATEGORIES_LOADING = CATEGORY_EXT + '/VARUS_CAFE_CATEGORIES_LOADING'
export const SET_CATEGORIES_PRODUCTS_COUNT = CATEGORY_EXT + '/CATEGORIES_PRODUCTS_COUNT'
export const SET_IS_CATEGORY_PRODUCTS_OUT_OF_STOCK = CATEGORY_EXT + '/IS_CATEGORY_PRODUCTS_OUT_OF_STOCK'
export const CATEGORY_SET_CATEGORY_FILTERS = CATEGORY_EXT + '/CATEGORY_SET_CATEGORY_FILTERS'
export const CATEGORY_SET_RESULT_CATEGORIES = CATEGORY_EXT + '/CATEGORY_SET_RESULT_CATEGORIES'
export const SET_CURRENT_CATEGORY = CATEGORY_EXT + '/SET_CURRENT_CATEGORY'
export const SET_PRODUCT_AGGREGATION = CATEGORY_EXT + '/SET_PRODUCT_AGGREGATION'
export const SET_FILTER_PRICE = CATEGORY_EXT + '/SET_FILTER_PRICE'
export const ADD_VARUS_NEW_PRODUCTS = CATEGORY_EXT + '/ADD_VARUS_NEW_PRODUCTS';
export const SET_IS_BRAND_CATEGORY = CATEGORY_EXT + '/SET_IS_BRAND_CATEGORY';
export const SET_VARUS_NEW_PRODUCTS = CATEGORY_EXT + '/SET_VARUS_NEW_PRODUCTS';
export const SET_VARUS_NEW_PRODUCTS_LOADING = CATEGORY_EXT + '/VARUS_NEW_PRODUCTS_LOADING';
export const SET_VARUS_NEW_PRODUCTS_TOTAL = CATEGORY_EXT + '/VARUS_NEW_PRODUCTS_TOTAL';
export const SET_CLICK_LOAD_MORE = CATEGORY_EXT + '/CLICK_LOAD_MORE';
export const SET_SPECIAL_CATEGORY_LOADING = CATEGORY_EXT + '/SPECIAL_CATEGORY_LOADING';
