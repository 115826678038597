import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { BrandsStore } from './store'

export const BrandsModule: StorefrontModule = function ({ store }) {
  store.registerModule('brands', BrandsStore)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('brand', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'brand', start, size)
      }
    });
  });
}
