<template>
  <footer class="o-footer-minimal">
    <div class="o-footer-minimal__social">
      <MPhoneContact />
      <MSocialContact />
    </div>
    <ALogo class="o-footer-minimal__logo" :is-pure="isError" />
    <MHomeApps class="o-footer-minimal__apps" />
  </footer>
</template>

<script>
import ALogo from 'theme/components/atoms/a-logo';
import MHomeApps from 'theme/components/molecules/m-home-apps';
import MPhoneContact from 'theme/components/molecules/m-phone-contact';
import MSocialContact from 'theme/components/molecules/m-social-contact';

export default {
  name: 'OFooterMinimal',
  components: {
    ALogo,
    MHomeApps,
    MSocialContact,
    MPhoneContact
  },
  props: {
    isError: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~theme/css/breakpoints';

.o-footer-minimal {
  border-top: 2px solid var(--color-zircon);
  display: grid;
  grid-template-columns: 1fr;
  padding: var(--spacer-15);
  row-gap: var(--spacer-10);

  @media (min-width: $tablet-min) {
    border-width: 1px;
    padding: var(--spacer-55) var(--spacer-90);
  }

  @include for-desktop {
    grid-template-columns: 1fr auto 1fr;
  }

  &__social {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include for-desktop {
      justify-content: flex-start;
      column-gap: 40px;
    }
  }

  &__logo {
    align-items: center;
    justify-content: center;

    @include for-mobile {
      order: -1;
    }
  }

  &__apps {
    align-items: center;
    display: flex;
    justify-content: center;

    @include for-desktop {
      justify-content: flex-end;
    }
  }
}
</style>
