import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ShippingModuleState from '../types/shipping-module-state';
import { fallbackDefaults } from '$modules/shipping/helpers/shipping-remap';
import { codes, newPostMethods } from '$modules/shipping/config';

const getters: GetterTree<ShippingModuleState, RootState> = {
  getCityList: (state) => (
    state.cityList || []
  ),
  getDefaultCity: (state) => (
    state.defaultCity
  ),
  getDefaultShop: (state) => (
    state.defaultShop
  ),
  getShopList: (state) => (
    state.shopList || []
  ),
  getShippingLoaded: (state) => (
    state.loaded.shipping
  ),
  getCurrent: (state) => (
    state.current
  ),
  getDraft: (state) => (
    state.draft
  ),
  isCurrentNewPost: (state) => (
    newPostMethods.includes(state.current.method)
  ),
  isDraftValidAddress: (state) => (
    state.draft.address?.label && state.draft.address?.category === 'adr_address'
  ),
  isDraftValidNova: (state, getters) => {
    const isAddress = state.draft?.npType === 'address'
      ? getters.isDraftValidAddress : false

    return !!(
      state.draft?.npCity && (isAddress || state.draft?.npShop)
    )
  },
  isDraftValidVarus: (state, getters) => {
    const validation = !!(
      state.draft.city &&
      state.draft.method
    )

    if (state.draft.method !== codes.delivery) {
      return !!(validation && state.draft.shop && state.draft.type)
    }

    return !!(validation && getters.isDraftValidAddress)
  },
  isDraftValid: (state, getters) => (
    state.draft.method === codes.newPost ? getters.isDraftValidNova : getters.isDraftValidVarus
  ),
  fallbackShippingDetails: (state, getters, rootState, rootGetters) => (
    fallbackDefaults(state.current, state.loaded.shipping, rootGetters['shipping/getShippingMethods'])
  ),
  fallbackShippingDetailsDraft: (state) => (
    fallbackDefaults(state.draft, true, [])
  )
}

export default getters
