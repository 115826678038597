import { Module } from 'vuex'
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import RootState from '@vue-storefront/core/types/RootState'
import PromotionState from '../types/PromotionState';

const state = (): PromotionState => ({
  brandsMain: [],
  promotionCategory: {},
  promotionCurrentCategory: {},
  promotionCurrentType: {},
  page: {},
  productList: [],
  promotionMetaData: {},
  promotionLoader: false,
  promotionProductsTotal: 0,
  availableProducts: false,
  productsIsLoading: false,
  promotions: [],
  searchProductsStats: {},
  isAdditionalPromotionsLoading: false,
  isAdditionalPromotionProductsLoading: false
});

export const PromotionStore: Module<PromotionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
