import * as types from './mutation-types'
import * as coreTypes from '@vue-storefront/core/modules/wishlist/store/mutation-types';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'

const mutationToWatch = [
  coreTypes.WISH_ADD_ITEM,
  coreTypes.WISH_DEL_ITEM,
  coreTypes.WISH_DEL_ALL_ITEMS
].map(m => `wishlist/${m}`)

const mutationGroupToWatch = [
  types.WISHLIST_GROUP_ADD_ITEMS,
  types.WISHLIST_ADD_GROUP,
  types.WISHLIST_DELETE_GROUP,
  types.WISHLIST_SAVE_GROUP,
  coreTypes.WISH_DEL_ITEM
].map(m => `wishlist/${m}`)

const wishlistPersistPlugin = (mutation, state) => {
  const wishListStorage = StorageManager.get('wishlist')

  if (mutationToWatch.includes(mutation.type)) {
    wishListStorage.setItem('current-wishlist', state.wishlist.items)
  }

  if (mutationGroupToWatch.includes(mutation.type)) {
    wishListStorage.setItem('wishlist-groups', state.wishlist.groups)
  }
}

export default wishlistPersistPlugin
