import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import Task from '@vue-storefront/core/lib/sync/types/Task'
import config from 'config'

const headers = {
  'Accept': '*/*',
  'Cache-Control': 'no-cache',
  'Content-Type': 'application/json'
};

export const ReviewService = {
  setReview: async ({ review }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.review, 'add_endpoint'))

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers,
        body: JSON.stringify({ review: review })
      }
    })
  },
  setAnswer: async ({ review }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.review, 'answer_endpoint'))

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers,
        body: JSON.stringify({ comment: review })
      }
    })
  },
  setUpvote: async ({ id }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.review, 'upvote_endpoint'))

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers,
        body: JSON.stringify({ review_id: id })
      }
    })
  },
  setDownvote: async ({ id }: any): Promise<Task> => {
    const url = processLocalizedURLAddress(getApiEndpointUrl(config.review, 'downvote_endpoint'))

    return TaskQueue.execute({
      url,
      payload: {
        method: 'POST',
        headers,
        body: JSON.stringify({ review_id: id })
      }
    })
  }
};
