import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import UserPaymentCardsState from '../types/UserPaymentCardsState'
import { UserPaymentCardsService } from '../data-resolver/UserPaymentCardsService';

import * as types from './mutation-types'
import { groups, sendToLogs } from 'theme/helpers/web-logging';

const actions: ActionTree<UserPaymentCardsState, RootState> = {
  loadPaymentCardsOptions () {
    return UserPaymentCardsService.getPaymentOptions()
  },
  changeDefaultCard (context, card) {
    return UserPaymentCardsService.changeDefaultPaymentCard(card)
  },
  deleteCard (context, card) {
    return UserPaymentCardsService.deletePaymentCard(card)
  },
  sendTokenData (context, payload) {
    return UserPaymentCardsService.sendTokenData(payload)
  },
  forceSetDefaultCard ({ state, getters }, { entryCard }) {
    const { getPaymentCards } = getters

    state.paymentCards = getPaymentCards.map(card => ({
      ...card,
      is_default: (entryCard.card_mask === card.card_mask)
    }))
  },
  async loadPaymentCardsList ({ commit, dispatch }) {
    const response = await UserPaymentCardsService.getPaymentCardsList()

    if (response.code !== 200) {
      sendToLogs(
        groups.Payments,
        'loadPaymentCardsList:not:200',
        { code: response.code },
        response?.transparentId
      )
    }

    if (response.code === 401) {
      await dispatch('user/logout', { silent: true }, { root: true })
    }

    if (Array.isArray(response?.result)) {
      commit(types.PAYMENT_CARDS_LIST_LOADED, response.result)
    }
    return response
  },
  clearPaymentCardsList ({ commit }) {
    commit(types.PAYMENT_CARDS_LIST_LOADED, [])
  },
  callVerificationWidget (ctx, payload) {
    return new Promise((resolve) => {
      const wayforpay = payload.wayforpay
      const parameters = payload.parameters

      wayforpay.run({
        requestType: parameters.requestType,
        straightWidget: parameters.straightWidget,
        merchantAuthType: parameters.merchant_auth_type,
        merchantDomainName: parameters.merchant_domain_name,
        merchantSignature: parameters.merchant_signature,
        merchantAccount: parameters.merchant_account,
        orderReference: parameters.order_reference,
        amount: parameters.amount,
        currency: parameters.currency,
        merchantTransactionSecureType: parameters.merchant_transaction_secure_type,
        returnUrl: parameters.return_url,
        serviceUrl: parameters.service_url,
        paymentSystem: parameters.payment_system,
        verifyType: parameters.verify_type,
        theme: parameters.theme,
        language: parameters.language
      },
      (response) => {
        // on approved
        resolve(response)
      },
      (response) => {
        // on declined
        // resolve(response)
        console.log('wayforpay declined: ', response);
      },
      (response) => {
        // on pending
        console.log('wayforpay pending: ', response);
      })
    })
  }
}

export default actions
