var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"glide__slide sf-grouped-product-item"},[_c('div',{staticClass:"sf-grouped-product-item__aside"},[_vm._t("image",function(){return [_c('SfImage',{staticClass:"sf-grouped-product-item__image",attrs:{"src":_vm.image,"alt":_vm.title,"width":_vm.imageWidth,"height":_vm.imageHeight,"placeholder":_vm.imagePlaceholder,"lazy":_vm.imageLazy,"image-picture-breakpoint":_vm.imagePictureBreakpoint}})]},null,{
        image: _vm.image,
        title: _vm.title,
        imagePlaceholder: _vm.imagePlaceholder,
        imageLazy: _vm.imageLazy,
        imagePictureBreakpoint: _vm.imagePictureBreakpoint,
      })],2),_vm._v(" "),_c('div',{staticClass:"sf-grouped-product-item__description"},[_vm._t("title",function(){return [_c('div',{staticClass:"sf-grouped-product-item__title-wraper"},[_c('SfLink',{staticClass:"sf-grouped-product-item__title",attrs:{"link":_vm.link}},[_vm._v(_vm._s(_vm.title))])],1)]},null,{ title: _vm.title }),_vm._v(" "),_vm._t("details")],2),_vm._v(" "),_vm._t("configuration"),_vm._v(" "),_c('div',{staticClass:"sf-grouped-product-item__info"},[_vm._t("price",function(){return [_c('SfPrice',{staticClass:"sf-grouped-product-item__price",attrs:{"regular":_vm.priceRegular,"special":_vm.priceSpecial}})]},null,{ priceSpecial: _vm.priceSpecial, priceRegular: _vm.priceRegular })],2),_vm._v(" "),_vm._t("input",function(){return [_c('SfQuantitySelector',{staticClass:"sf-grouped-product-item__quantity-selector",attrs:{"qty":_vm.qty,"aria-label":"Quantity"},on:{"input":function($event){return _vm.$emit('input', $event)}}})]},null,{ qty: _vm.qty })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }