<template>
  <transition name="fade" appear>
    <no-ssr>
      <div v-show="isOpen" class="m-cookie-notification">
        <div class="cookie">
          <div class="cookie-message">
            <p class="cookie-message__title" v-html="$t('Our site uses cookies')" />
            <p class="cookie-message__text">
              {{ $t('This site uses cookies for analytics, personalization and advertising. By continuing to browse, you agree to our use of cookies. To learn more or change your cookie settings,') }}
              <SfLink
                :link="localizedRoute('/user-agreement')"
                class="cookie-message__text_link"
                v-html="$t('Press here.')"
              />
            </p>
          </div>
          <SfButton
            class="cookie__button"
            @click="accept"
            v-html="$t('I agree')"
          />
        </div>
      </div>
    </no-ssr>
  </transition>
</template>

<script>
import { SfButton, SfLink } from '@storefront-ui/vue';
import NoSSR from 'vue-no-ssr'

export default {
  name: 'MCookieNotification',
  components: {
    SfButton,
    SfLink,
    'no-ssr': NoSSR
  },
  data () {
    return {
      isOpen: false
    };
  },
  beforeMount () {
    // disabled according to https://ltrino.atlassian.net/browse/VAR-2632
    /* this.$store
      .dispatch('claims/check', {
        claimCode: 'cookiesAccepted'
      })
      .then(cookieClaim => {
        if (!cookieClaim) {
          this.isOpen = true;
          this.$store.dispatch('claims/set', {
            claimCode: 'cookiesAccepted',
            value: false
          });
        } else {
          this.isOpen = !cookieClaim.value;
        }
      }); */
  },
  methods: {
    accept () {
      this.isOpen = false;
      this.$store.dispatch('claims/set', {
        claimCode: 'cookiesAccepted',
        value: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-cookie-notification {
  z-index: 100;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--white);
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.03);

  @include for-tablet {
    max-width: px2rem(470);
    bottom: var(--spacer-20);
    left: var(--spacer-20);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  @include for-desktop {
    padding: var(--spacer-10) 0;
  }

  .cookie {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    max-width: var(--max-width);
    margin: auto;
    padding: var(--spacer-20);

    @include for-desktop {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 var(--spacer-sm);
    }

    &-message {
      width: 100%;

      @include for-desktop {
        width: 80%;
      }

      &__title {
        margin: 0;
        padding: 0;
        font-size: var(--font-size-14);
        font-weight: var(--font-bold);
        color: var(-black);
      }

      &__text {
        margin: var(--spacer-10) 0 0 0;
        padding: 0;
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        line-height: var(--spacer-20);
        color: var(--black);

        &_link {
          cursor: pointer;
          color: var(--orange);
        }
      }
    }

    &__button {
      margin-top: var(--spacer-20);
      padding: var(--spacer-17) var(--spacer-40);
      font-size: var(--font-size-18);
      font-weight: var(--font-medium);
      color: var(--white);
      &:hover {
        --button-background: var(--orange-pressed);
        --button-color: var(--white);
      }

      @include for-desktop {
        margin-top: 0;
      }
    }
  }
}
</style>
