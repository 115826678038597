/* eslint-disable */
// @ts-nocheck
import { Module } from 'vuex'
import { recentlyViewedStore as rootRecentlyViewedStore } from '@vue-storefront/core/modules/recently-viewed/store'
import * as types from 'theme/store/recently-viewed/store/mutation-types';
import RootState from '@vue-storefront/core/types/RootState'
import RecentlyViewedState from 'theme/store/recently-viewed/types/RecentlyViewedState'
import merge from 'lodash/merge';
import config from 'config'
import modulesConfig from '$modules/config'
import { cacheStorage } from '@vue-storefront/core/modules/recently-viewed';
import { RecentlyViewedService } from 'theme/store/recently-viewed/data-resolver/RecentlyViewedService';
import { SearchQuery } from 'storefront-query-builder';
import { Logger } from '@vue-storefront/core/lib/logger'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { getCurrentDateString } from 'theme/helpers/text'
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

export const recentlyViewedStore: Module<RecentlyViewedState, RootState> = {
  ...rootRecentlyViewedStore,
  state: merge(rootRecentlyViewedStore.state, {
    loading: true,
    pageStart: 0,
    itemsTotal: 0,
    itemsData: []
  }),
  mutations: merge(rootRecentlyViewedStore.mutations, {
    [types.RECENTLY_VIEWED_ADD_ITEM] (state, { product }) {
      state.items.unshift(product);
    },
    [types.RECENTLY_VIEWED_LOAD] (state, storedItems) {
      state.items = storedItems || []
    },
    [types.RECENTLY_VIEWED_UPDATE_LOADING] (state, loading) {
      state.loading = loading
    },
    [types.RECENTLY_VIEWED_UPDATE_TOTAL] (state, total) {
      state.itemsTotal = total
    },
    [types.RECENTLY_VIEWED_UPDATE_ITEMS_DATA] (state, data) {
      state.itemsData = data
    },
    [types.RECENTLY_VIEWED_SET_PRODUCT_PAGE_START] (state, data) {
      state.pageStart = data
    }
  }),
  actions: merge(rootRecentlyViewedStore.actions, {
    load ({ dispatch }) {
      cacheStorage.getItem('recently-viewed', (err, storedItems) => {
        if (err) throw new Error(err)

        if (storedItems) {
          const itemsData = storedItems.map(e => e.added_at ? e : { sku: e.sku, added_at: getCurrentDateString() })
          dispatch('setItemsData', itemsData)
          dispatch('updateRecentlyViewedProducts')
        }
      })
    },
    addItem ({ commit, dispatch, state, rootGetters }, product) {
      const record = state.items.find(p => isSkuEqual(p, product));

      if (record) return;

      dispatch('updateItemsData', [{
        sku: product.sku,
        added_at: getCurrentDateString()
      }])

      commit(types.RECENTLY_VIEWED_ADD_ITEM, { product })

      if (rootGetters['user/isLoggedIn']) {
        const products = [product]
        dispatch('saveItemsSkuToServer', products)
      }
    },
    async getItemsDataFromServer ({ commit }, payload = {
      pageSize: config.recentlyViewed.pageSize,
      currentPage: 1
    }) {
      try {
        commit(types.RECENTLY_VIEWED_UPDATE_LOADING, true)
        const resp = await RecentlyViewedService.loadRecentlyViewed(payload)
        let serverData = null
        let serverTotal = 0

        if (resp.code === 200) {
          serverData = resp.result?.products || []
          serverTotal = resp.result?.total ? Number(resp.result?.total) : 0
        }
        commit(types.RECENTLY_VIEWED_UPDATE_TOTAL, serverTotal)
        commit(types.RECENTLY_VIEWED_UPDATE_LOADING, false)
        return serverData
      } catch (e) {
        commit(types.RECENTLY_VIEWED_UPDATE_LOADING, false)
        Logger.error('Recently-viewed products was not read ' + e, 'recently-viewed')()
        return null
      }
    },
    saveItemsSkuToServer (context, products = []) {
      if (!products.length) return

      const items = products.map(e => {
        return { sku: e.sku }
      })

      try {
        RecentlyViewedService.saveRecentlyViewed(items)
      } catch (e) {
        Logger.error('Recently-viewed products was not saved ' + e, 'recently-viewed')()
      }
    },
    async getRecentlyProducts ({ dispatch, getters }, itemsSku) {
      if (!itemsSku.length) return []

      const THEME_PAGE_SIZE = getters.pageStart;
      const defaultStart = 0

      let searchProductQuery = new SearchQuery()
      searchProductQuery = searchProductQuery
        .applyFilter({ key: 'visibility', value: { 'in': [2, 3, 4] } })
        .applyFilter({ key: 'status', value: { 'in': [0, 1] } }) /* 2 = disabled, 4 = out of stock */
        .applyFilter({ key: 'sku', value: { 'in': itemsSku.slice(defaultStart, THEME_PAGE_SIZE) } })

      const result = await dispatch('product/findProducts', {
        query: searchProductQuery,
        size: getters.viewedTotal,
        includeFields: modulesConfig.smallProduct.includeFields,
        excludeFields: modulesConfig.smallProduct.excludeFields,
        skipLoadOptions: true
      }, { root: true })

      return result.items
    },
    async resetFetchViewedProducts  ({ commit }) {
      commit(types.RECENTLY_VIEWED_LOAD, [])
      commit(types.RECENTLY_VIEWED_UPDATE_ITEMS_DATA, [])
    },
    async updateRecentlyViewedProducts ({ commit, dispatch, state }, itemsData = state.itemsData || []) {
      if (!itemsData.length) return

      const itemsSku = itemsData.map(e => e.sku)
      try {
        commit(types.RECENTLY_VIEWED_UPDATE_LOADING, true)
        const products = await dispatch('getRecentlyProducts', itemsSku) || []

        products.forEach(e => {
          e.store = currentStoreView().storeCode
          const productViewedData = itemsData.find(b => isSkuEqual(e, b)) || {}
          e.viewed_added_at = productViewedData.added_at || ''
        })

        products.sort((a, b) => (new Date(b.viewed_added_at) as any) - (new Date(a.viewed_added_at) as any));
        commit(types.RECENTLY_VIEWED_LOAD, products)
        commit(types.RECENTLY_VIEWED_UPDATE_LOADING, false)
      } catch (e) {
        Logger.error('Recently-viewed products was not load ' + e, 'recently-viewed')()
      }
    },
    updateItemsData ({ commit, state }, data) {
      if (!data || !data.length) return

      const itemsDataSku = state.itemsData.map(e => e.sku)
      const newItemsData = data.filter(e => !itemsDataSku.includes(e.sku))
      const itemsData = [ ...newItemsData, ...state.itemsData ]

      commit(types.RECENTLY_VIEWED_SET_PRODUCT_PAGE_START, data.length)

      commit(types.RECENTLY_VIEWED_UPDATE_ITEMS_DATA, itemsData)
    },
    setItemsData ({ commit }, data) {
      if (!data || !data.length) return
      commit(types.RECENTLY_VIEWED_UPDATE_ITEMS_DATA, data)
    },
    async syncRecentlyViewed ({ state, commit, dispatch }) {
      await dispatch('saveItemsSkuToServer', state.itemsData)
      const serverData = await dispatch('getItemsDataFromServer') || []
      commit(types.RECENTLY_VIEWED_SET_PRODUCT_PAGE_START, serverData.length)
      dispatch('setItemsData', serverData.slice(0, config.entities.product.carouselSize || 12))
      dispatch('updateRecentlyViewedProducts')
    }
  }),
  getters: {
    viewed: state => state.items,
    loading: state => state.loading,
    viewedTotalForProduct: state => state.itemsTotal - config.recentlyViewed.pageSize || 8,
    viewedTotal: state => state.itemsTotal,
    pageStart: state => state.pageStart
  }
}
