import { MutationTree } from 'vuex'
import BonusCardState from '../types/BonusCardState'
import * as types from './mutation-types'

const mutations: MutationTree<BonusCardState> = {
  [types.BONUS_SUMMARY_LOAD] (state, summary) {
    state.bonusSummary = summary || {}
  },
  [types.BONUS_SUMMARY_LOADING] (state, value) {
    state.bonusSummaryLoading = value
  },
  [types.BONUS_CARD_LOAD] (state, card) {
    state.bonusCard = card || {}
  }
};

export default mutations
