import modulesConfig from '$modules/config'

export default function getFindProductProps (props = {}) {
  return Object.assign({
    includeFields: modulesConfig.smallProduct.includeFields,
    excludeFields: modulesConfig.smallProduct.excludeFields,
    options: {
      populateRequestCacheTags: true,
      prefetchGroupProducts: false,
      setProductErrors: false,
      fallbackToDefaultWhenNoAvailable: true,
      assignProductConfiguration: false,
      separateSelectedVariant: false
    }
  }, props)
}
