export const remapCity = (city) => {
  const coordinates = {
    lng: +city.long,
    lat: +city.lat
  }

  const region = city.name

  delete city.long
  delete city.lat

  return {
    ...city,
    region,
    coordinates
  }
}
