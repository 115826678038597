import { price } from '@vue-storefront/core/filters';

export function formatPrice (value, withSign = true) {
  return value ? price(value, null, withSign) : ''
}

export function getProductPrice (
  product
) {
  if (!product) {
    return {
      regular: '',
      special: ''
    }
  }

  const specialPrice = +(product.sqpp?.special_price || product.price?.special)
  const regularPrice = +(product.sqpp?.price || product.price?.special)
  const isSpecialPrice = !!specialPrice

  return {
    regular: formatPrice(regularPrice),
    special: isSpecialPrice ? formatPrice(specialPrice, false) : ''
  }
}

export function getProductStock (product) {
  const stock = product.stock || {}

  return {
    ...stock,
    manage_stock: true,
    is_in_stock: typeof product?.sqpp?.in_stock === 'boolean' ? product?.sqpp?.in_stock : stock?.is_in_stock,
    qty: product?.sqpp?.qty
  }
}

export function getProductPriceFromTotals (product) {
  if (!product.totals || !product.totals.options) {
    return {
      regular: '',
      special: ''
    }
  }

  const isSpecialPrice = product.totals.discount_amount > 0

  const special = product.totals.row_total_incl_tax - product.totals.discount_amount
  const regular = product.totals.row_total_incl_tax

  return {
    regular: regular,
    special: isSpecialPrice ? special : ''
  }
}

export function getProductPriceFromSQPP (sqpp, qty) {
  if (!sqpp) return null

  const isSpecialPrice = sqpp?.discount &&
    sqpp?.specialPrice &&
    sqpp?.discount > 0 &&
    sqpp?.specialPrice !== sqpp?.originalPrice

  const special = sqpp?.specialPrice * (qty || 1)
  const regular = sqpp?.originalPrice * (qty || 1)

  return {
    regular: regular,
    special: isSpecialPrice ? special : ''
  }
}

export function getProductPriceFromPromo (sqpp, product) {
  return {
    regular: sqpp?.regular,
    special: product?.totals?.base_row_total
  }
}
