export const PhotogalleryService = {
  downloadFile: async (params): Promise<any> => {
    const url = `/img/0/0/resize/${params.path}`

    fetch(url, {
      method: 'GET'
    }).then(
      data => {
        return data.blob();
      }
    ).then(
      response => {
        const dataType = response.type;
        const binaryData = [];
        binaryData.push(response);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', params.title);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
      }
    )
  }
}
