import { Context } from '@vue-storefront/core/server/utils/types';
import { FastifyRequest } from 'fastify';

export const getCookies = ({ server }: Context): Record<string, string> => {
  if (!server) return {};

  const request = server.request as FastifyRequest;
  const cookies = request.headers.cookie;

  if (!cookies) return {};

  return cookies.split('; ').reduce((prev, curr) => {
    const [key, value] = curr.split('=');

    if (!key || !value) return { ...prev };

    return {
      ...prev,
      [key]: value
    }
  }, {});
}

export const getCookie = ({ server, ...otherParams }: Context, name: string): string | null => {
  const cookies = getCookies({ server, ...otherParams });

  if (!cookies[name]) return null;

  return cookies[name]
    ? cookies[name]
    : null
}

export const getCookieByName = (cookieName: string): string | null => {
  if (typeof window === 'undefined') return null
  const results = window.document.cookie.match('(^|;) ?' + cookieName + '=([^;]*)(;|$)')

  return results ? unescape(results[2]) : null
}
