import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import WishlistState from '../types/WishlistState'
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const getters: GetterTree<WishlistState, RootState> = {
  isOnWishlist: state => product => {
    let productInWishlist = state.items.some(p => isSkuEqual(p, product))

    if (!productInWishlist) {
      productInWishlist = state.groups.some(group => {
        return group.items.some(item => isSkuEqual(item, product))
      })
    }

    return productInWishlist
  },
  isGroupsLoaded: state => state.groupsLoaded,
  getGroups: state => state.groups,
  getIsModeCreateNewGroup: state => state.isModeCreateNewGroup,
  getWishlistItemsCount: (state, getters) => {
    return getters.getWishlistItems.length
  },
  getWishlists: state => state.wishlists,
  getWishlistItems: state => state.items
}

export default getters
