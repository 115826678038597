/* eslint-disable */
// @ts-nocheck
import { isServer } from '@vue-storefront/core/helpers';
import {clearQueries} from "@vue-storefront/core/scripts/clear-queries";

export const groups = {
  Auth: 'auth',
  User: 'user',
  Orders: 'orders',
  Payments: 'payments',
  Cart: 'cart',
  Checkout: 'checkout',
  Timeslots: 'timeslots',
  Other: 'other',
  Shipping: 'shipping'
}

export const webLoggingIdent = (userId, payload) => {
  try {
    if (isServer) return

    window.webLoggingUser = {userId, payload}
  } catch (e) {}
}

export const sendToLogs = (
  group: string,
  tag: string,
  payload: any,
  transparentId?: string
) => {
  try {
    if (isServer) return

    if (payload) {
      const context = {
        tag,
        type: 'web',
        group: `web/${group}`
      }

      if (transparentId) {
        context.transparentId = transparentId
      }

      // @ts-ignore
      window.errb.push({
        context,
        message: `${payload ? `Payload: ${JSON.stringify(payload)}` : ''}`,
        level: 'info'
      })
    }

    if (window.webLoggingUser && window.webLoggingUser.userId && window.webLoggingUser.payload) {
      // @ts-ignore
      webLoggingIdent(window.webLoggingUser.userId, window.webLoggingUser.payload)
    }
  } catch (e) {}
}

export const notFoundLogging = () => {
  try {
    if (isServer) return

    // @ts-ignore
    window.errb.push({
      'message': `Page not found`,
      'level': 'error',
      'context': {
        'type': 'page',
        'url': clearQueries(window.location.href),
        'pageUrl': window.location.href || '',
        'status': 404
      }
    });
  } catch (e) {}
}
