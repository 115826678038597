import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import merge from 'lodash/merge';
import { userStore as coreUserStore } from '@vue-storefront/core/modules/user/store';
import UserState from '../types/UserState'
import RootState from 'core/types/RootState';

export const userStore: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    groupToken: '',
    groupId: null,
    current: null,
    current_storecode: '',
    session_started: new Date(),
    orders_history: null,
    local_data_loaded: false,
    lastOrder: null,
    ordersOnline: {},
    ordersOffline: {}
  },
  getters,
  actions: merge(coreUserStore.actions, actions),
  mutations: merge(coreUserStore.mutations, mutations)
}
