import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { FAQState } from '../types';
import actions from './actions'
import mutations from './mutations'

export const FAQStore: Module<FAQState, RootState> = {
  namespaced: true,
  state: () => ({
    questions: []
  }),
  actions,
  mutations
}
