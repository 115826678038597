let parser = null

const getParser = async (data: string) => {
  try {
    const parserImport = await import('destr')

    parser = parserImport.default || JSON.parse
  } catch (e) {
    parser = JSON.parse
  }

  return parser(data)
}

export const jsonParse = async (data: string) => {
  if (!parser) return getParser(data)

  return parser(data)
}

export const jsonParseResponse = async (res) => {
  const text = await res.text()

  return jsonParse(text)
}
