<template>
  <SfOCarousel
    :key="banners.length"
    class="slider-carousel"
    :settings="sliderSettings"
    ref="slider"
    :is-show-bullets="true"
    :is-show-arrows="false"
  >
    <SfCarouselItem
      v-for="banner in bannerItems"
      :key="banner.id"
      :style="itemStyles(banner)"
    >
      <MBannerInform
        :banner="banner"
        :is-responsive="isResponsive"
      />
    </SfCarouselItem>
  </SfOCarousel>
</template>

<script>
import SfOCarousel from 'theme/components/storefront-override/SfOCarousel';
import DeviceType from 'theme/mixins/DeviceType';
import MBannerInform from 'theme/components/molecules/m-banner-inform';
import { getThumbnailPath } from '@vue-storefront/core/helpers';

export default {
  name: 'MBannerCarousel',
  components: {
    SfOCarousel,
    MBannerInform
  },
  mixins: [
    DeviceType
  ],
  props: {
    banners: {
      type: Array,
      default: () => []
    },
    isCustomHeight: {
      type: Boolean,
      default: () => false
    },
    isResponsive: {
      type: Boolean,
      default: () => false
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          dragThreshold: false,
          perView: 1
        };
      }
    }
  },
  data () {
    return {
      sliderData: {
        currentPage: 1,
        disablePrev: true,
        disableNext: false
      }
    };
  },
  computed: {
    bannerItems () {
      this.banners.forEach(e => {
        e.image = {
          mobile: getThumbnailPath(e.image_mobile),
          desktop: getThumbnailPath(e.image_desktop)
        }
      })

      return this.banners
    },
    bannerHeight () {
      if (this.isDesktop) return null

      const bannerWidth = window.innerWidth - 40
      /**
       * mobile banner aspect ratio is 335px/248px = 1.3508
       **/
      return bannerWidth / 1.3508
    }
  },
  methods: {
    itemStyles (banner) {
      const styles = { order: banner.sort_order }

      if (this.isCustomHeight && this.bannerHeight) styles.height = this.bannerHeight + 'px'

      return styles
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'theme/css/breakpoints';

.glide__slide {
  flex-shrink: initial;
}

.product-carousel {
  ::v-deep {
    .sf-carousel__controls {
      display: none;
    }

    .sf-product-card__wrapper {
      height: 100%;
    }

    .sf-carousel-item {
      height: auto;

      .sf-product-card {
        height: 100%;
      }
      .sf-price__special {
        .sf-product-card__price {
          overflow-x: hidden;
        }
        @include for-mobile {
          margin-bottom: 2px;
        }
      }
    }

    .glide__track {
      overflow: hidden;

      @include for-mobile {
        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    .glide__slides,
    .glide__slide {
      @include for-mobile {

        &::-webkit-scrollbar {
          display: none;
        }

        @-moz-document url-prefix() {
          scrollbar-width: none;
        }
      }
    }

    /* Skeleton part */
    .glide:not(.glide--slider) .glide__slides {
      @include only-mobile {
        padding-right: var(--spacer-10);
        width: 135%; // to show 2.3 items
      }
    }

    .glide:not(.glide--slider) .glide__slide {
      &:first-child {
        margin-right: var(--spacer-5);
      }

      @include only-mobile {
        &:nth-child(2) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(3) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+3)) {
          display: none;
        }
      }

      @include for-tablet {
        &:nth-child(2), &:nth-child(3) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(4) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+4)) {
          display: none;
        }
      }

      @media (min-width: $desktop-min) and (max-width: $desktop-l-min) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          margin-inline: var(--spacer-5);
        }

        &:nth-child(5) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+5)) {
          display: none;
        }
      }

      @media (min-width: $desktop-l-min) {
        &:nth-child(n+2):nth-child(-n+5) { // nth from 2 to 5
          margin-inline: var(--spacer-5);
        }

        &:nth-child(6) {
          margin-left: var(--spacer-5);
        }

        &:not(:nth-child(-n+6)) {
          display: none;
        }
      }
    }
  }
}
</style>
