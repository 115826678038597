import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import PromotionState from '../types/PromotionState';

const mutations: MutationTree<PromotionState> = {
  [types.SET_BRANDS_MAIN_COLLECTION] (state, brands) {
    state.brandsMain = brands || []
  },
  [types.SET_PROMOTION_CATEGORY_COLLECTION] (state, category) {
    state.promotionCategory = category || []
  },
  [types.SET_PROMOTIONS] (state, promotions) {
    state.promotions = promotions || []
  },
  [types.UPDATE_PROMOTIONS] (state, promotions) {
    state.promotions = [...state.promotions, ...promotions];
  },
  [types.SET_PROMOTION_METADATA] (state, metadata) {
    state.promotionMetaData = metadata || {}
  },
  [types.SET_PROMOTION_CURRENT_CATEGORY_COLLECTION] (state, currentCategory) {
    state.promotionCurrentCategory = currentCategory || []
  },
  [types.SET_PROMOTION_CURRENT_TYPE_COLLECTION] (state, currentType) {
    state.promotionCurrentType = currentType || []
  },
  [types.SET_CURRENT_PAGE] (state, page) {
    state.page = page || []
  },
  [types.SET_LOADER] (state, value) {
    state.promotionLoader = value
  },
  [types.SET_PROMOTION_PRODUCTS_TOTAL] (state, total) {
    state.promotionProductsTotal = total
  },
  [types.SET_PRODUCTS_IS_LOADING] (state, isLoading) {
    state.productsIsLoading = isLoading
  },
  [types.SET_SEARCH_PRODUCTS_STATS] (state, stats = {}) {
    state.searchProductsStats = stats
  },
  [types.SET_IS_ADDITIONAL_PROMOTIONS_LOADING] (state, isLoading) {
    state.isAdditionalPromotionsLoading = isLoading
  },
  [types.SET_IS_ADDITIONAL_PROMOTION_PRODUCTS_LOADING] (state, isLoading) {
    state.isAdditionalPromotionProductsLoading = isLoading
  },
  [types.AVAILABLE_PRODUCTS] (state, available) {
    state.availableProducts = available;
  }
}

export default mutations;
