export const SN_WISHLIST = 'wishlist'
export const WISHLIST_ADD_GROUP = SN_WISHLIST + '/ADD_GROUP'
export const WISHLIST_DELETE_GROUP = SN_WISHLIST + '/DELETE_GROUP'
export const WISHLIST_SAVE_GROUP = SN_WISHLIST + '/SAVE_GROUP'
export const WISHLIST_GROUP_ADD_ITEMS = SN_WISHLIST + '/GROUP_ADD_ITEMS'
export const SET_MODE_CREATE_NEW_GROUP = SN_WISHLIST + '/MODE_CREATE_NEW_GROUP'
export const SET_WISHLISTS = SN_WISHLIST + '/SET_WISHLISTS'
export const CLEAR_WISHLIST = SN_WISHLIST + '/CLEAR_WISHLIST'
export const CLEAR_WISHLIST_ITEMS = SN_WISHLIST + '/CLEAR_WISHLIST_ITEMS'
export const ADD_WISHLIST = SN_WISHLIST + '/ADD_WISHLIST'
export const UPDATE_WISHLIST = SN_WISHLIST + '/UPDATE_WISHLIST'
export const SET_WISHLISTS_ITEMS = SN_WISHLIST + '/SET_WISHLISTS_ITEMS'
