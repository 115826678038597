let glide = null

const initGlide = function () {
  if (glide) return glide

  return import('@glidejs/glide')
    .then(m => {
      glide = m.default
      return glide
    })
}

export default initGlide
