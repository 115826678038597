export function transliteration (string = '') {
  string = string
    .replace(/\u043E\u0440\u0441\u044C\u043A/g, 'orsk')
    .replace(/\u0027\u044F\u043D\u0441\u044C\u043A\u0435/g, 'ianske')
    .replace(/\u0401/g, 'Yo')
    .replace(/\u0419/g, 'I')
    .replace(/\u0426/g, 'Ts') // Ц
    .replace(/\u0423/g, 'U')
    .replace(/\u041A/g, 'K')
    .replace(/\u0415/g, 'E')
    .replace(/\u041D/g, 'N')
    .replace(/\u0413/g, 'G')
    .replace(/\u0428/g, 'Sh')
    .replace(/\u0429/g, 'Sch')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0425/g, 'H')
    .replace(/\u042A/g, '')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0439/g, 'i')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0443/g, 'u')
    .replace(/\u043A/g, 'k')
    .replace(/\u0435/g, 'e')
    .replace(/\u043D/g, 'n')
    .replace(/\u0433/g, 'h') // г
    .replace(/\u0448/g, 'sh')
    .replace(/\u0449/g, 'sch')
    .replace(/\u0437/g, 'z')
    .replace(/\u0445/g, 'h')
    .replace(/\u044A/g, "'") // ъ
    .replace(/\u0424/g, 'F')
    .replace(/\u042B/g, 'I')
    .replace(/\u0412/g, 'V')
    .replace(/\u0410/g, 'a')
    .replace(/\u041F/g, 'P')
    .replace(/\u0420/g, 'R')
    .replace(/\u041E/g, 'O')
    .replace(/\u041B/g, 'L')
    .replace(/\u0414/g, 'D')
    .replace(/\u0416/g, 'Zh')
    .replace(/\u042D/g, 'E')
    .replace(/\u0444/g, 'f')
    .replace(/\u044B/g, 'i')
    .replace(/\u0432/g, 'v')
    .replace(/\u0430/g, 'a')
    .replace(/\u043F/g, 'p')
    .replace(/\u0440/g, 'r')
    .replace(/\u043E/g, 'o')
    .replace(/\u043B/g, 'l')
    .replace(/\u0434/g, 'd')
    .replace(/\u0436/g, 'zh')
    .replace(/\u044D/g, 'e')
    .replace(/\u042F/g, 'Ya')
    .replace(/\u0427/g, 'Ch')
    .replace(/\u0421/g, 'S')
    .replace(/\u041C/g, 'M')
    .replace(/\u0418/g, 'I')
    .replace(/\u0422/g, 'T')
    .replace(/\u042C/g, "'") // Ь
    .replace(/\u0411/g, 'B')
    .replace(/\u042E/g, 'Yu')
    .replace(/\u044F/g, 'ia') // я
    .replace(/\u0447/g, 'ch')
    .replace(/\u0441/g, 's')
    .replace(/\u043C/g, 'm')
    .replace(/\u0438/g, 'y') // и
    .replace(/\u0442/g, 't')
    .replace(/\u044C/g, "'") // ь
    .replace(/\u0431/g, 'b')
    .replace(/\u044E/g, 'yu')
    .replace(/\u0456/g, 'i') // i
    .replace(/\u0457/g, 'i') // ї
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const transliterationCyr = (word, skipCheck) => {
  const isValidInput = skipCheck ? true : /^[a-zA-Z0-9]+$/.test(word);

  if (!isValidInput) return word

  const converter = {
    'sch': 'щ',
    'yo': 'ё',
    'zh': 'ж',
    'ch': 'ч',
    'sh': 'ш',
    'yu': 'ю',
    'ya': 'я',
    'a': 'а',
    'b': 'б',
    'v': 'в',
    'g': 'г',
    'd': 'д',
    'e': 'е',
    'z': 'з',
    'и': 'i',
    'y': 'й',
    'k': 'к',
    'l': 'л',
    'm': 'м',
    'n': 'н',
    'o': 'о',
    'p': 'п',
    'r': 'р',
    's': 'с',
    't': 'т',
    'u': 'у',
    'f': 'ф',
    'h': 'х',
    'c': 'ц'
  };

  const keys = Object.keys(converter).join('|');
  const regex = new RegExp(keys, 'gi');

  return word.replace(regex, matched => converter[matched.toLowerCase()]).toUpperCase();
}

export const skipAddress = (address) => (
  /(вул\.|пров\.|ул\.|пер\.|просп\.)/i.test(address)
)
