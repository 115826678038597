export const SM_SET_CITY_LIST = 'sm-set-city-list'
export const SM_SET_SHOP_LIST = 'sm-set-shop-list'
export const SM_SET_DEFAULT_CITY = 'sm-set-default-city'
export const SM_SET_DEFAULT_SHOP = 'sm-set-default-shop'
export const SM_SET_LOADING_SHOP_LIST = 'sm-set-loading-shop-list'
export const SM_SET_LOADED_SHOP_LIST = 'sm-set-loaded-shop-list'
export const SM_SET_LOADING_GEOCODE = 'sm-set-loading-geocode'
export const SM_SET_LOADED_GEOCODE = 'sm-set-loaded-geocode'
export const SM_SET_LOADING_SHIPPING = 'sm-set-loading-shipping'
export const SM_SET_LOADED_SHIPPING = 'sm-set-loaded-shipping'
export const SM_SET_DRAFT_CITY = 'sm-set-draft-city'
export const SM_SET_DRAFT_METHOD = 'sm-set-draft-method'
export const SM_SET_DRAFT_SHOP = 'sm-set-draft-shop'
export const SM_SET_DRAFT_TYPE = 'sm-set-draft-type'
export const SM_SET_DRAFT_TYPE_GROUP = 'sm-set-draft-type-group'
export const SM_SET_DRAFT_ADDRESS = 'sm-set-draft-address'
export const SM_SET_CURRENT_SHIPPING = 'sm-set-current-shipping'
export const SM_SET_DRAFT_SHIPPING = 'sm-set-draft-shipping'
export const SM_SET_DELIVERY_POLYGON = 'sm-set-delivery-polygon'
export const SM_SET_CITY_LIST_FOR_GEOCODE = 'sm-set-city-list-for-geocode'
export const SM_SET_NP_TYPE = 'sm-set-np-type'
export const SM_SET_NP_CENTER = 'sm-set-np-center'
export const SM_SET_NP_CITY_CURRENT = 'sm-set-np-city'
export const SM_SET_NP_CITY_LIST = 'sm-set-np-city-list'
export const SM_SET_NP_SHOP_CURRENT = 'sm-set-np-shop'
export const SM_SET_NP_SHOP_LIST = 'sm-set-np-shop-list'
