import Composite from '@vue-storefront/core/mixins/composite'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { getProductImagePath, getProductPrice } from 'theme/helpers';
import { SIZE_PRODUCT_GALLERY } from 'theme/meta/product';
import { Logger } from '@vue-storefront/core/lib/logger'

export default {
  name: 'GoogleTagManager',
  mixins: [Composite],
  methods: {
    gtmShippingError (payload) {
      window.dataLayer.push({
        event: 'delivery_error',
        delivery: payload
      });
    },
    gtmEventHandler ({ gtmEvent, parameters, eventMode = '' }) {
      let eventData = null

      if (eventMode === 'category') {
        eventData = {
          event: gtmEvent,
          eventCategory: parameters
        }
      }

      if (eventMode === 'params') {
        eventData = {
          event: gtmEvent,
          event_param: parameters
        }
      }

      if (!eventData || !parameters) return

      window.dataLayer.push(eventData)
    },
    gtmEventQuiztHandler (payload = {}) {
      if (!payload.gtmEvent || !payload.eventCategory || !payload.eventAction || !payload.eventLabel) return

      window.dataLayer.push({
        event: payload.gtmEvent,
        eventCategory: payload.eventCategory,
        eventAction: payload.eventAction,
        eventLabel: payload.eventLabel
      })
    },
    gtmEventParamHandler ({ event, eventParam }) {
      window.dataLayer.push({ event, event_param: eventParam })
    },
    gtmProductsHandler (products, gtm_event) {
      const groupedProducts = [...(products instanceof Array ? products : [products])];
      let idx = 0;

      if (groupedProducts.length) {
        const mappedProducts = groupedProducts.map((item) => {
          const categoriesName = (item && item.category && item.category.length) ? item.category.map((category) => category.name) : [];
          const isPromotion = item?.gift?.isPromotion

          const price = item.price || getProductPrice(item)

          return {
            item_name: item.name || item.title,
            item_id: item.sku,
            item_brand: item.brandl || item.brand || '',
            item_category: categoriesName[1] || '',
            item_category2: categoriesName[2] || '',
            item_category3: categoriesName[3] || '',
            item_category4: categoriesName[4] || '',
            item_variant: item.variant || '',
            index: idx++,
            quantity: 1,
            price: isPromotion ? item?.totals?.base_row_total
              : (
                parseFloat(price.special) ||
                parseFloat(price.regular) ||
                item.price
              )
          }
        });
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: gtm_event,
          ecommerce: {
            items: mappedProducts
          }
        })
      }
    },
    gtmProductsHandlerAds (products, gtm_event, needValue = false) {
      const groupedProducts = [...(products instanceof Array ? products : [products])];

      const mappedProductsPrice = groupedProducts.map((item) => {
        const isPromotion = item?.gift?.isPromotion

        const itemPrice = item.price || getProductPrice(item)

        let price = isPromotion ? parseFloat(item?.totals?.base_row_total)
          : (parseFloat(itemPrice.special) || parseFloat(itemPrice.regular) || item.price);
        if (typeof price === 'object') price = 0;
        return price
      })

      const mappedProducts = groupedProducts.map((item) => {
        return {
          id: item.sku,
          google_business_vertical: 'retail'
        }
      });

      if (!needValue) {
        window.dataLayer.push({
          event: gtm_event,
          items: mappedProducts
        });

        return
      }

      let total = mappedProductsPrice.length ? mappedProductsPrice.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }) : 0
      total = Math.floor(total * 100) / 100

      window.dataLayer.push({
        event: gtm_event,
        value: total,
        items: mappedProducts
      });
    },
    gtmBannerHandler (payload, gtm_event) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: gtm_event,
        ecommerce: {
          items: payload
        }
      });
    },
    isThankYouPageGtmAds (order, summary) {
      const mappedProducts = order.products.map((item) => {
        return {
          id: item.sku,
          google_business_vertical: 'retail'
        }
      });
      window.dataLayer.push({
        event: 'purchase_ads',
        value: summary.prices.subtotal,
        items: mappedProducts
      });
    },
    isThankYouPageGtm (order, summary, confirmation) {
      const mappedProducts = order.products.map((item) => {
        const categoriesName = (item && item.category && item.category.length) ? item.category.map((category) => category.name) : [];
        const price = item.price || getProductPrice(item)

        return {
          item_name: item.name || item.title || '',
          item_id: item.sku,
          price: parseFloat(price.regular) || parseFloat(price.regular),
          quantity: item.qty,
          item_category: categoriesName[4] || categoriesName[3] || categoriesName[2] || '',
          item_variant: item.variant || '',
          item_brand: item.brandl || item.brand || ''
        }
      });
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: confirmation.orderNumber,
          value: summary.prices.subtotal,
          tax: summary.prices.tax,
          shipping: summary.prices.shipping,
          currency: 'UAH',
          items: mappedProducts,
          affiliation: 'Online Store',
          coupon: summary.coupon
        }
      });
    },
    clearEvents () {
      window._retag = [];
      delete window.ad_category
      delete window.ad_product
      delete window.ad_products
      delete window.ad_order
      delete window.ad_amount
      delete window._retag_data
    },
    admitadRetagEvent (code, level = null) {
      try {
        window._retag = window._retag || [];
        window._retag.push(level !== null ? { code, level } : { code });
      } catch (e) {}
    },
    admitadHomePage () {
      try {
        this.clearEvents()
        this.admitadRetagEvent('9ce888483b', 0)
      } catch (e) {}
    },
    admitadRetagCategoryPage (categoryId) {
      try {
        this.clearEvents()
        window.ad_category = categoryId; // required
        this.admitadRetagEvent('9ce888483a', 1)
      } catch (e) {}
    },
    admitadRetagProductPage (product) {
      try {
        this.clearEvents()
        const baseUrl = this.$store.getters['siteData/baseUrl'];
        const url = `${baseUrl}${formatProductLink(product, currentStoreView().storeCode)}`;
        const image = getProductImagePath(
          product?.sku,
          SIZE_PRODUCT_GALLERY.desktop.width,
          SIZE_PRODUCT_GALLERY.desktop.height
        );

        const category = (product?.category || [])
          .sort((a, b) => a.position - b.position)

        const price = +((product?.price?.special || product?.price?.regular).replace(' грн', ''))

        window.ad_product = {
          'id': product?.sku, // required
          'vendor': product?.brand_data?.name || '',
          'price': price,
          'url': url,
          'picture': image,
          'name': product?.name,
          'category': category?.[0]?.category_id
        };

        this.admitadRetagEvent('9ce8884839', 2)
      } catch (e) {
        Logger.log('admitadRetagProductPage - Error:' + e)();
      }
    },
    admitadCart (products) {
      try {
        this.clearEvents()
        window.ad_products = products.map(i => ({
          id: i.sku,
          qty: i.qty
        }))

        this.admitadRetagEvent('9ce8884838', 3)
      } catch (e) {}
    },
    admitadThankYouPage (order, summary, confirmation) {
      try {
        this.clearEvents()
        window.ad_order = confirmation?.orderNumber
        window.ad_amount = summary?.prices?.grand_total;

        window.ad_products = (order?.products || []).map(i => ({
          id: i.sku,
          number: i.qty
        }))

        this.admitadRetagEvent('9ce888483f', 4)
      } catch (e) {}
    },
    admitadOrderPage () {
      try {
        this.clearEvents()

        window._retag_data = {
        };

        this.admitadRetagEvent('9ce888483e')
      } catch (e) {}
    }
  }
}
