import { MutationTree } from 'vuex'
import UserState from '../types/UserState'
import * as types from './mutation-types'

const mutations: MutationTree<UserState> = {
  [types.USER_ORDERS_HISTORY_ADD_ORDERS] (state, ordersHistory = {}) {
    let orders = []
    if (ordersHistory.orders) orders = ordersHistory.orders
    state.orders_history.orders.push(...orders)
  },
  [types.USER_LAST_ORDER_LOADED] (state, order = null) {
    state.lastOrder = order
  },
  [types.USER_LAST_ORDER_CLEAR] (state) {
    state.lastOrder = null
  },
  [types.SET_ORDERS_ONLINE] (state, payload) {
    state.ordersOnline = payload
  },
  [types.SET_ORDERS_OFFLINE] (state, payload) {
    state.ordersOffline = payload
  },
  [types.ADD_ORDERS_ONLINE] (state, orders) {
    state.ordersOnline.orders.push(...orders)
  },
  [types.ADD_ORDERS_OFFLINE] (state, orders) {
    state.ordersOffline.orders.push(...orders)
  }
};

export default mutations
