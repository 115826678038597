export const CHECKOUT_CACHE = 'checkout'

// Shipping cache names
export const SHIPPING_CACHE_DELIVERY_COORDINATES = 'shipping-delivery-coordinates'
export const SHIPPING_CACHE_DETAILS = 'shipping-details'

// Personal cache names
export const PERSONAL_CACHE_DETAILS = 'personal-details'

// Payment cache names
export const PAYMENT_CACHE_DETAILS = 'payment-details'
