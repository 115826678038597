import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { createDiffLog } from '@vue-storefront/core/modules/cart/helpers'
import { syncProductDataWithCart } from 'theme/helpers/product';
import { Logger } from '@vue-storefront/core/lib/logger'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const quantityActions = {
  async restoreQuantity ({ dispatch }, { product }) {
    const currentCartItem = await dispatch('getItem', { product })
    if (currentCartItem) {
      Logger.log('Restoring qty after error' + product.sku + currentCartItem.prev_qty, 'cart')()
      if (currentCartItem.prev_qty > 0) {
        await dispatch('updateItem', {
          product: {
            ...product,
            qty: currentCartItem.prev_qty
          }
        })
        EventBus.$emit('cart-after-itemchanged', { item: currentCartItem })
      } else {
        await dispatch('removeItem', { product: currentCartItem, removeByParentSku: false })
      }
    }
  },
  async updateQuantity ({ commit, dispatch, getters }, { product, qty, forceServerSilence = false }) {
    const { cartItem } = syncProductDataWithCart(getters['getCartItems'], product);

    commit(types.CART_UPD_ITEM, { product: cartItem, qty })

    if (getters.isCartSyncEnabled && !forceServerSilence) {
      return dispatch('sync', { forceClientState: true, forceSync: true, skipPull: true });
    }

    return createDiffLog()
      .pushClientParty({ status: 'wrong-qty', sku: cartItem.sku, 'client-qty': qty })
  }
}

export default quantityActions
