<template>
  <SfButton
    @click="openMegaMenu"
    data-transaction-name="MM - Open"
    class="sf-link sf-link--primary a-catalog-button"
    :class="{'show-open-button': isCatalog}"
  >
    <span>{{ $t('Product catalog') }}</span>
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapState } from 'vuex';

export default {
  name: 'ACatalogButton',
  components: {
    SfButton
  },
  methods: {
    openMegaMenu () {
      if (this.isCatalog) {
        this.$store.commit('ui/closeCatalog');
      } else {
        this.$store.commit('ui/openCatalog');
        this.$store.commit('ui/setSearchpanel', false);
      }
    }
  },
  computed: {
    ...mapState({
      isCatalog: state => state.ui.isMobileCatalog
    })
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.app {
  .a-catalog-button {
    outline: none;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    position: relative;
    display: none;

    @include for-desktop {
      display: flex;
    }

    &:hover {
      background-color: var(--orange);
    }

    &.show-open-button,
    &.show-open-button:hover {
      @include for-desktop {
        background: var(--c-primary);
        color: var(--white);
      }
    }

    span {
      font-size: var(--font-lg);
      display: flex;
      align-items: center;
      justify-content: center;

      @include font-icon(var(--icon-catalog));

      &:before {
        font-size: var(--font-size-25);
        color: var(--orange);
        padding-right: var(--spacer-12);
      }

      @media (min-width: $desktop-min) and (max-width: $desktop-l-min) {
        font-size: var(--font-size-15);
      }
    }

    &.sf-link {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-25);

      @media (min-width: $desktop-min) and (max-width: $desktop-l-min) {
        padding-right: var(--spacer-10);
      }
    }
  }
}

</style>
