const getUniqueItems = (items) => {
  return items.reduce((accumulator, item) => {
    if (!accumulator.some(existingItem => existingItem.product_id === item.product_id)) {
      accumulator.push(item);
    }
    return accumulator;
  }, []);
};

const getUniqueItemsIDs = (items) => {
  const uniqueItems = getUniqueItems(items);
  return uniqueItems.map(item => item.product_id)
}

export {
  getUniqueItems,
  getUniqueItemsIDs
};
