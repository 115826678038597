import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder'

export const getFAQForProductCategory = async ({
  size = 10,
  start = 0,
  query = null,
  id = null
}) => {
  if (!id) return;

  const searchQuery = new SearchQuery(query)
  searchQuery.applyFilter(
    {
      key: 'product_category_ids',
      value: {
        'in': [id]
      }
    }
  )
  const response = await quickSearchByQuery({ entityType: 'faq_question', query: searchQuery, size: size, start: start })
  return response.items
}
