<template>
  <div
    class="o-search"
    :class="{'o-search--opened': isSearchPanelVisible}"
    v-click-outside="hideSearch"
  >
    <SfInput
      v-model="search"
      class="sf-search-bar sf-input--filled"
      :label="inputLabel"
      :key="forceUpdate"
      data-transaction-name="Search Field"
      @input="startSearch"
      @keyup.enter="goToSearchPage(search, true)"
      @click.native="openSearchPanel"
    />

    <SfButton
      v-if="isSearchPanelVisible"
      class="cancel-button sf-button--text form__action-button form__action-button--secondary"
      data-transaction-name="Clear Search"
      @click="clearSearch"
    >
      <span class="close-icon" />
    </SfButton>

    <component
      v-if="hasSearchPanelContent"
      :is="searchPanelAsyncComponent"
      :search="search"
      :page-size="size"
      @see-all="goToSearchPage($event)"
      @close="$store.commit('ui/setSearchpanel', false)"
      @reload="reloadComponent()"
      @set-search="goToSearchPage($event)"
      @set-search-correction="setSearchCorrection"
    />
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import SearchPanelMixin from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import ALoadingError from 'theme/components/atoms/a-loading-error';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import DeviceType from 'theme/mixins/DeviceType';
import SfInput from 'theme/components/storefront-override/SfInput';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import Multisearch from 'theme/mixins/Multisearch';

const SearchPanel = () =>
  import(/* webpackChunkName: "vsf-search-panel" */ 'theme/components/organisms/o-search-panel');

export default {
  name: 'OSearch',
  components: {
    SfInput,
    SfButton
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      search: '',
      forceUpdate: 0,
      size: 3,
      searchPanelAsyncComponent: () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 30000
      })
    }
  },
  mixins: [SearchPanelMixin, DeviceType, Multisearch],
  watch: {
    $route: {
      handler (to, from) {
        if (
          (from.name === 'search' || from.name === 'ru-search') &&
          (to.name !== 'search' || to.name !== 'ru-search')
        ) {
          this.search = ''
        }
      }
    },
    isSearchPanelVisible (newValue, oldValue) {
      if (!this.isDesktop && newValue && !oldValue) {
        this.startSearch()
      }
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    ...mapGetters({
      getSearchHistory: 'userInfo/getSearchHistory'
    }),
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel
    }),
    inputLabel () {
      if (this.search.length !== 0) {
        return '';
      }

      return this.showPanel
        ? this.$t('Product Name')
        : this.$t('Searching in VARUS...')
    },
    hasSearchPanelContent () {
      if (!this.isDesktop) {
        return this.isSearchPanelVisible;
      }

      return this.isSearchPanelVisible && (
        this.getSearchHistory.length || this.search.length
      );
    }
  },
  methods: {
    ...mapActions({
      setCategory: 'search-result/setCategory',
      saveSearchHistory: 'userInfo/saveSearchHistory',
      loadSearchHistory: 'userInfo/loadSearchHistory'
    }),
    reloadComponent () {
      this.searchPanelAsyncComponent = () => ({
        component: SearchPanel(),
        loading: ALoadingSpinner,
        error: ALoadingError,
        timeout: 30000
      });
    },
    async goToSearchPage (search = '', onEnter = false) {
      if (search.length && search !== this.search) {
        this.search = search
      }

      if (onEnter) {
        this.unfocusInput()
      }

      await this.setCategory(null)
      this.saveSearchHistory(this.search)
      this.$store.commit('ui/setSearchpanel', false)
      await this.$router.push(localizedRoute({ name: 'search', query: { q: this.search } }))
      ++this.forceUpdate
    },
    unfocusInput () {
      setTimeout(() => {
        if (document.activeElement !== document.body) {
          const element = document.activeElement

          element?.blur()
          element?.parentElement?.focus()
        }
      }, 0)
    },
    async startSearch () {
      if (this.search.length === 0) {
        this.clearSearch(false)
      }

      return this.autocomplete(this.search)
    },
    setSearchCorrection (search) {
      this.search = search
      this.autocompleteCorrectionClear()
    },
    clearSearch (hide = true) {
      this.autocompleteClear()

      if (this.search.length) {
        this.search = ''
      } else if (hide) {
        this.$store.commit('ui/setSearchpanel', false)
      }
    },
    hideSearch () {
      if (this.isMobile) return
      this.$store.commit('ui/setSearchpanel', false)
    },
    openSearchPanel () {
      this.$store.commit('ui/setSearchpanel', true)

      if (this.search.length) {
        this.startSearch()
      }
    }
  },
  mounted () {
    this.loadSearchHistory()
    this.search = localStorage.getItem(`shop/user/searchQuery`) || this.$route.query.q || ''
  }
};
</script>
<style lang="scss" scoped>
.o-search {
  position: relative;

  &--opened {
    .sf-search-bar {
      width: 100%;
    }
  }

  .sf-search-bar {
    width: 100%;
    height: auto;
    margin: 0;

    ::v-deep input {
      height: 59.5px;
      padding-top: 1.219rem;
      padding-bottom: 1.219rem;

      &:focus ~ label {
        --input-label-left: var(--spacer-sm);
        --input-label-font-size: var(--font-size-15);
        --input-label-top: 50%;
      }
    }
    ::v-deep .sf-input__error-message {
      display: none;
    }
  }

  .cancel-button {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    width: 50px;
    height: 50px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    .close-icon {
      &:before {
        font-size: var(--font-size-20);
        color: var(--black);
      }
    }

    &:active {
      --button-background: none!important;
    }
  }
}

::v-deep {
  .sf-input__wrapper {
    margin: 0;
  }
}
</style>
