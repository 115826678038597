import { getCookieByName } from 'theme/helpers/cookie';
import { Module } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import createCategoryListQuery from '@vue-storefront/core/modules/catalog/helpers/createCategoryListQuery'
import config from 'config'
import modulesConfig from '$modules/config'
import { CATEGORY_SET_RESULT_CATEGORIES } from 'theme/store/category-extension/store/mutation-types';
import RootState from '@vue-storefront/core/types/RootState'
import { SearchQuery } from 'storefront-query-builder';
import BrandsState from '../types/BrandsState';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { BrandService } from '../data-resolver/BrandService';
import { jsonParseResponse } from '@vue-storefront/core/helpers/jsonParse';

export const BrandsStore: Module<BrandsState, RootState> = {
  namespaced: true,
  state: () => ({
    brands: [],
    category: {},
    categories: [],
    brandGroups: [],
    promotionBrandsList: [],
    brandGroupsAlphabet: [],
    brandsList: []
  }),
  actions: {
    async setCategoryBrands ({ commit, dispatch }, categoryIds) {
      let categoryIdsArray = []
      categoryIds.forEach(item => item?.brand_category ? categoryIdsArray.push(...item.brand_category) : [])
      categoryIdsArray = [...new Set(categoryIdsArray)]

      const categoryArray = await dispatch('category-extension/getCategoriesByChunk', { categoryIdsArray }, { root: true })

      const categories = categoryArray.map(category => {
        const brand_data = []
        categoryIds.forEach(categoryId => {
          if (categoryId?.brand_category && categoryId.brand_category.includes(category.id + '')) {
            brand_data.push({
              name: categoryId.name,
              id: categoryId.id
            })
          }
        })

        return {
          ...category,
          brand_data
        }
      }).filter(cat => cat.id !== 14779 && cat.id !== 2 && cat.id !== 470 && cat.parent_id !== 470)

      commit(`category-extension/${CATEGORY_SET_RESULT_CATEGORIES}`, categories, { root: true })
      commit('SET_BRANDS_CATEGORIES', categories)
    },
    async fetchBrandsCategoryList ({ dispatch }) {
      const parent = parseInt(await dispatch('config-varus/get', { path: 'categories_map_brand_category_id' }, { root: true }))
      const level = config.entities.category.categoriesDynamicPrefetch &&
      config.entities.category.categoriesDynamicPrefetchLevel >= 0
        ? config.entities.category.categoriesDynamicPrefetchLevel
        : null

      const includeFields = config.entities.optimize && isServer
        ? config.entities.category.includeFields
        : null

      const excludeFields = config.entities.optimize ? config.entities.category.excludeFields : null

      const { searchQuery } = createCategoryListQuery({
        parent,
        level,
        key: null,
        value: null,
        onlyActive: true,
        onlyNotEmpty: false
      })

      const { items } = await quickSearchByQuery({
        entityType: 'category',
        query: searchQuery,
        sort: 'position:asc',
        size: 4000,
        start: 0,
        includeFields,
        excludeFields
      })

      await dispatch('setCategoryBrands', items)

      return items
    },
    async getBrandsCategory ({ commit, dispatch }) {
      const brandCategoryId = await dispatch('config-varus/get', { path: 'categories_map_brand_category_id' }, { root: true })
      const filters = { id: brandCategoryId }
      const categories = await BrandService.getCategories({ filters })
      commit('SET_BRANDS_CATEGORY', categories[0])
      return categories[0]
    },
    async fetchBrandsCollection ({ state, commit }) {
      if (state.brands.length) return;

      const brandCategory = state.category
      const brands = await BrandService.getCategories({ parentId: brandCategory.id, size: brandCategory.children_count, onlyNotEmpty: true })
      const brandGroups = {}
      const brandGroupsAlphabet = {
        other: {
          brandsByLetter: {}
        },
        latin: {
          pattern: /[a-z]/,
          brandsByLetter: {}
        },
        cyrillic: {
          pattern: /^[\u0400-\u04FF]+$/,
          brandsByLetter: {}
        },
        numbers: {
          pattern: /[0-9]/,
          brandsByLetter: {}
        }
      }
      brands.forEach(brand => {
        let firstLetter = brand.name.slice(0, 1).toLowerCase();
        let group: string;

        if (brandGroupsAlphabet.latin.pattern.test(firstLetter)) {
          group = 'latin';
        } else if (brandGroupsAlphabet.cyrillic.pattern.test(firstLetter)) {
          group = 'cyrillic';
        } else if (brandGroupsAlphabet.numbers.pattern.test(firstLetter)) {
          group = 'numbers';
          firstLetter = '0-9';
        } else {
          group = 'other';
        }

        brandGroupsAlphabet[group].brandsByLetter[firstLetter]
          ? brandGroupsAlphabet[group].brandsByLetter[firstLetter].push(brand)
          : brandGroupsAlphabet[group].brandsByLetter[firstLetter] = [brand]

        brandGroups[firstLetter]
          ? brandGroups[firstLetter].push(brand)
          : brandGroups[firstLetter] = [brand]
      });

      commit('SET_BRANDS_COLLECTION', brands)
      commit('SET_BRANDS_GROUP_COLLECTION', brandGroups)
      commit('SET_BRANDS_GROUP_ALPHABET_COLLECTION', brandGroupsAlphabet)
    },
    async fetchBrandsList ({ commit }, {
      size = config?.entities?.product?.carouselSize
    }) {
      const query = new SearchQuery()

      query.applyFilter({ key: 'display_on_main_brand', value: { 'eq': 1 }, scope: 'default' })
      const resp = await quickSearchByQuery({
        entityType: 'category',
        query: query,
        excludeFields: modulesConfig.brandList.excludeFields,
        includeFields: modulesConfig.brandList.includeFields,
        size
      })

      commit('SET_BRANDS_LIST', resp)
    },
    async fetchPromotionBrandsList ({ commit }) {
      const query = new SearchQuery()
      query.applyFilter({ key: 'promotion_products_count', value: { 'gt': 0 }, scope: 'default' })
      query.applySort({ field: 'promotion_position', options: { order: 'asc' } })
      query.applySort({ field: 'promotion_products_count', options: { order: 'desc' } })
      const resp = await quickSearchByQuery({ entityType: 'brand', query: query, size: 8 })
      commit('SET_PROMOTION_BRANDS_LIST', resp)
    },
    async fetchBrandPageProduct (ctx, payload) {
      const { currentUrl, blockId } = payload
      const categoryField = 'tags_category_url'
      const cookie = getCookieByName('sc')

      try {
        const response = await BrandService.getBrandPageProduct({
          blockId,
          cookie,
          category: currentUrl,
          categoryField
        })

        if (response?.status === 200) {
          // const result = await response.json()
          const result = await jsonParseResponse(response)
          return result?.data?.recommendations?.products?.products || []
        } else {
          return null
        }
      } catch (e) {
        console.log(e)
        return null
      }
    }
  },
  mutations: {
    SET_BRANDS_CATEGORIES (state, categories) {
      state.categories = categories || []
    },
    SET_BRANDS_CATEGORY (state, category) {
      state.category = category || []
    },
    SET_BRANDS_COLLECTION (state, brands) {
      state.brands = brands || []
    },
    SET_BRANDS_GROUP_COLLECTION (state, brandGroups) {
      state.brandGroups = brandGroups || []
    },
    SET_BRANDS_GROUP_ALPHABET_COLLECTION (state, brandGroupsAlphabet) {
      state.brandGroupsAlphabet = brandGroupsAlphabet || []
    },
    SET_BRANDS_LIST (state, brandsList) {
      state.brandsList = brandsList?.items || []
    },
    SET_PROMOTION_BRANDS_LIST (state, brandsList) {
      state.promotionBrandsList = brandsList?.items || []
    }
  }
}
