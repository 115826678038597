import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { StateDiff, StateDiffItem } from 'theme/store/cart/types/CartState';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';

export const productPricesStateDefaults = {
  valid: true,
  totalDiffPrice: 0,
  diff: [],
  isShowDiff: false
}

const storage = StorageManager.get('cart')

const storageKey = 'productPricesState'

export class ProductPricesState {
  private diffState: StateDiff = productPricesStateDefaults

  private newDiff: StateDiffItem[] = []

  private replacements = []

  public constructor (prevState: StateDiff, replacements) {
    if (prevState && prevState.diff) {
      this.diffState = prevState
    }

    this.replacements = replacements
  }

  public async loadFromStorage () {
    const fromStorage = await storage.getItem(storageKey)

    this.diffState = fromStorage || this.diffState

    return this
  }

  private checkPriceNotChanged (sku, productPrice, qty, extension_attributes) {
    const toEqual = {
      sku,
      extension_attributes
    } as unknown as CartItem

    const findDiff = this.diffState.diff.find(i => (
      isSkuEqual(toEqual, i as unknown as CartItem)
    )) as unknown as StateDiffItem

    const productPriceQty = productPrice * qty

    if (!findDiff) {
      return false
    }

    if (findDiff.price === productPriceQty) {
      this.newDiff.push(findDiff)

      return true
    }

    return false
  }

  public checkItem = (cartPrice, serverPrice, qty, sku, extension_attributes) => {
    const diffPrice = serverPrice - cartPrice

    const isOutOfStock = !this.diffState.valid && this.replacements.includes(sku)

    if (isOutOfStock) {
      return null
    }

    const isPriceNotChanged = this.checkPriceNotChanged(
      Number(sku),
      serverPrice,
      qty,
      extension_attributes
    )

    if (diffPrice <= 0 || isNaN(diffPrice) || isPriceNotChanged) {
      return null
    }

    return this.newDiff.push({
      sku: Number(sku),
      price: serverPrice * qty,
      diff: diffPrice * qty,
      extension_attributes
    })
  }

  public async result (): Promise<StateDiff> {
    const totalDiffPrice = this.newDiff.reduce((a, b) => (a + b.diff), 0)

    const stateDiff = {
      valid: !this.newDiff.length,
      totalDiffPrice,
      diff: this.newDiff,
      isShowDiff: false
    }

    await storage.setItem(storageKey, stateDiff)

    return stateDiff
  }

  public static clearStorage () {
    try {
      return storage.removeItem(storageKey)
    } catch (e) {}
  }
}
