const template = (data) => `(function (k, w, i, z, b, o, t) {k['KwizbotWidget'] = b; k[b] = k[b] || function () { w.head.insertAdjacentHTML('beforeend', '<style type="text/css">.showAfterCssLoaded{display: none}</style>'); (k[b].q = k[b].q || []).push(arguments) }, k[b].l = 1 * new Date(); o = w.createElement(i), t = w.getElementsByTagName(i)[0]; o.async = 1; o.src = z; t.parentNode.insertBefore(o, t) })(window, document, 'script', 'https://widget-kwizbot.varus.ua/kwjs.js', 'kw');
kw('init', ${JSON.stringify(data)}) `

export default {
  name: 'ChatWidget',
  data () {
    return {
      initialized: false,
      config: {
        'language_code': 'en',
        'bot_id': '9',
        'params_from_site': {},
        'widgetButton': {
          'type': 'multi',
          'position': {
            'y': 'bottom',
            'x': 'right',
            'offset': 25,
            'offset_y': null,
            'offset_x': null
          }
        },
        'multi_channel': {
          'button_title': '',
          'messengers': {
            'viber': 'viber://pa?chatURI=varusbot',
            'telegram': 'https://t.me/varusua_bot'
            // 'facebook': 'https://m.me/varusua',
            // 'whatsapp': '',
            // 'instagram': ''
          }
        },
        'launcher': {
          'type': 'image',
          'staticImg': {
            'src': '/assets/support/support-logo.png',
            'fit': 'unset'
          }
        },
        'colors': {
          'device': {
            'bg': 'linear-gradient(130deg, #734FE1, #D14EE0)',
            'dot': '#B8A6EE',
            'border': '#E4E4E4',
            'container': 'linear-gradient(130deg, #594DA0, #7D5BAA)'
          },
          'keyboardButton': {
            'border': '#BB0044',
            'bg': '#BB0044',
            'text': '#FFFFFF'
          },
          'header': {
            'bg': 'linear-gradient(130deg, #734FE1, #D14EE0)',
            'dot': '#B8A6EE',
            'text': '#ffffff'
          },
          'poweredLink': {
            'color': '#FFFFFF'
          },
          'userInput': {
            'bg': 'linear-gradient(130deg, #594DA0, #7D5BAA)',
            'border': 'transparent',
            'text': '#FFFFFF'
          },
          'sentMessage': {
            'bg': '#4e8cff',
            'text': '#ffffff'
          },
          'receivedMessage': {
            'bg': '#eaeaea',
            'text': '#222222'
          },
          'inlineKeyboardButton': {
            'bg': '#4e8cff',
            'text': '#4e8cff',
            'bgc': '#ffffff'
          },
          'messageList': {
            'bg': 'transparent'
          }
        },
        'displayMode': 'widget',
        'subtitle': '',
        'title': '',
        'open_on_msg': false,
        'btn_restart_enabled': false
      }
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    initialization () {
      this.initialized = !!document.querySelector('#kwizbot_widget')

      const div = document.createElement('div')
      div.id = 'kwizbot_widget'

      document.body.appendChild(div)

      const script = document.createElement('script')
      script.setAttribute('defer', '1')
      script.innerHTML = template(this.config)

      document.body.appendChild(script)
    }
  }
}
