import { mapActions, mapState } from 'vuex';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';
import { emitTabEvent } from 'theme/store/cart/helpers/tabEvent';

const TIMER_STORE = 'timer/store'

export default {
  name: 'SmsTimer',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState({
      waitSmsSeconds: state => state.ui.waitSmsSeconds,
      canSendSms: state => state.ui.canSendSms
    })
  },
  methods: {
    ...mapActions({
      setCanSendSms: 'ui/setCanSendSms',
      setWaitSmsSeconds: 'ui/setWaitSmsSeconds'
    }),

    waitBeforeSendSms (initTime = null) {
      if (isServer) return
      if (!this.canSendSms) return
      clearInterval(this.timer)
      this.setCanSendSms(false)
      const waitBeforeSendSms = initTime || (config.sms ? config.sms.waitBeforeSendSms || 60 : 60)
      this.setWaitSmsSeconds(waitBeforeSendSms)

      const date = Date.now() + (waitBeforeSendSms * 1000)

      localStorage.setItem(TIMER_STORE, date.toString())

      emitTabEvent(waitBeforeSendSms, 'smsSent')

      this.timer = setInterval(() => {
        if (this.waitSmsSeconds > 1) {
          this.setWaitSmsSeconds(this.waitSmsSeconds - 1)
        } else {
          clearInterval(this.timer)
          this.setCanSendSms(true)
          localStorage.removeItem(TIMER_STORE)
        }
      }, 1000)
    },
    checkRunner () {
      const timer = localStorage.getItem(TIMER_STORE)

      if (!timer) return

      const seconds = +((+timer - Date.now()) / 1000).toFixed(0)

      if (seconds <= 0) {
        localStorage.removeItem(TIMER_STORE)
        return
      }

      this.waitBeforeSendSms(seconds)
    }
  },
  mounted: function () {
    this.$bus.$on('wait-before-send-sms', this.waitBeforeSendSms)
    this.checkRunner()
  },
  beforeDestroy () {
    this.$bus.$off('wait-before-send-sms', this.waitBeforeSendSms)
    clearInterval(this.timer)
  }
}
