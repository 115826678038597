import { RobotsEnum } from '../types';

export const relRobotsHandler = (robots: string, xEnv: string) => {
  if (xEnv === 'prod') {
    return {
      robots: {
        name: 'robots',
        content: robots
      }
    }
  }

  return {
    robots: {
      name: 'robots',
      content: RobotsEnum.NOINDEXNOFOLLOW
    },
    dbgRobots: {
      name: 'dbg:robots',
      content: `dbg-${robots}-${xEnv}`
    }
  }
}
