<template>
  <SfButton
      class="sf-button--pure sf-color"
      :class="{ 'sf-color--active': selected }"
      :style="style"
      :aria-pressed="selected.toString()"
      v-on="$listeners"
  >
    <transition name="sf-color__badge">
      <!-- @slot Use it to replace badge to custom element -->
      <slot name="badge" v-bind="{ selected, hasBadge }">
        <SfBadge
            v-if="selected && hasBadge"
            class="sf-color__badge mobile-only"
        >
          <SfIcon aria-hidden="true" icon="check" size="8px" color="white" />
        </SfBadge>
      </slot>
    </transition>
  </SfButton>
</template>
<script>
import SfBadge from '$ui/components/atoms/SfBadge/SfBadge.vue';
import SfIcon from '$ui/components/atoms/SfIcon/SfIcon.vue';
import SfButton from '$ui/components/atoms/SfButton/SfButton.vue';
export default {
  name: 'SfColor',
  components: {
    SfBadge,
    SfIcon,
    SfButton
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    hasBadge: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style () {
      return { '--color-background': this.color };
    }
  }
};
</script>
<style lang="scss">
@import "$ui/styles/components/atoms/SfColor.scss";
</style>
