import { productsEquals } from '@vue-storefront/core/modules/cart/helpers';
import i18n from '@vue-storefront/core/i18n';
import { transformToNumber } from 'theme/helpers';
import { groups, sendToLogs } from 'theme/helpers/web-logging';

interface SyncProductDataWithCart {
  cartItem: Record<string, unknown>
}

export const syncProductDataWithCart = (cartItems: [], product): SyncProductDataWithCart => {
  if (!cartItems && cartItems.length === 0) return { cartItem: product };

  const cartItem = cartItems.find(item => productsEquals(item, product));
  if (!cartItem) return { cartItem: product };
  const mergedProduct = Object.assign({}, product, cartItem);

  return { cartItem: mergedProduct };
}

const SINGLE_ITEM = 1;
const GRAM_PER_KG = 1000;

export const getProductType = (product) => {
  const isVolume = product.volume !== '0' ? !Number.isNaN(parseFloat(product.volume)) : false;
  const packing = !!product.packingtype

  if (isVolume) return { type: 'l', packing }

  const isWeight = product.weight && product.weight !== '-';

  if (isWeight || packing) return { type: 'kg', packing }

  return { type: 'pc', packing }
}

const getProductPackingQty = (type) => {
  const unit = i18n.t(type);

  return i18n.t('for {qty} {unit}', { qty: SINGLE_ITEM, unit: unit })
}

interface GetProductQtyFormattedArgs {
  mass: number,
  smallMeasure: 'g' | 'ml',
  bigMeasure: 'kg' | 'l',
  onlyMeasure: boolean,
  withoutBrackets: boolean
}

const getProductQtyFormatted = ({ mass, bigMeasure, smallMeasure, onlyMeasure, withoutBrackets }: GetProductQtyFormattedArgs) => {
  let divider = 1;
  let measure: string = smallMeasure;

  if (mass >= GRAM_PER_KG) {
    divider = GRAM_PER_KG;
    measure = bigMeasure;
  }

  const value = mass / divider;
  const forQtyUnit = i18n.t('for {qty} {unit}', { qty: SINGLE_ITEM, unit: i18n.t('pc') });
  const valueMeasure = withoutBrackets ? i18n.t('{value} {measure}', { value, measure: i18n.t(measure) }) : i18n.t('({value} {measure})', { value, measure: i18n.t(measure) })

  if (onlyMeasure) {
    return `${valueMeasure}`;
  } else {
    return `${forQtyUnit} ${valueMeasure}`;
  }
}

export const getProductQuantity = (product, allowEmpty = false, onlyMeasure = false) => {
  const { type, packing } = getProductType(product)

  if (packing) return getProductPackingQty(type);

  if (type === 'kg') {
    return getProductQtyFormatted({
      mass: product.weight,
      bigMeasure: 'kg',
      smallMeasure: 'g',
      onlyMeasure,
      withoutBrackets: false
    });
  }

  if (type === 'l') {
    return getProductQtyFormatted({
      mass: product.volume,
      bigMeasure: 'l',
      smallMeasure: 'ml',
      onlyMeasure,
      withoutBrackets: false
    });
  }

  if (allowEmpty) return ''

  const label = i18n.t(type)

  return i18n.t('for {qty} {unit}', { qty: SINGLE_ITEM, unit: label });
}

export const getProductWeight = (product) => {
  try {
    const { weight, volume, wghweigh, packingtype } = product;

    if (packingtype) return GRAM_PER_KG;
    const allWeightValues = [weight, volume, wghweigh].map(transformToNumber);

    return Math.max(...allWeightValues);
  } catch (e) {
    sendToLogs(
      groups.Other,
      'calculate:product:weight',
      { error: e?.message, product }
    )

    return 0
  }
}

export const aggregationRemap = (payload) => {
  const aggregations = {
    ...payload,
    agg_price: {
      filter: {
        price_max: {
          value: payload?.price_max?.value || null
        },
        price_min: {
          value: payload?.price_min?.value || null
        }
      }
    }
  }

  delete aggregations?.price_max
  delete aggregations?.price_min

  return aggregations
}
