<template>
  <transition :name="transition">
    <div
        v-if="visible"
        ref="overlay"
        class="sf-overlay"
        :class="[staticClass, className]"
        @click="$emit('click')"
    ></div>
  </transition>
</template>
<script>
export default {
  name: 'SfOOverlay',
  props: {
    /**
     * Transition effect to apply when overlay visibility is changed
     */
    transition: {
      type: String,
      default: 'fade'
    },
    /**
     * Visibility state
     */
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    staticClass () {
      return this.$vnode.data.staticClass;
    },
    className () {
      return this.$vnode.data.class;
    }
  }
};
</script>
<style lang="scss">
.sf-overlay {
  position: fixed;
  top: var(--overlay-top, 0);
  right: var(--overlay-right, 0);
  bottom: var(--overlay-bottom, 0);
  left: var(--overlay-left, 0);
  z-index: var(--overlay-z-index, 1);
  background: var(--overlay-background, rgba(var(--c-gray-base), 0.7));
}
</style>
