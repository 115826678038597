<template>
  <div class="sf-loader">
    <transition name="fade" mode="out-in">
      <div v-if="loading" class="sf-loader__overlay" :style="backgroundStyle">
        <!--@slot Use this slot to replace the loader -->
        <slot name="loader">
          <div class="sf-loader__spinner" :style="spinnerStyle" />
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SfLoader',
  props: {
    loading: {
      default: true,
      type: Boolean
    },
    size: {
      default: null,
      type: [Number, null]
    },
    weight: {
      default: null,
      type: [Number, null]
    },
    background: {
      default: '',
      type: String
    },
    color: {
      default: '',
      type: String
    }
  },
  computed: {
    backgroundStyle () {
      if (!this.background) {
        return null
      }

      return `background: ${this.background}`
    },
    spinnerStyle () {
      const styles = []

      if (this.color) {
        styles.push(`--loader-spinner-stroke: ${this.color}`)
      }

      if (this.weight) {
        styles.push(`--spiner-weight: ${this.weight}px`)
      }

      if (this.size) {
        styles.push(`--spiner-size: ${this.size}px`)
      }

      return styles.join('; ')
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/components/atoms/SfLoader.scss";

.sf-loader__spinner {
  display: block;
  width: var(--spiner-size);
  height: var(--spiner-size);
  position: relative;

  &:before, &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: var(--spiner-weight) solid var(--loader-spinner-stroke, var(--c-primary));
    border-radius: 50%;
    content: ' ';
  }

  &:before {
    opacity: 0.5;
  }

  &:after {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
</style>
