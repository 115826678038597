export const SN_SEARCH = 'search-result'
export const SEARCH_SET_RESULT_PRODUCTS = SN_SEARCH + '/SEARCH_SET_RESULT_PRODUCTS'
export const SEARCH_ADD_RESULT_PRODUCTS = SN_SEARCH + '/SEARCH_ADD_RESULT_PRODUCTS'
export const SEARCH_SET_RESULT_CATEGORIES = SN_SEARCH + '/SEARCH_SET_RESULT_CATEGORIES'
export const SEARCH_SET_SEARCH_FILTERS = SN_SEARCH + '/SEARCH_SET_SEARCH_FILTERS'
export const SEARCH_SET_RESULT_STATS = SN_SEARCH + '/SEARCH_SET_RESULT_STATS'
export const SET_CURRENT_CATEGORY = `${SN_SEARCH}/SET_CURRENT_CATEGORY`
export const SET_SEARCH_TOTAL_PRODUCTS = `${SN_SEARCH}/SET_SEARCH_TOTAL_PRODUCTS`
export const SET_LAST_SEARCH_QUERY_HASH = `${SN_SEARCH}/SET_LAST_SEARCH_QUERY_HASH`
export const SET_LAST_AUTOCOMPLETE_HASH = `${SN_SEARCH}/SET_LAST_SEARCH_AUTOCOMPLETE_HASH`
export const SET_FILTER_PRICE = SN_SEARCH + '/SET_FILTER_PRICE'
export const SET_META_TAGS = SN_SEARCH + '/SET_META_TAGS'
export const SET_AUTOCOMPLETE_RESULT = SN_SEARCH + '/SET_AUTOCOMPLETE_RESULT'
export const SET_LOADING_AUTOCOMPLETE = SN_SEARCH + '/SET_LOADING_AUTOCOMPLETE'
export const SET_LOADING_RESULT_PAGE = SN_SEARCH + '/SET_LOADING_RESULT_PAGE'
export const SET_LOADED_RESULT_PAGE = SN_SEARCH + '/SET_LOADED_RESULT_PAGE'
export const SET_AVAILABLE_FILTERS = SN_SEARCH + '/SET_AVAILABLE_FILTERS'
export const SET_FILTER_LABELS = SN_SEARCH + '/SET_FILTER_LABELS'
export const SET_ACTIVE_FILTER = SN_SEARCH + '/SET_ACTIVE_FILTER'
