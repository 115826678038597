import config from 'config'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';
import { groups, sendToLogs } from 'theme/helpers/web-logging';

const createCartItemForUpdate = (clientItem: any, serverItem: any, updateIds = false, mergeQty = false): CartItem => {
  try {
    const sku = clientItem.parentSku && config.cart.setConfigurableProductOptions ? clientItem.parentSku : clientItem.sku
    const cartItemPayload = {
      sku,
      ...((serverItem && serverItem.item_id) ? { item_id: serverItem.item_id } : {}),
      qty: mergeQty ? (clientItem.qty + (serverItem?.qty || 0)) : clientItem.qty,
      product_option: clientItem.product_option
    } as any as CartItem

    const extension_attributes = cartItemPayload?.extension_attributes || {}

    if (clientItem.markdown_id) {
      extension_attributes.markdown_id = clientItem.markdown_id
    }

    if (clientItem.uiModifiedAt) {
      extension_attributes.ui_modified_at = clientItem.uiModifiedAt
    }

    const cartItem = {
      ...cartItemPayload,
      extension_attributes
    }

    if (updateIds && serverItem.quote_id && serverItem.item_id) {
      return {
        ...cartItem,
        quoteId: serverItem.quote_id,
        item_id: serverItem.item_id
      }
    }

    return cartItem
  } catch (e) {
    sendToLogs(
      groups.Cart,
      'syncHandler:createCartItemForUpdate:error',
      { error: e }
    )

    throw new Error(e)
  }
}

export default createCartItemForUpdate
