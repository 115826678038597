<template>
  <th class="sf-table__header">
    <slot />
  </th>
</template>
<script>
export default {
  name: 'SfTableHeader'
};
</script>
