import config from 'config';
import * as DataResolver from './types/DataResolver';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task'

const subscribe = (email: string, storeCode: string): Promise<Task> => {
  const query = `?storeCode=${storeCode}`;
  const url = `${processURLAddress(getApiEndpointUrl(config.newsletter, 'endpoint'))}${query}`;
  const body = JSON.stringify({
    subscriber: {
      subscriber_email: email
    }
  })

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body
    }
  })
}
export const NewsletterService: DataResolver.NewsletterService = {
  subscribe
}
