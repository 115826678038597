import { transliteration } from 'theme/helpers/translit'
import { Logger } from '@vue-storefront/core/lib/logger'
import { codes, deliveryMethods, newPostMethods, shippingDefaults } from '$modules/shipping/config';

const cityNameRemap = {
  [`Кам'янське (Дніпродзержинськ)`]: 'Кам\'янське',
  'Дніпро (Дніпропетровськ)': 'Дніпро'
}

export const findCityByName = (cities = [], cityName) => {
  const name = cityNameRemap[cityName] || cityName
  const find = (cities || []).find(i => (i.name === name) || transliteration(i.name) === transliteration(name))

  return find || null
}

export const handleDeliveryLoad = async (
  addressInformation
) => {
  try {
    if (!addressInformation ||
      !addressInformation.shipping_method_code ||
      !addressInformation.shipping_address ||
      !addressInformation.shipping_address.extension_attributes) {
      return null
    }

    const cityId = addressInformation?.shipping_address?.extension_attributes?.city_id
    const shopId = addressInformation?.extension_attributes?.shop_id

    const isNova = addressInformation.shipping_method_code.toUpperCase() === codes.newPost

    if (!isNova && (!shopId || !cityId)) {
      return null
    }

    return {
      shippingAddress: addressInformation.shipping_address,
      shippingMethodCode: addressInformation.shipping_method_code,
      cityId,
      shopId,
      newPost: addressInformation.shipping_address?.extension_attributes?.np_warehouse
    }
  } catch (e) {
    Logger.error(e, 'handleDeliveryLoad')()
  }

  return null
}

const getMethod = (method) => {
  if (newPostMethods.includes(method)) {
    return codes.newPost
  }

  if (deliveryMethods.includes(method)) {
    return codes.delivery
  }

  return codes.pickup
}

export const buildShippingInformationState = (
  result,
  cities,
  shops,
  newPost
) => {
  const {
    shippingAddress,
    shippingMethodCode,
    cityId,
    shopId
  } = result

  const {
    street,
    extension_attributes
  } = shippingAddress

  const {
    latitude,
    longitude,
    available_delivery_types
  } = extension_attributes

  const cityDetails = cities.find(city => city.id === cityId)
  const shop = shops.find(shop => shop.id === shopId)

  const shippingMethodUppercase = shippingMethodCode.toUpperCase()
  const shippingMethod = deliveryMethods.includes(shippingMethodUppercase) ? codes.delivery : shippingMethodUppercase;
  const currentShippingMethod = shop?.delivery_methods?.map(s => s.key).find(s => s.includes(shippingMethod))

  if (!currentShippingMethod) return null

  const getAddress = (street) => {
    const proceed = street.join(', ')

    const prepareStreet = proceed.split(',').map(s => s.trim())

    const addressLabel = prepareStreet.join(', ')
    const addressHouse = prepareStreet.pop()
    const addressStreet = prepareStreet.join(', ')

    return {
      label: addressLabel,
      address: {
        street: addressStreet,
        house: addressHouse
      },
      coordinates: [
        +longitude,
        +latitude
      ],
      category: 'adr_address',
      settlement: cityDetails.name
    }
  }

  const isDelivery = deliveryMethods.includes(shippingMethod?.toUpperCase())
  const method = getMethod(shippingMethod?.toUpperCase())

  const group = available_delivery_types || []

  return {
    ...shippingDefaults,
    city: cityDetails || null,
    method: method,
    shop: shop || null,
    type: shippingMethod?.toUpperCase() || null,
    group,
    address: isDelivery ? getAddress(street) : null,
    ...newPost
  }
}

export const getCoords = (current) => {
  if (newPostMethods.includes(current?.method?.toUpperCase())) {
    return [+current?.npShop?.longitude, +current?.npShop?.latitude]
  }

  if (deliveryMethods.includes(current?.method?.toUpperCase())) {
    return current?.address?.coordinates || []
  }

  return [current?.shop?.long, current?.shop?.lat]
}

export const getAddress = (current) => {
  if (newPostMethods.includes(current?.method?.toUpperCase())) {
    return current?.npShop?.description
  }

  if (deliveryMethods.includes(current?.method?.toUpperCase())) {
    return current?.address?.label
  }

  return current?.shop?.address
}

export const getNPExtend = (current) => {
  return {
    'ref': current?.npShop?.ref,
    'number': current?.npShop?.number,
    'city_ref': current?.npCity?.ref,
    'type': current?.npType
  }
}

export const buildShippingInformationPayload = (current, currentTimeslot) => {
  const address = getAddress(current)

  if (!address) return null

  const [longitude, latitude] = getCoords(current)

  const result: any = {
    addressInformation: {
      billingAddress: {
        city: current?.city?.name,
        country_id: 'UA',
        firstname: '',
        lastname: '',
        telephone: '',
        postcode: '',
        street: [address]
      },
      shippingAddress: {
        city: current?.npCity?.description || current?.city?.name,
        country_id: 'UA',
        firstname: '',
        lastname: '',
        telephone: '',
        postcode: '',
        street: [address],
        extension_attributes: {
          latitude,
          longitude,
          city_id: current?.city?.id
        }
      },
      shipping_method_code: current?.type || null,
      shipping_carrier_code: 'varus',
      extension_attributes: {
        shop_id: current?.shop?.id
      }
    }
  }

  if (current?.group?.length) {
    result.addressInformation.shippingAddress.extension_attributes.available_delivery_types = current?.group
  }

  if (currentTimeslot && currentTimeslot.id) {
    result.addressInformation.shipping_method_code = currentTimeslot?.delivery_method?.toLowerCase() ||
      result.shipping_method_code

    result.addressInformation.shippingAddress.extension_attributes.timeslot = {
      id: currentTimeslot.id,
      date: currentTimeslot.date,
      value: currentTimeslot.originalValue || currentTimeslot.value,
      price: currentTimeslot.price,
      polygon_id: currentTimeslot.polygon_id
    }
  }

  if (current.npShop) {
    result.addressInformation.shippingAddress.extension_attributes.np_warehouse = getNPExtend(current)
  }

  return result
}

export const validationShippingVarus = (current, shippingDetails) => {
  const isDeliveryExternal = deliveryMethods.includes(shippingDetails?.shippingMethodCode?.toUpperCase())
  const isDeliveryCurrent = deliveryMethods.includes(current?.method?.toUpperCase())

  return (
    current?.city?.id === shippingDetails?.cityId &&
    current?.shop?.id === shippingDetails?.shopId &&
    isDeliveryExternal === isDeliveryCurrent &&
    (
      isDeliveryCurrent
        ? current?.address?.label === shippingDetails?.shippingAddress.street.join(', ')
        : true
    )
  )
}

export const validationShippingNova = (current, shippingDetails) => {
  return (
    current?.npShop?.ref === shippingDetails?.shippingAddress?.extension_attributes?.np_warehouse?.ref &&
    current?.npCity?.ref === shippingDetails?.shippingAddress?.extension_attributes?.np_warehouse?.city_ref
  )
}

export const groupRemap = (types, methods) => {
  const remapMethods = methods.map(m => JSON.parse(m))

  return types.map((i) => {
    const last_modified = remapMethods.find((j) => j.key === i.type?.toUpperCase())?.last_modified || null

    return {
      ...i,
      last_modified
    }
  })
}
