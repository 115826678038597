import { MutationTree } from 'vuex'
import SearchState from '../types/SearchResultState'
import * as types from './mutation-types'
import { prepareCategoryProduct } from 'theme/helpers';

const mutations: MutationTree<SearchState> = {
  [types.SEARCH_SET_RESULT_PRODUCTS] (state, { products }) {
    state.searchResultProducts = products.map(prepareCategoryProduct);
  },
  [types.SEARCH_ADD_RESULT_PRODUCTS] (state, { products }) {
    state.searchResultProducts.push(...products.map(prepareCategoryProduct));
  },
  [types.SEARCH_SET_RESULT_CATEGORIES] (state, payload) {
    state.categories = [...payload]
  },
  [types.SET_CURRENT_CATEGORY] (state, category) {
    state.currentCategory = category
  },
  [types.SEARCH_SET_RESULT_STATS] (state, stats) {
    state.resultStats = stats;
  },
  [types.SET_SEARCH_TOTAL_PRODUCTS] (state, total) {
    state.searchTotalProducts = total;
  },
  [types.SET_LAST_SEARCH_QUERY_HASH] (state, queryHash) {
    state.lastSearchQueryHash = queryHash;
  },
  [types.SET_LAST_AUTOCOMPLETE_HASH] (state, queryHash) {
    state.autocompleteHash = queryHash;
  },
  [types.SET_FILTER_PRICE] (state, filter) {
    state.filterPrice = filter
  },
  [types.SET_META_TAGS] (state, tags) {
    state.metaTags = tags
  },
  [types.SET_AUTOCOMPLETE_RESULT] (state, result) {
    state.autocomplete = result
  },
  [types.SET_LOADING_AUTOCOMPLETE] (state, result) {
    state.loading.autocomplete = result
  },
  [types.SET_LOADING_RESULT_PAGE] (state, result) {
    state.loading.resultPage = result
  },
  [types.SET_LOADED_RESULT_PAGE] (state, result) {
    state.loaded.resultPage = result
  },
  [types.SET_AVAILABLE_FILTERS] (state, result) {
    state.availableFilters = result
  },
  [types.SET_FILTER_LABELS] (state, result) {
    state.filterLabels = result
  },
  [types.SET_FILTER_LABELS] (state, result) {
    state.filterLabels = result
  },
  [types.SET_ACTIVE_FILTER] (state, result) {
    state.activeFilters = { ...(result || {}) }
  }
};

export default mutations
