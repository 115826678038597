const productActions = {
  async getProductVariant ({ dispatch }, { serverItem }) {
    try {
      const options = await dispatch('findProductOption', { serverItem })
      const singleProduct = await dispatch('product/single', { options }, { root: true })

      return {
        ...singleProduct,
        server_item_id: serverItem.item_id,
        qty: serverItem.qty,
        server_cart_id: serverItem.quote_id,
        product_option: serverItem.product_option || singleProduct.product_option,
        extension_attributes: serverItem.extension_attributes || {}
      }
    } catch (e) {
      return null
    }
  }
}

export default productActions
