import { ActionTree } from 'vuex';
import ImageState from 'theme/store/photogallery/types/ImageState';
import RootState from '@vue-storefront/core/types/RootState'
import { PhotogalleryService } from 'theme/store/photogallery/data-resolver/PhotogalleryService';

const actions: ActionTree<ImageState, RootState> = {
  downloadFile (context, params) {
    return PhotogalleryService.downloadFile(params);
  }
}

export default actions
