import { required, email } from 'vuelidate/lib/validators'
import { Logger } from '@vue-storefront/core/lib/logger'

/**
 * Newsletter subscription form component.
 *
 * #### Data
 * - `email: String` - email that will be used for subscription, validated with vuelidate (email, required)
 *
 * #### Methods
 * - `subscribe(success?: Function, failure?: Function)` dispatches `newsletter/subscribe` with `email` data property. `success(res)` and `failure(err)` are callback functions called depending on subscription result and contain response info or error.
 *
 */
export default {
  name: 'NewsletterSubscribe',
  data () {
    return {
      email: ''
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    async subscribe () {
      if (this.$v.$invalid) return;

      try {
        const { result } = await this.$store.dispatch('newsletter/subscribe', this.email);
        return result;
      } catch (err) {
        Logger.error(err, 'newsletter')();
      }
    },
    async onLoggedIn () {
      this.email = this.$store.state.user.current.email
    }
  },
  beforeMount () {
    // the user might already be logged in, so check the subscription status
    if (this.$store.state.user.current) this.onLoggedIn()
    this.$bus.$on('user-after-loggedin', this.onLoggedIn)
  },
  beforeDestroy () {
    this.$bus.$off('user-after-loggedin', this.onLoggedIn)
  }
}
