import { SN_CATEGORY } from '$modules/category/store/mutation-types';

export const SN_VARUS_CAFE = 'category'
export const CATEGORY_UPD_CATEGORIES = SN_VARUS_CAFE + '/UPD_CATEGORIES'
export const CATEGORY_UPD_CURRENT_CATEGORY_PATH = SN_VARUS_CAFE + '/UPD_CURRENT_CATEGORY_PATH'
export const CATEGORY_UPD_SEARCH_PRODUCT_QUERY = SN_VARUS_CAFE + '/UPD_SEARCH_PRODUCT_QUERY'
export const CATEGORY_ADD_AVAILABLE_FILTER = `${SN_VARUS_CAFE}/ADD_AVAILABLE_FILTER`
export const CATEGORY_REMOVE_FILTERS = SN_VARUS_CAFE + '/REMOVE_FILTERS'
export const CATEGORY_SET_SEARCH_OPTIONS = `${SN_VARUS_CAFE}/SET_SEARCH_OPTIONS`
export const CATEGORY_MERGE_SEARCH_OPTIONS = `${SN_VARUS_CAFE}/MERGE_SEARCH_OPTIONS`
export const SET_PRODUCTS_IS_LOADING = `${SN_VARUS_CAFE}/SET_PRODUCTS_IS_LOADING`
export const SET_IS_ADDITIONAL_LOADING = `${SN_VARUS_CAFE}/SET_IS_ADDITIONAL_LOADING`
export const SET_CATEGORIES_IS_LOADING = `${SN_VARUS_CAFE}/SET_CATEGORIES_IS_LOADING`
export const SET_PRODUCTS = `${SN_VARUS_CAFE}/SET_PRODUCTS`
export const ADD_PRODUCTS = `${SN_VARUS_CAFE}/ADD_PRODUCTS`
export const SET_PRODUCT_COUNTS_BY_CATEGORY = `${SN_VARUS_CAFE}/SET_PRODUCT_COUNTS_BY_CATEGORY`
export const SET_CATEGORY_DESCRIPTION = `${SN_VARUS_CAFE}/SET_CATEGORY_DESCRIPTION`
export const SET_SAMPLE_SPECIAL_OFFERS = `${SN_VARUS_CAFE}/SET_SAMPLE_SPECIAL_OFFERS`
export const SET_CATEGORY_PROMOTION_BANNERS_IDS = `${SN_VARUS_CAFE}/SET_CATEGORY_PROMOTION_BANNERS_IDS`
export const SET_CHILD_CATEGORIES = `${SN_CATEGORY}/SET_CHILD_CATEGORIES`
export const SET_TOP_BANNERS = `${SN_VARUS_CAFE}/SET_TOP_BANNERS`
export const SET_MIDDLE_BANNERS = `${SN_VARUS_CAFE}/SET_MIDDLE_BANNERS`

export const SET_AVAILABLE_PRODUCTS_STATUS = `${SN_VARUS_CAFE}/SET_AVAILABLE_PRODUCTS_STATUS`
