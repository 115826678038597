import { Filters } from '@vue-storefront/core/modules/catalog-next/types/Category';
import { getSystemFilterNames } from 'theme/helpers/filterHelpers';
import config from 'config';
import { products } from '$modules/brand/config';
import { SearchQuery } from 'storefront-query-builder';

export function buildFilterProductsQuery ({
  currentCategory,
  chosenFilters = {},
  defaultFilters = null,
  queryText = '',
  shopId = null,
  route = null
}) {
  const filters = getFilters(defaultFilters, currentCategory);

  const filterQr = baseFilterProductsQuery({
    filters
  })

  if (queryText.length) {
    filterQr.setSearchText(queryText)
  }

  // add chosen filters
  for (const code of Object.keys(chosenFilters)) {
    let filter = chosenFilters[code]
    if (Array.isArray(filter) && filter[0].type === config.attributes.filterIsPromo.type) {
      filter = config.attributes.filterIsPromo.type
    }

    const attributeCode = Array.isArray(filter) ? filter[0].attribute_code : filter.attribute_code
    if (Array.isArray(filter) && attributeCode !== 'price') {
      const values = filter.map(filter => filter.id)
      filterQr.applyFilter({ key: attributeCode, value: { 'in': values }, scope: 'catalog' })
    } else if (filter === config.attributes.filterIsPromo.type) {
      filterQr.applyFilter({ key: filter, value: { 'in': shopId ? [shopId] : null }, scope: 'default' })
    } else if (attributeCode !== 'price') {
      filterQr.applyFilter({ key: attributeCode, value: { 'eq': filter.id }, scope: 'catalog' })
    } else if (attributeCode === 'price') {
      filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
        value: {
          'gte': route.query.price.split('-')[0] || filter[0].price_min.value,
          'lte': route.query.price.split('-')[1] || filter[0].price_max.value
        },
        scope: 'default' })
    } else { // multi should be possible filter here?
      const rangeqr = {}
      const filterValues = Array.isArray(filter) ? filter : [filter]
      filterValues.forEach(singleFilter => {
        if (singleFilter.from) rangeqr['gte'] = singleFilter.from
        if (singleFilter.to) rangeqr['lte'] = singleFilter.to
      })
      filterQr.applyFilter({ key: attributeCode, value: rangeqr, scope: 'catalog' })
    }
  }

  filterQr.addAvailableFilter({ field: 'has_promotion_in_stores', scope: 'catalog' })
  filterQr.addAvailableFilter({ field: 'category_ids', scope: 'catalog' })
  filterQr.addAvailableFilter({ field: 'price',
    scope: 'catalog',
    options: {
      'shop_id': shopId,
      'version': '2'
    } })
  return filterQr
}

export function baseFilterProductsQuery ({ filters = [] }) { // TODO add aggregation of color_options and size_options fields
  const searchProductQuery = new SearchQuery()
  searchProductQuery
    .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
    .applyFilter({ key: 'status', value: { 'in': [0, 1] } }) /* 2 = disabled, 4 = out of stock */

  // Add available catalog filters
  for (const attrToFilter of filters) {
    if (attrToFilter === 'price') continue
    searchProductQuery.addAvailableFilter({ field: attrToFilter, scope: 'catalog' })
  }

  return searchProductQuery
}

export const getFiltersFromQuery = ({ filtersQuery = {}, availableFilters = {} } = {}): { filters: Filters } => {
  const searchQuery = {
    filters: {}
  }
  Object.keys(filtersQuery).forEach(filterKey => {
    const filter = availableFilters[filterKey]
    let queryValue = filtersQuery[filterKey]
    if (filterKey === 'block_issued_recipes') {
      searchQuery.filters[filterKey] = [{
        id: queryValue,
        label: queryValue,
        type: filterKey,
        attribute_code: filterKey
      }]
      return
    }
    if (!filter) return
    // keep original value for system filters - for example sort
    if (getSystemFilterNames.includes(filterKey)) {
      searchQuery[filterKey] = queryValue
    } else {
      queryValue = [].concat(filtersQuery[filterKey])
      queryValue.map(singleValue => {
        const variant = Array.isArray(filter) ? filter.find(filterVariant => filterVariant.id === singleValue) : filter
        if (!variant) return
        if (!Array.isArray(searchQuery.filters[filterKey])) searchQuery.filters[filterKey] = []
        searchQuery.filters[filterKey].push({ ...variant, attribute_code: filterKey })
      })
    }
  })
  return searchQuery
}

export const calcQueryStart = ({ areFiltersEmpty, routerQuery, pageSize }) =>
  areFiltersEmpty ? 0 : ((Number(routerQuery.page) || 1) - 1) * pageSize;

const getFilters = (defaultFilters, currentCategory) => {
  let filters;
  if (defaultFilters !== null) {
    filters = defaultFilters;
  } else if (currentCategory.filterattributes) {
    filters = currentCategory.filterattributes.split(',');
  } else {
    filters = products.defaultFilters;
  }

  const index = filters.indexOf('pim_brand_id');
  if (index > -1) {
    filters.splice(index, 1);
  }

  return filters;
}

export function buildFilterAvailableQuery (currentCategory, defaultFilters = null) {
  const filters = currentCategory?.filterattributes ? currentCategory.filterattributes?.split(',') : config.products.defaultFilters

  if (!filters.includes('pim_brand_id')) {
    filters.unshift('pim_brand_id')
  }

  return baseFilterProductsQuery({ filters: defaultFilters == null ? filters : defaultFilters })
}
